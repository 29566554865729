/* eslint-disable import/no-cycle */
import { action, makeObservable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import {
  Country,
  CountryService,
  Euronorm,
  FuelType,
  IdentifierStatus,
  InvoiceStatus,
  PaymentCardStatus,
  CreditCardType,
  ProductType,
  Service,
  VehicleSize,
  ProductService,
  Co2Class
} from '../../swagger/api';
import AmountInFilter from '../../features/table-filter/components/AmountInFilter';
import RangePicker from '../../common-components/RangePicker';
import { FilterObject } from '../../Types';
import isTollAppOrderOpen from '../../utils/isTollAppOrderOpen';

export default class ContentInitilizerStore extends BaseStore {
  euronormMenuItems: FilterObject[] = [
    {
      text: '',
      filter: ''
    }
  ];

  getAndSetAllCountries = () => {
    const promise = CountryService.getAll().then((val: Country[]) => {
      this.rootStore.contentStore.genericContentStore.setAllCountries(val);
    });
    return promise;
  };

  getAndSetLocalServices = () => {
    const promise = ProductService.getListProductVisibleSelfServiceAsync().then(
      (val: Service[]) => {
        this.rootStore.contentStore.genericContentStore.setLocalServices(val);
      }
    );
    return promise;
  };

  setEuronormInit = () => {
    this.euronormMenuItems = [
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.EEV)?.content!,
        filter: Euronorm.EEV
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro1)?.content!,
        filter: Euronorm.Euro1
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro2)?.content!,
        filter: Euronorm.Euro2
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro3)?.content!,
        filter: Euronorm.Euro3
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro4)?.content!,
        filter: Euronorm.Euro4
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro5)?.content!,
        filter: Euronorm.Euro5
      },
      {
        text: this.rootStore.contentStore.cmsStore.getContent(Euronorm.Euro6)?.content!,
        filter: Euronorm.Euro6
      }
    ];
  };

  setProductTableColumnNamesInit = () => {
    this.rootStore.productStore.productTableStore.productTableColumns = [
      'Product&Id',
      'Reference',
      'Country',
      'licensePlate',
      'ChassisNr',
      'VehicleType',
      'EmissionType',
      'Propellant',
      'MaxPermissibleWeightColumn',
      'Co2ClassColumn',
      'GreenDiscount',
      'OeresundCommercialServiceAgreementOnProductCard',
      'BizzUnderConversionColumn',
      'Changes',
      'DrivableInNorway'
    ];
    this.rootStore.productStore.productTableStore.defaultColumns = [
      'Product&Id',
      'Reference',
      'licensePlate',
      'GreenDiscount',
      'Changes',
      'burger'
    ];
    this.rootStore.productStore.productTableStore.activeColumns =
      this.rootStore.productStore.productTableStore.defaultColumns;
    this.addOneTimeAnprContentToProductTableColumns();
  };

  setTransactionsFilterContentInit = () => {
    this.rootStore.filterStore.transactionFilterStore.allMenus = {
      euronorms: {
        title: this.rootStore.contentStore.cmsStore.getContent('EmissionType')?.content!,
        menuItems: this.euronormMenuItems
      },
      dates: {
        title: this.rootStore.contentStore.cmsStore.getContent('Date')?.content!,
        component: RangePicker
      },
      amounts: {
        title: this.rootStore.contentStore.cmsStore.getContent('Amount')?.content!,
        component: AmountInFilter
      }
    };
  };

  setInvoiceFilterContentInit = () => {
    this.rootStore.filterStore.invoiceFilterStore.allMenus = {
      dates: {
        title: this.rootStore.contentStore.cmsStore.getContent('Date')?.content!,
        component: RangePicker
      },
      invoiceStatus: {
        title: this.rootStore.contentStore.cmsStore.getContent(InvoiceStatus.MissingPayment)
          ?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(InvoiceStatus.MissingPayment)
              ?.content!,
            filter: InvoiceStatus.MissingPayment
          }
        ]
      }
    };
  };

  setCardPaymentFilterContentInit = () => {
    this.rootStore.filterStore.cardPaymentFilterStore.allMenus = {
      dates: {
        title: this.rootStore.contentStore.cmsStore.getContent('Date')?.content!,
        component: RangePicker
      }
    };
  };

  setOtherTransactionsFilterContent = () => {
    this.rootStore.filterStore.otherTransactionFilterStore.allMenus = {
      dates: {
        title: this.rootStore.contentStore.cmsStore.getContent('Date')?.content!,
        component: RangePicker
      }
    };
  };

  setPaymentCardsFilterContent = () => {
    this.rootStore.filterStore.paymentCardsFilterStore.allMenus = {
      isMyCards: {
        title: this.rootStore.contentStore.cmsStore.getContent('MyCards')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('MyCards')?.content!,
            filter: true
          }
        ]
      },
      cardStatus: {
        title: this.rootStore.contentStore.cmsStore.getContent('PaymentCardStatus')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(PaymentCardStatus.ValidCard)
              ?.content!,
            filter: PaymentCardStatus.ValidCard
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(PaymentCardStatus.ExpiredCard)
              ?.content!,
            filter: PaymentCardStatus.ExpiredCard
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(PaymentCardStatus.BlockedCard)
              ?.content!,
            filter: PaymentCardStatus.BlockedCard
          }
        ]
      },
      cardType: {
        title: this.rootStore.contentStore.cmsStore.getContent('PaymentCardType')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(CreditCardType.DANKORT)?.content!,
            filter: CreditCardType.DANKORT
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(CreditCardType.MC)?.content!,
            filter: CreditCardType.MC
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(CreditCardType.VISA)?.content!,
            filter: CreditCardType.VISA
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(CreditCardType.VISA_DK)?.content!,
            filter: CreditCardType.VISA_DK
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(CreditCardType.VISA_ELEC)
              ?.content!,
            filter: CreditCardType.VISA_ELEC
          }
        ]
      },
      greyMainMenuSubTitles: {
        title: 'greyMainMenuSubTitles',
        greyTitles: [
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Show')?.content!,
            items: ['isMyCards']
          }
        ]
      }
    };
  };

  setProductFilterInit = () => {
    this.rootStore.filterStore.productFilterStore.allMenus = {
      filteringViews: {
        title: '',
        menuItems: [
          {
            text:
              this.rootStore.contentStore.cmsStore.getContent('ConvertingToTollingBox')?.content ??
              '',
            filter: true
          }
        ]
      },
      identifierStatuses: {
        title: this.rootStore.contentStore.cmsStore.getContent('Status')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusActive')?.content!,
            filter: IdentifierStatus.Active
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusInactive')
              ?.content!,
            filter: IdentifierStatus.Inactive
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusBlocked')?.content!,
            filter: [IdentifierStatus.Blocked]
          }
        ]
      },
      productTypes: {
        title: this.rootStore.contentStore.cmsStore.getContent('ProductType')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('Bizz')?.content!,
            filter: ProductType.BizzCommercial
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('PayByPlate')?.content!,
            filter: ProductType.LicensePlate
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(ProductType.TollingBox)?.content!,
            filter: ProductType.TollingBox
          }
        ]
      },
      specialAgreementIds: {
        title: this.rootStore.contentStore.cmsStore.getContent('LocalService')?.content!,
        menuItems: this.rootStore.contentStore.genericContentStore.localServices.map(
          (localService: Service) => {
            const localServiceSystemId = this.rootStore.contentStore.cmsStore.getContent(
              localService.name!
            )?.content!;
            const menuItem = {
              text: this.rootStore.contentStore.cmsStore.getContent(
                `${localServiceSystemId}InFilter`
              )?.content!,
              filter: localService.id!
            };
            return menuItem;
          }
        )
      },
      euronorms: {
        title: this.rootStore.contentStore.cmsStore.getContent('EmissionType')?.content!,
        menuItems: this.euronormMenuItems
      },
      vehicleSizes: {
        title: this.rootStore.contentStore.cmsStore.getContent('VehicleType')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.VehicleOver3500kg)
              ?.content!,
            filter: VehicleSize.VehicleOver3500kg
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.BusOver3500kg)
              ?.content!,
            filter: VehicleSize.BusOver3500kg
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.VehicleUnder3500kg)
              ?.content!,
            filter: VehicleSize.VehicleUnder3500kg
          }
        ]
      },
      fuelTypes: {
        title: this.rootStore.contentStore.cmsStore.getContent('Propellant')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Gasoline)?.content!,
            filter: FuelType.Gasoline
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.GasolineHybrid)
              ?.content!,
            filter: FuelType.GasolineHybrid
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Hydrogen)?.content!,
            filter: FuelType.Hydrogen
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Diesel)?.content!,
            filter: FuelType.Diesel
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.DieselHybrid)?.content!,
            filter: FuelType.DieselHybrid
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Electric)?.content!,
            filter: FuelType.Electric
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.LNG)?.content!,
            filter: FuelType.LNG
          }
        ]
      },
      maxPermissibleWeight: {
        title: this.rootStore.contentStore.cmsStore.getContent('MaxPermissibleWeightFilterTitle')
          ?.content!,
        component: AmountInFilter
      },
      co2Classes: {
        title: this.rootStore.contentStore.cmsStore.getContent('Co2ClassFilterTitle')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(Co2Class.Class1)?.content!,
            filter: Co2Class.Class1
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(Co2Class.Class2)?.content!,
            filter: Co2Class.Class2
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(Co2Class.Class3)?.content!,
            filter: Co2Class.Class3
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(Co2Class.Class4)?.content!,
            filter: Co2Class.Class4
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(Co2Class.Class5)?.content!,
            filter: Co2Class.Class5
          }
        ]
      },
      countryIds: {
        title: this.rootStore.contentStore.cmsStore.getContent('Country')?.content!,
        menuItems: this.rootStore.contentStore.genericContentStore
          .getCountriesWithQuery({ allowShipping: true })
          .map((country: Country) => {
            const menuItem = {
              text: this.rootStore.contentStore.cmsStore.getContent(country.name!)?.content!,
              filter: country.id!
            };
            return menuItem;
          })
      },
      hasGreenDiscount: {
        title: this.rootStore.contentStore.cmsStore.getContent('GreenDiscount')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('HasGreenDiscount')?.content!,
            filter: true
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('DoNotHaveGreenDiscount')
              ?.content!,
            filter: false
          }
        ]
      },
      greyMainMenuSubTitles: {
        title: 'greyMainMenuSubTitles',
        greyTitles: [
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Show')?.content!,
            items: ['filteringViews']
          },
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Product')?.content!,
            items: ['identifierStatuses', 'productTypes', 'specialAgreementIds']
          },
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Vehicle')?.content!,
            items: [
              'euronorms',
              'vehicleSizes',
              'fuelTypes',
              'maxPermissibleWeight',
              'co2Classes',
              'countryIds',
              'hasGreenDiscount'
            ]
          }
        ]
      }
    };
    this.addOneTimeAnprContentToProductFilter();
    this.addTollAppToProductFilter();
  };

  setBizzesToConvertFilterInit = () => {
    this.rootStore.filterStore.bizzesForConversionFilterStore.allMenus = {
      identifierStatuses: {
        title: this.rootStore.contentStore.cmsStore.getContent('Status')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusActive')?.content!,
            filter: IdentifierStatus.Active
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusInactive')
              ?.content!,
            filter: IdentifierStatus.Inactive
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('ProductStatusBlocked')?.content!,
            filter: [IdentifierStatus.Blocked]
          }
        ]
      },
      specialAgreementIds: {
        title: this.rootStore.contentStore.cmsStore.getContent('LocalService')?.content!,
        menuItems: this.rootStore.contentStore.genericContentStore.localServices.map(
          (localService: Service) => {
            const localServiceSystemId = this.rootStore.contentStore.cmsStore.getContent(
              localService.name!
            )?.content!;
            const menuItem = {
              text: this.rootStore.contentStore.cmsStore.getContent(
                `${localServiceSystemId}InFilter`
              )?.content!,
              filter: localService.id!
            };
            return menuItem;
          }
        )
      },
      euronorms: {
        title: this.rootStore.contentStore.cmsStore.getContent('EmissionType')?.content!,
        menuItems: this.euronormMenuItems
      },
      vehicleSizes: {
        title: this.rootStore.contentStore.cmsStore.getContent('VehicleType')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.VehicleOver3500kg)
              ?.content!,
            filter: VehicleSize.VehicleOver3500kg
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.BusOver3500kg)
              ?.content!,
            filter: VehicleSize.BusOver3500kg
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(VehicleSize.VehicleUnder3500kg)
              ?.content!,
            filter: VehicleSize.VehicleUnder3500kg
          }
        ]
      },
      fuelTypes: {
        title: this.rootStore.contentStore.cmsStore.getContent('Propellant')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Gasoline)?.content!,
            filter: FuelType.Gasoline
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.GasolineHybrid)
              ?.content!,
            filter: FuelType.GasolineHybrid
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Hydrogen)?.content!,
            filter: FuelType.Hydrogen
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Diesel)?.content!,
            filter: FuelType.Diesel
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.DieselHybrid)?.content!,
            filter: FuelType.DieselHybrid
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.Electric)?.content!,
            filter: FuelType.Electric
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent(FuelType.LNG)?.content!,
            filter: FuelType.LNG
          }
        ]
      },
      countryIds: {
        title: this.rootStore.contentStore.cmsStore.getContent('Country')?.content!,
        menuItems: this.rootStore.contentStore.genericContentStore
          .getCountriesWithQuery({ allowShipping: true })
          .map((country: Country) => {
            const menuItem = {
              text: this.rootStore.contentStore.cmsStore.getContent(country.name!)?.content!,
              filter: country.id!
            };
            return menuItem;
          })
      },
      hasGreenDiscount: {
        title: this.rootStore.contentStore.cmsStore.getContent('GreenDiscount')?.content!,
        menuItems: [
          {
            text: this.rootStore.contentStore.cmsStore.getContent('HasGreenDiscount')?.content!,
            filter: true
          },
          {
            text: this.rootStore.contentStore.cmsStore.getContent('DoNotHaveGreenDiscount')
              ?.content!,
            filter: false
          }
        ]
      },
      greyMainMenuSubTitles: {
        title: 'greyMainMenuSubTitles',
        greyTitles: [
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Product')?.content!,
            items: ['identifierStatuses', 'specialAgreementIds']
          },
          {
            title: this.rootStore.contentStore.cmsStore.getContent('Vehicle')?.content!,
            items: ['euronorms', 'vehicleSizes', 'fuelTypes', 'countryIds', 'hasGreenDiscount']
          }
        ]
      }
    };
  };

  addOneTimeAnprContentToProductFilter = () => {
    if (this.rootStore.manageUserAccessStore.hasInvoicePaymentAgreement()) {
      this.rootStore.filterStore.productFilterStore.allMenus.productTypes.menuItems?.push({
        text: this.rootStore.contentStore.cmsStore.getContent('OneTimeANPR')?.content!,
        filter: ProductType.OneTimeANPR
      });
    }
  };

  addTollAppToProductFilter = () => {
    if (isTollAppOrderOpen()) {
      this.rootStore.filterStore.productFilterStore.allMenus.productTypes.menuItems?.push({
        text: this.rootStore.contentStore.cmsStore.getContent('TollingApp')?.content!,
        filter: ProductType.TollingApp
      });
    }
  };

  addOneTimeAnprContentToProductTableColumns = () => {
    if (this.rootStore.manageUserAccessStore.hasInvoicePaymentAgreement()) {
      this.rootStore.productStore.productTableStore.productTableColumns.splice(
        12,
        0,
        ...['EticketExpirationDate', 'EticketRemainingTrips']
      );
    }
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      getAndSetAllCountries: action,
      getAndSetLocalServices: action,
      setProductTableColumnNamesInit: action,
      setOtherTransactionsFilterContent: action,
      setPaymentCardsFilterContent: action,
      setCardPaymentFilterContentInit: action,
      setProductFilterInit: action,
      setBizzesToConvertFilterInit: action,
      setEuronormInit: action,
      setInvoiceFilterContentInit: action
    });
  }
}
