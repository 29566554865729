import { Table } from 'antd';
import styled from 'styled-components';
import { ColorScheme } from '../layout/colorScheme';

export const BrobizzTable = styled(Table)<{
  colorScheme?: ColorScheme;
}>`
  table {
    --border-grey: #efefef;

    background: #f7f7f7;

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #f7f7f7;
      cursor: pointer;
    }

    .ant-table-row-selected > td {
      background: none;
    }

    // no data row styling
    .ant-table-expanded-row-fixed {
      background: #fff;
      height: 200px;
    }

    // table checkbox styling
    .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
      background: none;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: 2px solid #969696;
    }

    .ant-checkbox-inner,
    .ant-checkbox-input {
      transform: scale(1);
      height: 24px;
      width: 24px;
      border: 2px solid #969696;
      border-radius: 3px;
    }

    .ant-checkbox-inner::after {
      width: 7px;
      height: 14px;
      background: none;
      border-color: #fff !important;
    }

    .ant-checkbox-disabled.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #969696;
        border-color: #969696 !important;
      }
    }

    .ant-checkbox-checked {
      border-color: #00a050 !important;
      &::after {
        border-color: #00a050 !important;
      }
      .ant-checkbox-inner {
        background-color: #00a050;
        border-color: #00a050;
      }
    }
  }

  // table cell styling
  .ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background: #f7f7f7;
}

  table tr:nth-child(2n-1) td {
    background: #ffffff !important;
  }

  thead > tr > th {
    height: 70px;
    border-top: 1px solid var(--border-grey);
    border-bottom: 1px solid var(--border-grey);
    background: #ffffff !important;
    color: #747474;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.2px;
  }

  tbody > tr {
    border-top: 1px solid var(--border-grey);
    color: #2d2d2d;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.2px;
    height: 70px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {content: none;}

  // pagination styling
  .ant-pagination {
    margin: 50px;

    .ant-pagination-jump-next, .ant-pagination-jump-prev {
      pointer-events: none;
    }

    .ant-pagination-prev {
      margin-right: 50px;
    }

    .ant-pagination-next {
      margin-left: 50px;
    }

    justify-content: center;
  }

  .ant-pagination-item {
    border: none;
    background-color: ${({ colorScheme }) =>
      colorScheme === ColorScheme.LightGrey ? '#efefef' : 'fff'};
    font-family: Campton Book;
    font-size: 15px;
    font-weight: 500;
    &:hover a {
        color: #2d2d2d;
    }
  }

  .ant-pagination-item-active {
    border-bottom: medium solid #00a050;
    border-radius: 1px;
    a {
      color: #2d2d2d;
    }
  }

  .ant-pagination-item-link {
    font-size: 18px;
    border: none;
    background-color: #00a050;
    color: #ffffff;
    border-radius: 50%;
    &:hover {
      color: #ffffff;
      background-color: #03944c;
    }
    &:disabled {
      color: #ffffff;
      background-color: #00a050;
      opacity: 0.5;
      &:hover {
        color: #ffffff;
        background-color: #00a050;
        opacity: 0.5;
      }
  }

  .ant-pagination-item-ellipsis {
    font-size: 15px;
    letter-spacing: 0;
    color: #2d2d2d;
  }
`;

export default BrobizzTable;
