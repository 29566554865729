/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';

import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

import {
  VehicleService,
  VehicleCard,
  LicensePlateValidationResult,
  IdentifierService
} from '../../swagger/api';

export type CarInformation = {
  key: number;
  carBrand?: string;
  carModel?: string;
  carColor?: string;
};
export default class ProductVehicleStore extends BaseStore {
  formAutoFill?: VehicleCard;

  carBrand?: string = undefined;

  carModel?: string = undefined;

  carColor?: string = undefined;

  carsInformation: CarInformation[] = [];

  getVehicleCardFromEpass = (licensePlate: string, countryIsoCode: string, key?: number) => {
    const promise = VehicleService.fetchVehicleCardFromEpass({
      licensePlate: licensePlate.toUpperCase().trim(),
      countryIsoCode: countryIsoCode.toUpperCase()
    }).then((result) => {
      this.formAutoFill = result;
      this.carBrand = result.brand;
      this.carModel = result.model;
      this.carColor = result.color;
      if (key !== undefined) {
        this.replaceOrAddCarInformation(key);
      }
    });
    return promise;
  };

  replaceOrAddCarInformation = (key: number) => {
    let selectedCar = this.carsInformation.find(
      (carInformation: CarInformation) => carInformation.key === key
    );
    if (selectedCar) {
      selectedCar = {
        key,
        carBrand: this.carBrand,
        carColor: this.carColor,
        carModel: this.carModel
      };
    } else {
      this.carsInformation.push({
        key,
        carBrand: this.carBrand,
        carColor: this.carColor,
        carModel: this.carModel
      });
    }
  };

  isPlateRegistered: LicensePlateValidationResult = LicensePlateValidationResult.NotReferenced;

  keysOfAlreadyRegisteredPlates: number[] = [];

  setKeysOfAlreadyRegisteredPlates = (key: number) => {
    this.keysOfAlreadyRegisteredPlates.push(key);
  };

  removePlateFromListOfAlreadyRegisteredPlates = (key: number) => {
    while (this.keysOfAlreadyRegisteredPlates.indexOf(key) !== -1) {
      this.keysOfAlreadyRegisteredPlates.splice(this.keysOfAlreadyRegisteredPlates.indexOf(key), 1);
    }
  };

  setIsPlateRegistered = (isRegistered: LicensePlateValidationResult) => {
    this.isPlateRegistered = isRegistered;
  };

  isLicenseplateAlreadyRegistered = (licensePlate: string, countryCode: string) => {
    if (licensePlate && countryCode) {
      const promise = VehicleService.isLicensePlateRegistered({
        licensePlate: licensePlate.toUpperCase().trim(),
        countryCode: countryCode.toUpperCase()
      });
      promise.then((result) => this.setIsPlateRegistered(result));
      return promise;
    }
    return Promise.resolve();
  };

  /* eslint-disable class-methods-use-this */
  getVehicleDeclarationPdf = (accountId: string, identifierId: string) =>
    IdentifierService.generateVehicleDeclarationPdf(
      { accountId, identifierId },
      { responseType: 'blob' }
    );

  cleanStore = () => {
    this.formAutoFill = undefined;
    this.carBrand = undefined;
    this.carColor = undefined;
    this.carModel = undefined;
    this.carsInformation = [];
    this.isPlateRegistered = LicensePlateValidationResult.NotReferenced;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      formAutoFill: observable,
      isPlateRegistered: observable,
      keysOfAlreadyRegisteredPlates: observable,
      carBrand: observable,
      carModel: observable,
      carColor: observable,
      carsInformation: observable,
      replaceOrAddCarInformation: action,
      cleanStore: action,
      setKeysOfAlreadyRegisteredPlates: action,
      removePlateFromListOfAlreadyRegisteredPlates: action,
      setIsPlateRegistered: action,
      getVehicleCardFromEpass: action,
      isLicenseplateAlreadyRegistered: action,
      getVehicleDeclarationPdf: action
    });
  }
}
