/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import RouteUrlPaths from '../../RouteUrlPaths';
import UserStore from '../../stores/UserStore';

interface ProtectedSelectAccountRouteProps {
  userStore: UserStore;
}

const ProtectedSelectAccountRoute: FC<ProtectedSelectAccountRouteProps> = (props) => {
  const { children, userStore } = props;

  if (!userStore.isSelectAccountShown) {
    return <Navigate to={RouteUrlPaths.Frontpage} />;
  }

  return <>{children}</>;
};

export default ProtectedSelectAccountRoute;
