enum RouteUrlPaths {
  Frontpage = '/',
  NotFound = '*',
  SelectAccount = 'vaelg-konto',
  CommercialProducts = 'produkter',
  PrivateProducts = 'bizzer-og-nummerplader',
  Usage = 'forbrug',
  TransactionAndUsage = 'ture-og-forbrug',
  Payments = 'betaling',
  Order = 'bestilling',
  OrderBizz = 'bestilling/bizz',
  OrderLicensePlate = 'bestilling/nummerpladebetaling',
  OrderEticket = 'bestilling/eBillet',
  SelectTollingBoxOrderType = 'bestilling/vejafgift-boks',
  OrderTollingApp = 'bestilling/vejafgift-app',
  OrderTollingBox = 'bestilling/vejafgift-boks/forudbestil',
  ConvertToTollingBox = 'bestilling/vejafgift-boks/konvertering',
  Administration = 'administration',
  MyProfile = 'min-profil',
  CreateCustomer = 'opret-kunde',
  SignUp = 'register',
  CreatePrivateUser = 'opret-bruger',
  EmailIsUpdated = 'email-er-opdateret',
  PaymentCard = 'betalingskort',
  Welcome = 'velkommen',
  LogOut = 'log-ud'
}

export default RouteUrlPaths;
