import { Col, Divider, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledInput } from '../../../../common-components/Input';
import { H9CamptonBoldSemiBold19, SmallGrey12 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';

const RegisterInput = styled(StyledInput)`
  width: 245px;
  height: 48px;
`;

const SectionInfo = styled.div`
  width: 200px;
  margin-right: 150px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 36px;
  margin-bottom: 30px;
`;

const StyledRow = styled(Row)`
  @media screen and (min-width: 1218px) {
    flex-wrap: nowrap;
  }
`;

const StyledSubRow = styled(Row)`
  @media screen and (min-width: 1000px) and (max-width: 1217px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
`;

interface ContactManagerProps {
  contentStore: ContentStore;
}

const ContactManager: FC<ContactManagerProps> = (props) => {
  const { contentStore } = props;
  const contractResponsible = contentStore.cmsStore.getContent('ContractResponsible')?.content;
  const firstName = contentStore.cmsStore.getContent('FirstName')?.content;
  const lastName = contentStore.cmsStore.getContent('LastName')?.content;
  const contactManagerEmail = contentStore.cmsStore.getContent('ContactManagerEmail')?.content;
  const contractManagerPhoneNumber = contentStore.cmsStore.getContent(
    'ContractManagerPhoneNumber'
  )?.content;

  return (
    <>
      <StyledRow>
        <Col>
          <SectionInfo>
            <H9CamptonBoldSemiBold19>{contractResponsible}</H9CamptonBoldSemiBold19>
          </SectionInfo>
        </Col>
        <Col>
          <StyledSubRow gutter={20}>
            <Col>
              <SmallGrey12>{firstName}</SmallGrey12>
              <Form.Item name="managerName">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{lastName}</SmallGrey12>
              <Form.Item name="managerSurname">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{contactManagerEmail}</SmallGrey12>
              <Form.Item name="managerEmail">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{contractManagerPhoneNumber}</SmallGrey12>
              <Form.Item name="managerPhoneNumber">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
          </StyledSubRow>
        </Col>
      </StyledRow>
      <Row>
        <StyledDivider />
      </Row>
    </>
  );
};

export default observer(ContactManager);
