export const downloadCSV = (fileName: String, data: any) => {
  const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8' });
  const blobURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.download = `${fileName}.csv`;
  anchor.href = blobURL;
  anchor.click();
};

export default { downloadCSV };
