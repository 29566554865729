import { Col, Row } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentSmallCamptonBook, H9CamptonBoldSemiBold19 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';

const SectionInfo = styled.div`
  width: 200px;
  margin-right: 150px;
`;

const StyledParagraph = styled(ContentSmallCamptonBook)`
  line-height: 19px;
`;

interface OrderEmptyProductsProps {
  contentStore: ContentStore;
}
const OrderEmptyProducts: FC<OrderEmptyProductsProps> = (props) => {
  const { contentStore } = props;
  const orderShinyBizzers = contentStore.cmsStore.getContent('OrderShinyBizzers')?.content;
  const orderShinyBizzersInfo = contentStore.cmsStore.getContent('OrderShinyBizzersInfo')?.content;

  return (
    <Row style={{ paddingBottom: '150px' }}>
      <Col>
        <SectionInfo>
          <H9CamptonBoldSemiBold19>{orderShinyBizzers}</H9CamptonBoldSemiBold19>
        </SectionInfo>
      </Col>
      <Col>
        <Row>
          <StyledParagraph>{orderShinyBizzersInfo}</StyledParagraph>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderEmptyProducts;
