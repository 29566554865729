/* eslint-disable import/no-cycle */
import { action, makeObservable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class SharedDateFilterStore extends BaseStore {
  clearDateFilters = () => {
    this.rootStore.filterStore.cardPaymentFilterStore.clearDateFilters();
    this.rootStore.filterStore.transactionFilterStore.clearDateFilters();
    this.rootStore.filterStore.invoiceFilterStore.clearDateFilters();
    this.rootStore.filterStore.otherTransactionFilterStore.clearDateFilters();
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      clearDateFilters: action
    });
  }
}
