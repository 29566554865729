import React, { FC, useEffect, useState } from 'react';
import { ContentSmallCamptonBook } from '../../../layout/globalFonts';
import { ProductInfoPaginatedList } from '../../../swagger/api';
import LoadingSpinner from '../../../common-components/LoadingSpinner';
import useStore from '../../../hooks/useStore';
import AdministrationStore from '../../../stores/AdministrationStore';

interface NumberOfIdentifiersOnAccountProps {
  accountId: string;
}

const NumberOfIdentifiersOnAccount: FC<NumberOfIdentifiersOnAccountProps> = (props) => {
  const { accountId } = props;
  const [numberOfIdentifiers, setNumberOfIdentifiers] = useState<number>();
  const [isLoading, setIsloading] = useState(false);
  const administrationStore: AdministrationStore = useStore(AdministrationStore);

  useEffect(() => {
    setIsloading(true);
    const identifiersOnAccountPromise =
      administrationStore.administrationAccountStore.getIdentifiers(accountId);
    identifiersOnAccountPromise.then((paginatedProductsOnCard: ProductInfoPaginatedList) => {
      setNumberOfIdentifiers(paginatedProductsOnCard.totalCount!);
      setIsloading(false);
    });
  }, []);

  return isLoading ? (
    <LoadingSpinner size="sm" justify="left" />
  ) : (
    <ContentSmallCamptonBook>{numberOfIdentifiers}</ContentSmallCamptonBook>
  );
};

export default NumberOfIdentifiersOnAccount;
