/* eslint-disable import/no-cycle */
import { useContext } from 'react';

import BaseStore from '../stores/base-stores/BaseStore';
import { RootStore, storeContext } from '../stores/RootStore';

const useStore = (inputStore: typeof BaseStore) => {
  const rootStore: RootStore = useContext<RootStore>(storeContext);
  return Object.values(rootStore).find((store) => store instanceof inputStore);
};

export default useStore;
