/* eslint-disable import/no-cycle */
import React, { FC, useEffect } from 'react';
import { Row } from 'antd';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { da, enGB } from 'date-fns/locale';
import ContentStore from '../stores/ContentStore';
import { SmallGrey12 } from '../layout/globalFonts';
import { ReactComponent as CalenderIcon } from '../layout/icons/calendar.svg';
import { Filters } from '../Types';
import { StyledInput } from './Input';

import 'react-datepicker/dist/react-datepicker.css';
import { LanguageType } from '../swagger/api';

const StyledDatePickerDiv = styled.div<{
  isStartDateSelected: boolean;
  isEndDateSelected: boolean;
}>`
  margin: 24px 16px;

  .ant-input {
    ::placeholder {
      color: #747474;
    }
  }

  .react-datepicker-wrapper {
    margin-top: 12px;
  }

  .react-datepicker {
    width: 270px;
    font-family: Campton Book;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.2px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    padding: 0px 12px 12px 12px;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 0;
  }

  .react-datepicker__month {
    margin: 0 4px;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__current-month {
    padding-top: 17px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    color: #2d2d2d;
  }

  .react-datepicker__day-name {
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    color: #747474;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    line-height: 32px;
    margin: 0px;
    width: 32px;
    margin-bottom: 3px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0;
    background: rgb(1, 160, 80, 0.1);
    color: #2d2d2d;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    background: radial-gradient(circle 16.5px, rgb(1, 160, 80) 94%, rgb(1, 160, 80, 0.1));
    color: #fff;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    background: radial-gradient(circle 16.5px, rgb(1, 160, 80) 94%, rgb(1, 160, 80, 0.1));
    color: #fff;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  .react-datepicker__day--outside-month {
    color: transparent;
    background-color: transparent;
    pointer-events: none;
  }

  .react-datepicker__day:hover {
    border-radius: 100%;
    background-color: rgb(0, 160, 80);
    color: white;
    ${(props) =>
      props.isStartDateSelected &&
      `
      border-radius: 0;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: radial-gradient(
    circle 16.5px,
    rgb(1, 160, 80) 94%,
    rgb(1, 160, 80, 0.1)
  );
`}
    ${(props) =>
      props.isEndDateSelected &&
      `
  border-radius: 0;
border-top-left-radius: 100%;
border-bottom-left-radius: 100%;
background: radial-gradient(
circle 16.5px,
rgb(1, 160, 80) 94%,
rgb(1, 160, 80, 0.1)
);
`}
  }

  .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc !important;
    background: #fff !important;
  }

  .react-datepicker__navigation-icon::before {
    display: none;
  }

  .react-datepicker__navigation {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.34314 12.6569L1.68629 7L7.34315 1.34314' stroke='%2300A050' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
    height: 16px;
    margin: 0px 20px;
    padding: 0;
    top: 27px;
    width: 16px;
  }

  .react-datepicker__navigation--next {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65685 1.34315L7.31371 7L1.65685 12.6569' stroke='%2300A050' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
  }
`;

interface RangePickerProps {
  contentStore: ContentStore;
  temporaryFilters: Filters;
  setTemporaryFilters: (filters: Filters) => void;
  fromDate: Date | null | undefined;
  toDate: Date | null | undefined;
  setFromDate: ((fromDate: Date) => void) | undefined;
  setToDate: ((toDate: Date) => void) | undefined;
}

const RangePicker: FC<RangePickerProps> = (props) => {
  const {
    contentStore,
    temporaryFilters,
    setTemporaryFilters,
    fromDate,
    toDate,
    setFromDate,
    setToDate
  } = props;

  const setDateFilter = () => {
    const filterObject = {
      text: '',
      filter: [fromDate?.toString(), toDate?.toString()]
    };
    const copyOfTempFilter = JSON.parse(JSON.stringify(temporaryFilters));
    copyOfTempFilter.dates[0] = filterObject;
    setTemporaryFilters(copyOfTempFilter);
  };

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      setDateFilter();
    }
  }, [fromDate, toDate]);

  const setStartDate = async (selectedFromDate: Date) => {
    if (setFromDate) {
      setFromDate(selectedFromDate);
    }
  };

  const setEndDate = (selectedToDate: Date) => {
    if (setToDate) {
      setToDate(selectedToDate);
    }
  };

  const locale =
    contentStore.rootStore.userStore.person?.language === LanguageType.Danish ? da : enGB;

  return (
    <StyledDatePickerDiv
      isStartDateSelected={fromDate !== null}
      isEndDateSelected={toDate !== null}
    >
      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('From')?.content}</SmallGrey12>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setStartDate(date as Date)}
          selectsStart
          startDate={fromDate}
          endDate={toDate}
          showPopperArrow={false}
          locale={locale}
          placeholderText={contentStore.cmsStore.getContent('Choose')?.content}
          dateFormat="dd.MM.yyyy"
          customInput={<StyledInput suffix={<CalenderIcon />} />}
          disabledKeyboardNavigation
          maxDate={toDate}
        />
      </Row>

      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('To')?.content}</SmallGrey12>
      </Row>
      <Row>
        <DatePicker
          selected={toDate}
          onChange={(date) => setEndDate(date as Date)}
          selectsEnd
          startDate={fromDate}
          endDate={toDate}
          minDate={fromDate}
          showPopperArrow={false}
          locale={locale}
          placeholderText={contentStore.cmsStore.getContent('Choose')?.content}
          dateFormat="dd.MM.yyyy"
          customInput={<StyledInput suffix={<CalenderIcon />} />}
          disabledKeyboardNavigation
        />
      </Row>
    </StyledDatePickerDiv>
  );
};

export default observer(RangePicker);
