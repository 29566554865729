import { Col, FormInstance, Form, Radio, Row, Divider } from 'antd';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { StyledInput } from '../../../../common-components/Input';
import { StyledRadio } from '../../../../common-components/Selectors';
import { H9CamptonBoldSemiBold19, SmallGrey12 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import SubAccountStore, {
  SelectedPaymentMethod
} from '../../../../stores/adminstration-stores/SubAccountStore';
import SelectPaymentCardDropdown from '../../payment-card-features/SelectPaymentCardDropdown';
import { validateOnlyNumbers } from '../../../../utils/formValidators';

const RegisterInput = styled(StyledInput)`
  width: 245px;
  height: 48px;
`;

const SectionInfo = styled.div`
  width: 200px;
  margin-right: 150px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 36px;
  margin-bottom: 30px;
`;

const StyledRow = styled(Row)`
  @media screen and (max-width: 1217px) {
    display: block;
  }
`;

const StyledHeader = styled(H9CamptonBoldSemiBold19)`
  @media screen and (max-width: 1217px) {
    margin-bottom: 10px;
  }
`;

interface PaymentMethodProps {
  form: FormInstance;
  subAccountStore: SubAccountStore;
  contentStore: ContentStore;
}

const validateCheckBox = (checked: boolean) => {
  if (!checked) {
    return Promise.reject();
  }

  return Promise.resolve();
};

const PaymentMethod: FC<PaymentMethodProps> = (props) => {
  const { form, subAccountStore, contentStore } = props;
  const paymentMethod = contentStore.cmsStore.getContent('PaymentMethod')?.content;
  const pdfInvoice = contentStore.cmsStore.getContent('PdfInvoice')?.content;
  const eanInvoice = contentStore.cmsStore.getContent('EANInvoice')?.content;
  const creditCard = contentStore.cmsStore.getContent('CreditCard')?.content;
  const emailOnInvoiceReceipient = contentStore.cmsStore.getContent(
    'EmailOnInvoiceReceipient'
  )?.content;
  const EnterEanNumber = contentStore.cmsStore.getContent('EnterEanNumber')?.content;

  useEffect(() => subAccountStore.setPaymentMethod(SelectedPaymentMethod.Invoice, form), []);

  const invoiceRecipientEmailInput = (
    <Form.Item
      name="recipientEmail"
      rules={[
        {
          required: true,
          message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content
        },
        {
          type: 'email',
          message: contentStore.cmsStore.getContent('invalidEmail')!.content
        }
      ]}
    >
      <RegisterInput
        maxLength={100}
        placeholder={contentStore.cmsStore.getContent('EnterEmailOfInvoiceRecipient')?.content}
        type="text"
      />
    </Form.Item>
  );

  const eanNumberInput = (
    <Form.Item
      name="eanNumber"
      rules={[
        {
          required: true,
          message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content
        },
        {
          // eslint-disable-next-line consistent-return
          validator: (_, value) => validateOnlyNumbers(value),
          message: contentStore.cmsStore.getContent('useOnlyNumbers')?.content
        }
      ]}
    >
      <RegisterInput maxLength={13} placeholder={EnterEanNumber} type="text" />
    </Form.Item>
  );

  const debitCardDropdown = (
    <Form.Item name="debitCard">
      <SelectPaymentCardDropdown
        administrationPaymentCardsStore={
          contentStore.rootStore.administrationStore.administrationPaymentCardsStore
        }
        contentStore={contentStore}
        showAddCreditCardOption
      />
    </Form.Item>
  );

  return (
    <>
      <StyledRow>
        <Col>
          <SectionInfo>
            <StyledHeader>{paymentMethod}</StyledHeader>
          </SectionInfo>
        </Col>
        <Col>
          <Row>
            <Form.Item
              initialValue={SelectedPaymentMethod.Invoice}
              name="paymentType"
              rules={[
                {
                  message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content,
                  validator: () => validateCheckBox(form.getFieldValue('paymentType'))
                }
              ]}
            >
              <Radio.Group>
                <Row>
                  <StyledRadio
                    value={SelectedPaymentMethod.Invoice}
                    onClick={() =>
                      subAccountStore.setPaymentMethod(SelectedPaymentMethod.Invoice, form)
                    }
                  >
                    {pdfInvoice}
                  </StyledRadio>
                </Row>
                <Row style={{ paddingTop: '14px' }}>
                  <StyledRadio
                    value={SelectedPaymentMethod.EANInvoice}
                    onClick={() =>
                      subAccountStore.setPaymentMethod(SelectedPaymentMethod.EANInvoice, form)
                    }
                  >
                    {eanInvoice}
                  </StyledRadio>
                </Row>
                <Row style={{ paddingTop: '14px' }}>
                  <StyledRadio
                    value={SelectedPaymentMethod.CreditCard}
                    onClick={() =>
                      subAccountStore.setPaymentMethod(SelectedPaymentMethod.CreditCard, form)
                    }
                  >
                    {creditCard}
                  </StyledRadio>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row gutter={20}>
            <Col style={{ width: '260px' }}>
              {subAccountStore.selectedPaymentMethod === SelectedPaymentMethod.Invoice && (
                <>
                  <SmallGrey12>{emailOnInvoiceReceipient}</SmallGrey12>
                  {invoiceRecipientEmailInput}
                </>
              )}
              {subAccountStore.selectedPaymentMethod === SelectedPaymentMethod.EANInvoice && (
                <>
                  <SmallGrey12>{eanInvoice}</SmallGrey12>
                  {eanNumberInput}
                </>
              )}
              {subAccountStore.selectedPaymentMethod === SelectedPaymentMethod.CreditCard &&
                debitCardDropdown}
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <Row>
        <StyledDivider />
      </Row>
    </>
  );
};

export default observer(PaymentMethod);
