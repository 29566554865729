/* eslint-disable import/no-cycle */
import AccountInformationStore from './adminstration-stores/AccountInformationStore';
import AdministrationAccountStore from './adminstration-stores/AdministrationAccountStore';
import AdministrationPaymentCardsStore from './adminstration-stores/AdministrationPaymentCardsStore';
import AdministrationTabStore from './adminstration-stores/AdministrationTabStore';
import AdministrationUserStore from './adminstration-stores/AdministrationUserStore';
import CreateUserStore from './adminstration-stores/CreateUserStore';
import EditUserStore from './adminstration-stores/EditUserStore';
import SubAccountStore from './adminstration-stores/SubAccountStore';
import BaseStore from './base-stores/BaseStore';

export default class AdministrationStore extends BaseStore {
  administrationAccountStore: AdministrationAccountStore = new AdministrationAccountStore(
    this.rootStore
  );

  administrationUserStore: AdministrationUserStore = new AdministrationUserStore(this.rootStore);

  administrationPaymentCardsStore: AdministrationPaymentCardsStore =
    new AdministrationPaymentCardsStore(this.rootStore);

  administrationTabStore: AdministrationTabStore = new AdministrationTabStore(this.rootStore);

  subAccountStore: SubAccountStore = new SubAccountStore(this.rootStore);

  accountInformationStore: AccountInformationStore = new AccountInformationStore(this.rootStore);

  editUserStore: EditUserStore = new EditUserStore(this.rootStore);

  createUserStore: CreateUserStore = new CreateUserStore(this.rootStore);
}
