/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import {
  Co2Class,
  Euronorm,
  FuelType,
  IdentifierStatus,
  ProductType,
  UserRole,
  VehicleSize
} from '../../swagger/api/index';
import { Filters, FilterMenuContent, FilterObject } from '../../Types';
import getDefaultProductFilters from '../../utils/filterUtilities';

export type ProductTableFilter = {
  identifierStatuses: FilterObject[];
  productTypes: FilterObject[];
  specialAgreementIds: FilterObject[];
  euronorms: FilterObject[];
  vehicleSizes: FilterObject[];
  fuelTypes: FilterObject[];
  weights: FilterObject[];
  co2Classes: FilterObject[];
  countryIds: FilterObject[];
  hasGreenDiscount: FilterObject[];
  filteringViews: FilterObject[];
};

export default class ProductFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setProductFilterInit() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: ProductTableFilter = {
    identifierStatuses: [],
    productTypes: [],
    specialAgreementIds: [],
    euronorms: [],
    vehicleSizes: [],
    fuelTypes: [],
    weights: [],
    co2Classes: [],
    countryIds: [],
    hasGreenDiscount: [],
    filteringViews: []
  };

  temporaryFilters: ProductTableFilter = {
    identifierStatuses: [],
    productTypes: [],
    specialAgreementIds: [],
    euronorms: [],
    vehicleSizes: [],
    fuelTypes: [],
    weights: [],
    co2Classes: [],
    countryIds: [],
    hasGreenDiscount: [],
    filteringViews: []
  };

  requestParams: {
    accountId: string;
    userRole?: UserRole;
    searchString?: string | undefined;
    identifierStatuses?: IdentifierStatus[] | undefined;
    productTypes?: ProductType[] | undefined;
    specialAgreementIds?: string[] | undefined;
    euronorms?: Euronorm[] | undefined;
    vehicleTypes?: VehicleSize[] | undefined;
    fuelTypes?: FuelType[] | undefined;
    co2Classes?: Co2Class[] | undefined;
    fromWeight?: number | undefined;
    toWeight?: number | undefined;
    countryIds?: string[] | undefined;
    hasGreenDiscount?: boolean | undefined;
    pageNumber?: number | undefined;
    convertingToTollingBox?: boolean;
  } = {
    accountId: ''
  };

  setRequestParams = () => {
    this.requestParams.searchString =
      this.rootStore.filterStore.productFilterStore.productSearchVal;
    this.requestParams.pageNumber = this.rootStore.productStore.productTableStore.currentPage;
    this.requestParams.identifierStatuses = this.activeFilters.identifierStatuses.map(
      (element: FilterObject) => element.filter as IdentifierStatus
    );
    this.requestParams.productTypes = this.activeFilters.productTypes.map(
      (element: FilterObject) => element.filter as ProductType
    );
    this.requestParams.specialAgreementIds = this.activeFilters.specialAgreementIds.map(
      (element: FilterObject) => element.filter as string
    );
    this.requestParams.euronorms = this.activeFilters.euronorms.map(
      (element: FilterObject) => element.filter as Euronorm
    );

    if (this.activeFilters.vehicleSizes !== undefined) {
      this.requestParams.vehicleTypes = this.activeFilters.vehicleSizes.map(
        (element: FilterObject) => element.filter as VehicleSize
      );
    }

    this.requestParams.fuelTypes = this.activeFilters.fuelTypes.map(
      (element: FilterObject) => element.filter as FuelType
    );
    this.requestParams.countryIds = this.activeFilters.countryIds.map(
      (element: FilterObject) => element.filter as string
    );
    this.requestParams.co2Classes = this.activeFilters.co2Classes.map(
      (element: FilterObject) => element.filter as Co2Class
    );
    let fromWeight: number | undefined;
    let toWeight: number | undefined;
    if (this.activeFilters.weights.length === 1) {
      [fromWeight, toWeight] = this.activeFilters.weights[0].filter as number[];
    }
    this.requestParams.fromWeight = fromWeight;
    this.requestParams.toWeight = toWeight;
    if (this.activeFilters.hasGreenDiscount.length === 1)
      this.requestParams.hasGreenDiscount = this.activeFilters.hasGreenDiscount[0]
        .filter as boolean;
    else this.requestParams.hasGreenDiscount = undefined;
    if (this.activeFilters.filteringViews) {
      this.requestParams.convertingToTollingBox =
        (this.activeFilters.filteringViews.find(
          (filterObject) =>
            filterObject.text ===
            this.rootStore.contentStore.cmsStore.getContent('ConvertingToTollingBox')?.content
        )?.filter as boolean) ?? null;
    }
  };

  productSearchVal: string = '';

  setSearchValue = (searchValue: string) => {
    this.productSearchVal = searchValue;
    this.rootStore.productStore.productTableStore.setCurrentPage(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.productStore.productTableStore.setCurrentPage(1);
  };

  setTemporaryFilters = (filter: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filter));
  };

  resetProductFilters = () => {
    this.temporaryFilters = getDefaultProductFilters();
    this.activeFilters = getDefaultProductFilters();
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      activeFilters: observable,
      temporaryFilters: observable,
      productSearchVal: observable,
      setSearchValue: action,
      setActiveFilters: action,
      setTemporaryFilters: action,
      resetProductFilters: action
    });
  }
}
