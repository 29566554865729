import { useEffect } from 'react';

const useCheckIfClickedOutside = (isOpen: boolean, setIsOpen: any, refDiv: any) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpen && refDiv.current && !refDiv.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);
};

export default useCheckIfClickedOutside;
