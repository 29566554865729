import React, { FC } from 'react';
import CustomModal from '../../../../common-components/CustomModal';
import ModalStore from '../../../../stores/ModalStore';
import ReepayEmbedded from '../../../payment-features/ReepayEmbeded';
// eslint-disable-next-line import/no-cycle
import PaymentCardCreatedModal from './PaymentCardCreatedModal';
import { PaymentMethod } from '../../../../swagger/api';

interface CreateNewPaymentCardModalProps {
  modalStore: ModalStore;
  oldPaymentCard?: PaymentMethod;
  identifierId?: string;
  setChosenMethodInStoresAndForm?: (chosenMethod: PaymentMethod) => void;
}

const CreateNewPaymentCardModal: FC<CreateNewPaymentCardModalProps> = (props) => {
  const { modalStore, oldPaymentCard, identifierId, setChosenMethodInStoresAndForm } = props;
  const { contentStore, paymentCardStore, administrationStore, userStore, loadingStore } =
    modalStore.rootStore;
  const { administrationPaymentCardsStore } = administrationStore;
  const { productItemsStore } = contentStore.rootStore.productStore;

  const onSuccessModal = async () => {
    const newPaymentCard = paymentCardStore.paymentMethods.find(
      (paymentMethod: PaymentMethod) =>
        paymentMethod.handleId === paymentCardStore.newPaymentCardHandleId
    )!;
    loadingStore.enableGlobalSpinner();
    if (identifierId) {
      await paymentCardStore
        .setPaymentCardOnProduct(identifierId, newPaymentCard.id!)
        .then(async () => {
          await productItemsStore.getProduct(identifierId, 'TollingBoxConversion');
        });
    } else if (setChosenMethodInStoresAndForm) {
      await administrationPaymentCardsStore.getValidPaymentCardsOwnedByLoggedInPerson();
      const chosenMethod =
        administrationPaymentCardsStore.validPaymentCardsOwnedByLoggedInPerson.find(
          (paymentMethod: PaymentMethod) =>
            paymentMethod.handleId === paymentCardStore.newPaymentCardHandleId
        )!;
      setChosenMethodInStoresAndForm(chosenMethod);
    }
    modalStore.closeModal();
    loadingStore.disableGlobalSpinner();
    modalStore.openModal(
      <PaymentCardCreatedModal
        contentStore={contentStore}
        paymentMethod={newPaymentCard}
        closeModal={modalStore.closeModal}
        isUpdate={!!oldPaymentCard}
        isFromOrderFlow={!!setChosenMethodInStoresAndForm}
      />
    );
    if (!setChosenMethodInStoresAndForm && !identifierId) {
      loadingStore.enableGlobalSpinner();
      await administrationPaymentCardsStore.getPaymentCardsInfo();
      loadingStore.disableGlobalSpinner();
    }
  };

  return (
    <CustomModal
      header={contentStore.cmsStore.getContent('CreateNewPaymentCard')?.content}
      contentText={contentStore.cmsStore.getContent('CreateNewPaymentCard')?.subText}
      closable
      removeHorizontalPaddingForChildElement
    >
      <ReepayEmbedded
        paymentCardStore={paymentCardStore}
        onSuccess={onSuccessModal}
        accountId={userStore.currentAccount?.id}
        oldPaymentMethodId={oldPaymentCard?.id}
      />
    </CustomModal>
  );
};

export default CreateNewPaymentCardModal;

CreateNewPaymentCardModal.defaultProps = {
  oldPaymentCard: undefined,
  identifierId: undefined,
  setChosenMethodInStoresAndForm: undefined
};
