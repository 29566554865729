/* eslint-disable import/no-cycle */
import { action, computed, makeObservable, observable } from 'mobx';
import { Country, Service } from '../../swagger/api';

import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export interface CountryQuery {
  name?: string;
  isoCode?: string;
  isApprovedCountry?: boolean;
  allowShipping?: boolean;
  allowForCommercialMinKontoAnpr?: boolean;
  allowForCommercialMinKontoSignup?: boolean;
  allowForPrivateMinKontoAnpr?: boolean;
  allowForPrivateMinKontoSignup?: boolean;
  allowForeTicket?: boolean;
}

export default class GenericContentStore extends BaseStore {
  // allCountries is initialized on content load in the method getAndSetAllCountries() of ContentInitializerStore.tsx
  allCountries: Country[] = [];

  // localServices is initialized on content load in the method getAndSetLocalServices() of ContentInitializerStore.tsx
  localServices: Service[] = [];

  get getAllCountriesIsoCodes() {
    return this.allCountries
      .filter((country: Country) => country.isoCode !== null && country.isoCode !== undefined)
      .map((country: Country) => country.isoCode!);
  }

  get getApprovedCountriesIsoCodes() {
    return this.getCountriesWithQuery({
      isApprovedCountry: true
    })
      .filter((country: Country) => country.isoCode)
      .map((country: Country) => country.isoCode);
  }

  get privateLicensePlateOrderIsoCodes() {
    return this.getCountriesWithQuery({
      allowForPrivateMinKontoAnpr: true
    }).map((country: Country) => country.isoCode);
  }

  getCountry = (countryName: string) =>
    this.allCountries.find((country) => country.name === countryName);

  setAllCountries = (countries: Country[]) => {
    this.allCountries = countries;
  };

  setLocalServices = (localService: Service[]) => {
    this.localServices = localService;
  };

  getCountriesWithQuery = (query: CountryQuery) => {
    let queriedCountries = [...this.allCountries];

    Object.keys(query).forEach((key: string) => {
      if (key === 'name' && query[key]) {
        queriedCountries = queriedCountries.filter((country: Country) => {
          const nameLowerCase = country.name?.toLowerCase();
          return nameLowerCase?.includes(query[key]!.toLowerCase());
        });
      } else if (query[key as keyof CountryQuery]) {
        queriedCountries = queriedCountries.filter(
          (country: Country) =>
            country[key as keyof CountryQuery] === query[key as keyof CountryQuery]
        );
      }
    });

    return queriedCountries;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      allCountries: observable,
      getApprovedCountriesIsoCodes: computed,
      getAllCountriesIsoCodes: computed,
      privateLicensePlateOrderIsoCodes: computed,
      setAllCountries: action,
      setLocalServices: action,
      getCountry: action,
      getCountriesWithQuery: action
    });
  }
}
