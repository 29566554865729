import React, { FC } from 'react';
import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import useStore from '../../hooks/useStore';
import ContentStore from '../../stores/ContentStore';
import { ContentSmallCamptonBook } from '../../layout/globalFonts';

interface TableCounterProps {
  totalCount: number;
  filteredCount: number;
  entityInTotal: string;
}

const TableCounter: FC<TableCounterProps> = (props) => {
  const { totalCount, filteredCount, entityInTotal } = props;
  const contentStore: ContentStore = useStore(ContentStore);

  return (
    <Row>
      <ContentSmallCamptonBook>
        {totalCount === filteredCount
          ? `${totalCount} ${entityInTotal}`
          : `${filteredCount} ${
              contentStore.cmsStore.getContent('EntitiesFound')?.content
            } ${totalCount}`}
      </ContentSmallCamptonBook>
    </Row>
  );
};

export default observer(TableCounter);
