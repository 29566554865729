import React, { FC, useState, useRef } from 'react';
import { Select, Input, Form } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { StyledSelect } from '../../../common-components/Input';
import useCheckIfClickedOutside from '../../../hooks/useCheckIfClickedOutside';
import ContentStore from '../../../stores/ContentStore';
import { DropdownArrow, SearchGreenIcon, RemoveIcon } from '../../../common-components/StyledIcons';
import { Account } from '../../../swagger/api';
import { ReactComponent as CheckIcon } from '../../../layout/icons/check.svg';
import useFetchListData from '../../../hooks/fetchDataHooks/useFetchListData';

const { Option } = Select;

const SelectorContainer = styled.div`
  width: 100%;
`;
const AccountSelector = styled(StyledSelect)`
  width: 100%;
  text-align: left;
`;
const StyledInput = styled(Input)`
  border-radius: 5px;
  border-color: #efefef;
  background: #efefef;
  padding-left: 6px;
  left: 10px;
  width: calc(100% - 20px);
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: none !important;
  :hover {
    border-color: #efefef !important;
  }
  .ant-input {
    background-color: #efefef;
  }
`;

const StyledRemove = styled(RemoveIcon)`
  vertical-align: middle;
`;

interface SiblingaccountDropdownProps {
  contentStore: ContentStore;
}

const SiblingaccountDropdown: FC<SiblingaccountDropdownProps> = (props) => {
  const { contentStore } = props;
  const { accountStore, userStore } = contentStore.rootStore;
  const [isOpen, setIsOpen] = useState(false);
  const refDiv: any = useRef(null);
  const [searchString, setSearchString] = useState('');

  const currentAccountId = userStore.currentAccount?.id;

  const dropdownOptions =
    accountStore.siblingaccounts.filter(
      (siblingAccount: Account) =>
        siblingAccount.name!.toLowerCase().includes(searchString.toLowerCase()) ||
        siblingAccount.customerNumber!.toLowerCase().includes(searchString.toLowerCase())
    ).length === 0 ? (
      <Option style={{ textAlign: 'left' }} disabled value="No results">
        {contentStore.cmsStore.getContent('SearchYieldedNoResultsDescription')?.content}
      </Option>
    ) : (
      accountStore.siblingaccounts
        .filter(
          (siblingAccount: Account) =>
            (siblingAccount.name!.toLowerCase().includes(searchString.toLowerCase()) ||
              siblingAccount.customerNumber!.toLowerCase().includes(searchString.toLowerCase())) &&
            siblingAccount.id !== currentAccountId
        )
        .map((siblingAccount: Account) => (
          <Option style={{ textAlign: 'left' }} key={siblingAccount.id} value={siblingAccount.id!}>
            {siblingAccount.name!} - {siblingAccount.customerNumber!}
          </Option>
        ))
    );

  useFetchListData(accountStore.siblingaccounts, accountStore.getSiblingAccounts);

  // Hook so dropdown will close when you click outside of it.
  useCheckIfClickedOutside(isOpen, setIsOpen, refDiv);

  const selectAndSetSiblingaccount = (siblingaccountId: any) => {
    const selectedSiblingaccount: Account | undefined = accountStore.siblingaccounts.find(
      (account: Account) => account.id === siblingaccountId
    );
    accountStore.setChosenSiblingaccount(selectedSiblingaccount);
    setIsOpen(false);
  };

  const suffixIcon = searchString ? null : <SearchGreenIcon />;

  const siblingAccountSearch = (menu: any) => (
    <Form.Item name="searchSiblingAccountInput" status="success">
      <StyledInput
        maxLength={100}
        id="searchfield"
        onClick={() => setIsOpen(true)}
        placeholder={contentStore.cmsStore.getContent('SearchCustomerNo')?.content!}
        suffix={suffixIcon}
        allowClear={{ clearIcon: <StyledRemove /> }}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
      />
      {menu}
    </Form.Item>
  );

  return (
    <SelectorContainer ref={refDiv}>
      <Form.Item
        name="SelectSiblingAccount"
        style={{ marginBottom: '0px' }}
        validateTrigger={['onSubmit', 'onChange']}
        rules={[
          {
            validator() {
              if (
                accountStore.chosenSiblingaccount === undefined ||
                accountStore.chosenSiblingaccount?.agreements?.length! <= 1
              ) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
            message: `${contentStore.cmsStore.getContent(
              'SelectSiblingAccountMultipleAgreementsErrorMessage'
            )?.content!}`
          }
        ]}
      >
        <AccountSelector
          id="selector"
          getPopupContainer={(trigger: any) => trigger}
          suffixIcon={<DropdownArrow />}
          placeholder={contentStore.cmsStore.getContent('SelectCustomerNo')?.content!}
          onChange={selectAndSetSiblingaccount}
          open={isOpen}
          dropdownRender={siblingAccountSearch}
          placement="bottomLeft"
          menuItemSelectedIcon={<CheckIcon />}
          onClick={(e: any) => {
            if (!((e.target as HTMLTextAreaElement).id === 'searchfield')) {
              setIsOpen(!isOpen);
            }
          }}
        >
          {dropdownOptions}
        </AccountSelector>
      </Form.Item>
    </SelectorContainer>
  );
};

export default observer(SiblingaccountDropdown);
