import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { MobileContentManchet } from '../../../layout/globalFonts';
import ContentStore from '../../../stores/ContentStore';
import { Transaction } from '../../../swagger/api';
import MobileTransactionRow from './components/MobileTransactionRow';
import TransactionsAndUsageTableMobileColumnTitleRow from './components/TransactionsAndUsageTableMobileColumnTitleRow';
import MobiletransactionsPagination from './components/MobiletransactionsPagination';
// eslint-disable-next-line import/no-cycle
import PrivateProductCardModal from '../../product-features/product-card/PrivateProductCardModal';
import TransactionsStore from '../../../stores/TransactionsStore';
import LoadingSpinner from '../../../common-components/LoadingSpinner';

interface TransactionsAndUsageTableMobileProps {
  contentStore: ContentStore;
  transactions: Transaction[];
  showOutstandingOnly: boolean;
  isTableOnProductCard: boolean;
  transactionsStore: TransactionsStore;
  isLoading: boolean;
}

const TransactionsAndUsageTableMobile: FC<TransactionsAndUsageTableMobileProps> = (props) => {
  const {
    contentStore,
    transactions,
    showOutstandingOnly,
    isTableOnProductCard,
    transactionsStore,
    isLoading
  } = props;
  const { productItemsStore, productCardStore } = contentStore.rootStore.productStore;

  const noTripsRow = (
    <div style={{ padding: '38px 20% 0 20% ', textAlign: 'center' }}>
      <MobileContentManchet>
        {showOutstandingOnly
          ? contentStore.cmsStore.getContent('NoOutstandings')?.content
          : contentStore.cmsStore.getContent('HereYouWillSeeConsumptionWhenRegistered')?.content}
      </MobileContentManchet>
    </div>
  );

  const showPagination = () =>
    (showOutstandingOnly &&
      transactionsStore.pageSizeOutstanding < transactionsStore.numberOfOutstandingTransactions) ||
    (!showOutstandingOnly && transactionsStore.pageSize < transactionsStore.numberOfTransactions);

  return (
    <div style={{ margin: '0 -3%' }}>
      <Row>
        <Col span={24}>
          <TransactionsAndUsageTableMobileColumnTitleRow contentStore={contentStore} />
          {isLoading ? (
            <LoadingSpinner />
          ) : transactions.length === 0 ? (
            noTripsRow
          ) : (
            transactions.map((transaction: Transaction, index) => (
              <MobileTransactionRow
                contentStore={contentStore}
                transaction={transaction}
                isEven={index % 2 === 0}
                showOutstandingOnly={showOutstandingOnly}
                isTableOnProductCard={isTableOnProductCard}
              />
            ))
          )}
          {productCardStore.isModalVisible &&
            productItemsStore.product &&
            !isTableOnProductCard && (
              <PrivateProductCardModal
                contentStore={contentStore}
                productCardStore={productCardStore}
                product={productItemsStore.product}
              />
            )}
        </Col>
      </Row>
      {showPagination() && !isTableOnProductCard && (
        <MobiletransactionsPagination
          transactionsStore={transactionsStore}
          showOutstandingOnly={showOutstandingOnly}
        />
      )}
    </div>
  );
};

export default observer(TransactionsAndUsageTableMobile);
