/* eslint-disable import/no-cycle */
import { SignUpService, SignUpPrivateRequest } from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import CustomerCreationStore from './CustomerCreationStore';
import RegisterStore from './RegisterStore';

export default class SignUpStore extends BaseStore {
  registerStore: RegisterStore = new RegisterStore(this.rootStore);

  customerCreationStore: CustomerCreationStore = new CustomerCreationStore(this.rootStore);

  signUpPrivate = () => {
    const request: SignUpPrivateRequest = {
      privateCustomer: this.rootStore.createPrivateUserStore.privateCustomer,
      privatePerson: this.rootStore.createPrivateUserStore.privatePerson,
      paymentProviderTicket: this.rootStore.paymentCardStore.paymentMethod
    };

    const promise = SignUpService.signUpPrivate({ body: request });
    return promise;
  };
}
