/* eslint-disable import/no-cycle */
import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { AccountService, ProductInfo, ProductInfoPaginatedList } from '../../swagger/api';

export default class SelectBizzesforConversionStore extends BaseStore {
  bizzes: ProductInfo[] = [];

  numberOfBizzes: number = 0;

  filteredNumberOfBizzes: number = 0;

  pageNumber: number = 1;

  pageSize: number = 0;

  selectedBizzes: ProductInfo[] = [];

  resetSelectedBizzes = () => {
    this.selectedBizzes = [];
  };

  addSelectedBizzes = (selectedBizzes: ProductInfo[]) => {
    selectedBizzes.forEach((bizz: ProductInfo) => {
      const isAlreadySaved = this.selectedBizzes.some(
        (selectedBizz: ProductInfo) => selectedBizz.identifierId === bizz.identifierId
      );
      if (!isAlreadySaved) {
        this.selectedBizzes.push(bizz);
      }
    });
  };

  removeSelectedBizzes = (deselectedBizzes: ProductInfo[]) => {
    this.selectedBizzes = this.selectedBizzes.filter(
      (selectedBizz: ProductInfo) =>
        !deselectedBizzes.some(
          (deselectedBizz: ProductInfo) => deselectedBizz.identifierId === selectedBizz.identifierId
        )
    );
  };

  selectOrDeselectAll = (selected: boolean, selectedBizzes: ProductInfo[]) => {
    if (selected) {
      this.addSelectedBizzes(selectedBizzes);
    } else {
      this.removeSelectedBizzes(selectedBizzes);
    }
  };

  getBizzes = () => {
    this.rootStore.filterStore.bizzesForConversionFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.bizzesForConversionFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    params.userRole = this.rootStore.userStore.person?.role;
    const promise = AccountService.getIdentifiersForAccountAsync(params);
    promise.then((val: ProductInfoPaginatedList) => {
      runInAction(() => {
        this.bizzes = val.paginatedItems!;
        this.pageSize = val.pageSize!;
        this.numberOfBizzes = val.totalCount!;
        this.filteredNumberOfBizzes = val.filteredCount!;
      });
    });
    promise.catch(() => {
      this.bizzes = [];
    });
    return promise;
  };

  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      bizzes: observable,
      filteredNumberOfBizzes: observable,
      pageNumber: observable,
      pageSize: observable,
      selectedBizzes: observable,
      addSelectedBizzes: action,
      getBizzes: action,
      removeSelectedBizzes: action,
      resetSelectedBizzes: action,
      selectOrDeselectAll: action,
      setPageNumber: action
    });
  }
}
