import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { CamptonWhite22 } from '../globalFonts';

const Header = styled.div`
  margin-top: 45px;
`;

interface DefaultAccountPageHeaderProps {
  pageName: string;
}

const DefaultAccountPageHeader: FC<DefaultAccountPageHeaderProps> = (props) => {
  const { pageName } = props;
  return (
    <Header>
      <Row>
        <Col>
          <CamptonWhite22>{pageName}</CamptonWhite22>
        </Col>
        <Col flex="auto" />
      </Row>
    </Header>
  );
};

export default DefaultAccountPageHeader;
