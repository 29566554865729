import { Col, Form, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import OrderBlankBizzer from './components/OrderEmptyProducts';
import AdditionalHeaderInformation from './components/AdditionalHeaderInformation';
import ContactManager from './components/ContactManager';
import DailyContact from './components/DailyContact';
import PaymentMethod from './components/PaymentMethod';
import { ContentSmallCamptonBook, H3CamptonBold30 } from '../../../layout/globalFonts';
import useStore from '../../../hooks/useStore';
import CreateButton from './components/CreateButton';
import FullPageModal from '../../modals/FullPageModal';
import { ReactComponent as CloseIcon } from '../../../layout/icons/close-green.svg';
import AdministrationStore from '../../../stores/AdministrationStore';
import useIsMobileView from '../../../hooks/useIsMobileView';
import { SelectedPaymentMethod } from '../../../stores/adminstration-stores/SubAccountStore';

const StyledHeader = styled(H3CamptonBold30)`
  margin-bottom: 13px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: fixed;
`;

const HeaderSection = styled.div`
  padding-top: 52px;
  text-align: center;
`;

const FormSection = styled(Row)`
  padding-left: 179px;
  padding-right: 179px;
`;

const SubAccountModal: FC = () => {
  const [form] = Form.useForm();
  const administrationStore: AdministrationStore = useStore(AdministrationStore);
  const { administrationAccountStore, subAccountStore, administrationPaymentCardsStore } =
    administrationStore;
  const { signUpStore, contentStore, userStore } = administrationStore.rootStore;
  const { customerCreationStore } = signUpStore;
  const isMobileView = useIsMobileView();
  const [hideCreateButton, setHideCreateButton] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const isPaymentTypeCreditCard = () =>
    form.getFieldValue('paymentType') === SelectedPaymentMethod.CreditCard;
  const createNewSubAccount = contentStore.cmsStore.getContent('CreateNewSubAccount')?.content;
  const subAccountInfo = contentStore.cmsStore.getContent('SubAccountInfo')?.content;
  const requiredFields: string[] = [
    'cvr',
    'companyName',
    'streetName',
    'zipCode',
    'cityName',
    ...(subAccountStore.selectedPaymentMethod === SelectedPaymentMethod.Invoice
      ? ['recipientEmail']
      : subAccountStore.selectedPaymentMethod === SelectedPaymentMethod.EANInvoice
      ? ['eanNumber']
      : [])
  ];

  const calculateHideCreateButton = () =>
    !form.isFieldsTouched(requiredFields, true) ||
    !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
    !!(isPaymentTypeCreditCard() && !administrationPaymentCardsStore.paymentCardSelectedInDropdown);

  useEffect(() => {
    setHideCreateButton(calculateHideCreateButton());
  }, [administrationPaymentCardsStore.paymentCardSelectedInDropdown]);

  const onFieldsChange = () => {
    setHideCreateButton(calculateHideCreateButton());
  };

  const dailyContactName = form.getFieldValue('dailyContactName');
  const dailyContactEmail = form.getFieldValue('dailyContactEmail');
  const dailyContactPhone = form.getFieldValue('dailyContactPhoneNumber');

  form.setFieldsValue({
    managerName: userStore.currentAccount?.contractManagerFirstName,
    managerSurname: userStore.currentAccount?.contractManagerLastName,
    managerEmail: userStore.currentAccount?.contractManagerEmail,
    managerPhoneNumber: userStore.currentAccount?.contractManagerPhone,
    dailyContactName:
      dailyContactName === undefined
        ? userStore.currentAccount?.dailyContactPersonName
        : dailyContactName,
    dailyContactEmail:
      dailyContactEmail === undefined
        ? userStore.currentAccount?.dailyContactPersonEmail
        : dailyContactEmail,
    dailyContactPhoneNumber:
      dailyContactPhone === undefined
        ? userStore.currentAccount?.dailyContactPersonPhone
        : dailyContactPhone
  });

  const onSubmit = async () => {
    setIsCreating(true);
    try {
      await subAccountStore.createSubAccount(form, contentStore, administrationAccountStore);
    } catch (error) {
      setIsCreating(false);
    }
    setIsCreating(false);
  };

  const onModalCancel = () => {
    administrationAccountStore.closeModal();
    subAccountStore.setSelectedPerson(undefined);
  };

  return (
    <FullPageModal
      visible={administrationAccountStore.isModalVisible}
      footer={null}
      onCancel={onModalCancel}
      maskClosable
      closeIcon={<StyledCloseIcon />}
      transitionName="modalFade"
      isopen={administrationAccountStore.isAnimationOpen}
      ismobileview={isMobileView}
    >
      <Row justify="center" style={{ marginBottom: '67px' }}>
        <Col>
          <HeaderSection>
            <StyledHeader>{createNewSubAccount}</StyledHeader>
            <ContentSmallCamptonBook>{subAccountInfo}</ContentSmallCamptonBook>
          </HeaderSection>
        </Col>
      </Row>
      <Row justify="center">
        <Form
          name="CreateSubAccount"
          form={form}
          onFinish={onSubmit}
          initialValues={{
            remember: true
          }}
          onFieldsChange={() => {
            onFieldsChange();
          }}
        >
          <FormSection>
            <Col>
              <AdditionalHeaderInformation
                form={form}
                contentStore={contentStore}
                customerStore={customerCreationStore}
              />
              <ContactManager contentStore={contentStore} />
              <DailyContact
                form={form}
                contentStore={contentStore}
                subAccountStore={subAccountStore}
              />
              <PaymentMethod
                form={form}
                subAccountStore={subAccountStore}
                contentStore={contentStore}
              />
              <OrderBlankBizzer contentStore={contentStore} />
            </Col>
          </FormSection>
          {!hideCreateButton && <CreateButton loading={isCreating} contentStore={contentStore} />}
        </Form>
      </Row>
    </FullPageModal>
  );
};

export default observer(SubAccountModal);
