import { Tabs } from 'antd';
import styled from 'styled-components';

export const TabsPanel = styled(Tabs)`
  .ant-tabs-nav-wrap {
    margin-top: 26px;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-ink-bar {
    background: #00a050;
    height: 3px !important;
    border-radius: 1px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 60px;
  }

  .ant-tabs-tab-btn {
    font-family: Campton Semi Bold;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: #2d2d2d !important;
    .ant-tabs-tab.ant-tabs-tab-active {
      text-shadow: none;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  overflow: unset;
`;

export default { TabsPanel };
