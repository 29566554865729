import CMSStore from '../stores/content-stores/CMSStore';

const dateMonthNameYearFormatting = (dateTime: Date, cmsStore: CMSStore) => {
  const modifiedDate = new Date(dateTime);
  const year = modifiedDate.getFullYear().toString();
  const month = modifiedDate.toLocaleString(cmsStore.localeFromPerson(), { month: 'long' });
  const date = modifiedDate.getDate() < 10 ? `0${modifiedDate.getDate()}` : modifiedDate.getDate();
  return `${date}. ${month} ${year}`;
};

export default dateMonthNameYearFormatting;
