import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../common-components/CustomModal';
import ProductItemsStore from '../../../../stores/product-stores/ProductItemsStore';
import { ProductInfo, IdentifierStatus } from '../../../../swagger/api/index';
import ContentStore from '../../../../stores/ContentStore';
import useStore from '../../../../hooks/useStore';
import { isTollingBox } from '../../../../utils/productTypeUtilities';

interface ActiveInactiveModalProps {
  closeModal: () => void;
  productInfo: ProductInfo;
  productItemsStore: ProductItemsStore;
}

const activeInactiveModal: FC<ActiveInactiveModalProps> = (props) => {
  const { closeModal, productInfo, productItemsStore } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const [isLoading, setIsloading] = useState(false);

  const activeEntry = isTollingBox(productInfo.productType)
    ? 'ActivateTollBox'
    : 'DeactivateProduct';

  const inactiveEntry = isTollingBox(productInfo.productType)
    ? 'DeactivateTollBox'
    : 'DeactivateProduct';

  const header =
    productInfo.identifierStatus === IdentifierStatus.Active
      ? contentStore.cmsStore.getContent(inactiveEntry)?.content!
      : contentStore.cmsStore.getContent(activeEntry)?.content!;

  const content =
    productInfo.identifierStatus === IdentifierStatus.Active
      ? contentStore.cmsStore.getContent(inactiveEntry)?.subText!
      : contentStore.cmsStore.getContent(activeEntry)?.subText!;

  const primaryButtonText = contentStore.cmsStore.getContent('Correct')?.content;

  const greenLinkText = contentStore.cmsStore.getContent('Undo')?.content;

  const handlePrimaryButtonClick = async () => {
    setIsloading(true);
    try {
      await productItemsStore.toggleBizzStatus(productInfo.identifierId!);
      await productItemsStore.getProduct(productInfo.identifierId!);
    } finally {
      setIsloading(false);
      closeModal();
    }
  };

  return (
    <CustomModal
      header={header}
      contentText={content}
      primaryButtonText={primaryButtonText}
      isLoading={isLoading}
      closable
      greenLinkText={greenLinkText}
      onGreenLinkClick={closeModal}
      onPrimaryButtonClick={handlePrimaryButtonClick}
    />
  );
};

export default observer(activeInactiveModal);
