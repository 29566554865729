import React, { FC } from 'react';
import { Col, Row, Divider, Input, Form, Tooltip, FormInstance } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import ActiveInactiveModal from '../modals/ActiveInactiveModal';
import ProductIsBlockedModal from '../modals/ProductIsBlockedModal';
import ProductMenu from '../../product-menu/ProductMenu';
import { OutlinedIconButton } from '../../../../common-components/Buttons';
import { StyledToggle } from '../../../../common-components/Selectors';
import ModalStore from '../../../../stores/ModalStore';
import useStore from '../../../../hooks/useStore';
import PanDropdown from './PanDropdown';
import {
  IdentifierInfo,
  IdentifierStatus,
  ProductInfo,
  ProductType
} from '../../../../swagger/api/index';
import ProductStore from '../../../../stores/ProductStore';
import { ReactComponent as BizzIcon } from '../../../../layout/icons/payment/bizz.svg';
import { ReactComponent as CheckIcon } from '../../../../layout/icons/check.svg';
import { ReactComponent as LicensePlateIcon } from '../../../../layout/icons/payment/license-plate.svg';
import { ReactComponent as MoreIcon } from '../../../../layout/icons/more-lines.svg';
import { ReactComponent as OneTimeANPRIcon } from '../../../../layout/icons/one-time-anpr.svg';
import { ReactComponent as TollBoxIcon } from '../../../../layout/icons/tolling-box.svg';
import { ReactComponent as TollAppIcon } from '../../../../layout/icons/tolling-app.svg';
import { ReactComponent as XIcon } from '../../../../layout/icons/circle-x-grey.svg';
import {
  ContentSmallCamptonBook,
  SmallGrey12,
  OutlinedButtonText,
  OutlinedButtonTextGrey
} from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import ManageUserAccessStore from '../../../../stores/ManageUserAccessStore';
import { overlayInnerStyle } from '../../../../common-components/TooltipStyling';
import ProductStatusAndText from '../../private-product-features/ProductStatusAndText';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import {
  isANPR,
  isANPROrOneTimeANPR,
  isBizz,
  isOneTimeANPR,
  isTollingApp,
  isTollingBox
} from '../../../../utils/productTypeUtilities';
import dateMonthNameYearFormatting from '../../../../utils/dateMonthnameYearFormatting';
import ConfirmCloseProductModal from '../modals/ConfirmCloseProductModal';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';

interface ProductCardHeaderProps {
  product: ProductInfo;
}

const Header = styled.div<{ ismobileview: boolean }>`
  position: relative;
  margin: 0;
  ${(props) =>
    props.ismobileview ? 'padding: 5.09vh 20px 3.56vh;' : 'padding: 5.09vh 10.65% 3.56vh;'}
  .ant-switch-checked {
    background: #00a050;
  }
`;

const ReferenceInput = styled(Input)`
  padding: 0;
  font-family: Campton Bold;
  font-size: 38px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  text-overflow: ellipsis;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0px;
  background-color: #969696;
  border-radius: 5px;
`;

const Toggle = styled(StyledToggle)`
  margin: 0 8px;
`;

const ToggleBox = styled.div`
  padding: 9px 9px 9px 15px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 48px;
`;

const TitleRow = styled(Row)`
  margin-bottom: 10px;
`;

const StyledMoreIcon = styled(MoreIcon)`
  margin-right: 10px;
`;

const StyledXIcon = styled(XIcon)`
  margin-right: 10px;
`;

const StyledLicensePlateIcon = styled(LicensePlateIcon)`
  margin-top: 5px;
`;

const StyledBizzIcon = styled(BizzIcon)`
  margin-top: 2px;
`;

const StyledTollBoxIcon = styled(TollBoxIcon)`
  margin-top: -5px;
`;

const StyledTollAppIcon = styled(TollAppIcon)`
  margin-top: -5px;
`;

const ProductIsBlocked = styled(OutlinedButtonText)`
  color: #ff004a;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 12px 20px;
`;

const ToggleAndMoreCol = styled(Col)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex: none;
`;

interface ProductCardHeaderProps {
  product: ProductInfo;
  contentStore: ContentStore;
  form?: FormInstance;
}

const ProductCardHeader: FC<ProductCardHeaderProps> = (props) => {
  const { product, contentStore, form } = props;
  const modalStore: ModalStore = useStore(ModalStore);
  const manageUserAccessStore: ManageUserAccessStore = useStore(ManageUserAccessStore);
  const productStore: ProductStore = useStore(ProductStore);
  const { productItemsStore, productCardStore } = productStore;
  const isMobileView = useIsMobileView();

  let paymentIcon;
  switch (product.productType) {
    case ProductType.Bizz:
      paymentIcon = <StyledBizzIcon />;
      break;
    case ProductType.BizzCommercial:
      paymentIcon = <StyledBizzIcon />;
      break;
    case ProductType.LicensePlate:
      paymentIcon = <StyledLicensePlateIcon />;
      break;
    case ProductType.OneTimeANPR:
      paymentIcon = <OneTimeANPRIcon />;
      break;
    case ProductType.TollingBox:
      paymentIcon = <StyledTollBoxIcon />;
      break;
    case ProductType.TollingApp:
      paymentIcon = <StyledTollAppIcon />;
      break;
    default:
  }

  let idNumberTitle = '';
  switch (product.productType) {
    case ProductType.Bizz:
      idNumberTitle = contentStore.cmsStore.getContent('BizzNumber')?.content ?? '';
      break;
    case ProductType.BizzCommercial:
      idNumberTitle = contentStore.cmsStore.getContent('BizzNumber')?.content ?? '';
      break;
    case ProductType.TollingBox:
      idNumberTitle = contentStore.cmsStore.getContent('Id&PanForTollBox')?.content ?? '';
      break;
    default:
      idNumberTitle = contentStore.cmsStore.getContent('VKnumber')?.content ?? '';
  }

  const productTypeText = contentStore.cmsStore.getContent(product.productType!)?.content;

  const referenceTitle = manageUserAccessStore.isPrivateCustomer()
    ? contentStore.cmsStore
        .getContent('NameOfProduct')
        ?.content.replace(
          '{productType}',
          contentStore.cmsStore.getContent(product.productType!)!.content
        )
    : contentStore.cmsStore.getContent('Reference')?.content;

  const idNumberTooltip = isTollingBox(product.productType)
    ? contentStore.cmsStore.getContent('Id&PanForTollBox')?.tooltip
    : !isANPR(product.productType) && !isTollingApp(product.productType)
    ? contentStore.cmsStore.getContent('Id&Pan')?.tooltip
    : '';

  const productStatusText =
    product.identifierStatus === IdentifierStatus.Blocked
      ? `${product.productType}IsBlocked`
      : undefined;

  let productStatus: string = '';

  switch (product.identifierStatus as IdentifierStatus) {
    case IdentifierStatus.Active:
      productStatus = contentStore.cmsStore.getContent('ProductStatusActive')?.content!;
      break;
    case IdentifierStatus.Inactive:
      if (isANPR(product.productType)) {
        productStatus = contentStore.cmsStore.getContent('ProductIsBlocked')?.content!;
      } else {
        productStatus = contentStore.cmsStore.getContent('ProductStatusInactive')?.content!;
      }
      break;
    default:
      productStatus = contentStore.cmsStore.getContent('ProductIsBlocked')?.content!;
  }

  const deleteProductIsNotAllowed = manageUserAccessStore.isProductAccessUser();

  const showStatusModal = () => {
    if (product.identifierStatus === IdentifierStatus.Blocked) {
      modalStore.openModal(
        <ProductIsBlockedModal contentStore={contentStore} productType={product.productType!} />
      );
    }
  };

  const openCloseProductModal = () =>
    modalStore.openModal(
      <ConfirmCloseProductModal
        contentStore={contentStore}
        productInfo={product}
        modalStore={modalStore}
        productCardStore={productCardStore}
      />
    );

  const openActiveInactiveModal = () => {
    if (
      product.identifierStatus === IdentifierStatus.Active ||
      product.identifierStatus === IdentifierStatus.Inactive
    ) {
      modalStore.openModal(
        <ActiveInactiveModal
          closeModal={modalStore.closeModal}
          productInfo={product}
          productItemsStore={productItemsStore}
        />
      );
    }
  };

  const updatePrivateProductReference = () => {
    const values: IdentifierInfo = JSON.parse(JSON.stringify(product));
    const changedValues = form!.getFieldsValue();
    values.reference = changedValues.reference;
    productCardStore.saveProductcardChanges(product.identifierId!, values);
  };

  const showToggleComponent = () =>
    (isBizz(product.productType) || isTollingBox(product.productType)) &&
    (product.identifierStatus === IdentifierStatus.Active ||
      product.identifierStatus === IdentifierStatus.Inactive);

  const showStaticProductStatus = () =>
    !showToggleComponent() &&
    !(
      (isANPROrOneTimeANPR(product.productType) || isTollingApp(product.productType)) &&
      product.identifierStatus === IdentifierStatus.Active
    );

  const toggleComponent = (
    <ToggleBox>
      <Row>
        <Col>
          <OutlinedButtonText>{productStatus}</OutlinedButtonText>
        </Col>
        <Col>
          <Toggle
            checked={product.identifierStatus === IdentifierStatus.Active}
            onClick={openActiveInactiveModal}
          />
        </Col>
      </Row>
    </ToggleBox>
  );

  const staticProductStatus = (
    <Row>
      <ProductIsBlocked>{productStatus}</ProductIsBlocked>
    </Row>
  );

  const convertingToTollingBoxText = (
    <Col style={{ marginRight: 14 }}>
      <Row align="middle" justify="center" gutter={8}>
        <CheckIcon />
        <Col>
          <OutlinedButtonText>
            {contentStore.cmsStore.getContent('ConvertingToTollingBox')?.subText}
          </OutlinedButtonText>
        </Col>
      </Row>
    </Col>
  );

  const isTollAppActivatedByUserText = (
    <Col>
      <Row align="middle" justify="center" gutter={8}>
        {product.tollAppUser?.isTollAppActivated && <CheckIcon style={{ marginRight: 5 }} />}
        <Col>
          {product.tollAppUser?.isTollAppActivated ? (
            <OutlinedButtonText>
              {contentStore.cmsStore.getContent('TollAppIsActivatedByUser')?.content}
            </OutlinedButtonText>
          ) : (
            <OutlinedButtonTextGrey>
              {contentStore.cmsStore.getContent('TollAppNotActivatedByUser')?.content}
            </OutlinedButtonTextGrey>
          )}
        </Col>
      </Row>
    </Col>
  );

  const moveOrRequestToCancelProductButton = (
    <ProductMenu product={product} isMoveToSubaccountLoading={false}>
      <OutlinedIconButton icon={<StyledMoreIcon />}>
        <OutlinedButtonText>{contentStore.cmsStore.getContent('More')?.content}</OutlinedButtonText>
      </OutlinedIconButton>
    </ProductMenu>
  );

  const showCloseProductButton = () =>
    (isANPROrOneTimeANPR(product.productType) || isTollingApp(product.productType)) &&
    !deleteProductIsNotAllowed;

  const closeProductButton = (
    <OutlinedIconButton
      icon={<StyledXIcon />}
      onClick={openCloseProductModal}
      disabled={deleteProductIsNotAllowed}
    >
      <OutlinedButtonText>
        {contentStore.cmsStore.getContent('CloseProduct')?.content}
      </OutlinedButtonText>
    </OutlinedIconButton>
  );

  const renderToggleButtonAndMoreButton = (
    <ToggleAndMoreCol>
      {product.convertingToTollingBoxId && convertingToTollingBoxText}
      {productCardStore.isLoadingExtraData ? (
        <LoadingSpinner size="sm" />
      ) : (
        product.tollAppUser && isTollAppActivatedByUserText
      )}
      <Row align="middle" justify="space-between" gutter={16}>
        <Col>
          <Tooltip
            placement="top"
            title={contentStore.cmsStore.getContent('ProductCardStatusToggle')?.tooltip}
            color="#2d2d2d"
            overlayInnerStyle={overlayInnerStyle}
          >
            {showToggleComponent() && toggleComponent}
            {showStaticProductStatus() && staticProductStatus}
          </Tooltip>
        </Col>
        <Col>
          {manageUserAccessStore.showMoveOrRequestToCancelProductButton(product.productType!) &&
            moveOrRequestToCancelProductButton}
          {showCloseProductButton() && closeProductButton}
        </Col>
      </Row>
    </ToggleAndMoreCol>
  );

  const renderIdAndPanWithDropdown = (
    <Tooltip
      placement="bottom"
      title={idNumberTooltip}
      color="#2d2d2d"
      overlayInnerStyle={overlayInnerStyle}
    >
      <Col>
        <TitleRow>
          <SmallGrey12>{idNumberTitle}</SmallGrey12>
        </TitleRow>
        <Row>
          <PanDropdown product={product} />
        </Row>
      </Col>
    </Tooltip>
  );

  const remainingTrips = (
    <Col>
      <TitleRow>
        <SmallGrey12>{contentStore.cmsStore.getContent('RemainingTrips')?.content}</SmallGrey12>
      </TitleRow>
      <Row>
        <Col>
          <ContentSmallCamptonBook>{product.remainingTrips}</ContentSmallCamptonBook>
        </Col>
      </Row>
    </Col>
  );

  const renderOneTimeANPRExpirationDate = (
    <Col>
      <TitleRow>
        <SmallGrey12>{contentStore.cmsStore.getContent('OneTimeAnprExpires')?.content}</SmallGrey12>
      </TitleRow>
      <Row>
        <Col>
          <ContentSmallCamptonBook>
            {dateMonthNameYearFormatting(product.expirationDate!, contentStore.cmsStore)}
          </ContentSmallCamptonBook>
        </Col>
      </Row>
    </Col>
  );

  const renderCustomerNumber = (
    <Col>
      <TitleRow>
        <SmallGrey12>{contentStore.cmsStore.getContent('CustomerNr')?.content}</SmallGrey12>
      </TitleRow>
      <Row>
        <Col>
          <ContentSmallCamptonBook>{product.customerNumber}</ContentSmallCamptonBook>
        </Col>
      </Row>
    </Col>
  );

  const renderPrivateProductStatusWithText = (
    <Col>
      <TitleRow justify="end">
        <SmallGrey12>{contentStore.cmsStore.getContent('Status')?.content}</SmallGrey12>
      </TitleRow>
      <Row
        style={{ cursor: product.identifierStatus === IdentifierStatus.Blocked ? 'pointer' : '' }}
        onClick={showStatusModal}
      >
        <ProductStatusAndText
          productStatus={product.identifierStatus!}
          contentStore={contentStore}
          customTextSystemId={productStatusText}
          isBlockedTextRed
        />
      </Row>
    </Col>
  );

  return (
    <Header ismobileview={isMobileView}>
      <Row align="bottom" justify="space-between" gutter={16} style={{ flexWrap: 'nowrap' }}>
        <Col flex={1}>
          <TitleRow>
            <SmallGrey12>{referenceTitle}</SmallGrey12>
          </TitleRow>
          <Row>
            <Form.Item name="reference" noStyle>
              <ReferenceInput
                maxLength={30}
                bordered={false}
                onBlur={() => {
                  if (
                    manageUserAccessStore.isPrivateCustomer() &&
                    form?.getFieldValue('reference') !== product.reference
                  ) {
                    updatePrivateProductReference();
                  }
                }}
              />
            </Form.Item>
          </Row>
        </Col>
        {!manageUserAccessStore.isPrivateCustomer() && renderToggleButtonAndMoreButton}
      </Row>
      <StyledDivider />
      <Row gutter={[48, 14]}>
        <Col span={isMobileView ? 14 : undefined}>
          <TitleRow>
            <SmallGrey12>{contentStore.cmsStore.getContent('productHeader')?.content}</SmallGrey12>
          </TitleRow>
          <Row gutter={[48, 14]} wrap={false}>
            <Col>
              <Row gutter={8} wrap={false}>
                <Col>{paymentIcon}</Col>
                <Col style={{ whiteSpace: 'nowrap' }}>
                  <ContentSmallCamptonBook>{productTypeText}</ContentSmallCamptonBook>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {isOneTimeANPR(product.productType)
          ? remainingTrips
          : !isMobileView && renderIdAndPanWithDropdown}

        {!isMobileView && <Col flex="auto" />}

        <Col span={isMobileView ? 10 : undefined}>
          <Row justify="end" wrap={false} gutter={48}>
            {isOneTimeANPR(product.productType) &&
              !manageUserAccessStore.isPrivateCustomer() &&
              renderOneTimeANPRExpirationDate}
            {!manageUserAccessStore.isPrivateCustomer() && renderCustomerNumber}
            {manageUserAccessStore.isPrivateCustomer() && renderPrivateProductStatusWithText}
          </Row>
        </Col>
      </Row>
      {isMobileView && <Row style={{ paddingTop: '10px' }}>{renderIdAndPanWithDropdown}</Row>}
    </Header>
  );
};

export default observer(ProductCardHeader);

ProductCardHeader.defaultProps = {
  form: undefined
};
