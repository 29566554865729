import AuthenticationStore from '../stores/AuthenticationStore';

const logOut = (authenticationStore: AuthenticationStore) => {
  const promise = authenticationStore.authContext?.logout({
    logoutParams: { returnTo: window.location.origin }
  });
  promise?.then(() => {
    sessionStorage.clear();
    authenticationStore.setIsAuthenticated(false);
  });
};

export default logOut;
