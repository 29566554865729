/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import {
  Euronorm,
  FuelType,
  IdentifierStatus,
  ProductType,
  UserRole,
  VehicleSize
} from '../../swagger/api/index';
import { Filters, FilterMenuContent, FilterObject } from '../../Types';

export type BizzConversionTableFilter = {
  identifierStatuses: FilterObject[];
  specialAgreementIds: FilterObject[];
  euronorms: FilterObject[];
  vehicleSizes: FilterObject[];
  fuelTypes: FilterObject[];
  countryIds: FilterObject[];
  hasGreenDiscount: FilterObject[];
};

export default class BizzesForConversionFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setBizzesToConvertFilterInit() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: BizzConversionTableFilter = {
    identifierStatuses: [],
    specialAgreementIds: [],
    euronorms: [],
    vehicleSizes: [],
    fuelTypes: [],
    countryIds: [],
    hasGreenDiscount: []
  };

  temporaryFilters: BizzConversionTableFilter = {
    identifierStatuses: [],
    specialAgreementIds: [],
    euronorms: [],
    vehicleSizes: [],
    fuelTypes: [],
    countryIds: [],
    hasGreenDiscount: []
  };

  requestParams: {
    accountId: string;
    userRole?: UserRole;
    searchString?: string | undefined;
    identifierStatuses?: IdentifierStatus[] | undefined;
    productTypes?: ProductType[] | undefined;
    specialAgreementIds?: string[] | undefined;
    euronorms?: Euronorm[] | undefined;
    vehicleTypes?: VehicleSize[] | undefined;
    fuelTypes?: FuelType[] | undefined;
    countryIds?: string[] | undefined;
    hasGreenDiscount?: boolean | undefined;
    pageNumber?: number | undefined;
  } = {
    accountId: '',
    productTypes: [ProductType.BizzCommercial]
  };

  setRequestParams = () => {
    this.requestParams.searchString =
      this.rootStore.filterStore.bizzesForConversionFilterStore.searchValue;
    this.requestParams.pageNumber =
      this.rootStore.orderStore.selectBizzesforConversionStore.pageNumber;
    this.requestParams.identifierStatuses = this.activeFilters.identifierStatuses.map(
      (element: FilterObject) => element.filter as IdentifierStatus
    );
    this.requestParams.specialAgreementIds = this.activeFilters.specialAgreementIds.map(
      (element: FilterObject) => element.filter as string
    );
    this.requestParams.euronorms = this.activeFilters.euronorms.map(
      (element: FilterObject) => element.filter as Euronorm
    );

    if (this.activeFilters.vehicleSizes !== undefined) {
      this.requestParams.vehicleTypes = this.activeFilters.vehicleSizes.map(
        (element: FilterObject) => element.filter as VehicleSize
      );
    }

    this.requestParams.fuelTypes = this.activeFilters.fuelTypes.map(
      (element: FilterObject) => element.filter as FuelType
    );
    this.requestParams.countryIds = this.activeFilters.countryIds.map(
      (element: FilterObject) => element.filter as string
    );
    if (this.activeFilters.hasGreenDiscount.length === 1)
      this.requestParams.hasGreenDiscount = this.activeFilters.hasGreenDiscount[0]
        .filter as boolean;
    else this.requestParams.hasGreenDiscount = undefined;
  };

  searchValue: string = '';

  setSearchValue = (searchValue: string) => {
    this.searchValue = searchValue;
    this.rootStore.orderStore.selectBizzesforConversionStore.setPageNumber(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.orderStore.selectBizzesforConversionStore.setPageNumber(1);
  };

  setTemporaryFilters = (filter: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filter));
  };

  resetBizzesForConversionFilterStore = () => {
    const emptyFilters: Filters = {
      identifierStatuses: [],
      specialAgreementIds: [],
      euronorms: [],
      vehicleSizes: [],
      fuelTypes: [],
      countryIds: [],
      hasGreenDiscount: []
    };
    this.setActiveFilters(emptyFilters);
    this.setTemporaryFilters(emptyFilters);
    this.searchValue = '';
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      activeFilters: observable,
      temporaryFilters: observable,
      searchValue: observable,
      resetBizzesForConversionFilterStore: action,
      setSearchValue: action,
      setActiveFilters: action,
      setTemporaryFilters: action
    });
  }
}
