import dateFormatting from './dateFormatting';

const dateTimeFormatting = (dateTime: Date) => {
  const modifiedDate = new Date(dateTime);
  const date = dateFormatting(modifiedDate);
  const hours =
    modifiedDate.getHours() < 10 ? `0${modifiedDate.getHours()}` : modifiedDate.getHours();
  const minutes =
    modifiedDate.getMinutes() < 10 ? `0${modifiedDate.getMinutes()}` : modifiedDate.getMinutes();
  return `${date}  ${hours}:${minutes}`;
};

export default dateTimeFormatting;
