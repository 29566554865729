import { Col, Form, FormInstance, Row } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledInput, StyledSelect } from '../../../../common-components/Input';
import { DropdownArrow } from '../../../../common-components/StyledIcons';
import { SmallGrey12 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import CustomerCreationStore from '../../../../stores/CustomerCreationStore';

const Select = styled(StyledSelect)`
  width: 245px !important;
`;

const RegisterInput = styled(StyledInput)`
  width: 245px;
  height: 48px;
`;

const NumberInput = styled(StyledInput)`
  width: 96px;
  height: 48px;
`;

const ByInput = styled(StyledInput)`
  width: 137px;
  height: 48px;
`;

const Required = (value: string, required: string) => {
  if (value === undefined || value === '') {
    return Promise.reject(new Error(required));
  }
  return Promise.resolve();
};

const ValidateIsOnlyNumbers = (number: string, onlyNumbers: string) => {
  if (number === undefined || number === '') {
    return Promise.reject(new Error(onlyNumbers));
  }
  // Allowed only numbers
  if (!number.match('^[0-9\b]+$') && number.match('[a-zA-Z]+')) {
    return Promise.reject(new Error(onlyNumbers));
  }

  return Promise.resolve();
};

const ValidateIsOnlyCharacters = (character: string) => {
  if (character === undefined || character === '') {
    return Promise.reject(new Error('Only symbols'));
  }
  // Only characters
  if (character.match('[0-9\b]+')) {
    return Promise.reject(new Error('Only symbols'));
  }

  return Promise.resolve();
};

const GetCompanyInfo = (
  character: string,
  customerStore: CustomerCreationStore,
  form: FormInstance
) => {
  if (character.length === 8) {
    customerStore.setCustomerInfo(character, form);
  }

  return Promise.resolve();
};

interface SubAccountInformationProps {
  form: FormInstance;
  contentStore: ContentStore;
  customerCreationStore: CustomerCreationStore;
}

const SubAccountInformation: FC<SubAccountInformationProps> = (props) => {
  const { form, contentStore, customerCreationStore } = props;
  const enter = contentStore.cmsStore.getContent('Enter')?.content;
  const cvr = contentStore.cmsStore.getContent('CVR')?.content;
  const companyName = contentStore.cmsStore.getContent('CompanyName')?.content;
  const adress = contentStore.cmsStore.getContent('Adress')?.content;
  const zipCode = contentStore.cmsStore.getContent('ZipCode')?.content;
  const town = contentStore.cmsStore.getContent('Town')?.content;
  const country = contentStore.cmsStore.getContent('Country')?.content;
  const onlyNumbers = contentStore.cmsStore.getContent('useOnlyNumbers')!.content;
  const onlySymbols = contentStore.cmsStore.getContent('SymbolsValidation')!.content;
  const required = contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content;
  const countries = contentStore.genericContentStore.allCountries;
  const defaultCountry = countries.filter((e) => e.name === 'Denmark' || e.name === 'Danmark')[0]
    ?.name;

  const gutterNum = 20;

  return (
    <>
      <Row gutter={gutterNum}>
        <Col>
          <SmallGrey12>{cvr}</SmallGrey12>
          <Form.Item
            name="cvr"
            rules={[
              {
                required: true
              }
            ]}
          >
            <RegisterInput
              maxLength={100}
              placeholder={`${enter} ${cvr}`}
              onChange={(e) => GetCompanyInfo(e.target.value, customerCreationStore, form)}
              type="text"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutterNum}>
        <Col>
          <SmallGrey12>{companyName}</SmallGrey12>
          <Form.Item
            name="companyName"
            rules={[
              {
                required: true,
                validator: () => Required(form.getFieldValue('companyName'), required!)
              }
            ]}
          >
            <RegisterInput
              maxLength={100}
              placeholder={`${enter} ${companyName?.toLowerCase()}`}
              type="text"
            />
          </Form.Item>
        </Col>
        <Col>
          <SmallGrey12>{adress}</SmallGrey12>
          <Form.Item
            name="streetName"
            rules={[
              {
                required: true,
                validator: () => Required(form.getFieldValue('streetName'), required!)
              }
            ]}
          >
            <RegisterInput
              maxLength={100}
              placeholder={`${enter} ${adress?.toLowerCase()}`}
              type="text"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutterNum}>
        <Col>
          <Row gutter={gutterNum / 2} style={{ gap: '2px' }}>
            <Col>
              <SmallGrey12>{zipCode}</SmallGrey12>
              <Form.Item
                name="zipCode"
                rules={[
                  {
                    required: true,
                    validator: () =>
                      ValidateIsOnlyNumbers(form.getFieldValue('zipCode'), onlyNumbers)
                  }
                ]}
              >
                <NumberInput
                  maxLength={50}
                  placeholder={`${enter} ${zipCode?.toLowerCase()}`}
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{town}</SmallGrey12>
              <Form.Item
                name="cityName"
                rules={[
                  {
                    message: onlySymbols,
                    validator: () => ValidateIsOnlyCharacters(form.getFieldValue('cityName'))
                  }
                ]}
              >
                <ByInput placeholder={`${enter} ${town?.toLowerCase()}`} type="text" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <SmallGrey12>{country}</SmallGrey12>
          {defaultCountry !== undefined && (
            <Form.Item
              name="countryName"
              initialValue={defaultCountry}
              valuePropName={defaultCountry}
            >
              <Select
                defaultValue={defaultCountry}
                getPopupContainer={(trigger) => trigger}
                suffixIcon={<DropdownArrow />}
              >
                {countries.map((c) => (
                  <RegisterInput maxLength={100} value={c.name} key={c.id}>
                    {contentStore.cmsStore.getContent(c.name!)?.content!}
                  </RegisterInput>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SubAccountInformation;
