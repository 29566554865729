import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { RichText } from '@graphcms/rich-text-react-renderer';
import useStore from '../hooks/useStore';
import MultiTablePageLayout from '../layout/components/MultibleTablePageLayout';
import ContentStore from '../stores/ContentStore';
import TransactionsAndUsageTableDesktop from '../features/transaction-features/transactions-and-usage/TransactionsAndUsageTableDesktop';
import useIsMobileView from '../hooks/useIsMobileView';
import TransactionsAndUsageTableMobile from '../features/transaction-features/transactions-and-usage/TransactionsAndUsageTableMobile';
import { NotificationType, Notification } from '../swagger/api';
import useFetchListDataWithPagination from '../hooks/fetchDataHooks/useFetchListDataWithPagination';
import { GreenLinkText } from '../layout/globalFonts';

const TransactionAndUsage: FC = () => {
  const contentStore: ContentStore = useStore(ContentStore);
  const { transactionsStore, notificationStore } = contentStore.rootStore;
  const isMobileView = useIsMobileView();

  const isTransactionsLoading = useFetchListDataWithPagination(
    transactionsStore.getAccountTransactions,
    transactionsStore.transactionsPageNumber
  );

  const isOutstandingTransactionsLoading = useFetchListDataWithPagination(
    transactionsStore.getAccountOutstandingTransactions,
    transactionsStore.outstandingPagenumber
  );

  const transactions =
    transactionsStore.activeTab === 1
      ? transactionsStore.accountTransactions
      : transactionsStore.accountOutstandingTransactions;

  const showOutstandingOnly = transactionsStore.activeTab !== 1;

  const missingPaymentNotification = notificationStore.notifications.filter(
    (noti: Notification) => noti.type === NotificationType.MissingPayment
  );

  const isLoading = showOutstandingOnly ? isOutstandingTransactionsLoading : isTransactionsLoading;

  const table = isMobileView ? (
    <TransactionsAndUsageTableMobile
      contentStore={contentStore}
      transactions={transactions}
      showOutstandingOnly={showOutstandingOnly}
      isTableOnProductCard={false}
      transactionsStore={transactionsStore}
      isLoading={isLoading}
    />
  ) : (
    <TransactionsAndUsageTableDesktop
      contentStore={contentStore}
      transactionsStore={transactionsStore}
      showOutstandingOnly={showOutstandingOnly}
      isTableOnProductCard={false}
      transactions={transactions}
      isLoading={isLoading}
    />
  );

  const notificationCircleContent =
    transactionsStore.numberOfOutstandingTransactions > 99
      ? '99'
      : transactionsStore.numberOfOutstandingTransactions;

  const previousTransactions = (
    <div>
      <RichText
        content={
          contentStore.cmsStore.getContent('PreviousTransactionAndUsage')?.richContent?.raw ?? []
        }
        renderers={{
          p: ({ children }) => (
            <p style={{ marginBottom: '0px', lineHeight: '19px' }}>{children}</p>
          ),
          a: ({ children, href }) => (
            <a target="_blank" href={href} rel="noopener noreferrer">
              <GreenLinkText style={{ lineHeight: '19px' }}>{children}</GreenLinkText>
            </a>
          )
        }}
      />
    </div>
  );

  return (
    <MultiTablePageLayout
      pageName={contentStore.cmsStore.getContent('TransactionAndUsage')?.content!}
      subText={previousTransactions}
      primaryTableName={contentStore.cmsStore.getContent('TransactionAndUsage')?.content!}
      primaryTabToolTip={contentStore.cmsStore.getContent('TransactionAndUsage')?.tooltip!}
      secondaryTableName={contentStore.cmsStore.getContent('Outstanding')?.content!}
      secondaryTabToolTip={contentStore.cmsStore.getContent('Outstanding')?.tooltip!}
      secondaryTabCount={
        transactionsStore.numberOfOutstandingTransactions ? notificationCircleContent : undefined
      }
      table={table}
      activeTab={transactionsStore.activeTab}
      setActiveTab={transactionsStore.setActiveTab}
      notification={transactionsStore.activeTab === 1 ? missingPaymentNotification[0] : undefined}
    />
  );
};

export default observer(TransactionAndUsage);
