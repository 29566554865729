import React, { FC, useEffect } from 'react';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import useStore from '../../hooks/useStore';
import AuthenticationStore from '../../stores/AuthenticationStore';
import RouteUrlPaths from '../../RouteUrlPaths';
import useContentLoader from '../../hooks/useContentLoader';
import EmailNotVerifiedModal from './authentication-features/EmailNotVerifiedModal';
import { UserRole } from '../../swagger/api';

const Authenticator: FC = ({ children }) => {
  const authenticationStore: AuthenticationStore = useStore(AuthenticationStore);
  const { userStore, manageUserAccessStore, notificationStore } = authenticationStore.rootStore;
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search);

  const isAccountFetchedOrAwaitingAccountCreationOrHasNotSelectAccount =
    !!userStore.currentAccount ||
    userStore.person?.signUpInProgress === true ||
    userStore.isSelectAccountShown ||
    userStore.person?.role === UserRole.ProductAccess;

  const isContentLoaded = useContentLoader();

  const loadData = () => {
    userStore.loadCurrentUser().then(() => {
      if (manageUserAccessStore.isPrivateCustomer()) {
        notificationStore.getNotifications();
      }

      // consider to use same sign-up process for commercial as private. Then the signUpInProgress checks can be avoided.
      if (userStore.person?.signUpInProgress === true) {
        navigate(RouteUrlPaths.CreateCustomer);
      }

      if (userStore.isSelectAccountShown) {
        navigate(RouteUrlPaths.SelectAccount);
      }
    });
  };

  const initializeAuthentication = async () => {
    const auth0client: Auth0Client = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH_DOMAIN!,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,

      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH_AUDIENCE
      },
      cacheLocation: 'localstorage', // important when browser blocks 3rd party cookies.
      useRefreshTokens: true //  refresh tokens are continually exchanged and invalidated - limits the chance of illegitimate access
    });
    authenticationStore.setAuthContext(auth0client);

    const query = window.location.search;
    const shouldHandleRedirect = query.includes('code=') && query.includes('state=');

    if (shouldHandleRedirect) {
      await auth0client.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    await auth0client
      .getTokenSilently()
      .then(() => {
        authenticationStore.setIsAuthenticated(true);
      })
      .catch(() => {
        const isAccessDenied = searchParams.get('error') === 'access_denied';
        if (!isAccessDenied) {
          auth0client.loginWithRedirect();
          auth0client.handleRedirectCallback();
        } else {
          authenticationStore.setIsAccessDenied(isAccessDenied);
        }
      });
  };

  useEffect(() => {
    if (!authenticationStore.isAuthenticated) {
      initializeAuthentication().then(() => {
        if (authenticationStore.isAuthenticated) {
          loadData();
        }
      });
    }
  }, []);

  return (
    <>
      {authenticationStore.isAuthenticated &&
        userStore.person &&
        isContentLoaded &&
        isAccountFetchedOrAwaitingAccountCreationOrHasNotSelectAccount &&
        children}{' '}
      {authenticationStore.isAccessDenied && <EmailNotVerifiedModal />}
    </>
  );
};

export default observer(Authenticator);
