/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getIdentifiers"] }] */
import { action, makeObservable, observable } from 'mobx';
import { Account, AccountService } from '../../swagger/api';
import { downloadCSV } from '../../utils/downloadCSV';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class AdministrationAccountStore extends BaseStore {
  accounts?: Account[] = [];

  searchVal: string = '';

  pageSize: number = 0;

  accountPageNumber: number = 1;

  filteredAcounts?: number = 0;

  isModalVisible: boolean = false;

  isAnimationOpen: boolean = false;

  openModal = () => {
    this.setIsAnimationOpen(true);
    this.isModalVisible = true;
  };

  closeModal = async () => {
    this.setIsAnimationOpen(false);
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 500));
    this.isModalVisible = false;
  };

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  getIdentifiers = (accountId: string) =>
    AccountService.getIdentifiersForAccountAsync({ accountId });

  getAccountAdminInfo = () => {
    const personId = this.rootStore.userStore.person?.id!;
    const promise = AccountService.getPaginatedSubAccountsListItems({
      personId,
      searchString: this.searchVal,
      pageNumber: this.accountPageNumber
    });
    promise.then((result) => {
      this.accounts = result.paginatedItems;
      this.filteredAcounts = result.filteredCount;
      this.pageSize = result.pageSize!;
    });

    return promise;
  };

  setSearchVal = (searchValue: string) => {
    this.searchVal = searchValue;
  };

  setCurrentPage = (pageNumber: number) => {
    this.accountPageNumber = pageNumber;
  };

  downloadAccounts = () => {
    const promise = AccountService.downloadSubAccountsListItemsICsv({
      personId: this.rootStore.userStore.person?.id!,
      searchString: this.searchVal
    });
    promise.then((data) => {
      downloadCSV(this.rootStore.userStore.person?.id!, data);
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      accounts: observable,
      searchVal: observable,
      pageSize: observable,
      filteredAcounts: observable,
      accountPageNumber: observable,
      isModalVisible: observable,
      isAnimationOpen: observable,
      getAccountAdminInfo: action,
      setSearchVal: action,
      downloadAccounts: action,
      setCurrentPage: action,
      openModal: action,
      closeModal: action,
      setIsAnimationOpen: action,
      getIdentifiers: action
    });
  }
}
