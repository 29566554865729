import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { H6CamptonBold25 } from '../globalFonts';

const Header = styled.div`
  padding-top: 50px;
`;

interface PageHeaderProps {
  pageName?: string;
  headerColor?: string;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
  const { pageName, headerColor } = props;
  return (
    <Header>
      <Row>
        <Col>
          <H6CamptonBold25 style={{ color: `${headerColor}` }}>{pageName}</H6CamptonBold25>
        </Col>
        <Col flex="auto" />
      </Row>
    </Header>
  );
};

export default PageHeader;

PageHeader.defaultProps = {
  headerColor: '2d2d2d',
  pageName: ''
};
