import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import LoadingSpinner from '../common-components/LoadingSpinner';
import RouteUrlPaths from '../RouteUrlPaths';

const Register = lazy(() => import('../pages/Register'));
const CreatePrivateUser = lazy(() => import('../pages/CreatePrivateUser'));
const Welcome = lazy(() => import('../pages/Welcome'));
const EmailIsUpdated = lazy(() => import('../pages/EmailIsUpdated'));

const PublicRoutes: FC = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path={RouteUrlPaths.SignUp} element={<Register />} />
      <Route path={RouteUrlPaths.CreatePrivateUser} element={<CreatePrivateUser />} />
      <Route path={RouteUrlPaths.EmailIsUpdated} element={<EmailIsUpdated />} />
      <Route path={RouteUrlPaths.Welcome} element={<Welcome />} />
    </Routes>
  </Suspense>
);

export default PublicRoutes;
