/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["languageFromPerson", "languageFromBrowser"],  }] */
import { action, makeObservable, observable } from 'mobx';
import { RichTextContent } from '@graphcms/rich-text-types';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { ContentService, LanguageType } from '../../swagger/api';

export interface MinKontoContent {
  systemId: string;
  content: string;
  subText: string | null;
  tooltip: string | null;
  richContent: MinKontoRichContent;
}

export interface MinKontoRichContent {
  raw?: RichTextContent;
  html?: string;
  markdown?: string;
  text?: string;
}

export interface MinKontoErrorModel {
  errorCode: string;
  errorMessage: string;
}

export interface Data {
  minKontoErrorModels?: MinKontoErrorModel[];
  minKontoContents?: MinKontoContent[];
}

export interface RootObject {
  data: Data;
}

export enum Locale {
  Danish = 'da_DK',
  English = 'en_GB'
}

export default class CMSStore extends BaseStore {
  content?: MinKontoContent[];

  errorContent?: MinKontoErrorModel[];

  languageFromPerson = () =>
    this.rootStore.userStore.person?.language === LanguageType.Danish
      ? Locale.Danish
      : Locale.English;

  localeFromPerson = () =>
    this.rootStore.userStore.person?.language === LanguageType.Danish ? 'da-DK' : 'en-US';

  languageFromBrowser = (): Locale => {
    const defaultLanguages = ['da', 'da-DK'];
    if (defaultLanguages.includes(window.navigator.language)) {
      return Locale.Danish;
    }
    return Locale.English;
  };

  getCMSLocale = () =>
    this.rootStore.userStore.person ? this.languageFromPerson() : this.languageFromBrowser();

  getCMSContent = () => {
    const locale = this.rootStore.userStore.person
      ? this.languageFromPerson()
      : this.languageFromBrowser();
    const getContentEndpoint = this.rootStore.manageUserAccessStore.isPrivateCustomer()
      ? ContentService.getContent
      : ContentService.getCommercialContent;
    const contentService = getContentEndpoint({ locale });
    contentService.then((data) => {
      const parsedData: RootObject = JSON.parse(data);
      this.content = parsedData.data.minKontoContents;
      if (this.rootStore.authenticationStore.isAuthenticated) {
        this.setInitialContent();
      }
    });
    return contentService;
  };

  getCMSErrorContent = () => {
    const locale = this.rootStore.userStore.person
      ? this.languageFromPerson()
      : this.languageFromBrowser();
    const contentService = ContentService.getBusinessErrorContent({ locale });
    contentService.then((data) => {
      const parsedData: RootObject = JSON.parse(data);
      this.errorContent = parsedData.data.minKontoErrorModels;
    });
    return contentService;
  };

  getContent = (contentId: string) => {
    const contentString: MinKontoContent | undefined = this.content?.find(
      (content) => content.systemId === contentId
    );
    return contentString;
  };

  getErrorMessage = (errorMessageKey: string) => {
    const errorMessage: MinKontoErrorModel | undefined = this.errorContent?.find(
      (error) => error.errorCode === errorMessageKey
    );
    return errorMessage?.errorMessage;
  };

  setInitialContent = () => {
    this.rootStore.contentStore.contentInitializerStore.setEuronormInit();
    this.rootStore.contentStore.contentInitializerStore.setInvoiceFilterContentInit();
    Promise.all([
      this.rootStore.contentStore.contentInitializerStore.getAndSetAllCountries(),
      this.rootStore.contentStore.contentInitializerStore.getAndSetLocalServices()
    ]).then(() => {
      this.rootStore.contentStore.contentInitializerStore.setProductTableColumnNamesInit();
      this.rootStore.contentStore.contentInitializerStore.setTransactionsFilterContentInit();
      this.rootStore.contentStore.contentInitializerStore.setOtherTransactionsFilterContent();
      this.rootStore.contentStore.contentInitializerStore.setPaymentCardsFilterContent();
      this.rootStore.contentStore.contentInitializerStore.setBizzesToConvertFilterInit();
      this.rootStore.contentStore.contentInitializerStore.setCardPaymentFilterContentInit();
      this.rootStore.contentStore.contentInitializerStore.setProductFilterInit();
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      content: observable,
      errorContent: observable,
      getCMSLocale: action,
      getCMSContent: action,
      getContent: action,
      getCMSErrorContent: action,
      getErrorMessage: action,
      setInitialContent: action
    });
  }
}
