import { Col, Row } from 'antd';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import CustomModal from '../../../../common-components/CustomModal';
import {
  ContentSmallCamptonBookWeight475,
  ContentSmallGreenLink,
  ContentSmallLinkCamptonBook,
  H9CamptonBoldSemiBold19
} from '../../../../layout/globalFonts';
import { ReactComponent as Checkmark } from '../../../../layout/icons/check.svg';
import ContentStore from '../../../../stores/ContentStore';
import ModalStore from '../../../../stores/ModalStore';
import ProductCardStore from '../../../../stores/product-stores/ProductCardStore';
import {
  MkClosedCause,
  ProductInfo,
  ProductType,
  Service,
  UserRole
} from '../../../../swagger/api';
import { ReactComponent as ExclamationIcon } from '../../../../layout/icons/yellow-circle-black-exclamation.svg';
import { ReactComponent as BigExclamationIcon } from '../../../../layout/icons/yellow-circle-black-exclamation-big.svg';
import ProductWillBeClosedModal from './ProductWillBeClosedModal';
import { isANPR, isBizz, isTollingBox } from '../../../../utils/productTypeUtilities';

const GreyContentSmallCamptonBookWeight475 = styled(ContentSmallCamptonBookWeight475)`
  color: #818181;
`;

const LocalServiceRow = styled(Row)`
  margin-top: 30px;
  text-align: left;
`;

interface ConfirmCloseProductModalProps {
  contentStore: ContentStore;
  productInfo: ProductInfo;
  modalStore: ModalStore;
  productCardStore: ProductCardStore;
}

const ConfirmCloseProductModal: FC<ConfirmCloseProductModalProps> = (props) => {
  const { contentStore, productInfo, modalStore, productCardStore } = props;
  const { productItemsStore } = contentStore.rootStore.productStore;
  const { person } = contentStore.rootStore.userStore;
  const [isLoading, setIsloading] = useState(false);

  let headerText;
  let contentText;
  let primaryButtonText;
  switch (productInfo.productType) {
    case ProductType.Bizz:
      headerText = contentStore.cmsStore.getContent('ConfirmCloseBizzText')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmCloseBizzText')
        ?.subText!.replace('{identifierNumber}', productInfo.identifierNumber!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisBizz')?.content!;
      break;
    case ProductType.BizzCommercial:
      headerText = contentStore.cmsStore.getContent('ConfirmCloseBizzText')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmCloseBizzText')
        ?.subText!.replace('{identifierNumber}', productInfo.identifierNumber!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisBizz')?.content!;
      break;
    case ProductType.LicensePlate:
      headerText = contentStore.cmsStore.getContent('ConfirmClosePayByPlateText')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmClosePayByPlateText')
        ?.subText!.replace('{licensePlate}', productInfo.licensePlate!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisLicensePlate')?.content!;
      break;
    case ProductType.OneTimeANPR:
      headerText = contentStore.cmsStore.getContent('ConfirmCloseOneTimeANPRText')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmCloseOneTimeANPRText')
        ?.subText!.replace('{licensePlate}', productInfo.licensePlate!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisOneTimeANPR')?.content!;
      break;
    case ProductType.TollingBox:
      headerText = contentStore.cmsStore.getContent('ConfirmCloseTollingBox')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmCloseTollingBox')
        ?.subText!.replace('{identifierNumber}', productInfo.identifierNumber!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisTollingBox')?.content!;
      break;
    case ProductType.TollingApp:
      headerText = contentStore.cmsStore.getContent('ConfirmCloseTollingApp')?.content!;
      contentText = contentStore.cmsStore
        .getContent('ConfirmCloseTollingApp')
        ?.subText!.replace('{licensePlate}', productInfo.licensePlate!);
      primaryButtonText = contentStore.cmsStore.getContent('CloseThisTollingApp')?.content!;
      break;
    default:
  }

  const timeUntilClosedRow = (
    <Row style={{ margin: '-22px 0px 33px 0px' }}>
      <ContentSmallCamptonBookWeight475>
        {contentStore.cmsStore.getContent('TimeUntilCloseProductIsDone')?.content!}
      </ContentSmallCamptonBookWeight475>
    </Row>
  );

  const greenLinkText = contentStore.cmsStore.getContent('Cancel')?.content!;

  const onPrimaryButtonClick = () => {
    setIsloading(true);
    const promise = productCardStore.closeProduct(
      productInfo.identifierId!,
      productInfo.productType === ProductType.BizzCommercial ||
        isTollingBox(productInfo.productType)
        ? MkClosedCause.L11
        : MkClosedCause.None
    );
    promise.then(() => {
      productItemsStore.getProducts().then(() => {
        setIsloading(false);
        modalStore.rootStore.notificationStore.getNotifications();
        modalStore.closeModal();
        modalStore.openModal(
          <ProductWillBeClosedModal
            contentStore={contentStore}
            productInfo={productInfo}
            modalStore={modalStore}
          />
        );
      });
    });
  };

  const discountAwarenessTitle = isBizz(productInfo.productType)
    ? contentStore.cmsStore.getContent('BeAwareOfDiscountsWhenClosingBizz')?.content!
    : isANPR(productInfo.productType)
    ? contentStore.cmsStore.getContent('BeAwareOfDiscountsWhenClosingPayByPlate')?.content!
    : null;
  const discountAwarenessSubText = isBizz(productInfo.productType)
    ? contentStore.cmsStore.getContent('BeAwareOfDiscountsWhenClosingBizz')?.subText!
    : isANPR(productInfo.productType)
    ? contentStore.cmsStore.getContent('BeAwareOfDiscountsWhenClosingPayByPlate')?.subText!
    : null;

  const servicesExcludingStorebaelt = () =>
    productInfo.servicesOnIdentifier!.filter(
      (localService) =>
        contentStore.cmsStore.getContent(localService.name!)?.content! !==
        'StorebeltPrivateServiceAgreement'
    );

  const deleteProductIsNotAllowed = person?.role === UserRole.ProductAccess;
  const isPrivateCustomer = contentStore.rootStore.manageUserAccessStore.isPrivateCustomer();
  const hasServices = servicesExcludingStorebaelt().length > 0;

  const localServicesSection = (
    <>
      <Row>
        <H9CamptonBoldSemiBold19>{discountAwarenessTitle}</H9CamptonBoldSemiBold19>
      </Row>
      {servicesExcludingStorebaelt().map((localService: Service) => {
        const serviceSystemId = contentStore.cmsStore.getContent(localService.name!)?.content!;
        return (
          <LocalServiceRow gutter={12} justify="start">
            <Col span={2}>
              <Checkmark />
            </Col>
            <Col span={14}>
              <Row>
                <ContentSmallCamptonBookWeight475>
                  {contentStore.cmsStore
                    .getContent('DiscountOnServiceSupplier')
                    ?.content!.replace(
                      '{service}',
                      contentStore.cmsStore.getContent(`${serviceSystemId}Supplier`)?.content!
                    )}
                </ContentSmallCamptonBookWeight475>
              </Row>
              <Row>
                <GreyContentSmallCamptonBookWeight475>
                  {contentStore.cmsStore
                    .getContent('DiscountOnServiceSupplier')
                    ?.subText!.replace(
                      '{service}',
                      contentStore.cmsStore.getContent(`${serviceSystemId}Supplier`)?.content!
                    )}
                </GreyContentSmallCamptonBookWeight475>
              </Row>
            </Col>
            <Col span={8}>
              <a
                target="_blank"
                href={contentStore.cmsStore.getContent(`${serviceSystemId}Link`)?.content!}
                rel="noopener noreferrer"
              >
                <ContentSmallGreenLink>
                  {contentStore.cmsStore
                    .getContent('ContactServiceSupplier')
                    ?.content!.replace(
                      '{service}',
                      contentStore.cmsStore.getContent(`${serviceSystemId}Supplier`)?.content!
                    )}
                </ContentSmallGreenLink>
              </a>
            </Col>
          </LocalServiceRow>
        );
      })}
      <Row style={{ margin: '34px 0px 23px 0px' }}>
        <ContentSmallLinkCamptonBook>{discountAwarenessSubText}</ContentSmallLinkCamptonBook>
      </Row>
    </>
  );

  return (
    <CustomModal
      header={headerText}
      contentText={contentText}
      primaryButtonText={primaryButtonText}
      topIconDesktop={<ExclamationIcon />}
      topIconMobile={<BigExclamationIcon />}
      closable
      onPrimaryButtonClick={onPrimaryButtonClick}
      onGreenLinkClick={() => modalStore.closeModal()}
      greenLinkText={greenLinkText}
      redPrimaryButton
      isLoading={isLoading}
      isPrimaryButtonDisabled={deleteProductIsNotAllowed}
    >
      {timeUntilClosedRow}
      {isPrivateCustomer && hasServices && localServicesSection}
    </CustomModal>
  );
};

export default ConfirmCloseProductModal;
