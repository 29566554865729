import React, { FC, useState, useRef, useEffect } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ReactComponent as CheckIcon } from '../../../layout/icons/check.svg';
import AdministrationPaymentCardsStore from '../../../stores/adminstration-stores/AdministrationPaymentCardsStore';
import { PaymentMethod } from '../../../swagger/api';
import {
  ContentSmallCamptonBook,
  ContentSmallCamptonBookGrey,
  SmallGrey12
} from '../../../layout/globalFonts';
import { StyledSelect } from '../../../common-components/Input';
import ContentStore from '../../../stores/ContentStore';
import { DropdownArrow } from '../../../common-components/StyledIcons';
import useCheckIfClickedOutside from '../../../hooks/useCheckIfClickedOutside';
import useFetchListData from '../../../hooks/fetchDataHooks/useFetchListData';
import addCreditCardOption from '../../order-features/business-order-features/choose-payment/components/ChoosePaymentCardComponents/addCreditCardOption';
// eslint-disable-next-line import/no-cycle
import CreateNewPaymentCardModal from './modals/CreateNewPaymentCardModal';

const { Option } = Select;

const SelectorContainer = styled.div`
  width: 100%;
`;
const PaymentCardSelector = styled(StyledSelect)`
  width: 100%;
  text-align: left;
`;

interface SelectPaymentCardDropdownProps {
  contentStore: ContentStore;
  administrationPaymentCardsStore: AdministrationPaymentCardsStore;
  showAddCreditCardOption?: boolean;
}
const SelectPaymentCardDropdown: FC<SelectPaymentCardDropdownProps> = (props) => {
  const { contentStore, administrationPaymentCardsStore, showAddCreditCardOption } = props;
  const { modalStore } = contentStore.rootStore;
  const [isOpen, setIsOpen] = useState(false);
  const refDiv: any = useRef(null);
  const addCreditCardKey = 'addCreditCardKey';

  const isLoading = useFetchListData(
    administrationPaymentCardsStore.validPaymentCardsOwnedByLoggedInPerson,
    administrationPaymentCardsStore.getValidPaymentCardsOwnedByLoggedInPerson
  );

  useEffect(() => administrationPaymentCardsStore.setPaymentCardSelectedInDropdown(undefined), []);

  useCheckIfClickedOutside(isOpen, setIsOpen, refDiv);

  const dropdownOptions =
    administrationPaymentCardsStore.validPaymentCardsOwnedByLoggedInPerson!.map(
      (paymentCard: PaymentMethod) => (
        <Option style={{ textAlign: 'left' }} key={paymentCard.id} value={paymentCard.id!}>
          <ContentSmallCamptonBook>{paymentCard.cardNumber}</ContentSmallCamptonBook>{' '}
          <ContentSmallCamptonBookGrey>{paymentCard.cardType}</ContentSmallCamptonBookGrey>
        </Option>
      )
    );

  const onSelectPaymentMethod = (value: string) => {
    if (value === addCreditCardKey) {
      modalStore.openModal(
        <CreateNewPaymentCardModal
          modalStore={modalStore}
          setChosenMethodInStoresAndForm={
            administrationPaymentCardsStore.setPaymentCardSelectedInDropdown
          }
        />
      );
    } else {
      const chosenMethod =
        administrationPaymentCardsStore.validPaymentCardsOwnedByLoggedInPerson.find(
          (paymentMethod) => paymentMethod.id === value
        )!;
      administrationPaymentCardsStore.setPaymentCardSelectedInDropdown(chosenMethod);
    }
  };

  return (
    <>
      <SmallGrey12>{contentStore.cmsStore.getContent('PaymentCard')?.content!}</SmallGrey12>
      <SelectorContainer ref={refDiv}>
        <PaymentCardSelector
          id="userSelector"
          loading={isLoading}
          getPopupContainer={(trigger: any) => trigger}
          suffixIcon={<DropdownArrow />}
          placeholder={contentStore.cmsStore.getContent('ChoosePaymentCard')?.content!}
          onChange={(selection: any) => onSelectPaymentMethod(selection)}
          open={isOpen}
          value={administrationPaymentCardsStore.paymentCardSelectedInDropdown?.id ?? null}
          placement="bottomLeft"
          menuItemSelectedIcon={<CheckIcon />}
          onClick={(e: any) => {
            if (!((e.target as HTMLTextAreaElement).id === 'searchfield')) {
              setIsOpen(!isOpen);
            }
          }}
        >
          {dropdownOptions}
          {showAddCreditCardOption && addCreditCardOption(addCreditCardKey, contentStore.cmsStore)}
        </PaymentCardSelector>
      </SelectorContainer>
    </>
  );
};

export default observer(SelectPaymentCardDropdown);

SelectPaymentCardDropdown.defaultProps = {
  showAddCreditCardOption: false
};
