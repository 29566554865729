import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { NotificationType, Notification, PaymentCardStatus } from '../../swagger/api';
import { ContentSmallCamptonBookWeight475, ContentSmallGreenLink } from '../../layout/globalFonts';
import { ReactComponent as NotificationIcon } from '../../layout/icons/red-circle-exclamation-small.svg';
import CMSStore from '../../stores/content-stores/CMSStore';
import RouteUrlPaths from '../../RouteUrlPaths';
import useIsMobileView from '../../hooks/useIsMobileView';
import { AdministrationTab } from '../../stores/adminstration-stores/AdministrationTabStore';
import { PaymentCardsTableFilter } from '../../stores/filter-stores/paymentCardsFilterStore';

const NotificationBar = styled.div<{ isabouttoexpire: boolean }>`
  background: ${({ isabouttoexpire }) =>
    isabouttoexpire ? 'rgba(255, 255, 133, 0.5)' : 'rgba(250, 226, 226, 0.5)'};
  border-radius: 8px;
  width: 100%;
  height: 68px;
`;

const StyledNotificationIcon = styled(NotificationIcon)<{ isabouttoexpire: boolean }>`
  & path {
    stroke: ${({ isabouttoexpire }) => isabouttoexpire && '#2d2d2d'};
  }
`;

interface PrivateNotificationBarProps {
  notification: Notification;
  cmsStore: CMSStore;
}

const PrivateNotificationBar: FC<PrivateNotificationBarProps> = ({ notification, cmsStore }) => {
  const { manageUserAccessStore } = cmsStore.rootStore;
  let notificationText;
  let linkText;

  const isMobile = useIsMobileView();
  const isPrivateCustomer = () => manageUserAccessStore.isPrivateCustomer();

  const columnHeight = isMobile ? '' : '20px';

  const cardExpiredFilter: PaymentCardsTableFilter = {
    isMyCards: [],
    cardStatus: [
      {
        text: cmsStore.getContent(PaymentCardStatus.ExpiredCard)?.content!,
        filter: PaymentCardStatus.ExpiredCard
      },
      {
        text: cmsStore.getContent(PaymentCardStatus.BlockedCard)?.content!,
        filter: PaymentCardStatus.BlockedCard
      }
    ],
    cardType: []
  };

  if (notification.type === NotificationType.MissingPayment) {
    linkText = (
      <Link
        style={{ color: '#00a050' }}
        to={{ pathname: `/${RouteUrlPaths.TransactionAndUsage}` }}
        onClick={() => cmsStore.rootStore.transactionsStore.setActiveTab(2)}
      >
        {cmsStore.getContent('PayOutstanding')?.content}
      </Link>
    );
    notificationText = cmsStore.getContent('PrivateOutstandingsNotificationSingular')?.content;
    if (notification.numberOfNotifications! > 1) {
      notificationText = cmsStore.getContent('PrivateOutstandingsNotificationPlural')?.content;
    }
  }

  if (notification.type === NotificationType.CardAboutToExpire) {
    notificationText = cmsStore.getContent('PrivatePaymentCardExpiresSoon')?.content;
  }

  if (notification.type === NotificationType.CardExpired) {
    const location = useLocation();
    if (location.pathname !== `/${RouteUrlPaths.PaymentCard}`) {
      linkText = (
        <Link
          style={{ color: '#00a050' }}
          to={{
            pathname: isPrivateCustomer()
              ? `/${RouteUrlPaths.PaymentCard}`
              : `/${RouteUrlPaths.Administration}`
          }}
          onClick={() => {
            if (!isPrivateCustomer()) {
              cmsStore.rootStore.filterStore.paymentCardsFilterStore.setActiveFilters(
                cardExpiredFilter
              );
              cmsStore.rootStore.administrationStore.administrationTabStore.setActiveTab(
                AdministrationTab.PaymentCardsTab
              );
            }
          }}
        >
          {cmsStore.getContent('ChangePaymentCardLinkInNotificationBar')?.content}
        </Link>
      );
    }

    notificationText = cmsStore.getContent('PrivatePaymentCardExpiredNotification')?.content;
  }

  return (
    <div style={{ paddingTop: '30px' }}>
      <NotificationBar isabouttoexpire={notification.type === NotificationType.CardAboutToExpire}>
        <Row align="middle" style={{ height: '100%', width: '100%' }} gutter={14}>
          <Col
            span={isMobile ? 3 : undefined}
            style={{ paddingLeft: '25px', height: columnHeight }}
          >
            <StyledNotificationIcon
              isabouttoexpire={notification.type === NotificationType.CardAboutToExpire}
            />
          </Col>
          <Col span={isMobile ? 15 : undefined} style={{ height: columnHeight }}>
            <ContentSmallCamptonBookWeight475>{notificationText}</ContentSmallCamptonBookWeight475>
          </Col>
          {!isMobile && <Col flex="auto" />}
          {linkText && (
            <Col
              span={isMobile ? 6 : undefined}
              style={{ paddingRight: '25px', height: columnHeight }}
            >
              <ContentSmallGreenLink style={{ textAlign: 'right' }}>
                {linkText}
              </ContentSmallGreenLink>
            </Col>
          )}
        </Row>
      </NotificationBar>
    </div>
  );
};

export default PrivateNotificationBar;
