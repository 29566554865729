/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction } from 'mobx';
import { Person, AccountService, PersonPaginatedList } from '../../swagger/api';
import { downloadCSV } from '../../utils/downloadCSV';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class AdministrationUserStore extends BaseStore {
  users?: Person[] = [];

  searchVal: string = '';

  pageSize: number = 0;

  userPageNumber: number = 1;

  filteredUsers?: number = 0;

  getUsersForAccount = () => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const searchString = this.searchVal;
    const promise = AccountService.getPersonsForAccount({
      accountId,
      searchString,
      pageNumber: this.userPageNumber
    });
    promise.then((result: PersonPaginatedList) => {
      runInAction(() => {
        this.users = result.paginatedItems;
        this.userPageNumber = result.currentPage!;
        this.filteredUsers = result.filteredCount!;
        this.pageSize = result.pageSize!;
      });
    });
    return promise;
  };

  setSearchVal = (searchValue: string) => {
    this.searchVal = searchValue;
  };

  setCurrentPage = (pageNumber: number) => {
    this.userPageNumber = pageNumber;
  };

  downloadUsers = () => {
    const promise = AccountService.downloadPersonsListCsv({
      accountId: this.rootStore.userStore.currentAccount?.id!,
      searchString: this.searchVal
    });
    promise.then((data) => {
      downloadCSV(this.rootStore.userStore.person?.id!, data);
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      users: observable,
      searchVal: observable,
      pageSize: observable,
      filteredUsers: observable,
      userPageNumber: observable,
      getUsersForAccount: action,
      setSearchVal: action,
      downloadUsers: action,
      setCurrentPage: action
    });
  }
}
