/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { ProductService, ProductType } from '../../swagger/api';
import { RootStore } from '../RootStore';
import BaseStore from '../base-stores/BaseStore';

export default class ProductTypeStore extends BaseStore {
  tollingBoxCreationFee = 0;

  tollingBoxSubscriptionFee = 0;

  getTollingBoxProductInfoForCustomer(customerId: string) {
    const promise = ProductService.listProductsAsync({
      productTypes: [ProductType.TollingBox, ProductType.TollingBoxSubscription],
      customerIds: [customerId],
      currency: this.rootStore.userStore.currentAccount?.currency
    });

    promise.then((products) => {
      const tollingBox = products.find((product) => product.type === ProductType.TollingBox);
      const tollingBoxSubscription = products.find(
        (product) => product.type === ProductType.TollingBoxSubscription
      );

      const currentDate = new Date();
      const endOfPreOrderDate = new Date('2024-08-01T00:00:00+00:00');
      if (currentDate >= endOfPreOrderDate) {
        this.tollingBoxCreationFee =
          tollingBox?.customerProducts?.[0]?.price ?? tollingBox?.price ?? 0;
      }

      this.tollingBoxSubscriptionFee =
        tollingBoxSubscription?.customerProducts?.[0]?.price ?? tollingBoxSubscription?.price ?? 0;
    });

    return promise;
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      tollingBoxCreationFee: observable,
      tollingBoxSubscriptionFee: observable,
      getTollingBoxProductInfoForCustomer: action
    });
  }
}
