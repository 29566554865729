import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { BrobizzPagination } from '../../../../common-components/BrobizzPagination';
import TransactionsStore from '../../../../stores/TransactionsStore';

interface MobiletransactionsPaginationProps {
  transactionsStore: TransactionsStore;
  showOutstandingOnly: boolean;
}

const MobiletransactionsPagination: FC<MobiletransactionsPaginationProps> = (props) => {
  const { transactionsStore, showOutstandingOnly } = props;
  const pagination = (
    <Row justify="center" style={{ margin: '44px 0px 56px 0px' }}>
      <BrobizzPagination
        pageSize={transactionsStore.pageSize}
        showSizeChanger={false}
        total={transactionsStore.filteredNumberOfTransactions}
        current={transactionsStore.transactionsPageNumber}
        hideOnSinglePage
        onChange={(pageNumber: number) => {
          window.scrollTo({ top: 0 });
          transactionsStore.setTransactionsCurrentPage(pageNumber);
        }}
      />
    </Row>
  );

  const outstandingPagination = (
    <Row justify="center" style={{ margin: '44px 0px 56px 0px' }}>
      <BrobizzPagination
        pageSize={transactionsStore.pageSizeOutstanding}
        showSizeChanger={false}
        total={transactionsStore.numberOfOutstandingTransactions}
        current={transactionsStore.outstandingPagenumber}
        hideOnSinglePage
        onChange={(pageNumber: number) => {
          window.scrollTo({ top: 0 });
          transactionsStore.setOutstandingCurrentPage(pageNumber);
        }}
      />
    </Row>
  );

  return showOutstandingOnly ? outstandingPagination : pagination;
};

export default observer(MobiletransactionsPagination);
