/* eslint-disable import/no-cycle */
import BaseStore from './base-stores/BaseStore';
import CMSStore from './content-stores/CMSStore';
import ContentInitilizerStore from './content-stores/ContentInitializerStore';
import GenericContentStore from './content-stores/GenericContentStore';

export default class ContentStore extends BaseStore {
  cmsStore: CMSStore = new CMSStore(this.rootStore);

  contentInitializerStore: ContentInitilizerStore = new ContentInitilizerStore(this.rootStore);

  genericContentStore: GenericContentStore = new GenericContentStore(this.rootStore);
}
