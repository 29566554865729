import styled from 'styled-components';

export const H1CamptonExtraBold90 = styled.h1`
  font-family: Campton Extra Bold;
  font-size: 90px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 90px;
`;

export const H1CamptonExtraBold80 = styled.h1`
  font-family: Campton Extra Bold;
  font-size: 80px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 100px;
`;

export const H1CamptonExtraBold32 = styled.h1`
  font-family: Campton Extra Bold;
  font-size: 32px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 36px;
`;

export const H2CamptonBold66 = styled.h2`
  font-family: Campton Bold;
  font-size: 66px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 72px;
`;

export const H2Campton66 = styled.h2`
  font-family: Campton book;
  font-size: 66px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 72px;
  text-align: center;
`;

export const H2CamptonBold30 = styled.h2`
  font-family: Campton Bold;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 40px;
`;

export const H2Campton30 = styled.h2`
  font-family: Campton Book;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.14px;
  line-height: 34px;
  text-align: center;
`;

export const H3CamptonBold44 = styled.h3`
  font-family: Campton Bold;
  font-size: 44px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 50px;
`;

export const H3CamptonBold30 = styled.h3`
  font-family: Campton Bold;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 36px;
`;
export const TextBoldCamptionBold30 = styled.text`
  font-family: Campton Bold;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 36px;
`;
export const H4CamptonBold38 = styled.h4`
  font-family: Campton Bold;
  font-size: 38px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 44px;
`;

export const H4CamptonBold30 = styled.h4`
  font-family: Campton Bold;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 38px;
`;

export const H5CamptonBold30 = styled.h5`
  font-family: Campton Bold;
  font-size: 30px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 36px;
`;

export const H5CamptonBold26 = styled.h5`
  font-family: Campton Bold;
  font-size: 26px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 32px;
`;

export const H6CamptonBold25 = styled.h5`
  font-family: Campton Bold;
  font-size: 25px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 30px;
`;

export const H6CamptonBold25Red = styled(H6CamptonBold25)`
  color: #ff004a;
`;

export const H6CamptonBold22 = styled.h6`
  font-family: Campton Bold;
  font-size: 22px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 26px;
  font-weight: 625;
`;

export const H7CamptonSemiBold22 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 22px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 28px;
`;

export const H8amptonSemiBold20Weight625 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 20px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
`;

export const H8CamptonSemiBold20 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 20px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 24px;
`;

export const H8CamptonSemiBold19 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 19px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 24px;
`;

export const H8CamptonSemiBold18 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 18px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 24px;
`;

export const H9CamptonBoldSemiBold19 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 19px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 20px;
  margin-bottom: 0;
`;

export const H9CamptonBoldSemiBold15 = styled.h6`
  font-family: Campton Semi Bold;
  font-size: 15px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 16px;
`;

export const ManchetContentCamptonBook22 = styled.text`
  font-family: Campton Book;
  font-size: 22px;
  font-weight: 475;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 40px;
`;

export const ManchetContentCamptonBook22Grey = styled(ManchetContentCamptonBook22)`
  color: #747474;
`;

export const ManchetContentCamptonBook19 = styled.text`
  font-family: Campton Book;
  font-size: 19px;
  font-weight: 400;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  line-height: 28px;
`;

export const ContentCamptonBook18 = styled.text`
  font-family: Campton Book;
  font-size: 18px;
  font-weight: 400;
  color: #2d2d2d;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ContentCamptonBook18Grey = styled(ContentCamptonBook18)`
  color: #747474;
`;

export const ContentSmallLinkCamptonBook = styled.div`
  font-family: Campton Book;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #2d2d2d;
`;

export const TableHeader = styled.div`
  color: #747474;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
`;

export const SemiBold15 = styled(ContentSmallLinkCamptonBook)`
  font-family: Campton Semi Bold;
`;

export const ContentSmallCamptonBook = styled.text`
  font-family: Campton Book;
  font-size: 15px;
  color: #2d2d2d;
  letter-spacing: -0.2px;
`;

export const ContentSmallCamptonMedium = styled.span`
  font-family: Campton Medium;
  font-size: 15px;
`;

export const ContentSmallCamptonBookWeight625 = styled(ContentSmallCamptonBook)`
  font-weight: 625;
  letter-spacing: -0.3px;
  font-family: Campton Book;
  font-size: 15px;
  color: #2d2d2d;
`;

export const ContentSmallCamptonBookWeight475 = styled(ContentSmallCamptonBook)<{
  customColor?: string;
}>`
  font-weight: 475;
  ${(props) => props.customColor && `color: ${props.customColor}`}
`;

export const ContentSmallCamptonBookBold = styled(ContentSmallCamptonBook)`
  font-weight: 700;
`;

export const ContentSmallCamptonBookGrey = styled(ContentSmallCamptonBook)`
  color: #747474;
`;

export const CamptonMedium15 = styled.div`
  font-family: Campton Medium;
  font-size: 15px;
  letter-spacing: -0.2;
  cursor: pointer;
`;

export const ContentSmallGreenLink = styled.div`
  font-family: Campton Medium;
  font-size: 15px;
  letter-spacing: -0.2;
  color: #00a050 !important;
  cursor: pointer;
`;

export const DateCamptonBook = styled.text`
  font-family: Campton Book;
  font-size: 16px;
  font-weight: 400;
  color: #2d2d2d;
  letter-spacing: 0;
  line-height: 20px;
`;
export const Black16Weight700 = styled.text`
  font-family: Campton Bold;
  font-size: 16px;
  color: #2d2d2d;
  letter-spacing: -0.2;
  line-height: 15px;
`;

export const Bold24Weight700 = styled(Black16Weight700)`
  font-size: 24px;
`;

export const GreenButtonText = styled.text`
  font-family: Campton Book;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0;
  line-height: 24px;
`;

export const Campton10pxWeight550 = styled.text`
  font-family: Campton Book;
  font-size: 10px;
  font-weight: 550;
  color: #fff;
  letter-spacing: 0;
  line-height: 24px;
`;

export const White15px475weight = styled(GreenButtonText)`
  font-weight: 475;
`;

export const OutlinedButtonText = styled.text`
  font-family: Campton Book;
  font-size: 15px;
  font-weight: 400;
  color: #2d2d2d;
  letter-spacing: 0;
  line-height: 24px;
`;

export const AccountListText = styled(OutlinedButtonText)`
  color: #fff;
  opacity: 0.5;
`;

export const GreenLinkText = styled.text`
  font-family: Campton Medium;
  font-size: 15px;
  color: #00a050;
  letter-spacing: 0;
  line-height: 24px;
`;

export const OutlinedButtonTextGrey = styled(OutlinedButtonText)`
  color: #747474;
`;

export const DropDownText = styled.text`
  font-family: Campton Book;
  font-size: 15px;
  font-weight: 400;
  color: #747474;
  letter-spacing: 0;
  line-height: 26px;
`;

export const DropDownTextBlack = styled(DropDownText)`
  color: #2d2d2d;
`;

export const SmallGrey12 = styled.text`
  font-family: Campton Book;
  font-size: 12px;
  font-weight: 475;
  color: #747474;
  letter-spacing: -0.002%;
  line-height: 21px;
`;

export const SmallBlack12 = styled(SmallGrey12)`
  letter-spacing: -0.2px;
  line-height: 19px;
  color: #2d2d2d;
`;

export const SmallRed12 = styled(SmallGrey12)`
  color: #ff004a;
  line-height: 16px;
  font-weight: 475;
`;

export const NotificationTagText = styled.div`
  font-family: Campton Book;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.2px;
  line-height: 18px;
`;

export const AccountInformationText = styled.div`
  font-family: Campton Book;
  font-size: 13px;
  font-weight: 475;
  color: #fff;
  letter-spacing: -0.2px;
  line-height: 19px;
`;

export const Campton13Weight475 = styled(AccountInformationText)`
  color: #2d2d2d;
`;

export const NotificationDate = styled(NotificationTagText)`
  line-height: 12px;
  opacity: 0.66;
`;

export const CamptonWhite22 = styled.text`
  font-family: Campton Book;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  fill: solid;
`;

export const H8SemiBoldGreenWeight625 = styled(H8CamptonSemiBold20)`
  font-weight: 625;
  color: #00a050;
`;

export const SmallGreenLinkLineHeight19 = styled.div`
  font-family: Campton Medium;
  font-size: 15px;
  color: #00a050;
  cursor: pointer;
  letter-spacing: -0.2px;
`;

export const SmallCamptonText = styled(SmallGrey12)`
  weight: 475;
  line-height: 17px;
  color: #2d2d2d;
`;

export const ContentSmallCamptonBookWeight475Grey = styled(ContentSmallCamptonBookWeight475)`
  line-height: 17px;
  color: #747474;
`;

export const SmallGrey12LineHeight19 = styled(SmallGrey12)`
  font-weight: 475;
  line-height: 19px;
`;

export const Grey22Center = styled(CamptonWhite22)`
  font-weight: 475;
  color: #747474;
  text-align: center;
`;

export const CamptonBlack22 = styled.text`
  font-family: Campton Semi Bold;
  font-size: 22px;
  color: #2d2d2d;
  font-weight: 625;
  line-height: 28px;
`;

export const ContentLink = styled(H8CamptonSemiBold18)`
  color: #00a050;
  line-height: 18px;
`;

export const MobileSmall = styled.text`
  font-family: Campton Book;
  font-size: 12px;
  font-weight: 475;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #2d2d2d;
`;

export const MobileContentManchet = styled.text`
  font-family: Campton Book;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: #747474;
`;

export const MobileSmallGrey = styled(MobileSmall)`
  color: #747474;
`;

export const MobileSmallGreen = styled(MobileSmall)`
  font-weight: 600;
  color: #00a050;
`;

export const SmallGrey12LineHeight12 = styled(SmallGrey12)`
  font-family: Campton Book;
  font-weight: 475;
  line-height: 12px;
`;
