import { Col, Divider, Row, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ContentStore from '../../../../stores/ContentStore';
import { InvoiceStatus, Transaction } from '../../../../swagger/api';
import { ReactComponent as WarningIcon } from '../../../../layout/icons/red-circle-exclamation-small.svg';
import { ReactComponent as CheckIcon } from '../../../../layout/icons/white-check-in-green-circle-small.svg';
import timeFormatting from '../../../../utils/timeFormatting';
import dateFormatting from '../../../../utils/dateFormatting';
import {
  ContentSmallGreenLink,
  ContentSmallLinkCamptonBook,
  MobileSmall,
  MobileSmallGreen,
  MobileSmallGrey,
  ContentSmallCamptonBookWeight625
} from '../../../../layout/globalFonts';
import { overlayInnerStyle } from '../../../../common-components/TooltipStyling';
import { ReactComponent as BizzIcon } from '../../../../layout/icons/payment/bizz-big-less-white-space.svg';
import { ReactComponent as LicensePlateIcon } from '../../../../layout/icons/payment/license-plate-big-less-white-space.svg';
import decimalFormatting from '../../../../utils/decimalFormatting';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';
import { isANPR, isBizz } from '../../../../utils/productTypeUtilities';

const ColoredRow = styled(Row)<{ iseven: boolean }>`
  ${(props) => props.iseven && 'background-color: #f7f7f7;'}
  margin: 0px;
  padding: 18px 20px 0px 20px;
`;

const DividerLine = styled(Divider)`
  margin: 0;
  background-color: #e0e0e0;
  height: 1px;
`;

const ContentRow = styled(Row)`
  margin-bottom: 15px;
`;

const FlexEndColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const AmountText = styled(ContentSmallCamptonBookWeight625)`
  color: #ff004a;
  font-weight: 625;
`;

const TollChargerNameText = styled(ContentSmallLinkCamptonBook)`
  font-weight: 600;
`;

interface MobileTransactionRowProps {
  contentStore: ContentStore;
  transaction: Transaction;
  isEven: boolean;
  showOutstandingOnly?: boolean;
  isTableOnProductCard?: boolean;
}

const MobileTransactionRow: FC<MobileTransactionRowProps> = (props) => {
  const { contentStore, transaction, isEven, showOutstandingOnly, isTableOnProductCard } = props;
  const { productItemsStore, productCardStore } = contentStore.rootStore.productStore;
  const { paymentStore } = contentStore.rootStore;
  const [isLoading, setIsLoading] = useState(false);

  const isPaidText =
    transaction.invoiceStatus === InvoiceStatus.Paid
      ? contentStore.cmsStore.getContent('Paid')?.content
      : contentStore.cmsStore.getContent('MissingPaymentInTransactiontable')?.content;
  const statusIcon =
    transaction.invoiceStatus === InvoiceStatus.Paid ? <CheckIcon /> : <WarningIcon />;

  const statusTooltip =
    transaction.invoiceStatus === InvoiceStatus.Paid
      ? contentStore.cmsStore.getContent('TripRegisteredAndPaidIcon')?.tooltip
      : contentStore.cmsStore.getContent('MissingPaymentIcon')?.tooltip;

  const date = transaction.date && dateFormatting(transaction.date);
  const time = transaction.date && timeFormatting(transaction.date);

  const productTypeIcon = isANPR(transaction.productType) ? (
    <LicensePlateIcon />
  ) : isBizz(transaction.productType) ? (
    <BizzIcon />
  ) : null;

  const showProductCardModalOnClick = (identifierId: string) => {
    if (!isTableOnProductCard) {
      setIsLoading(true);
      const productInStore = productItemsStore.findProductFromId(identifierId);
      if (productInStore) {
        productItemsStore.product = productInStore;
        productCardStore.openModal();
        setIsLoading(false);
      } else {
        productItemsStore.getProduct(identifierId).then(() => {
          productCardStore.openModal();
          setIsLoading(false);
        });
      }
    }
  };

  const downloadInvoiceOrReceipt = transaction.invoiceId && (
    <ContentRow justify="end">
      <Col>
        <ContentSmallGreenLink
          onClick={() => {
            setIsLoading(true);
            paymentStore
              .downloadInvoicePdfPrivate(transaction.invoiceId!, transaction.invoiceNumber!)
              .then(() => {
                setIsLoading(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          }}
        >
          {transaction.invoiceStatus === InvoiceStatus.Paid
            ? contentStore.cmsStore.getContent('ViewReceipt')?.content
            : contentStore.cmsStore.getContent('ViewInvoice')?.content}
        </ContentSmallGreenLink>
      </Col>
    </ContentRow>
  );

  const dateAndTime = showOutstandingOnly ? (
    <MobileSmall>{transaction.dueDate && dateFormatting(transaction.dueDate)}</MobileSmall>
  ) : (
    <>
      <Row>
        <MobileSmall>{date}</MobileSmall>
      </Row>
      <Row>
        <MobileSmallGrey>{time}</MobileSmallGrey>
      </Row>
    </>
  );

  return (
    <>
      <ColoredRow iseven={isEven}>
        {isLoading ? (
          <Col style={{ width: '100%' }}>
            <LoadingSpinner size="md" />
          </Col>
        ) : (
          <Col style={{ width: '100%' }}>
            <ContentRow align="middle">
              <Col span={5}>{productTypeIcon}</Col>
              <Col span={9}>
                <Row>
                  <MobileSmallGreen
                    onClick={() => showProductCardModalOnClick(transaction.identifierId!)}
                  >
                    {transaction.identifierNumber}
                  </MobileSmallGreen>
                </Row>
                <Row>
                  <MobileSmallGrey>{transaction.identifierReference}</MobileSmallGrey>
                </Row>
              </Col>
              <FlexEndColumn span={10}>
                <Tooltip
                  placement="bottomRight"
                  title={statusTooltip}
                  color="#2d2d2d"
                  trigger={['click']}
                  overlayInnerStyle={overlayInnerStyle}
                >
                  <Row>
                    <MobileSmall style={{ marginRight: '6px' }}>{isPaidText}</MobileSmall>
                    {statusIcon}
                  </Row>
                </Tooltip>
              </FlexEndColumn>
            </ContentRow>
            <ContentRow gutter={12}>
              <Col span={5}>{dateAndTime}</Col>
              <Col span={10}>
                <Row>
                  <TollChargerNameText>{transaction.tollChargerName}</TollChargerNameText>
                </Row>
                <Row>
                  <MobileSmallGrey>
                    {contentStore.cmsStore.getContent(transaction.description!)?.content}
                  </MobileSmallGrey>
                </Row>
              </Col>
              <FlexEndColumn span={9}>
                {transaction.invoiceStatus === InvoiceStatus.Paid ? (
                  <ContentSmallLinkCamptonBook>{`${decimalFormatting(transaction.amount!)} ${
                    transaction.currency
                  }`}</ContentSmallLinkCamptonBook>
                ) : (
                  <AmountText>{`${decimalFormatting(transaction.amount!)} ${
                    transaction.currency
                  }`}</AmountText>
                )}
              </FlexEndColumn>
            </ContentRow>
            {downloadInvoiceOrReceipt}
          </Col>
        )}
      </ColoredRow>
      <DividerLine />
    </>
  );
};

export default MobileTransactionRow;

MobileTransactionRow.defaultProps = {
  showOutstandingOnly: false,
  isTableOnProductCard: false
};
