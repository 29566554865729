/* eslint-disable import/no-cycle */
import { FormInstance } from 'antd';
import AddressSelectorStore from '../stores/AddressSelectorStore';
import { AddressLight } from '../swagger/api';

const getMkAddressObject = (form: FormInstance, addressSelectorStore: AddressSelectorStore) => {
  let mkAddressObject: AddressLight = {
    countryId: '',
    addressLine1: '',
    postalCode: '',
    city: '',
    addressLine2: ''
  };
  const countryName = form.getFieldValue('country');
  const country =
    addressSelectorStore.rootStore.contentStore.genericContentStore.getCountry(countryName);
  const selectedAddres = addressSelectorStore.selectedAddress?.data.resultater[0].adresse;
  const streetAndNo = `${selectedAddres?.vejnavn} ${selectedAddres?.husnr ?? ''}`;
  const floorAndDoor = `${selectedAddres?.etage ? `${selectedAddres?.etage}.` : ''} ${
    selectedAddres?.dør ?? ''
  }`;
  if (addressSelectorStore.useDawaSelector && selectedAddres && country?.id) {
    mkAddressObject = {
      countryId: country.id,
      addressLine1:
        selectedAddres.dør || selectedAddres.etage
          ? `${streetAndNo}, ${floorAndDoor}`
          : streetAndNo,
      postalCode: selectedAddres.postnr,
      city: selectedAddres.postnrnavn,
      addressLine2: form.getFieldValue('addressLine2')
    };
    return mkAddressObject;
  }
  if (addressSelectorStore.useDawaSelector && selectedAddres === undefined && country?.id) {
    const addressLine = form.isFieldTouched('dawaAddress')
      ? form.getFieldValue('dawaAddress')
      : addressSelectorStore.rootStore.userStore.currentAccount?.streetName;
    const postalCode = addressSelectorStore.rootStore.userStore.currentAccount?.zipCode;
    const city = addressSelectorStore.rootStore.userStore.currentAccount?.cityName;
    const addressLine2 = form.getFieldValue('addressLine2');
    if (addressLine && postalCode && city)
      mkAddressObject = {
        countryId: country.id,
        addressLine1: addressLine,
        postalCode,
        city,
        addressLine2
      };
    return mkAddressObject;
  }
  if (country?.id) {
    mkAddressObject = {
      countryId: country.id,
      addressLine1: form.getFieldValue('addressLine'),
      postalCode: form.getFieldValue('zipCode'),
      city: form.getFieldValue('city'),
      addressLine2: form.getFieldValue('addressLine2')
    };
    return mkAddressObject;
  }
  return mkAddressObject;
};

export default getMkAddressObject;
