import React from 'react';
import styled from 'styled-components';

const Subscript = styled.span`
  vertical-align: sub;
  font-size: smaller;
`;

const subscriptTwos = (text?: string) =>
  text &&
  text.split('').map((char) => {
    if (char === '2') {
      return <Subscript key={char}>2</Subscript>;
    }
    return char;
  });

export default subscriptTwos;
