const timeFormatting = (dateTime: Date) => {
  const modifiedDate = new Date(dateTime);
  const hours =
    modifiedDate.getHours() < 10 ? `0${modifiedDate.getHours()}` : modifiedDate.getHours();
  const minutes =
    modifiedDate.getMinutes() < 10 ? `0${modifiedDate.getMinutes()}` : modifiedDate.getMinutes();
  return `${hours}:${minutes}`;
};

export default timeFormatting;
