import { ColorScheme } from '../layout/colorScheme';

export const isNativeApp = () => sessionStorage.getItem('app') === 'true';

export const setPageColor = (pageColor: string = ColorScheme.DarkGreen) => {
  if (isNativeApp()) window.nativeLayer.postMessage({ type: 'theme', color: pageColor });
};

export const webAppIsFullyLoaded = () => {
  if (isNativeApp()) window.nativeLayer.postMessage({ type: 'hideOverlay' });
};

export const openLicensePlateScanner = () => {
  window.nativeLayer.postMessage({ type: 'plateScanner' });
};
