import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { keyframes, css } from 'styled-components';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Row } from 'antd';
import useStore from '../../hooks/useStore';
import AuthenticationStore from '../../stores/AuthenticationStore';
import { ReactComponent as Logo } from '../../layout/logos/brobizz-logo-white.svg';
import { ReactComponent as ProductsIcon } from '../../layout/icons/menuSider/products.svg';
import { ReactComponent as SpendingsIcon } from '../../layout/icons/menuSider/spendings.svg';
import { ReactComponent as CardIcon } from '../../layout/icons/menuSider/card.svg';
import { ReactComponent as Shoppingcart } from '../../layout/icons/menuSider/shoppingcart.svg';
import { ReactComponent as Logout } from '../../layout/icons/menuSider/logout.svg';
import { ReactComponent as User } from '../../layout/icons/menuSider/user.svg';
import { ReactComponent as Settings } from '../../layout/icons/menuSider/settings.svg';
import { ReactComponent as BizzAndLicensePlateIcon } from '../../layout/icons/menuSider/bizzAndLicenseplate.svg';
import { ReactComponent as FrontpageIcon } from '../../layout/icons/menuSider/frontpage.svg';
import MenuItem, { MenuItemType } from './components/MenuItem';
import CustomerInformation from './components/CustomerInformation';
import { UserRole } from '../../swagger/api';
import useIsMobileView from '../../hooks/useIsMobileView';
import { MenuItemTitle } from '../../stores/MenuStore';
import RouteUrlPaths from '../../RouteUrlPaths';
import { White15px475weight } from '../../layout/globalFonts';
import logOut from '../../utils/logOut';

const BrobizzLogo = styled(Logo)`
  margin-left: 44px;
  margin-top: 54px;
`;

const openMenu = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const closeMenu = keyframes`
  to {
    transform: translateX(-100%);
  }
`;

const Sider = styled.div<{
  isopen: boolean;
  ismobileview: boolean;
  width: string;
}>`
  z-index: 10;
  min-height: 100vh;
  // opens below burger menu which is 82px
  ${({ ismobileview }) => ismobileview && 'top: 82px; position: absolute;'}
  background-color: #193232;
  width: ${({ width }) => width};

  ${(props) =>
    props.ismobileview
      ? props.isopen
        ? css`
            animation-name: ${openMenu};
          `
        : css`
            animation-name: ${closeMenu};
          `
      : ''}
  animation-timing-function: ease-out;
  animation-duration: 300ms;
`;

const MenuItemWrapper = styled.div<{
  ismobileview: boolean;
}>`
  margin-top: ${({ ismobileview }) => (ismobileview ? '0px' : '40px')};
  display: grid;
  grid-gap: 6px;
`;

const StyledNavLink = styled(NavLink)<{
  isorderitem: boolean;
  isproductaccessonproductsitem: boolean;
  isbizzandnumberplates: boolean;
  isprivatepaymentcarditem: boolean;
}>`
  &.active {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    height: 40px;
    box-sizing: border-box;
    ${({ isbizzandnumberplates }) => isbizzandnumberplates && 'height: 58px'};
    ${({ isorderitem, isproductaccessonproductsitem, isprivatepaymentcarditem }) =>
      (isorderitem || isproductaccessonproductsitem || isprivatepaymentcarditem) &&
      'margin-bottom: 50px'};
  }
  div.active {
    text,
    g,
    path {
      opacity: 1;
      color: #ffffff;
    }
  }
`;

const BottomMenuItem = styled.div<{ isopen: boolean; width: string }>`
  bottom: 0;
  width: ${({ width }) => width};
  position: fixed;
`;

const StyledWhite15px475weight = styled(White15px475weight)<{
  isselected: boolean;
  ismobileview: boolean;
}>`
  opacity: ${({ isselected }) => !isselected && '0.8'};
  font-size: ${({ ismobileview }) => ismobileview && '24px'};
`;

const Menu: FC = () => {
  const authenticationStore: AuthenticationStore = useStore(AuthenticationStore);
  const { contentStore, accountStore, manageUserAccessStore, userStore, menuStore } =
    authenticationStore.rootStore;
  const isMobileView = useIsMobileView();
  const SiderWidth = isMobileView ? '100vw' : '260px';
  const isPrivateCustomer = manageUserAccessStore.isPrivateCustomer();

  const location = useLocation();

  useEffect(() => {}, [
    manageUserAccessStore.isParentAccount(),
    manageUserAccessStore.showAdministrationPage()
  ]);

  const getItem = (menuLabel: string, path: string, icon: React.ReactNode) => {
    const key = path;
    const label = (
      <StyledWhite15px475weight
        ismobileview={isMobileView}
        isselected={location.pathname.includes(key)}
      >
        {contentStore.cmsStore.getContent(menuLabel)?.content}
      </StyledWhite15px475weight>
    );
    return {
      key,
      path,
      icon,
      label
    } as MenuItemType;
  };

  const commercialMenuItems: MenuItemType[] = [
    manageUserAccessStore.showProductPage()
      ? getItem(MenuItemTitle.Products, RouteUrlPaths.CommercialProducts, <ProductsIcon />)
      : null,
    manageUserAccessStore.showPagesUsageOrderTransactions()
      ? getItem(MenuItemTitle.Usage, RouteUrlPaths.Usage, <SpendingsIcon />)
      : null,
    manageUserAccessStore.showPagesUsageOrderTransactions()
      ? getItem(MenuItemTitle.Payment, RouteUrlPaths.Payments, <CardIcon />)
      : null,
    manageUserAccessStore.showPagesUsageOrderTransactions()
      ? getItem(MenuItemTitle.Ordering, RouteUrlPaths.Order, <Shoppingcart />)
      : null,
    manageUserAccessStore.showAdministrationPage()
      ? getItem(MenuItemTitle.Administration, RouteUrlPaths.Administration, <Settings />)
      : null,
    getItem(MenuItemTitle.MyProfile, RouteUrlPaths.MyProfile, <User />),
    getItem(MenuItemTitle.LogOut, RouteUrlPaths.LogOut, <Logout />)
  ];

  const privateMenuItems: MenuItemType[] = [
    getItem(MenuItemTitle.Frontpage, RouteUrlPaths.Frontpage, <FrontpageIcon />),
    getItem(
      MenuItemTitle.BizzAndLicenseplate,
      RouteUrlPaths.PrivateProducts,
      <BizzAndLicensePlateIcon />
    ),
    getItem(
      MenuItemTitle.TransactionsAndUsage,
      RouteUrlPaths.TransactionAndUsage,
      <SpendingsIcon />
    ),
    getItem(MenuItemTitle.Ordering, RouteUrlPaths.Order, <Shoppingcart />),
    getItem(MenuItemTitle.PaymentCard, RouteUrlPaths.PaymentCard, <CardIcon />),
    getItem(MenuItemTitle.MyProfile, RouteUrlPaths.MyProfile, <User />),
    getItem(MenuItemTitle.LogOut, RouteUrlPaths.LogOut, <Logout />)
  ];

  const menuItems = manageUserAccessStore.isPrivateCustomer()
    ? privateMenuItems
    : commercialMenuItems;

  if (isMobileView && menuStore.isAnimationOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <Sider width={SiderWidth} ismobileview={isMobileView} isopen={menuStore.isAnimationOpen}>
      <div style={{ position: 'fixed', width: SiderWidth }}>
        {!isMobileView && (
          <Link
            to={{ pathname: RouteUrlPaths.Frontpage }}
            onClick={() => menuStore.setSelectedMenuItem('')}
          >
            <BrobizzLogo />
          </Link>
        )}
        <MenuItemWrapper ismobileview={isMobileView}>
          {menuItems.map(
            (item) =>
              item && (
                <Row style={{ padding: '0 30px' }}>
                  <StyledNavLink
                    isbizzandnumberplates={item?.key === RouteUrlPaths.PrivateProducts}
                    isprivatepaymentcarditem={item?.key === RouteUrlPaths.PaymentCard}
                    isproductaccessonproductsitem={
                      item.key === RouteUrlPaths.CommercialProducts &&
                      userStore.person?.role === UserRole.ProductAccess
                    }
                    isorderitem={item.key === RouteUrlPaths.Order && !isPrivateCustomer}
                    style={{ width: '100%' }}
                    to={item?.key!}
                    onClick={() => {
                      menuStore.setSelectedMenuItem(item?.key!);
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      item?.key! === RouteUrlPaths.LogOut
                        ? logOut(authenticationStore)
                        : menuStore.setIsMenuSiderOpen(!isMobileView);
                    }}
                  >
                    <MenuItem
                      menuStore={menuStore}
                      menuItem={item}
                      isParent={manageUserAccessStore.isParentAccount()}
                      isSelected={location.pathname.includes(item?.key!)}
                      cmsStore={contentStore.cmsStore}
                    />
                  </StyledNavLink>
                </Row>
              )
          )}
        </MenuItemWrapper>

        {userStore.person?.role !== UserRole.ProductAccess && (
          <BottomMenuItem isopen={menuStore.isMenuSiderOpen} width={SiderWidth}>
            <CustomerInformation contentStore={contentStore} accountStore={accountStore} />
          </BottomMenuItem>
        )}
      </div>
    </Sider>
  );
};

export default observer(Menu);
