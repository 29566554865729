import React, { FC } from 'react';
import 'antd/dist/antd.min.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import ModalManager from './features/modals/ModalManager';
import MenuContainer from './features/navigation/MenuContainer';
import Authenticator from './features/authentication/Authenticator';
import ChangeAccountContainer from './features/navigation/components/ChangeAccountContainer';
import BurgerMenuHeader from './features/navigation/BurgerMenuHeader';
import AppRoutes from './routes/Routes';
import PublicRoutes from './routes/PublicRoutes';
import useIsMobileView from './hooks/useIsMobileView';
import useContentLoader from './hooks/useContentLoader';
import LoadingSpinner from './common-components/LoadingSpinner';

declare global {
  interface Window {
    Reepay: any;
    nativeLayer: any;
    setLicensePlateWebApp: any;
  }
}

const Wrapper = styled.div<{
  ismobile: boolean;
}>`
  height: ${({ ismobile }) => (ismobile ? '100svh' : '100%')};
  min-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

const publicRoutes = ['/register', '/opret-bruger', '/velkommen', '/email-er-opdateret'];

const AppRoute: FC = () => {
  const isMobileView = useIsMobileView();

  const mobileContent = (
    <>
      <BurgerMenuHeader />
      <MenuContainer />
      <Content>
        <AppRoutes />
      </Content>
    </>
  );

  const desktopContent = (
    <Container>
      <MenuContainer />
      <Content>
        <ChangeAccountContainer />
        <AppRoutes />
      </Content>
    </Container>
  );

  return <Wrapper ismobile={isMobileView}>{isMobileView ? mobileContent : desktopContent}</Wrapper>;
};

const App: FC = () => {
  const { pathname } = window.location;
  const isPublicPage = publicRoutes.includes(pathname);
  const isContentLoaded = useContentLoader(isPublicPage);

  const searchParams = new URLSearchParams(document.location.search);

  const isWrapperApp = searchParams.get('app');
  if (isWrapperApp) {
    sessionStorage.setItem('app', 'true');
  }

  if (document.getElementById('root')) {
    document.getElementById('root')!.style.height = '100%';
  }

  return (
    <BrowserRouter>
      <LoadingSpinner global />
      <ModalManager />
      {isPublicPage ? (
        isContentLoaded && <PublicRoutes />
      ) : (
        <Authenticator>
          <AppRoute />
        </Authenticator>
      )}
    </BrowserRouter>
  );
};

export default App;
