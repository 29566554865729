import util from 'util';
import React, { ChangeEvent, FC, useState } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import CustomModal from '../../../common-components/CustomModal';
import useStore from '../../../hooks/useStore';
import ContentStore from '../../../stores/ContentStore';
import { SmallGrey12 } from '../../../layout/globalFonts';
import { openSnackbar } from '../../notifications/Snackbar';

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)`
  resize: none;
  border: 1px solid #c0c0c0 !important;
  box-shadow: none !important;
  border-radius: 5px;
  ::placeholder {
    color: #747474;
  }
  margin-bottom: 30px;

  .ant-input:focus,
  .ant-input:hover {
    border-color: #c0c0c0;
    box-shadow: none;
  }
`;

const TextAreaHeader = styled.div`
  color: #747474;
  text-align: left;
`;

interface CloseAccountModalProps {
  closeModal: () => void;
  accountNumber: string;
}

const CloseAccountModal: FC<CloseAccountModalProps> = (props) => {
  const { closeModal, accountNumber } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const { accountStore, customerServiceStore } = contentStore.rootStore;
  const [inputValue, setInputValue] = useState('');

  const header = contentStore.cmsStore.getContent('ClosingSubAccountRequestPopUp')?.content!;
  const content = contentStore.cmsStore.getContent('ClosingSubAccountRequestPopUp')?.subText!;
  const greenLinkText = contentStore.cmsStore.getContent('Cancel')?.content;
  const primaryButtonText = contentStore.cmsStore.getContent('RequestClosing')?.content;

  const requestToCloseAccount = () => {
    customerServiceStore.requestToCloseAccount(accountNumber, inputValue).then(() => {
      openSnackbar(contentStore.cmsStore.getContent('CloseAccountSnackbar')?.content!);
      accountStore.rootStore.administrationStore.administrationAccountStore.getAccountAdminInfo();
    });
    closeModal();
  };

  const checkForInputValue = (value: ChangeEvent<HTMLTextAreaElement>) => {
    const input = (value.target as HTMLTextAreaElement).value;
    setInputValue(input);
  };

  return (
    <CustomModal
      header={util.format(header, accountNumber)}
      key="cancelAccountModal"
      contentText={content}
      closable
      greenLinkText={greenLinkText}
      primaryButtonText={primaryButtonText}
      onGreenLinkClick={() => closeModal()}
      onPrimaryButtonClick={() => requestToCloseAccount()}
      isPrimaryButtonDisabled={inputValue.length === 0}
    >
      <TextAreaHeader>
        <SmallGrey12>
          {contentStore.cmsStore.getContent('ProvideReasonHeader')?.content}
        </SmallGrey12>
      </TextAreaHeader>
      <StyledTextArea
        rows={3}
        placeholder={contentStore.cmsStore.getContent('ProviderReasonTextBoxText')?.content}
        maxLength={3000}
        showCount
        onChange={(e) => checkForInputValue(e)}
      />
    </CustomModal>
  );
};

export default CloseAccountModal;
