/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { FilterMenuContent, FilterObject, Filters } from '../../Types';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export type CardPaymentTableFilter = {
  dates: FilterObject[];
};
export default class CardPaymentFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setCardPaymentFilterContentInit() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: CardPaymentTableFilter = {
    dates: []
  };

  temporaryFilters: CardPaymentTableFilter = {
    dates: []
  };

  cardSearchVal: string = '';

  fromDate: Date | null = null;

  toDate: Date | null = null;

  requestParams: {
    accountId: string;
    searchString?: string | undefined;
    fromDate?: string | undefined;
    toDate?: string | undefined;
    pageNumber?: number | undefined;
  } = {
    accountId: ''
  };

  setRequestParams = () => {
    this.requestParams.searchString = this.cardSearchVal;
    this.requestParams.pageNumber = this.rootStore.paymentStore.cardPaymentPageNumber;
    this.requestParams.fromDate = this.fromDate
      ? this.fromDate?.toLocaleString('en-US')
      : undefined;
    this.requestParams.toDate = this.toDate ? this.toDate?.toLocaleString('en-US') : undefined;
  };

  setFromDate = (fromDate: Date) => {
    this.fromDate = fromDate;
  };

  setToDate = (toDate: Date) => {
    this.toDate = toDate;
  };

  clearDateFilters = () => {
    this.fromDate = null;
    this.toDate = null;
  };

  setCardSearchVal = (searchVal: string) => {
    this.cardSearchVal = searchVal;
    this.rootStore.paymentStore.setCardPageNumber(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.paymentStore.setCardPageNumber(1);
  };

  setTemporaryFilters = (filters: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filters));
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      allMenus: observable,
      activeFilters: observable,
      temporaryFilters: observable,
      cardSearchVal: observable,
      fromDate: observable,
      toDate: observable,
      setFromDate: action,
      setToDate: action,
      clearDateFilters: action,
      setCardSearchVal: action,
      setActiveFilters: action
    });
  }
}
