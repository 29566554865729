import { Modal } from 'antd';
import styled, { css, keyframes } from 'styled-components';

const openModal = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const closeModal = keyframes`
  to {
    transform: translateY(100%);
    opacity: 1;
  }
`;

const FullPageModal = styled(Modal)<{ isopen: boolean; ismobileview: boolean }>`
  ${(props) => (props.ismobileview ? 'top: 0%' : 'top: 4%')};
  margin: 0;
  padding: 0;
  ::-webkit-scrollbar {
    width: 21px;
  }
  .ant-modal-content {
    ${(props) => props.ismobileview && 'height: 100%'};
    width: ${({ ismobileview }) => (ismobileview ? '100vw' : 'calc(100vw - 17px)')};
    border-radius: ${({ ismobileview }) => (ismobileview ? '0px' : '20px 20px 0px 0px')};
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 17px;
    }
  }
  .ant-modal-body {
    padding: 0;
    width: 100vw;
  }

  .ant-modal-close {
    margin: 23px 23px 0 0;
  }

  ${(props) =>
    props.isopen
      ? css`
          animation-name: ${openModal};
        `
      : css`
          animation-name: ${closeModal};
        `}
  animation-timing-function: ease-out;
  animation-duration: 500ms;
`;

export default FullPageModal;
