/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { Filters, FilterMenuContent, FilterObject } from '../../Types';
import { Euronorm } from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export type TransactionsTableFilter = {
  euronorms: FilterObject[];
  dates: FilterObject[];
  amounts: FilterObject[];
};
export default class TransactionFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setTransactionFilterContentInit() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: TransactionsTableFilter = {
    euronorms: [],
    dates: [],
    amounts: []
  };

  temporaryFilters: TransactionsTableFilter = {
    euronorms: [],
    dates: [],
    amounts: []
  };

  searchVal: string = '';

  fromDate: Date | null = null;

  toDate: Date | null = null;

  requestParams: {
    accountId: string;
    searchString?: string | undefined;
    euronorms?: Euronorm[] | undefined;
    fromAmount?: number | undefined;
    toAmount?: number | undefined;
    fromDate?: string | undefined;
    toDate?: string | undefined;
    pageNumber?: number | undefined;
    includeDraft?: boolean;
  } = {
    accountId: ''
  };

  setRequestParams = () => {
    this.requestParams.searchString = this.searchVal;
    this.requestParams.pageNumber = this.rootStore.transactionsStore.transactionsPageNumber;
    this.requestParams.euronorms = this.activeFilters.euronorms.map(
      (element: FilterObject) => element.filter as Euronorm
    );
    this.requestParams.fromDate = this.fromDate
      ? this.fromDate?.toLocaleString('en-US')
      : undefined;
    this.requestParams.toDate = this.toDate ? this.toDate?.toLocaleString('en-US') : undefined;
    let fromAmount: number | undefined;
    let toAmount: number | undefined;
    if (this.activeFilters.amounts.length === 1) {
      [fromAmount, toAmount] = this.activeFilters.amounts[0].filter as number[];
    }
    this.requestParams.fromAmount = fromAmount;
    this.requestParams.toAmount = toAmount;
  };

  setSearchVal = (searchString: string) => {
    this.searchVal = searchString;
    this.rootStore.transactionsStore.setTransactionsCurrentPage(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.transactionsStore.setTransactionsCurrentPage(1);
  };

  setTemporaryFilters = (filters: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filters));
  };

  setFromDate = (fromDate: Date) => {
    this.fromDate = fromDate;
  };

  setToDate = (toDate: Date) => {
    toDate.setHours(23, 59, 59, 999);
    this.toDate = toDate;
  };

  clearDateFilters = () => {
    this.fromDate = null;
    this.toDate = null;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      allMenus: observable,
      activeFilters: observable,
      searchVal: observable,
      temporaryFilters: observable,
      fromDate: observable,
      toDate: observable,
      setFromDate: action,
      setToDate: action,
      clearDateFilters: action,
      setTemporaryFilters: action,
      setSearchVal: action,
      setActiveFilters: action
    });
  }
}
