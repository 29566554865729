/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router';
import RouteUrlPaths from '../../RouteUrlPaths';
import UserStore from '../../stores/UserStore';

interface ProtectedCreateCustomerRouteProps {
  userStore: UserStore;
}

const ProtectedCreateCustomerRoute: FC<ProtectedCreateCustomerRouteProps> = (props) => {
  const { userStore, children } = props;
  if (userStore.person?.signUpInProgress) {
    return <Navigate to={RouteUrlPaths.CreateCustomer} />;
  }
  return <>{children}</>;
};

export default ProtectedCreateCustomerRoute;
