import React, { FC, useEffect, useState } from 'react';
import useIsMobileView from '../../hooks/useIsMobileView';
import PaymentCardStore from '../../stores/PaymentCardStore';

interface ReepayEmbeddedProps {
  paymentCardStore: PaymentCardStore;
  accountId?: string;
  isNewPrivateUser?: boolean;
  isNewCommercialCustomer?: boolean;
  onSuccess?: () => void;
  oldPaymentMethodId?: string;
}

export type ReepayEvent = {
  customer: string;
  error: string;
  id: string;
  invoice: string;
  payment_method: string;
  subscribtion: string;
};

const ReepayEmbedded: FC<ReepayEmbeddedProps> = (props) => {
  const {
    paymentCardStore,
    accountId,
    isNewPrivateUser,
    isNewCommercialCustomer,
    oldPaymentMethodId,
    onSuccess
  } = props;
  const [isReepayScriptLoaded, setIsReepayScriptLoaded] = useState(false);
  const isMobileView = useIsMobileView();
  const reepay = window.Reepay;
  const { productItemsStore } = paymentCardStore.rootStore.productStore;
  const { loadingStore } = paymentCardStore.rootStore;

  const reepayWindowMinWidth = isMobileView ? `${window.innerWidth}px` : '430px';

  const reepayEvent = async (data: ReepayEvent) => {
    paymentCardStore.rootStore.modalStore.closeModal();
    loadingStore.enableGlobalSpinner();
    // payment_method is ca_token
    if (data.payment_method) {
      if (isNewPrivateUser || isNewCommercialCustomer) {
        await paymentCardStore.setPaymentMethod(data.payment_method);
      } else {
        await paymentCardStore.createOrUpdatePaymentCard(
          data.payment_method,
          accountId,
          isNewCommercialCustomer,
          oldPaymentMethodId
        );
        if (!isNewPrivateUser && !isNewCommercialCustomer) {
          await paymentCardStore.getPaymentMethodsByAccountId().then(() => {
            paymentCardStore.rootStore.notificationStore.getNotifications();
            productItemsStore.getProducts();
          });
        }
      }
      if (onSuccess) onSuccess();
    }
    loadingStore.disableGlobalSpinner();
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.reepay.com/checkout.js';
    script.addEventListener('load', () => setIsReepayScriptLoaded(true));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isReepayScriptLoaded) {
      if (isNewPrivateUser || isNewCommercialCustomer) {
        paymentCardStore.getCreatePaymentCardUrl().then((result) => {
          const rp = new reepay.EmbeddedCheckout(result.id, {
            html_element: 'reepay_container'
          });
          rp.addEventHandler(reepay.Event.Accept, (data: ReepayEvent) => reepayEvent(data));
        });
      } else {
        paymentCardStore.getCreatePaymentCardUrlForExistingCustomer(accountId).then((result) => {
          const rp = new reepay.EmbeddedCheckout(result.id, {
            html_element: 'reepay_container'
          });
          rp.addEventHandler(reepay.Event.Accept, (data: ReepayEvent) => reepayEvent(data));
        });
      }
    }
  }, [isReepayScriptLoaded]);

  return (
    <div style={{ minWidth: reepayWindowMinWidth }}>
      <div style={{ height: '650px' }} id="reepay_container" />
    </div>
  );
};

ReepayEmbedded.defaultProps = {
  accountId: undefined,
  isNewPrivateUser: false,
  isNewCommercialCustomer: false,
  oldPaymentMethodId: undefined,
  onSuccess: () => {}
};

export default ReepayEmbedded;
