/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getLastTransactionByid"] }] */
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  InvoiceStatus,
  Transaction,
  TransactionPaginatedList,
  TransactionService
} from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import { downloadExcel } from '../utils/downloadExcel';

export enum TransactionsAndUsageTabs {
  TripsAndUsageTab = 1,
  OutstandingPaymentTab = 2
}

export default class TransactionsStore extends BaseStore {
  accountTransactions: Transaction[] = [];

  identifierTransactions: Transaction[] = [];

  accountOtherTransactions: Transaction[] = [];

  accountOutstandingTransactions: Transaction[] = [];

  pageSize: number = 0;

  pageSizeOther: number = 0;

  pageSizeOutstanding: number = 0;

  numberOfTransactions: number = 0;

  filteredNumberOfTransactions: number = 0;

  numberOfOtherTransactions: number = 0;

  filteredNumberOfOtherTransactions: number = 0;

  numberOfOutstandingTransactions: number = 0;

  transactionsPageNumber: number = 1;

  otherPageNumber: number = 1;

  outstandingPagenumber: number = 1;

  activeTab: number = 1;

  hasOutstandingDebt = (): Promise<boolean> =>
    new Promise((resolve) => {
      if (this.accountOutstandingTransactions.length > 0) {
        resolve(true);
      }
      if (this.accountOutstandingTransactions.length === 0) {
        this.getAccountOutstandingTransactions().then(() => {
          if (this.accountOutstandingTransactions.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });

  downloadTransactions = () => {
    this.rootStore.filterStore.transactionFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.transactionFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = TransactionService.downloadTransactionsForAccountExcel(params);
    promise.then((data) => {
      downloadExcel(`${this.rootStore.userStore.currentAccount?.id!} Transactions`, data);
    });
  };

  downloadOtherTransactions = () => {
    this.rootStore.filterStore.otherTransactionFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.otherTransactionFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = TransactionService.downloadOtherTransactionsForAccountExcel(params);
    promise.then((data) => {
      downloadExcel(`${this.rootStore.userStore.currentAccount?.id!} Other Transactions`, data);
    });
  };

  getAccountTransactions = () => {
    this.rootStore.filterStore.transactionFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.transactionFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    params.includeDraft = !this.rootStore.manageUserAccessStore.isPrivateCustomer();
    const promise = TransactionService.getTransactionsByAccountIdAsync(params);
    promise.then((val: TransactionPaginatedList) => {
      runInAction(() => {
        this.accountTransactions = val.paginatedItems!;
        this.numberOfTransactions = val.totalCount!;
        this.filteredNumberOfTransactions = val.filteredCount!;
        this.pageSize = val.pageSize!;
      });
    });
    return promise;
  };

  getAccountOutstandingTransactions = () => {
    const params = {
      accountId: this.rootStore.userStore.currentAccount?.id!,
      pageNumber: this.rootStore.transactionsStore.outstandingPagenumber,
      invoiceStatus: InvoiceStatus.NotPaid
    };

    const promise = TransactionService.getTransactionsByAccountIdAsync(params);

    promise.then((val: TransactionPaginatedList) => {
      runInAction(() => {
        this.accountOutstandingTransactions = val.paginatedItems!;
        this.numberOfOutstandingTransactions = val.filteredCount!;
        this.pageSizeOutstanding = val.pageSize!;
      });
    });
    return promise;
  };

  getAccountOtherTransactions = () => {
    this.rootStore.filterStore.otherTransactionFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.otherTransactionFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = TransactionService.getOtherTransactionsByAccountIdAsync(params);
    promise.then((val: TransactionPaginatedList) => {
      runInAction(() => {
        this.accountOtherTransactions = val.paginatedItems!;
        this.numberOfOtherTransactions = val.totalCount!;
        this.filteredNumberOfOtherTransactions = val.filteredCount!;
        this.pageSizeOther = val.pageSize!;
      });
    });
    return promise;
  };

  getIdentifierTransactions = (identifierId: string) => {
    const includeDraft = !this.rootStore.manageUserAccessStore.isPrivateCustomer();
    const promise = TransactionService.getTransactionsByIdentifierIdAsync({
      identifierId,
      includeDraft
    });
    promise.then((result) => {
      this.identifierTransactions = result;
    });
    return promise;
  };

  getLastTransactionByid = (identifierId: string) => {
    const promise = TransactionService.getLatestTransactionByIdentifierIdAsync({ identifierId });
    return promise;
  };

  setTransactionsCurrentPage = (pageNumber: number) => {
    this.transactionsPageNumber = pageNumber;
  };

  setOtherCurrentPage = (pageNumber: number) => {
    this.otherPageNumber = pageNumber;
  };

  setOutstandingCurrentPage = (pageNumber: number) => {
    this.outstandingPagenumber = pageNumber;
  };

  setActiveTab = (activeTab: number) => {
    this.activeTab = activeTab;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      accountTransactions: observable,
      identifierTransactions: observable,
      accountOtherTransactions: observable,
      accountOutstandingTransactions: observable,
      pageSize: observable,
      pageSizeOther: observable,
      pageSizeOutstanding: observable,
      numberOfTransactions: observable,
      filteredNumberOfTransactions: observable,
      numberOfOtherTransactions: observable,
      numberOfOutstandingTransactions: observable,
      filteredNumberOfOtherTransactions: observable,
      transactionsPageNumber: observable,
      otherPageNumber: observable,
      outstandingPagenumber: observable,
      activeTab: observable,
      downloadTransactions: action,
      downloadOtherTransactions: action,
      getAccountTransactions: action,
      getAccountOtherTransactions: action,
      getAccountOutstandingTransactions: action,
      getIdentifierTransactions: action,
      getLastTransactionByid: action,
      setTransactionsCurrentPage: action,
      setOtherCurrentPage: action,
      setActiveTab: action,
      hasOutstandingDebt: action
    });
  }
}
