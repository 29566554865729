import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Divider, Row } from 'antd';
import {
  ContentSmallLinkCamptonBook,
  H9CamptonBoldSemiBold19
} from '../../../../../layout/globalFonts';
import { ReactComponent as CheckIcon } from '../../../../../layout/icons/check.svg';
import { PrimaryButton } from '../../../../../common-components/Buttons';

const StyledCardWrapper = styled(Col)`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  min-width: 314px;
  max-width: calc(50% - 26px);
`;

const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 36px 0;
`;

const StyledHeaderContent = styled(H9CamptonBoldSemiBold19)`
  max-width: 200px;
  text-align: center;
`;

const StyledDivider = styled(Divider)`
  margin-block: 23px;
`;

const StyledCardBody = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: 100%;
  gap: 12px;
  margin-bottom: 31px;
  padding-inline: 61px;
`;

const StyledCardFooter = styled(Row)`
  justify-content: center;
  padding-bottom: 36px;
`;

const AgreementContent = styled(ContentSmallLinkCamptonBook)`
  word-break: break-word;
`;

export type TollingBoxAgreementText = string | null | undefined;

interface TollingBoxTypeProps {
  icon: JSX.Element;
  header?: string;
  agreements: TollingBoxAgreementText[];
  buttonContent?: string;
  selectTypeHandler: React.MouseEventHandler<HTMLElement>;
}

const TollingBoxTypeCard: FC<TollingBoxTypeProps> = ({
  icon,
  header,
  agreements,
  buttonContent,
  selectTypeHandler
}) => {
  const agreementRow = (agreement: string) => (
    <Row key={Math.random().toString()}>
      <Col span={2}>
        <CheckIcon />
      </Col>
      <Col span={22} style={{ paddingLeft: '12px' }}>
        <AgreementContent>{agreement}</AgreementContent>
      </Col>
    </Row>
  );

  return (
    <StyledCardWrapper span={12}>
      <StyledCardHeader>
        {icon}
        <StyledHeaderContent>{header}</StyledHeaderContent>
      </StyledCardHeader>
      <StyledDivider />
      <StyledCardBody>
        {agreements.map((agreement) => agreement && agreementRow(agreement))}
      </StyledCardBody>
      <StyledCardFooter>
        <PrimaryButton onClick={selectTypeHandler}>{buttonContent}</PrimaryButton>
      </StyledCardFooter>
    </StyledCardWrapper>
  );
};

export default observer(TollingBoxTypeCard);

TollingBoxTypeCard.defaultProps = {
  buttonContent: '',
  header: ''
};
