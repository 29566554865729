/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { NewPerson, SignUpService } from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class RegisterStore extends BaseStore {
  isChecked: boolean = false;

  isSubmitted: boolean = false;

  setIsChecked = (checked: boolean) => {
    this.isChecked = checked;
  };

  setIsSubmitted = (submitted: boolean) => {
    this.isSubmitted = submitted;
  };

  saveUser = (user: NewPerson) => {
    const promise = SignUpService.registerNewB2BUser({ body: user });
    this.isChecked = false;
    promise.then(() => this.setIsSubmitted(true));
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      isChecked: observable,
      isSubmitted: observable,
      setIsChecked: action,
      setIsSubmitted: action,
      saveUser: action
    });
  }
}
