/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export enum AddressSelection {
  AccountAddress = 0,
  OtherAddress = 1
}

export default class OrderAddressStore extends BaseStore {
  addressSelection: number = AddressSelection.AccountAddress;

  setUseOtherAddress = (selection: number) => {
    this.addressSelection = selection;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      addressSelection: observable,
      setUseOtherAddress: action
    });
  }
}
