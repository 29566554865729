import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';
import styled, { keyframes, css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import DefaultAccountPageHeader from '../../../layout/components/DefaultAccountPageHeader';
import AccountStore from '../../../stores/AccountStore';
import AccountCardList from '../../account-features/AccountCardList';
import SearchBar from '../../search-bar/SearchBar';
import { ContentSmallCamptonBook, H7CamptonSemiBold22 } from '../../../layout/globalFonts';

const ContentSmallCamptonBookStyled = styled(ContentSmallCamptonBook)`
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 0.66;
  margin-top: 70px;
  margin-bottom: 10px;
`;

const StyledContent = styled.div`
  left: 260px;
  padding: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
`;

const openMenu = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const closeMenu = keyframes`
  to {
    transform: translateX(-100%);
    opacity: 1;
  }
`;

const Wrapper = styled.div<{ isopen: boolean }>`
  max-width: 558px;
  height: 100vh;
  border-left: 1px solid #122424;
  background: #193232;
  overflow: auto;

  ${(props) =>
    props.isopen
      ? css`
          animation-name: ${openMenu};
        `
      : css`
          animation-name: ${closeMenu};
        `}
  animation-timing-function: ease-out;
  animation-duration: 500ms;
`;

const StyleSearchBarWrapper = styled.div`
  width: 100%;
  margin-top: 40px;

  .ant-input {
    opacity: 0.66;
  }
  .ant-input-affix-wrapper {
    background-color: #efefef;
    opacity: 0.66;
  }
`;

interface ChangeAccountProps {
  accountStore: AccountStore;
}

const ChangeAccount: FC<ChangeAccountProps> = (props) => {
  const { accountStore } = props;
  const { contentStore, userStore } = accountStore.rootStore;
  const [isLoading, setIsLoading] = useState(false);

  const showSearchBar =
    userStore.person?.accountIds?.length && userStore.person?.accountIds?.length >= 8;

  return (
    <StyledContent>
      <Wrapper isopen={accountStore.isAnimationOpen}>
        <Row justify="center">
          <Col style={{ width: '376px' }}>
            <Row justify="center">
              <H7CamptonSemiBold22>
                <DefaultAccountPageHeader
                  pageName={contentStore.cmsStore.getContent('ChangeAccount')?.content!}
                />
              </H7CamptonSemiBold22>
            </Row>
            {showSearchBar && (
              <Row justify="center">
                <StyleSearchBarWrapper>
                  <SearchBar
                    isAccountSearchBar
                    searchPlaceholder={
                      contentStore.cmsStore.getContent('AccountAdministrationSearchBarText')
                        ?.content!
                    }
                    searchVal={userStore.accountSearchValue}
                    setSearchVal={userStore.setSearchValue}
                    searchFunction={() => {
                      setIsLoading(true);
                      userStore.getAccountInfoLightBySearching().finally(() => setIsLoading(false));
                    }}
                  />
                </StyleSearchBarWrapper>
              </Row>
            )}
            <Row justify="center">
              <ContentSmallCamptonBookStyled style={{ marginBottom: '20px' }}>
                {contentStore.cmsStore.getContent('Accounts')?.content}
              </ContentSmallCamptonBookStyled>
            </Row>
            <Row justify="center">
              <AccountCardList
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userStore={userStore}
                contentStore={contentStore}
              />
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </StyledContent>
  );
};

export default observer(ChangeAccount);
