import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Tooltip, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { AccountInformationText } from '../../../layout/globalFonts';
import { ReactComponent as SmallArrowRightWhite } from '../../../layout/icons/small-white-arrow-right.svg';
import { ReactComponent as DefaultStar } from '../../../layout/icons/default-star.svg';
import useStore from '../../../hooks/useStore';
import UserStore from '../../../stores/UserStore';
import LoadingStore from '../../../stores/LoadingStore';
import RouteUrlPaths from '../../../RouteUrlPaths';

const InvisibleButton = styled(Button)`
  border: none;
  background: none;
  visibility: hidden;
`;

const StyledAccountCard = styled.div<{ isSelectedAccount: boolean }>`
  background-color: rgba(255, 255, 255, 0.05);
  opacity: 100%;
  height: 70px;
  width: 376px;
  border-radius: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
  ${(props) => props.isSelectedAccount && `background-color: rgba(255, 255, 255, 0.12);`}
`;

const RowStyled = styled(Row)`
  margin-left: -54px;
`;

const overlayInnerStyle = {
  borderRadius: '5px',
  padding: '6px 10px 5px 10px',
  font: 'Campton book',
  fontSize: '15px',
  color: '#2d2d2d'
};

const StyledDefaultStar = styled(DefaultStar)`
  cursor: pointer;
  visibility: hidden;

  ${RowStyled}:hover & {
    visibility: visible;
    opacity: 0.66;
  }
`;

const ChosenAccountIcon = styled(DefaultStar)`
  visibility: visible;
  opacity: 1 !important;
`;

const StyledInvisbleButton = styled(InvisibleButton)`
  margin-right: 22px;
  margin-top: 25px;
  border: none;
  padding: 22px;
  background: none;
  padding: 0;
  :hover {
    border: none;
    background: none;
  }
`;

const CustomerNo = styled(AccountInformationText)`
  font-size: 15px;
  line-height: 18px;
`;

const AccountName = styled(AccountInformationText)`
  opacity: 0.66;
`;

const AccountCardArrow = styled(SmallArrowRightWhite)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
`;

interface AccountCardProps {
  companyName: string;
  number: string;
  accountId: string;
  isDefaultAccount: boolean;
  setDefaultAccountId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AccountCard: FC<AccountCardProps> = (props) => {
  const userStore: UserStore = useStore(UserStore);
  const loadingStore: LoadingStore = useStore(LoadingStore);
  const { contentStore, accountStore } = userStore.rootStore;
  const { companyName, number, accountId, isDefaultAccount, setDefaultAccountId } = props;
  const [starIcon, setStarIcon] = useState(<ChosenAccountIcon />);

  const navigate = useNavigate();
  const routeChange = () => {
    contentStore.rootStore.notificationStore.resetNotifications();
    accountStore.setIsChangeAccountOpen(false);
    userStore.setSelectedAccountId(accountId);
    loadingStore.enableGlobalSpinner();
    userStore
      .loadCurrentAccount(accountId)
      .then(() => {
        userStore.setIsSelectAccountShown();
        navigate(RouteUrlPaths.Frontpage);
      })
      .finally(() => loadingStore.disableGlobalSpinner());
  };

  useEffect(() => {
    setStarIcon(isDefaultAccount ? <ChosenAccountIcon /> : <StyledDefaultStar />);
  }, [isDefaultAccount]);

  return (
    <RowStyled>
      <Col>
        <Tooltip
          placement="bottom"
          title={contentStore.cmsStore.getContent('ChooseDefaultAccount')?.tooltip}
          color="#fff"
          overlayInnerStyle={overlayInnerStyle}
        >
          <StyledInvisbleButton
            onClick={async () => {
              await userStore.chooseDefaultAccount(accountId);
              setDefaultAccountId(accountId);
            }}
            icon={starIcon}
          />
        </Tooltip>
      </Col>
      <Col>
        <StyledAccountCard
          isSelectedAccount={userStore.currentAccount?.id === accountId}
          onClick={routeChange}
        >
          <Row justify="space-between" align="middle" style={{ padding: '18px 24px' }}>
            <Col>
              <Row>
                <AccountName>{companyName}</AccountName>
              </Row>
              <Row>
                <CustomerNo>{number}</CustomerNo>
              </Row>
            </Col>
            <Col>
              <AccountCardArrow />
            </Col>
          </Row>
        </StyledAccountCard>
      </Col>
    </RowStyled>
  );
};

export default observer(AccountCard);
