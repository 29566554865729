import ContentStore from '../stores/ContentStore';
import { ProductInfo, VehicleTractorAxles, VehicleSize } from '../swagger/api';

enum VehicleTractorAxlesHyGraph {
  Two = 'TractorAxlesTwo',
  Three = 'TractorAxlesThree',
  FourOrMore = 'TractorAxlesFourOrMore',
  Empty = 'TractorAxlesEmpty'
}

export enum VehicleDeclarationMandatoryFields {
  IdentifierNumber = 'IdentifierNumber',
  IdentifierReference = 'IdentifierReference',
  TractorAxles = 'TractorAxles',
  Co2Class = 'Co2Class',
  EUClass = 'EUClass',
  FuelType = 'FuelType',
  VehicleType = 'VehicleType',
  MaxPermissibleWeight = 'MaxPermissibleWeight',
  LicensePlate = 'LicensePlate',
  CountryCode = 'CountryCode'
}

const convertVehicleTractorAxlesToHyGraph = (vehicleTractorAxles?: VehicleTractorAxles) => {
  switch (vehicleTractorAxles) {
    case VehicleTractorAxles.Two:
      return VehicleTractorAxlesHyGraph.Two;
    case VehicleTractorAxles.Three:
      return VehicleTractorAxlesHyGraph.Three;
    case VehicleTractorAxles.FourOrMore:
      return VehicleTractorAxlesHyGraph.FourOrMore;
    default:
      return VehicleTractorAxlesHyGraph.Empty;
  }
};

const convertVehicleDeclarationFieldToHygraph = (
  vehicleDeclarationField: VehicleDeclarationMandatoryFields,
  contentStore: ContentStore
) => {
  switch (vehicleDeclarationField) {
    case VehicleDeclarationMandatoryFields.IdentifierNumber:
      return contentStore.cmsStore.getContent('ProductId')?.content;
    case VehicleDeclarationMandatoryFields.IdentifierReference:
      return contentStore.cmsStore.getContent('Reference')?.content;
    case VehicleDeclarationMandatoryFields.Co2Class:
      return contentStore.cmsStore.getContent('Co2Class')?.content;
    case VehicleDeclarationMandatoryFields.CountryCode:
      return contentStore.cmsStore.getContent('Country')?.content;
    case VehicleDeclarationMandatoryFields.EUClass:
      return contentStore.cmsStore.getContent('EmissionType')?.content;
    case VehicleDeclarationMandatoryFields.LicensePlate:
      return contentStore.cmsStore.getContent('licensePlate')?.content;
    case VehicleDeclarationMandatoryFields.TractorAxles:
      return contentStore.cmsStore.getContent('TractorAxles')?.content;
    case VehicleDeclarationMandatoryFields.VehicleType:
      return contentStore.cmsStore.getContent('VehicleTypeBusOrHeavy')?.content;
    default:
      return '';
  }
};

const validateFieldsForVehicleDeclaration = (product: ProductInfo) => {
  const failedFields: VehicleDeclarationMandatoryFields[] = [];
  const {
    identifierNumber,
    hasObeIdentifierReference,
    co2Class,
    countryIsoCode,
    euronorm,
    licensePlate,
    tractorAxles,
    vehicleSize
  } = product;

  const fields = [
    { value: identifierNumber, field: VehicleDeclarationMandatoryFields.IdentifierNumber },
    {
      value: hasObeIdentifierReference,
      field: VehicleDeclarationMandatoryFields.IdentifierReference
    },
    { value: co2Class, field: VehicleDeclarationMandatoryFields.Co2Class },
    { value: countryIsoCode, field: VehicleDeclarationMandatoryFields.CountryCode },
    { value: euronorm, field: VehicleDeclarationMandatoryFields.EUClass },
    { value: licensePlate, field: VehicleDeclarationMandatoryFields.LicensePlate },
    {
      value: tractorAxles,
      field: VehicleDeclarationMandatoryFields.TractorAxles
    },
    {
      value: vehicleSize,
      field: VehicleDeclarationMandatoryFields.VehicleType,
      rule: [{ validator: (value: any) => value === VehicleSize.VehicleUnder3500kg }]
    }
  ];

  fields.forEach(({ value, field, rule }) => {
    if (!value) {
      failedFields.push(field);
    }
    const isNonEmptyValidationArray = Array.isArray(rule) && rule.length > 0;
    if (isNonEmptyValidationArray && rule.some(({ validator }) => validator(value))) {
      failedFields.push(field);
    }
  });

  return failedFields;
};

export default {
  convertVehicleTractorAxlesToHyGraph,
  convertVehicleDeclarationFieldToHygraph,
  validateFieldsForVehicleDeclaration
};
