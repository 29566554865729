/* eslint-disable import/no-cycle */
import { action, makeObservable, observable, runInAction } from 'mobx';
import React from 'react';
import {
  AccountService,
  Person,
  PersonService,
  ProductInfo,
  ProductInfoPaginatedList,
  SubAccountsListItem,
  SubAccountsListItemPaginatedList,
  UserRole
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class CreateUserStore extends BaseStore {
  isCreateUserModalVisible: boolean = false;

  isAnimationOpen: boolean = false;

  selectedUserRole?: UserRole;

  accountsWithAccessRights?: SubAccountsListItem[] = [];

  productAccessProducts?: ProductInfo[];

  selectedAccountIds: React.Key[] = [];

  selectedProductAccessProduct?: ProductInfo;

  accountSearchVal: string = '';

  productsSearchVal: string = '';

  accountPageSize: number = 0;

  productsPageSize: number = 0;

  accountPageNumber: number = 1;

  productsPageNumber: number = 1;

  filteredProductCount: number = 0;

  filteredAccountCount: number = 0;

  setSelectedProduct = (selectedProduct: ProductInfo | undefined) => {
    this.selectedProductAccessProduct = selectedProduct;
  };

  resetSelectedAccountIds = () => {
    this.selectedAccountIds = [];
  };

  addSelectedAccountIds = (selectedAccountIds: React.Key[]) => {
    selectedAccountIds.forEach((id) => {
      if (this.selectedAccountIds.indexOf(id) === -1) {
        this.selectedAccountIds.push(id);
      }
    });
    if (this.selectedUserRole === UserRole.ProductAccess) {
      this.getProductsForAccount();
    }
  };

  removeSelectedAccountIds = (deselectedAccountId: React.Key[]) => {
    deselectedAccountId.forEach((id) => {
      const index = this.selectedAccountIds.indexOf(id);
      this.selectedAccountIds.splice(index, 1);
    });
  };

  selectOrDeselectAll = (selected: boolean, selectedAccounts: SubAccountsListItem[]) => {
    const selectedAccountsWithReferenceToCurrentUser: React.Key[] = [];
    selectedAccounts.forEach((account: SubAccountsListItem) => {
      if (this.rootStore.userStore.person?.accountIds?.includes(account.id!)) {
        selectedAccountsWithReferenceToCurrentUser.push(account.id!);
      }
    });
    if (selected) {
      this.addSelectedAccountIds(selectedAccountsWithReferenceToCurrentUser);
    } else {
      this.removeSelectedAccountIds(selectedAccountsWithReferenceToCurrentUser);
    }
  };

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  setSelectedUserRole = (userRole: UserRole | undefined) => {
    this.selectedUserRole = userRole;
  };

  closeCreateUserModal = async () => {
    this.setIsAnimationOpen(false);
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 500));
    this.isCreateUserModalVisible = false;
  };

  openCreateUserModal = () => {
    this.setIsAnimationOpen(true);
    this.isCreateUserModalVisible = true;
  };

  setAccountSearchVal = (searchValue: string) => {
    this.accountSearchVal = searchValue;
  };

  setProductSearchVal = (searchValue: string) => {
    this.productsSearchVal = searchValue;
  };

  setCurrentAccountPage = (pageNumber: number) => {
    this.accountPageNumber = pageNumber;
  };

  setCurrentProductPage = (pageNumber: number) => {
    this.productsPageNumber = pageNumber;
  };

  getAccountsWithAccessRights = () => {
    const searchString = this.accountSearchVal;
    const promise = AccountService.getSiblingAccountsRelatedToCurrentAndEditedPersonAsync({
      editedPersonId: this.rootStore.userStore.person?.id!,
      searchString,
      pageNumber: this.accountPageNumber
    });
    promise.then((value: SubAccountsListItemPaginatedList) => {
      this.accountsWithAccessRights = value.paginatedItems;
      this.accountPageSize = value.pageSize!;
      this.filteredAccountCount = value.filteredCount!;
    });
    return promise;
  };

  getProductsForAccount = () => {
    const promise = AccountService.getIdentifiersForAccountAsync({
      accountId: this.selectedAccountIds[0]!.toString(),
      searchString: this.productsSearchVal,
      pageNumber: this.productsPageNumber,
      userRole: this.rootStore.userStore.person?.role
    });
    promise.then((val: ProductInfoPaginatedList) => {
      runInAction(() => {
        this.productAccessProducts = val.paginatedItems!;
        this.productsPageSize = val.pageSize!;
        this.filteredProductCount = val.filteredCount!;
      });
    });
    return promise;
  };

  submitCreateUser = (formValues: any) => {
    if (formValues.userAccess === UserRole.ParentAccountAdmin) {
      (this.selectedAccountIds as string[]) = this.rootStore.userStore.person
        ?.accountIds as string[];
    }
    const body: Person | undefined = {
      currentAccountId: this.rootStore.userStore.currentAccount?.id!,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      role: formValues.userAccess,
      accountIds: this.selectedAccountIds as string[],
      identifierId: this.selectedProductAccessProduct?.identifierId,
      language: this.rootStore.userStore.person?.language
    };

    const promise = PersonService.createPerson({ body });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      accountsWithAccessRights: observable,
      accountSearchVal: observable,
      productsSearchVal: observable,
      accountPageSize: observable,
      productsPageSize: observable,
      accountPageNumber: observable,
      productsPageNumber: observable,
      selectedUserRole: observable,
      selectedAccountIds: observable,
      selectedProductAccessProduct: observable,
      isAnimationOpen: observable,
      isCreateUserModalVisible: observable,
      productAccessProducts: observable,
      filteredProductCount: observable,
      filteredAccountCount: observable,
      closeCreateUserModal: action,
      openCreateUserModal: action,
      getAccountsWithAccessRights: action,
      setAccountSearchVal: action,
      setProductSearchVal: action,
      setCurrentAccountPage: action,
      setCurrentProductPage: action,
      addSelectedAccountIds: action,
      removeSelectedAccountIds: action,
      selectOrDeselectAll: action,
      setSelectedProduct: action,
      submitCreateUser: action,
      resetSelectedAccountIds: action,
      getProductsForAccount: action
    });
  }
}
