/* eslint-disable import/no-cycle */
import BaseStore from './base-stores/BaseStore';
import OrderInfoStore from './order-stores/OrderInfoStore';
import OrderHistoryStore from './order-stores/OrderHistoryStore';
import OrderFlowStepperStore from './order-stores/OrderFlowStepperStore';
import OrderAddressStore from './order-stores/OrderAddressStore';
import SubmitOrderStore from './order-stores/SubmitOrderStore';
import PrivateOrderStore from './order-stores/PrivateOrderStore';
import SelectBizzesforConversionStore from './order-stores/SelectBizzesforConversionStore';

export default class OrderStore extends BaseStore {
  orderHistoryStore: OrderHistoryStore = new OrderHistoryStore(this.rootStore);

  orderInfoStore: OrderInfoStore = new OrderInfoStore(this.rootStore);

  privateOrderStore: PrivateOrderStore = new PrivateOrderStore(this.rootStore);

  orderFlowStepperStore: OrderFlowStepperStore = new OrderFlowStepperStore(this.rootStore);

  orderAddressStore: OrderAddressStore = new OrderAddressStore(this.rootStore);

  submitOrderStore: SubmitOrderStore = new SubmitOrderStore(this.rootStore);

  selectBizzesforConversionStore: SelectBizzesforConversionStore =
    new SelectBizzesforConversionStore(this.rootStore);
}
