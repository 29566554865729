/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import CustomModal from '../../common-components/CustomModal';

interface ErrorModalProps {
  header: string;
  message: string;
}

const ErrorModal: FC<ErrorModalProps> = (props) => {
  const { header, message } = props;
  return <CustomModal header={header} closable contentText={message} />;
};

export default ErrorModal;
