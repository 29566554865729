import React from 'react';
import { Tooltip, Row, Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { overlayInnerStyle } from '../common-components/TooltipStyling';
import {
  ContentSmallLinkCamptonBook,
  ContentSmallCamptonBook,
  ContentSmallGreenLink
} from '../layout/globalFonts';
import { ReactComponent as BizzIcon } from '../layout/icons/payment/bizz-big-less-white-space.svg';
import { ReactComponent as LicensePlateIcon } from '../layout/icons/payment/license-plate-big-less-white-space.svg';
import { ReactComponent as IsPaidIcon } from '../layout/icons/white-check-in-green-circle-small.svg';
import { ReactComponent as IsNotPaidIcon } from '../layout/icons/red-circle-exclamation-small.svg';
import { Transaction, InvoiceStatus } from '../swagger/api';
import ContentStore from '../stores/ContentStore';
import dateFormatting from '../utils/dateFormatting';
import dateTimeFormatting from '../utils/dateTimeFormatting';
import decimalFormatting from '../utils/decimalFormatting';
import { isANPR, isBizz } from '../utils/productTypeUtilities';

const BolderContentSmallLinkCamptonBook = styled(ContentSmallLinkCamptonBook)`
  font-weight: 600;
`;

const useTransactionsAndUsageColumns = (
  contentStore: ContentStore,
  isTableOnProductCard: boolean,
  showOutstandingOnly: boolean
) => {
  const { productItemsStore, productCardStore } = contentStore.rootStore.productStore;
  const { paymentStore, loadingStore } = contentStore.rootStore;

  const showProductCardModalOnClick = (identifierId: string) => {
    if (!isTableOnProductCard) {
      loadingStore.enableGlobalSpinner();
      const productInStore = productItemsStore.findProductFromId(identifierId);
      if (productInStore) {
        productItemsStore.product = productInStore;
        productCardStore.openModal();
        loadingStore.disableGlobalSpinner();
      } else {
        productItemsStore.getProduct(identifierId).then(() => {
          productCardStore.openModal();
          loadingStore.disableGlobalSpinner();
        });
      }
    }
  };

  const dateAndTimeColumn = showOutstandingOnly
    ? {
        title: contentStore.cmsStore.getContent('TransactionsAndUsageTableDueDate')?.content,
        width: '10%',
        render: (row: Transaction) => <>{dateFormatting(row.dueDate!)}</>
      }
    : {
        title: contentStore.cmsStore.getContent('DateAndTime')?.content,
        width: '15%',
        render: (row: Transaction) => <>{dateTimeFormatting(row.date!)}</>
      };

  const columns: ColumnsType<Transaction> = [
    dateAndTimeColumn,
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('DescriptionInTableHeader')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('DescriptionInTableHeader')?.content}
        </Tooltip>
      ),
      render: (row: Transaction) => (
        <>
          <Row>
            <BolderContentSmallLinkCamptonBook>
              {row.tollChargerName}
            </BolderContentSmallLinkCamptonBook>
          </Row>
          <Row>
            <ContentSmallCamptonBook style={{ color: '#747474' }}>
              {contentStore.cmsStore.getContent(row.description!)?.content}
            </ContentSmallCamptonBook>
          </Row>
        </>
      ),
      width: '20%'
    },
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('ProductOrReference')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('ProductOrReference')?.content}
        </Tooltip>
      ),
      render: (row: Transaction) => (
        <Row align="middle">
          <Col style={{ marginRight: '18px', marginBottom: '6px' }}>
            <Row align="top">
              {isANPR(row.productType) ? (
                <LicensePlateIcon />
              ) : isBizz(row.productType) ? (
                <BizzIcon />
              ) : null}
            </Row>
          </Col>
          <Col>
            <Row>
              <ContentSmallGreenLink
                style={{ cursor: isTableOnProductCard ? 'auto' : 'pointer' }}
                onClick={() => showProductCardModalOnClick(row.identifierId!)}
              >
                {row.identifierNumber}
              </ContentSmallGreenLink>
            </Row>
            <Row>
              <ContentSmallCamptonBook style={{ color: '#747474' }}>
                {row.identifierReference}
              </ContentSmallCamptonBook>
            </Row>
          </Col>
        </Row>
      ),
      width: '20%'
    },
    {
      title: contentStore.cmsStore.getContent('StatusInTableHeader')?.content,
      render: (row: Transaction) => (
        <Row>
          <Col>
            <Tooltip
              placement="bottom"
              title={
                row.invoiceStatus === InvoiceStatus.Paid
                  ? contentStore.cmsStore.getContent('IsPaidInTransactionsTable')?.tooltip
                  : contentStore.cmsStore.getContent('MissingPaymentInTransactiontable')?.tooltip
              }
              color="#2d2d2d"
              overlayInnerStyle={overlayInnerStyle}
              style={{ width: '100%' }}
            >
              <Row>
                <Col style={{ marginRight: '6px' }}>
                  {row.invoiceStatus === InvoiceStatus.Paid ? <IsPaidIcon /> : <IsNotPaidIcon />}
                </Col>
                <Col>
                  <ContentSmallCamptonBook>
                    {row.invoiceStatus === InvoiceStatus.Paid
                      ? contentStore.cmsStore.getContent('IsPaidInTransactionsTable')?.content
                      : contentStore.cmsStore.getContent('MissingPaymentInTransactiontable')
                          ?.content}
                  </ContentSmallCamptonBook>
                </Col>
              </Row>
            </Tooltip>
          </Col>
          <Col flex="auto" />
        </Row>
      ),
      width: '20%'
    },
    {
      title: contentStore.cmsStore.getContent('Amount')?.content,
      render: (row: Transaction) => (
        <ContentSmallCamptonBook
          style={{
            color: row.invoiceStatus === InvoiceStatus.Paid ? '#2d2d2d' : '#ff004a',
            fontWeight: row.invoiceStatus === InvoiceStatus.Paid ? 'inherit' : '625'
          }}
        >
          {decimalFormatting(row.amount!)}
          {` ${row.currency}`}
        </ContentSmallCamptonBook>
      ),
      width: '10%'
    },
    {
      title: undefined,
      render: (row: Transaction) => (
        <ContentSmallGreenLink
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            loadingStore.enableGlobalSpinner();
            paymentStore.downloadInvoicePdfPrivate(row.invoiceId!, row.invoiceNumber!).then(() => {
              loadingStore.disableGlobalSpinner();
            });
          }}
        >
          {row.invoiceStatus === InvoiceStatus.Paid
            ? contentStore.cmsStore.getContent('ViewReceipt')?.content
            : contentStore.cmsStore.getContent('ViewInvoice')?.content}
        </ContentSmallGreenLink>
      ),
      align: 'left',
      width: '10%'
    }
  ];

  return columns;
};

export default useTransactionsAndUsageColumns;
