/* eslint-disable import/no-cycle */
import { action, makeObservable, observable, runInAction } from 'mobx';
import axios, { CancelTokenSource } from 'axios';
import {
  ProductInfo,
  ProductService,
  ProductInfoPaginatedList,
  AccountService,
  IdentifierStatus,
  UserRole,
  IdentifierService
} from '../../swagger/api/index';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { downloadExcel } from '../../utils/downloadExcel';

export default class ProductItemsStore extends BaseStore {
  productItems: ProductInfo[] = [];

  product?: ProductInfo = undefined;

  numberOfProducts: number = 0;

  filteredNumberOfProducts: number = 0;

  idOfProductLoadingReference?: string = undefined;

  setIdOfProductLoadingReference = (id: string | undefined) => {
    this.idOfProductLoadingReference = id;
  };

  getProducts = () => {
    if (this.rootStore.userStore.person!.role === UserRole.ProductAccess) {
      return this.getProductAccessProductById();
    }
    return this.getProductsByAccountWithSearchParams();
  };

  getProductsByAccountWithSearchParams = () => {
    this.rootStore.filterStore.productFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.productFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    params.userRole = this.rootStore.userStore.person?.role;
    const promise = AccountService.getIdentifiersForAccountAsync(params);
    promise.then((val: ProductInfoPaginatedList) => {
      runInAction(() => {
        this.productItems = val.paginatedItems!;
        this.numberOfProducts = val.totalCount!;
        this.filteredNumberOfProducts = val.filteredCount!;
        this.rootStore.productStore.productTableStore.pageSize = val.pageSize!;
      });
    });
    promise.catch(() => {
      this.productItems = [];
    });
    return promise;
  };

  getProductAccessProductById = () => {
    const identifierId = this.rootStore.userStore.person?.identifierId!;
    const promise = ProductService.getProductForIdentifierIdAsync({
      identifierId
    });
    promise.then((result: ProductInfo) => {
      runInAction(() => {
        this.productItems = [result];
        this.numberOfProducts = 1;
        this.filteredNumberOfProducts = 1;
        this.rootStore.productStore.productTableStore.pageSize = 20;
      });
    });
    return promise;
  };

  findProductFromId(productId: string) {
    const isToggledProduct = (product: ProductInfo) => product.identifierId === productId;
    const index: number = this.productItems.findIndex(isToggledProduct);
    if (index === -1) return null;
    return this.productItems[index];
  }

  cancelTokenSource: CancelTokenSource | null = null;

  getProduct = (productId: string, relations?: string) => {
    // Cancel the previous request if it exists
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
    // Create a new CancelToken source
    this.cancelTokenSource = axios.CancelToken.source();

    this.rootStore.productStore.productCardStore.setIsLoadingExtraData(true);
    const promise = ProductService.getProductForIdentifierIdAsync(
      {
        identifierId: productId,
        includes: relations
      },
      { cancelToken: this.cancelTokenSource.token }
    );
    promise
      .then((clickedProduct) => {
        this.product = this.findProductFromId(clickedProduct.identifierId!)
          ? clickedProduct
          : this.productItems[this.productItems.push(clickedProduct) - 1];
      })
      .finally(() => this.rootStore.productStore.productCardStore.setIsLoadingExtraData(false));
    return promise;
  };

  toggleBizzStatus = (productId: string) => {
    const product = this.findProductFromId(productId)!;
    const identifierId: string = product.identifierId!;
    const accountId: string = this.rootStore.userStore.currentAccount?.id!;
    const body: IdentifierStatus =
      product.identifierStatus === IdentifierStatus.Active
        ? IdentifierStatus.Inactive
        : IdentifierStatus.Active;
    const promise = IdentifierService.setBizzStatus({ identifierId, accountId, body });
    promise.then(() => {
      if (product.identifierStatus === IdentifierStatus.Active)
        product.identifierStatus = IdentifierStatus.Inactive;
      else if (product.identifierStatus === IdentifierStatus.Inactive)
        product.identifierStatus = IdentifierStatus.Active;
    });
    return promise;
  };

  downloadProducts = () => {
    this.rootStore.filterStore.productFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.productFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = AccountService.downloadIdentifierListExcel(params);
    promise.then((data) => {
      downloadExcel(`${this.rootStore.userStore.currentAccount?.id!} Products`, data);
    });
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      productItems: observable,
      product: observable,
      numberOfProducts: observable,
      filteredNumberOfProducts: observable,
      idOfProductLoadingReference: observable,
      getProductsByAccountWithSearchParams: observable,
      getProductAccessProductById: observable,
      getProducts: action,
      toggleBizzStatus: action,
      setIdOfProductLoadingReference: action,
      downloadProducts: action
    });
  }
}
