/* eslint-disable import/no-cycle */
import { action, makeObservable } from 'mobx';
import {
  AccountPaymentType,
  CustomerEconomyBlockedCause,
  MKAccountStatus,
  Person,
  ProductType,
  UserRole
} from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import { isBizz, isTollingBox } from '../utils/productTypeUtilities';

export default class ManageUserAccessStore extends BaseStore {
  isPrivateCustomer = () => this.rootStore.userStore.person?.role === UserRole.PrivatePerson;

  isParentAccount = () => this.rootStore.userStore.currentAccount?.isParent!;

  isProductAccessUser = () => this.rootStore.userStore.person?.role === UserRole.ProductAccess;

  isPrivateCustomerInDebtCollection = () =>
    this.rootStore.userStore.currentAccount?.mkAccountStatus === MKAccountStatus.Frozen &&
    this.isPrivateCustomer();

  isEconomyBlocked = () =>
    this.rootStore.userStore.currentAccount?.economyBlockedCause !==
    CustomerEconomyBlockedCause.Unblocked;

  hasInvoicePaymentAgreement = () =>
    this.rootStore.userStore.currentAccount?.accountPaymentType !== AccountPaymentType.CreditCard;

  showMoveOrRequestToCancelProductButton = (productType: ProductType) =>
    this.rootStore.userStore.person?.role !== UserRole.ProductAccess &&
    (isBizz(productType) || isTollingBox(productType));

  showProductPage = () => !this.isParentAccount();

  showDeleteUserButton = (userBeingEdited: Person) =>
    (this.rootStore.userStore.person?.role === UserRole.ParentAccountAdmin ||
      this.rootStore.userStore.person?.role === UserRole.SubAccountAdmin) &&
    (userBeingEdited.role !== UserRole.ParentAccountAdmin ||
      userBeingEdited.id === this.rootStore.userStore.person!.id);

  showPagesUsageOrderTransactions = () =>
    !this.isParentAccount() && this.rootStore.userStore.person?.role !== UserRole.ProductAccess;

  showAdministrationPage = () => this.rootStore.userStore.person?.role !== UserRole.ProductAccess;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      hasInvoicePaymentAgreement: action,
      isPrivateCustomer: action,
      showProductPage: action,
      isPrivateCustomerInDebtCollection: action,
      isEconomyBlocked: action,
      isProductAccessUser: action,
      showPagesUsageOrderTransactions: action,
      showAdministrationPage: action,
      showMoveOrRequestToCancelProductButton: action
    });
  }
}
