/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import BaseStore from './base-stores/BaseStore';

export default class LoadingStore extends BaseStore {
  showGlobalSpinner: boolean = false;

  enableGlobalSpinner = () => {
    this.showGlobalSpinner = true;
  };

  disableGlobalSpinner = () => {
    this.showGlobalSpinner = false;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      showGlobalSpinner: observable,
      enableGlobalSpinner: action,
      disableGlobalSpinner: action
    });
  }
}
