/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { NotificationService, Notification } from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class NotificationStore extends BaseStore {
  notifications: Notification[] = [];

  getNotifications = () => {
    NotificationService.getNotifications({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((value: Notification[]) => {
      this.notifications = value;
    });
  };

  resetNotifications = () => {
    this.notifications = [];
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      notifications: observable,
      getNotifications: action,
      resetNotifications: action
    });
  }
}
