/* eslint-disable prettier/prettier */
import { Input, Select } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px !important;
  min-height: 48px;
  box-shadow: none !important;
  border-color: #c0c0c0;
  :disabled {
    background-color: #efefef !important;
    color: #2d2d2d !important;
  }
  :focus,
  :hover {
    border-color: #c0c0c0 !important;
  }
  .ant-input-suffix {
    color: #747474;
  }
  &.ant-input-affix-wrapper-disabled {
    background-color: #efefef !important;
    color: #2d2d2d !important;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: #ff004a !important;
    box-shadow: none;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #efefef !important;
    color: #2d2d2d !important;
  }

  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: #ff004a !important;
    }
  }

  .ant-select-arrow {
    transform: rotate(0deg);
    transition: transform 0.1s ease 0s;
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 0.1s ease 0s;
    }
  }

  .ant-select-selector {
    align-items: center !important;
    min-height: 48px;
    border: 1px solid #c0c0c0 !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    :focus {
      border-color: #c0c0c0;
    }
  }
  .ant-select-item-option {
    width: calc(100% - 20px);
    margin-left: 10px;
    padding-left: 6px;
  }
  .ant-select-item-option-active {
    background-color: #efefef !important;
    border-radius: 5px;
  }

  .ant-select-item-option-active:hover {
    background-color: #efefef !important;
  }

  .ant-select-item-option-selected {
    background-color: #fff !important;
    font-weight: 400;
  }

  .ant-select-dropdown-placement-bottomLeft {
    border-radius: 8px !important;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153) !important;
  }
`;

export const StyledGroup = styled(Input.Group)`
  .ant-input {
    background: rgba(0, 0, 0, 0);
  }

  .ant-input[disabled] {
    color: #2d2d2d;
  }

  .ant-input-affix-wrapper {
    padding: 0px 4px 0px 4px;
    height: 48px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-color: #c0c0c0;
    :hover {
      border-color: #c0c0c0;
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: #c0c0c0;
    box-shadow: none;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: #efefef !important;
    color: #2d2d2d !important;
  }
  /* Change Autocomplete styles in webkit browsers (Chrome, Safari) by delaying a transition for a looong time*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  .ant-select-selector {
    align-items: center !important;
    min-height: 48px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border: 1px solid #c0c0c0 !important;
    box-shadow: none !important;
    :focus {
      border-color: #c0c0c0;
    }
  }
  .ant-select-item-option-active {
    background-color: #efefef !important;
  }

  .ant-select-item-option-active:hover {
    background-color: #efefef !important;
  }

  .ant-select-item-option-selected {
    background-color: #fff !important;
    font-weight: 400;
  }

  .ant-select-dropdown-placement-bottomLeft {
    border-radius: 8px !important;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153) !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #efefef !important;
    color: #2d2d2d !important;
  }

  .ant-select-arrow {
    transform: rotate(0deg);
    transition: transform 0.1s ease 0s;
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 0.1s ease 0s;
    }
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: #ff004a !important;
    }
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: #ff004a;
    box-shadow: none;
  }

  .ant-select-in-form-item {
    width: 100%;
  }
`;

export default { StyledInput, StyledSelect, StyledGroup };
