import React, { FC } from 'react';
import ManageUserAccessStore from '../../stores/ManageUserAccessStore';
import ProtectedUsagePaymentOrderRoute from './ProtectedUsagePaymentOrderRoute';
import NotFound from '../../pages/NotFound';
import isTollAppOrderOpen from '../../utils/isTollAppOrderOpen';

interface ProtectedTollAppOrderRouteProps {
  manageUserAccessStore: ManageUserAccessStore;
}

const ProtectedTollAppOrderRoute: FC<ProtectedTollAppOrderRouteProps> = (props) => {
  const { manageUserAccessStore, children } = props;
  if (!isTollAppOrderOpen()) {
    return <NotFound />;
  }
  return (
    <ProtectedUsagePaymentOrderRoute manageUserAccessStore={manageUserAccessStore}>
      {children}
    </ProtectedUsagePaymentOrderRoute>
  );
};

export default ProtectedTollAppOrderRoute;
