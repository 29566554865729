/* eslint-disable import/no-cycle */
import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { OrderService, OrderHistory } from '../../swagger/api';

export default class OrderHistoryStore extends BaseStore {
  searchDate?: string = this.rootStore.manageUserAccessStore.isPrivateCustomer()
    ? new Date(this.rootStore.userStore.person?.meta_CreatedDate!).toLocaleString('en-US')
    : new Date().toLocaleString('en-US');

  pageNumber: number = 1;

  totalPages?: number = 1;

  filteredCount?: number = 0;

  pageSize?: number = 0;

  orderHistory: OrderHistory[] = [];

  setSearchDate = (searchYear: string) => {
    this.setPageNumber(1);
    this.searchDate = searchYear;
  };

  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
  };

  getOrderHistory = () => {
    const requestParams: {
      accountId: string;
      searchDate?: string;
      searchToDate?: string;
      pageNumber?: number;
    } = {
      accountId: this.rootStore.userStore.currentAccount?.id!,
      searchDate: this.searchDate,
      pageNumber: this.pageNumber
    };

    if (
      requestParams.searchDate != null &&
      !this.rootStore.manageUserAccessStore.isPrivateCustomer()
    ) {
      const searchDateYear = new Date(requestParams.searchDate).getFullYear();
      const utcDate = Date.UTC(searchDateYear, 11, 31);
      [requestParams.searchToDate] = new Date(utcDate).toISOString().split('T');
    }

    const promise = OrderService.getPaginatedOrderHistories(requestParams).then((val) => {
      runInAction(() => {
        if (this.pageNumber === 1) this.orderHistory = val.paginatedItems!;
        else this.orderHistory.push(...val.paginatedItems!);
        this.totalPages = val.totalPages;
        this.filteredCount = val.filteredCount;
        this.pageSize = val.pageSize;
      });
    });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      pageNumber: observable,
      orderHistory: observable,
      totalPages: observable,
      filteredCount: observable,
      pageSize: observable,
      searchDate: observable,
      setSearchDate: action,
      setPageNumber: action,
      getOrderHistory: action
    });
  }
}
