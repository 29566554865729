/* eslint-disable import/no-cycle */
import { ProductTableFilter } from '../stores/filter-stores/ProductfilterStore';

const getDefaultProductFilters = (): ProductTableFilter => ({
  identifierStatuses: [],
  productTypes: [],
  specialAgreementIds: [],
  euronorms: [],
  vehicleSizes: [],
  fuelTypes: [],
  weights: [],
  co2Classes: [],
  countryIds: [],
  hasGreenDiscount: [],
  filteringViews: []
});

export default getDefaultProductFilters;
