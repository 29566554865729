import { Row } from 'antd';
import styled from 'styled-components';
import React, { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { H6CamptonBold25 } from '../layout/globalFonts';
import { PrimaryButton } from '../common-components/Buttons';
import { ReactComponent as WarningIcon } from '../layout/icons/yellow-circle-black-exclamation-big.svg';
import RouteUrlPaths from '../RouteUrlPaths';
import useStore from '../hooks/useStore';
import ContentStore from '../stores/ContentStore';
import MenuStore from '../stores/MenuStore';

const StyledPage = styled.div`
  background-color: #efefef;
  min-height: 100vh;
  padding: 5vh 20px 5vh 20px;
  width: 100vw;
  z-index: 2000;
`;

const ButtonRow = styled(Row)`
  margin-top: 20px;
`;

const NotFound: FC = () => {
  const contentStore: ContentStore = useStore(ContentStore);
  const menuStore: MenuStore = useStore(MenuStore);

  const location = useLocation();

  useEffect(() => {
    menuStore.setIsMenuSiderOpen(false);
    menuStore.setIsAnimationOpen(false);
    menuStore.setSelectedMenuItem('');
  }, [location]);

  return (
    <StyledPage>
      <Row justify="center" style={{ margin: 24 }}>
        <WarningIcon />
      </Row>
      <H6CamptonBold25 style={{ textAlign: 'center' }}>
        {contentStore.cmsStore.getContent('NotFound')?.content}
      </H6CamptonBold25>
      <Row justify="center" style={{ textAlign: 'center', marginTop: 24 }}>
        {contentStore.cmsStore.getContent('NotFound')?.subText}
      </Row>
      <Link to={{ pathname: RouteUrlPaths.Frontpage }}>
        <ButtonRow justify="center">
          <PrimaryButton>
            {contentStore.cmsStore.getContent('GoToFrontPage')?.content}
          </PrimaryButton>
        </ButtonRow>
      </Link>
    </StyledPage>
  );
};

export default observer(NotFound);
