import { useEffect, useState } from 'react';

const useFetchListDataWithFunctionParameters = (
  dataProp: any[],
  alwaysFetchData: boolean,
  fetchFunction: (id: string) => Promise<any>,
  id: string
) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (dataProp.length === 0 || alwaysFetchData) {
      setIsLoading(true);
      fetchFunction(id).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return isLoading;
};

export default useFetchListDataWithFunctionParameters;
