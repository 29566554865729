import React, { FC } from 'react';
import { Row, Col, Form } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line import/no-cycle
import CustomModal from '../../../common-components/CustomModal';
import useStore from '../../../hooks/useStore';
import { H9CamptonBoldSemiBold19, SmallGrey12 } from '../../../layout/globalFonts';
import ContentStore from '../../../stores/ContentStore';
import { StyledInput } from '../../../common-components/Input';

const FormItem = styled(Form.Item)`
  width: 100%;

  .ant-form-item-explain-error {
    text-align: start;
  }
`;

export type DailyContactInformation = {
  name: string;
  email: string;
  phoneNumber: string;
};

interface EditDailyContactModalProps {
  closeModal: () => void;
  primaryButtonClick: (dailyContactInformation: DailyContactInformation) => void;
  dailyContactName: string;
  dailyContactEmail: string;
  dailyContactPhone: string;
  isFromAccountInformationTab?: boolean;
}

const EditDailyContactModal: FC<EditDailyContactModalProps> = (props) => {
  const {
    closeModal,
    primaryButtonClick,
    dailyContactName,
    dailyContactEmail,
    dailyContactPhone,
    isFromAccountInformationTab
  } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const [form] = Form.useForm();
  const header = contentStore.cmsStore.getContent('DailyContactEditRequest')?.content!;
  const content = contentStore.cmsStore.getContent('DailyContactEditRequest')?.subText!;
  const primaryButtonText = contentStore.cmsStore.getContent('Choose')?.content;
  const greenLinkText = contentStore.cmsStore.getContent('Undo')?.content;

  const getDailyContactInformation = () => {
    const dailyContactInformation: DailyContactInformation = {
      name: form.getFieldValue('name'),
      email: form.getFieldValue('email'),
      phoneNumber: form.getFieldValue('phoneNumber')
    };
    return dailyContactInformation;
  };

  const name = (
    <>
      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('Name')?.content}</SmallGrey12>
      </Row>
      <Row>
        <FormItem
          name="name"
          initialValue={dailyContactName}
          rules={[
            {
              required: true,
              message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content
            }
          ]}
        >
          <StyledInput maxLength={100} />
        </FormItem>
      </Row>
    </>
  );

  const email = (
    <>
      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('Email')?.content}</SmallGrey12>
      </Row>
      <Row>
        <FormItem
          name="email"
          initialValue={dailyContactEmail}
          rules={[
            {
              required: true,
              message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content
            },
            {
              type: 'email',
              message: contentStore.cmsStore.getContent('invalidEmail')!.content
            }
          ]}
        >
          <StyledInput maxLength={100} />
        </FormItem>
      </Row>
    </>
  );

  const phone = (
    <>
      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('PhoneNumber')?.content}</SmallGrey12>
      </Row>
      <Row>
        <FormItem
          name="phoneNumber"
          initialValue={dailyContactPhone}
          rules={[
            {
              required: true,
              message: contentStore.cmsStore.getContent('ThisFieldIsRequiredWarning')?.content
            }
          ]}
        >
          <StyledInput maxLength={100} />
        </FormItem>
      </Row>
    </>
  );

  return (
    <Form form={form}>
      <Form.Item shouldUpdate style={{ display: 'none' }}>
        {() => (
          <CustomModal
            header={header}
            contentText={content}
            primaryButtonText={primaryButtonText}
            closable
            greenLinkText={greenLinkText}
            isFromAccountInformationTab={isFromAccountInformationTab}
            onGreenLinkClick={() => {
              closeModal();
            }}
            isPrimaryButtonDisabled={
              !form.isFieldsTouched() ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
            onPrimaryButtonClick={() => {
              const dailyContactInformation = getDailyContactInformation();
              primaryButtonClick(dailyContactInformation);
              closeModal();
            }}
            onCloseClick={() => {
              closeModal();
            }}
          >
            <Row>
              <H9CamptonBoldSemiBold19 style={{ marginBottom: 24 }}>
                {contentStore.cmsStore.getContent('InformationForDailyContactPerson')?.content}
              </H9CamptonBoldSemiBold19>
            </Row>
            <Row gutter={16}>
              <Col span={24}>{name}</Col>
            </Row>
            <Row>
              <Col span={24}>{email}</Col>
            </Row>
            <Row>
              <Col span={24}>{phone}</Col>
            </Row>
          </CustomModal>
        )}
      </Form.Item>
    </Form>
  );
};

EditDailyContactModal.defaultProps = {
  isFromAccountInformationTab: false
};

export default observer(EditDailyContactModal);
