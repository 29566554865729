/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getSiblingAccountsRelatedToCurrentAndEditedPersonAsync(
    params: {
      /**  */
      editedPersonId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubAccountsListItemPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons/{editedPersonId}/sibling-accounts';
      url = url.replace('{editedPersonId}', params['editedPersonId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSubAccount(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: SubAccount;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/sub-accounts';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCustomer(
    params: {
      /** requestBody */
      body?: Customer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerIdAndCustomerNumber> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIdentifiersForAccountAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierStatuses?: IdentifierStatus[];
      /**  */
      productTypes?: ProductType[];
      /**  */
      specialAgreementIds?: any | null[];
      /**  */
      euronorms?: Euronorm[];
      /**  */
      vehicleTypes?: VehicleSize[];
      /**  */
      fuelTypes?: FuelType[];
      /**  */
      co2Classes?: Co2Class[];
      /**  */
      fromWeight?: number;
      /**  */
      toWeight?: number;
      /**  */
      countryIds?: any | null[];
      /**  */
      hasGreenDiscount?: boolean;
      /**  */
      includeCustomerBlocked?: boolean;
      /**  */
      includeEconomyBlocked?: boolean;
      /**  */
      includeCardBlocked?: boolean;
      /**  */
      convertingToTollingBox?: boolean;
      /**  */
      discardClosedIdentifiers?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      userRole?: UserRole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductInfoPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IdentifierStatuses: params['identifierStatuses'],
        ProductTypes: params['productTypes'],
        SpecialAgreementIds: params['specialAgreementIds'],
        Euronorms: params['euronorms'],
        VehicleTypes: params['vehicleTypes'],
        FuelTypes: params['fuelTypes'],
        Co2Classes: params['co2Classes'],
        FromWeight: params['fromWeight'],
        ToWeight: params['toWeight'],
        CountryIds: params['countryIds'],
        HasGreenDiscount: params['hasGreenDiscount'],
        IncludeCustomerBlocked: params['includeCustomerBlocked'],
        IncludeEconomyBlocked: params['includeEconomyBlocked'],
        IncludeCardBlocked: params['includeCardBlocked'],
        ConvertingToTollingBox: params['convertingToTollingBox'],
        DiscardClosedIdentifiers: params['discardClosedIdentifiers'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        userRole: params['userRole']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSiblingAccountsRelatedToCurrentUserAsync(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/siblingAccounts';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccountInfoById(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadIdentifierListCsv(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierStatuses?: IdentifierStatus[];
      /**  */
      productTypes?: ProductType[];
      /**  */
      specialAgreementIds?: any | null[];
      /**  */
      euronorms?: Euronorm[];
      /**  */
      vehicleTypes?: VehicleSize[];
      /**  */
      fuelTypes?: FuelType[];
      /**  */
      co2Classes?: Co2Class[];
      /**  */
      fromWeight?: number;
      /**  */
      toWeight?: number;
      /**  */
      countryIds?: any | null[];
      /**  */
      hasGreenDiscount?: boolean;
      /**  */
      includeCustomerBlocked?: boolean;
      /**  */
      includeEconomyBlocked?: boolean;
      /**  */
      includeCardBlocked?: boolean;
      /**  */
      convertingToTollingBox?: boolean;
      /**  */
      discardClosedIdentifiers?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/download';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IdentifierStatuses: params['identifierStatuses'],
        ProductTypes: params['productTypes'],
        SpecialAgreementIds: params['specialAgreementIds'],
        Euronorms: params['euronorms'],
        VehicleTypes: params['vehicleTypes'],
        FuelTypes: params['fuelTypes'],
        Co2Classes: params['co2Classes'],
        FromWeight: params['fromWeight'],
        ToWeight: params['toWeight'],
        CountryIds: params['countryIds'],
        HasGreenDiscount: params['hasGreenDiscount'],
        IncludeCustomerBlocked: params['includeCustomerBlocked'],
        IncludeEconomyBlocked: params['includeEconomyBlocked'],
        IncludeCardBlocked: params['includeCardBlocked'],
        ConvertingToTollingBox: params['convertingToTollingBox'],
        DiscardClosedIdentifiers: params['discardClosedIdentifiers'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadIdentifierListExcel(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierStatuses?: IdentifierStatus[];
      /**  */
      productTypes?: ProductType[];
      /**  */
      specialAgreementIds?: any | null[];
      /**  */
      euronorms?: Euronorm[];
      /**  */
      vehicleTypes?: VehicleSize[];
      /**  */
      fuelTypes?: FuelType[];
      /**  */
      countryIds?: any | null[];
      /**  */
      hasGreenDiscount?: boolean;
      /**  */
      includeCustomerBlocked?: boolean;
      /**  */
      includeEconomyBlocked?: boolean;
      /**  */
      includeCardBlocked?: boolean;
      /**  */
      convertingToTollingBox?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/download-excel';
      url = url.replace('{accountId}', params['accountId'] + '');
      options.responseType = 'blob';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IdentifierStatuses: params['identifierStatuses'],
        ProductTypes: params['productTypes'],
        SpecialAgreementIds: params['specialAgreementIds'],
        Euronorms: params['euronorms'],
        VehicleTypes: params['vehicleTypes'],
        FuelTypes: params['fuelTypes'],
        CountryIds: params['countryIds'],
        HasGreenDiscount: params['hasGreenDiscount'],
        IncludeCustomerBlocked: params['includeCustomerBlocked'],
        IncludeEconomyBlocked: params['includeEconomyBlocked'],
        IncludeCardBlocked: params['includeCardBlocked'],
        ConvertingToTollingBox: params['convertingToTollingBox'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaginatedSubAccountsListItems(
    params: {
      /**  */
      personId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubAccountsListItemPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons/{personId}/sub-accounts';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadSubAccountsListItemsICsv(
    params: {
      /**  */
      personId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{personId}/sub-accounts/download';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPersonsForAccount(
    params: {
      /**  */
      accountId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/persons';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadPersonsListCsv(
    params: {
      /**  */
      accountId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/persons/download';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasConvertibleIdentifier(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/any-convertible-bizz';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AddressService {
  /**
   *
   */
  static updateAddressAsync(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: AddressLight;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Address> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/addresses/{accountId}';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgreementService {
  /**
   *
   */
  static getAgreementsByCustomerId(
    params: {
      /**  */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agreement[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customer/{customerId}/agreements';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConsentService {
  /**
   *
   */
  static getConsents(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/consents';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static grantConsents(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/consents';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeConsents(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/consents';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContentService {
  /**
   *
   */
  static getContent(
    params: {
      /**  */
      locale: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/{locale}';
      url = url.replace('{locale}', params['locale'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCommercialContent(
    params: {
      /**  */
      locale: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/commercial-content/{locale}';
      url = url.replace('{locale}', params['locale'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBusinessErrorContent(
    params: {
      /**  */
      locale: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/businessErrorContent/{locale}';
      url = url.replace('{locale}', params['locale'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CountryService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<Country[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCountryById(
    params: {
      /**  */
      countryId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Country> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/countries/{countryId}';
      url = url.replace('{countryId}', params['countryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerServiceService {
  /**
   *
   */
  static createCustomerServiceIncident(
    params: {
      /** requestBody */
      body?: CreateCustomerServiceIncidentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/create-customer-service-incident';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CvrService {
  /**
   *
   */
  static getCvrInfo(
    params: {
      /**  */
      cvrNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cvrInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cvrNumber: params['cvrNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IdentifierService {
  /**
   *
   */
  static updateIdentifierCard(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
      /** requestBody */
      body?: IdentifierInfo;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IdentifierInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/{identifierId}';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentCardOnIdentifier(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
      /**  */
      paymentMethodId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IdentifierInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/{identifierId}/update-payment-card';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { paymentMethodId: params['paymentMethodId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static moveIdentifierToAccountAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/{identifierId}/moveToAccount';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setBizzStatus(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
      /** requestBody */
      body?: IdentifierStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifier/{identifierId}/setBizzStatus';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodToIdentifierAsync(
    params: {
      /**  */
      identifierId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/identifiers/{identifierId}/paymentMethod';
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIdentifierByPersonId(
    params: {
      /**  */
      personId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IdentifierInfoWithAccountInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons/{personId}/identifier';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfIdentifiersNotApplicableForGreenDiscount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/NumberOfNotApplicableForGreenDiscount';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfActiveBizzesOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/active-bizzes/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfInactiveBizzesOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/inactive-bizzes/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfActiveLicensePlateProductsOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/active-license-plate-products/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfInactiveLicensePlateProductsOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/inactive-license-plate-products/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfActiveOneTimeAnprProductsOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/active-one-time-anpr-products/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfTollBoxesOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/toll-boxes/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNumberOfTollAppOnAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/toll-app/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentMethodReferencesOnIdentifiers(
    params: {
      /**  */
      accountId: string;
      /**  */
      oldCardId?: string;
      /**  */
      newCardId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/payment-methods/identifiers';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { oldCardId: params['oldCardId'], newCardId: params['newCardId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static closeIdentifier(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
      /** requestBody */
      body?: MkIdentifierCloseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/{identifierId}/close-identifier';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIdentifiersByPaymentMethod(
    params: {
      /**  */
      paymentMethodId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductInfoPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/payment-methods/{paymentMethodId}/identifiers';
      url = url.replace('{paymentMethodId}', params['paymentMethodId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateVehicleDeclarationPdf(
    params: {
      /**  */
      accountId: string;
      /**  */
      identifierId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/identifiers/{identifierId}/generate-vehicle-declaration-pdf';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InvoiceService {
  /**
   *
   */
  static getInvoiceInfo(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      invoiceStatus?: InvoiceStatus;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceInfoPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/invoices';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        InvoiceStatus: params['invoiceStatus'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoicePdf(
    params: {
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoices/{invoiceId}/pdf';
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadInvoices(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/invoices/download';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadInvoice(
    params: {
      /**  */
      accountId: string;
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/invoices/{invoiceId}/download';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDunningNoticeNumberFromLatestDunningStepByDunningId(
    params: {
      /**  */
      accountId: string;
      /**  */
      dunningId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/{dunningId}/dunningNoticeNumberFromLatestStep';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{dunningId}', params['dunningId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadReminderLetter(
    params: {
      /**  */
      accountId: string;
      /**  */
      dunningNoticeNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/{dunningNoticeNumber}/download-reminder-letter';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{dunningNoticeNumber}', params['dunningNoticeNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCardPaymentsByAccountIdAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentCardPaymentInfoPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/cardPayments';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationService {
  /**
   *
   */
  static getNotifications(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/notifications';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMissingPaymentsCount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/notifications/missing-payments/count';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasTollApp(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/notifications/has-toll-app';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrderService {
  /**
   *
   */
  static createOrder(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isAccountBlockedAsync(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/isAccountBlocked';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createFillOutLaterOrder(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/fillOutLater';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPrivateOrder(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/privateOrder';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLicensePlateOrder(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/licensePlate';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTollAppOrder(
    params: {
      /**  */
      accountId: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/tollApp';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaginatedOrderHistories(
    params: {
      /**  */
      accountId: string;
      /**  */
      searchDate?: string;
      /**  */
      searchToDate?: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderHistoryPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/history';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchDate: params['searchDate'],
        SearchToDate: params['searchToDate'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOneTimeAnprMaxInitialRemainingTrips(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/orders/maxEticketTrips';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentMethodService {
  /**
   *
   */
  static createOrUpdatePaymentMethod(
    params: {
      /**  */
      accountId?: string;
      /**  */
      paymentProviderTicket?: string;
      /**  */
      oldPaymentAgreement?: string;
      /**  */
      isNewCustomer?: boolean;
      /**  */
      paymentType?: PaymentType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        accountId: params['accountId'],
        paymentProviderTicket: params['paymentProviderTicket'],
        oldPaymentAgreement: params['oldPaymentAgreement'],
        isNewCustomer: params['isNewCustomer'],
        paymentType: params['paymentType']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodCreationUrlAsync(
    params: {
      /**  */
      cancelUrl?: string;
      /**  */
      acceptUrl?: string;
      /**  */
      currency?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethodCreationUrlResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods/reepay-creation-url';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cancelUrl: params['cancelUrl'], acceptUrl: params['acceptUrl'], currency: params['currency'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodCreationUrlForExistingCustomerAsync(
    params: {
      /**  */
      cancelUrl?: string;
      /**  */
      acceptUrl?: string;
      /**  */
      currency?: string;
      /**  */
      accountId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethodCreationUrlResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods/reepay-creation-url-existing-customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        cancelUrl: params['cancelUrl'],
        acceptUrl: params['acceptUrl'],
        currency: params['currency'],
        accountId: params['accountId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodsByAccountId(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/paymentMethods';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodsByPersonId(
    params: {
      /**  */
      personId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons/{personId}/paymentMethods';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaginatedCreditCardPaymentMethodsByAccountId(
    params: {
      /**  */
      accountId: string;
      /**  */
      paymentAgreementOwnerPersonId?: string;
      /**  */
      cardTypes?: CreditCardType[];
      /**  */
      cardStatuses?: PaymentCardStatus[];
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethodPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/paymentMethods/paginated';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PaymentAgreementOwnerPersonId: params['paymentAgreementOwnerPersonId'],
        CardTypes: params['cardTypes'],
        CardStatuses: params['cardStatuses'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethodById(
    params: {
      /**  */
      paymentMethodId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods/{paymentMethodId}';
      url = url.replace('{paymentMethodId}', params['paymentMethodId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deletePaymentAgreementById(
    params: {
      /**  */
      customerId: string;
      /**  */
      paymentAgreementId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/customers/{customerId}/paymentAgreement/{paymentAgreementId}';
      url = url.replace('{customerId}', params['customerId'] + '');
      url = url.replace('{paymentAgreementId}', params['paymentAgreementId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPrimaryPaymentAgreementByBillingAccountId(
    params: {
      /**  */
      billingAccountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods/{billingAccountId}/primaryPaymentAgreement';
      url = url.replace('{billingAccountId}', params['billingAccountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static makeProductsOnPaymentCardInactive(
    params: {
      /**  */
      paymentMethodId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/paymentMethods/{paymentMethodId}/inactivate-products';
      url = url.replace('{paymentMethodId}', params['paymentMethodId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PersonService {
  /**
   *
   */
  static createPerson(
    params: {
      /** requestBody */
      body?: Person;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccountInfoLight(
    params: {
      /**  */
      personId: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountInfoLightPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/persons/{personId}/accounts';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static chooseDefaultAccount(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/chooseDefaultAccount/{accountId}';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserDefaultAccountId(
    params: {
      /**  */
      personId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/getUserDefaultAccount/{personId}';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserById(
    params: {
      /**  */
      personId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{personId}';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsersAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromAllSubAccounts?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/users';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromAllSubAccounts: params['fromAllSubAccounts'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCurrentPerson(options: IRequestOptions = {}): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/getCurrentPerson';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deletePersonById(
    params: {
      /**  */
      accountId: string;
      /**  */
      personId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/persons/{personId}';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePersonDetails(
    params: {
      /**  */
      accountId: string;
      /**  */
      personId: string;
      /** requestBody */
      body?: PersonDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/persons/{personId}';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personPrivilegesUpdate(
    params: {
      /**  */
      personId: string;
      /** requestBody */
      body?: PersonPrivilegesUpdate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/person/{personId}';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateNewsLetterConsent(
    params: {
      /**  */
      accountId: string;
      /**  */
      isConsenting?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/update-consent';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { isConsenting: params['isConsenting'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLastTimeConsentWasShown(
    params: {
      /**  */
      accountId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/update-consent-shown';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestChangePassword(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/person/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductService {
  /**
   *
   */
  static getListProductVisibleSelfServiceAsync(options: IRequestOptions = {}): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/products/listSelfServiceOnly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProductForIdentifierIdAsync(
    params: {
      /**  */
      identifierId: string;
      /**  */
      includes?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/identifiers/{identifierId}/productInfo';
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Includes: params['includes'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listProductsAsync(
    params: {
      /**  */
      productTypes?: ProductType[];
      /**  */
      includeCustomerProducts?: boolean;
      /**  */
      customerIds?: any | null[];
      /**  */
      currency?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProductTypes: params['productTypes'],
        IncludeCustomerProducts: params['includeCustomerProducts'],
        CustomerIds: params['customerIds'],
        Currency: params['currency']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceService {
  /**
   *
   */
  static getPartnerUrlAsync(
    params: {
      /**  */
      customerNumber: string;
      /**  */
      partner: PartnersMkLinksTo;
      /**  */
      isPrivateCustomer?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Partner> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/token/{customerNumber}/{partner}';
      url = url.replace('{customerNumber}', params['customerNumber'] + '');
      url = url.replace('{partner}', params['partner'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { isPrivateCustomer: params['isPrivateCustomer'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SignUpService {
  /**
   *
   */
  static registerNewB2BUser(
    params: {
      /** requestBody */
      body?: NewPerson;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/registerUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signUpPrivate(
    params: {
      /** requestBody */
      body?: SignUpPrivateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/signup-private';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TollAppUserService {
  /**
   *
   */
  static isTollAppUserRegistered(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/toll-app-user/is-user-registered';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TransactionService {
  /**
   *
   */
  static getTransactionsByIdentifierIdAsync(
    params: {
      /**  */
      identifierId: string;
      /**  */
      euronorms?: Euronorm[];
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      fromAmount?: number;
      /**  */
      toAmount?: number;
      /**  */
      invoiceStatus?: InvoiceStatus;
      /**  */
      transactionItemTypeIds?: any | null[];
      /**  */
      includeDraft?: boolean;
      /**  */
      isWrittenOff?: boolean;
      /**  */
      isInvoiceable?: boolean;
      /**  */
      sorted?: boolean;
      /**  */
      getOtherTransactions?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Transaction[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/identifiers/{identifierId}/transactions';
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Euronorms: params['euronorms'],
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        FromAmount: params['fromAmount'],
        ToAmount: params['toAmount'],
        InvoiceStatus: params['invoiceStatus'],
        TransactionItemTypeIds: params['transactionItemTypeIds'],
        IncludeDraft: params['includeDraft'],
        IsWrittenOff: params['isWrittenOff'],
        IsInvoiceable: params['isInvoiceable'],
        Sorted: params['sorted'],
        GetOtherTransactions: params['getOtherTransactions'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLatestTransactionByIdentifierIdAsync(
    params: {
      /**  */
      identifierId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Transaction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/identifiers/{identifierId}/transaction';
      url = url.replace('{identifierId}', params['identifierId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionsByAccountIdAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      euronorms?: Euronorm[];
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      fromAmount?: number;
      /**  */
      toAmount?: number;
      /**  */
      invoiceStatus?: InvoiceStatus;
      /**  */
      transactionItemTypeIds?: any | null[];
      /**  */
      includeDraft?: boolean;
      /**  */
      isWrittenOff?: boolean;
      /**  */
      isInvoiceable?: boolean;
      /**  */
      sorted?: boolean;
      /**  */
      getOtherTransactions?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/transactions';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Euronorms: params['euronorms'],
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        FromAmount: params['fromAmount'],
        ToAmount: params['toAmount'],
        InvoiceStatus: params['invoiceStatus'],
        TransactionItemTypeIds: params['transactionItemTypeIds'],
        IncludeDraft: params['includeDraft'],
        IsWrittenOff: params['isWrittenOff'],
        IsInvoiceable: params['isInvoiceable'],
        Sorted: params['sorted'],
        GetOtherTransactions: params['getOtherTransactions'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOtherTransactionsByAccountIdAsync(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionPaginatedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/otherTransactions';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadTransactionsForAccount(
    params: {
      /**  */
      accountId: string;
      /**  */
      euronorms?: Euronorm[];
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      fromAmount?: number;
      /**  */
      toAmount?: number;
      /**  */
      invoiceStatus?: InvoiceStatus;
      /**  */
      transactionItemTypeIds?: any | null[];
      /**  */
      includeDraft?: boolean;
      /**  */
      isWrittenOff?: boolean;
      /**  */
      isInvoiceable?: boolean;
      /**  */
      sorted?: boolean;
      /**  */
      getOtherTransactions?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/transactions/download';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Euronorms: params['euronorms'],
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        FromAmount: params['fromAmount'],
        ToAmount: params['toAmount'],
        InvoiceStatus: params['invoiceStatus'],
        TransactionItemTypeIds: params['transactionItemTypeIds'],
        IncludeDraft: params['includeDraft'],
        IsWrittenOff: params['isWrittenOff'],
        IsInvoiceable: params['isInvoiceable'],
        Sorted: params['sorted'],
        GetOtherTransactions: params['getOtherTransactions'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadTransactionsForAccountExcel(
    params: {
      /**  */
      accountId: string;
      /**  */
      euronorms?: Euronorm[];
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      fromAmount?: number;
      /**  */
      toAmount?: number;
      /**  */
      invoiceStatus?: InvoiceStatus;
      /**  */
      transactionItemTypeIds?: any | null[];
      /**  */
      includeDraft?: boolean;
      /**  */
      isWrittenOff?: boolean;
      /**  */
      isInvoiceable?: boolean;
      /**  */
      sorted?: boolean;
      /**  */
      getOtherTransactions?: boolean;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/transactions/download-excel';
      url = url.replace('{accountId}', params['accountId'] + '');
      options.responseType = 'blob';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Euronorms: params['euronorms'],
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        FromAmount: params['fromAmount'],
        ToAmount: params['toAmount'],
        InvoiceStatus: params['invoiceStatus'],
        TransactionItemTypeIds: params['transactionItemTypeIds'],
        IncludeDraft: params['includeDraft'],
        IsWrittenOff: params['isWrittenOff'],
        IsInvoiceable: params['isInvoiceable'],
        Sorted: params['sorted'],
        GetOtherTransactions: params['getOtherTransactions'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadOtherTransactionsForAccount(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/otherTransactions/download';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadOtherTransactionsForAccountExcel(
    params: {
      /**  */
      accountId: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      searchString?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/otherTransactions/download-excel';
      url = url.replace('{accountId}', params['accountId'] + '');
      options.responseType = 'blob';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        SearchString: params['searchString'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadTransactionsForInvoicePeriodAsCsv(
    params: {
      /**  */
      accountId: string;
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/invoices/{invoiceId}/transactions/download-csv';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadTransactionsForInvoicePeriodAsExcel(
    params: {
      /**  */
      accountId: string;
      /**  */
      invoiceId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/accounts/{accountId}/invoices/{invoiceId}/transactions/download-excel';
      url = url.replace('{accountId}', params['accountId'] + '');
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VehicleService {
  /**
   *
   */
  static fetchVehicleCardFromEpass(
    params: {
      /**  */
      licensePlate: string;
      /**  */
      countryIsoCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleCard> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicle/{licensePlate}/{countryIsoCode}';
      url = url.replace('{licensePlate}', params['licensePlate'] + '');
      url = url.replace('{countryIsoCode}', params['countryIsoCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isLicensePlateRegistered(
    params: {
      /**  */
      licensePlate: string;
      /**  */
      countryCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicensePlateValidationResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license-plate/{licensePlate}/{countryCode}/is-registered';
      url = url.replace('{licensePlate}', params['licensePlate'] + '');
      url = url.replace('{countryCode}', params['countryCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fetchByNumberPlateAndRegistrationCountryCodeAsync(
    params: {
      /**  */
      numberPlate: string;
      /**  */
      registrationCountryCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/{numberPlate}/countries/{registrationCountryCode}';
      url = url.replace('{numberPlate}', params['numberPlate'] + '');
      url = url.replace('{registrationCountryCode}', params['registrationCountryCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createVehicle(
    params: {
      /**  */
      customerId: string;
      /** requestBody */
      body?: Vehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/createVehicle/{customerId}';
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateVehicle(
    params: {
      /**  */
      vehicleId: string;
      /** requestBody */
      body?: Vehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/updateVehicle/{vehicleId}';
      url = url.replace('{vehicleId}', params['vehicleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static vehiclesWithGreenDiscountAndDocumentation(
    params: {
      /**  */
      vehicleId: string;
      /**  */
      customerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/VehiclesWithGreenDiscountAndDocumentation/{vehicleId}/{customerId}';
      url = url.replace('{vehicleId}', params['vehicleId'] + '');
      url = url.replace('{customerId}', params['customerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VehicleDocumentationService {
  /**
   *
   */
  static downloadVehicleDocumentation(
    params: {
      /**  */
      vehicleDocumentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicleDocumentation/{vehicleDocumentId}/DownloadDocumentation';
      url = url.replace('{vehicleDocumentId}', params['vehicleDocumentId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface Account {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  parentId?: string;

  /**  */
  name?: string;

  /**  */
  cvr?: string;

  /**  */
  nemID?: string;

  /**  */
  customerNumber?: string;

  /**  */
  type?: AccountType;

  /**  */
  creditApproved?: boolean;

  /**  */
  creditDeviation?: boolean;

  /**  */
  paymentTerms?: string;

  /**  */
  addressId?: string;

  /**  */
  paymentMethods?: PaymentMethod[];

  /**  */
  agreements?: Agreement[];

  /**  */
  vehicles?: Vehicle[];

  /**  */
  persons?: Person[];

  /**  */
  state?: AccountState;

  /**  */
  mkAccountStatus?: MKAccountStatus;

  /**  */
  contactPerson?: Person;

  /**  */
  identifierCount?: number;

  /**  */
  contractManagerFirstName?: string;

  /**  */
  contractManagerLastName?: string;

  /**  */
  contractManagerEmail?: string;

  /**  */
  contractManagerPhone?: string;

  /**  */
  dailyContactPersonName?: string;

  /**  */
  dailyContactPersonEmail?: string;

  /**  */
  dailyContactPersonPhone?: string;

  /**  */
  consent?: boolean;

  /**  */
  primaryBillingAccountId?: string;

  /**  */
  economyBlockedCause?: CustomerEconomyBlockedCause;

  /**  */
  currency?: string;
}

export interface AccountInfo {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  isParent?: boolean;

  /**  */
  customerNumber?: string;

  /**  */
  contractManagerFirstName?: string;

  /**  */
  contractManagerLastName?: string;

  /**  */
  contractManagerEmail?: string;

  /**  */
  contractManagerPhone?: string;

  /**  */
  dailyContactPerson?: Person;

  /**  */
  dailyContactPersonName?: string;

  /**  */
  dailyContactPersonEmail?: string;

  /**  */
  dailyContactPersonPhone?: string;

  /**  */
  name?: string;

  /**  */
  companyName: string;

  /**  */
  streetName: string;

  /**  */
  addressLineSecond: string;

  /**  */
  cityName: string;

  /**  */
  zipCode: string;

  /**  */
  countryName?: string;

  /**  */
  countryId: string;

  /**  */
  isoCode: string;

  /**  */
  cvr: string;

  /**  */
  vehicleType?: DrivingVehicleType;

  /**  */
  isAcceptedPolicy: boolean;

  /**  */
  paymentMethod?: PaymentMethod;

  /**  */
  creditApproved?: boolean;

  /**  */
  creditDeviation?: boolean;

  /**  */
  invoiceRecipientEmail?: string;

  /**  */
  consent?: boolean;

  /**  */
  accountPaymentType?: AccountPaymentType;

  /**  */
  accountState?: AccountState;

  /**  */
  mkAccountStatus?: MKAccountStatus;

  /**  */
  economyBlockedCause?: CustomerEconomyBlockedCause;

  /**  */
  isSignedUpForSupplierService?: boolean;

  /**  */
  currency?: string;
}

export interface AccountInfoLight {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  cvr?: string;

  /**  */
  customerNumber?: string;

  /**  */
  isParent?: boolean;
}

export interface AccountInfoLightPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: AccountInfoLight[];
}

export interface AccountRequest {
  /**  */
  contractManagerId?: string;

  /**  */
  companyName: string;

  /**  */
  subAccountName?: string;

  /**  */
  streetName: string;

  /**  */
  addressLineSecond?: string;

  /**  */
  cityName: string;

  /**  */
  zipCode: string;

  /**  */
  countryName?: string;

  /**  */
  countryId: string;

  /**  */
  isoCode: string;

  /**  */
  cvr: string;

  /**  */
  vehicleType?: DrivingVehicleType;

  /**  */
  isAcceptedPolicy: boolean;

  /**  */
  creditApproved?: boolean;

  /**  */
  creditDeviation?: boolean;
}

export interface Address {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name: string;

  /**  */
  addressLineSecond: string;

  /**  */
  street: string;

  /**  */
  city: string;

  /**  */
  zipCode: string;

  /**  */
  region?: string;

  /**  */
  countryId?: string;

  /**  */
  country?: Country;

  /**  */
  phonePrefix?: string;

  /**  */
  phoneNumber?: string;
}

export interface AddressLight {
  /**  */
  countryId: string;

  /**  */
  addressLine1: string;

  /**  */
  postalCode: string;

  /**  */
  city: string;

  /**  */
  addressLine2?: string;
}

export interface AddressResponse {
  /**  */
  id?: string;

  /**  */
  careOfName?: string;

  /**  */
  addressLine1?: string;

  /**  */
  addressLine2?: string;

  /**  */
  postalCode?: string;

  /**  */
  city?: string;

  /**  */
  countryId?: string;

  /**  */
  country?: CountryResponse;

  /**  */
  region?: string;

  /**  */
  state?: string;

  /**  */
  createdOn?: Date;

  /**  */
  updatedOn?: Date;
}

export interface Agreement {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  billingAddressId?: string;

  /**  */
  startDate?: Date;

  /**  */
  accountId?: string;

  /**  */
  billingAccountId?: string;

  /**  */
  paymentMethodId?: string;
}

export interface Country {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  isoCode?: string;

  /**  */
  phonePrefix?: string;

  /**  */
  isApprovedCountry?: boolean;

  /**  */
  allowShipping?: boolean;

  /**  */
  allowForPrivateMinKontoSignup?: boolean;

  /**  */
  allowForPrivateMinKontoAnpr?: boolean;

  /**  */
  allowForCommercialMinKontoSignup?: boolean;

  /**  */
  allowForCommercialMinKontoAnpr?: boolean;

  /**  */
  allowForeTicket?: boolean;
}

export interface CountryResponse {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  isoCode?: string;

  /**  */
  phonePrefix?: string;

  /**  */
  isApprovedCountry?: boolean;

  /**  */
  createdOn?: Date;

  /**  */
  updatedOn?: Date;

  /**  */
  salesTaxGroup?: SalesTaxGroup;

  /**  */
  allowShipping?: boolean;

  /**  */
  allowForPrivateMinKontoSignup?: boolean;

  /**  */
  allowForPrivateMinKontoAnpr?: boolean;

  /**  */
  allowForCommercialMinKontoSignup?: boolean;

  /**  */
  allowForCommercialMinKontoAnpr?: boolean;

  /**  */
  allowForeTicket?: boolean;
}

export interface CreateCustomerServiceIncidentRequest {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  brand?: string;

  /**  */
  isNewCustomer?: boolean;

  /**  */
  companyName?: string;

  /**  */
  customerNumber?: string;

  /**  */
  vinNumber?: string;

  /**  */
  numberOfVehicles?: string;

  /**  */
  haveLightVehiclesBelow3500?: boolean;

  /**  */
  areaDrivingIn?: string;

  /**  */
  cvr?: string;

  /**  */
  queueEmail?: string;

  /**  */
  subject?: string;

  /**  */
  subSubject?: string;

  /**  */
  description?: string;

  /**  */
  isBusinessCustomer?: boolean;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  licensePlateNumber?: string;

  /**  */
  licensePlateCountry?: string;

  /**  */
  bizzNumber?: string;

  /**  */
  files?: File[];
}

export interface CreateTollAppUserRequest {
  /**  */
  firstName?: string;

  /**  */
  surName?: string;

  /**  */
  email?: string;
}

export interface Customer {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  accountRequest?: AccountRequest;

  /**  */
  paymentType?: PaymentType;

  /**  */
  isAcceptPaymentTypePolicy?: boolean;

  /**  */
  declarationOfConsent?: DeclarationOfConsent;
}

export interface CustomerIdAndCustomerNumber {
  /**  */
  customerId?: string;

  /**  */
  customerNumber?: string;
}

export interface CustomerProduct {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  customerId?: string;

  /**  */
  productId?: string;

  /**  */
  price?: number;
}

export interface CustomerResponse {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  customerNumber?: string;

  /**  */
  customerStatus?: CustomerStatus;

  /**  */
  customerType?: CustomerType;

  /**  */
  email?: string;

  /**  */
  salesDistrictResponsible?: string;

  /**  */
  customerResponsible?: string;

  /**  */
  finalCloseCustomerDate?: Date;

  /**  */
  dateSentToInvoiceDistributor?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  customerAddress?: AddressResponse;

  /**  */
  customerAddressId?: string;

  /**  */
  allowInvoiceMessages?: boolean;

  /**  */
  customerLegacyId?: string;

  /**  */
  parentCustomerId?: string;

  /**  */
  creditApprovalStatus?: boolean;

  /**  */
  isToBeDeleted?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  languageId?: string;

  /**  */
  creditDeviation?: boolean;

  /**  */
  segment?: Segment;

  /**  */
  lineOfBusiness?: string;

  /**  */
  expectedTurnover?: number;

  /**  */
  legacyRating?: number;

  /**  */
  rating?: number;

  /**  */
  equity?: number;

  /**  */
  guarantee?: number;

  /**  */
  rki?: string;

  /**  */
  personIds?: string[];

  /**  */
  createdOn?: Date;

  /**  */
  updatedOn?: Date;

  /**  */
  vatNumber?: string;

  /**  */
  contactPersonId?: string;

  /**  */
  contactPerson?: PersonResponse;

  /**  */
  contractManagerFirstName?: string;

  /**  */
  contractManagerLastName?: string;

  /**  */
  contractManagerEmail?: string;

  /**  */
  contractManagerPhone?: string;

  /**  */
  economyBlockedCause?: EconomyBlockedCause;

  /**  */
  closedCause?: ClosedCause;

  /**  */
  isParent?: boolean;

  /**  */
  isAnonymized?: boolean;

  /**  */
  externalOrganizationId?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  balance?: number;

  /**  */
  currency?: string;

  /**  */
  primaryPaymentAgreementId?: string;

  /**  */
  statusChangedOn?: Date;

  /**  */
  alwaysUseCustCurrency?: boolean;

  /**  */
  passingsDeadLine?: Date;

  /**  */
  bicCode?: string;

  /**  */
  shippingAddressId?: string;

  /**  */
  shippingAddress?: AddressResponse;

  /**  */
  billingAddressId?: string;

  /**  */
  billingAddress?: AddressResponse;

  /**  */
  taxGroup?: string;

  /**  */
  invoiceFrequency?: InvoiceFrequency;

  /**  */
  accountStatementFrequency?: string;

  /**  */
  bbdkShipment?: string;

  /**  */
  invoiceDistributionChannel?: string;

  /**  */
  invoiceLayout?: string;

  /**  */
  eanNumber?: string;

  /**  */
  oioContactId?: string;

  /**  */
  agentResellerModel?: AgentResellerModel;

  /**  */
  salesTaxGroup?: SalesTaxGroup;

  /**  */
  agreementRef?: string;

  /**  */
  accountStatus?: AccountStatus;

  /**  */
  dailyPersonName?: string;

  /**  */
  dailyPersonPhone?: string;

  /**  */
  dailyPersonEmail?: string;

  /**  */
  insuranceStatus?: InsuranceStatus;

  /**  */
  prepaidAmount?: number;

  /**  */
  primaryAccountId?: string;

  /**  */
  customerGroupId?: string;
}

export interface DeclarationOfConsent {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  expectMinimumSpendingAmount?: boolean;

  /**  */
  understandBrobizzCheckUsage?: boolean;

  /**  */
  understandCreditApprovalProcess?: boolean;

  /**  */
  amount?: number;
}

export interface File {
  /**  */
  fileName?: string;

  /**  */
  content?: string;
}

export interface IdentifierInfo {
  /**  */
  identifierId?: string;

  /**  */
  identifierNumber?: string;

  /**  */
  identifierStatus?: IdentifierStatus;

  /**  */
  reference?: string;

  /**  */
  licensePlate?: string;

  /**  */
  vin?: string;

  /**  */
  vehicleSize?: VehicleSize;

  /**  */
  fuelType?: FuelType;

  /**  */
  euronorm?: Euronorm;

  /**  */
  co2Class?: Co2Class;

  /**  */
  maxPermissibleWeight?: number;

  /**  */
  countryId?: string;

  /**  */
  vehicleId?: string;

  /**  */
  encodedDocumentPdf?: string;

  /**  */
  encodedCo2DocumentPdf?: string;

  /**  */
  vehicleDocumentId?: string;

  /**  */
  productType?: ProductType;

  /**  */
  accountId?: string;

  /**  */
  paymentMethodId?: string;

  /**  */
  agreementId?: string;

  /**  */
  productRef?: string;

  /**  */
  model?: string;

  /**  */
  brand?: string;

  /**  */
  color?: string;

  /**  */
  remainingTrips?: number;

  /**  */
  createTollAppUserRequest?: CreateTollAppUserRequest;

  /**  */
  tractorAxles?: VehicleTractorAxles;

  /**  */
  expirationDate?: Date;

  /**  */
  replacedIdentifier?: string;
}

export interface IdentifierInfoWithAccountInfo {
  /**  */
  identifierInfo?: IdentifierInfo;

  /**  */
  customerNumber?: string;

  /**  */
  customerName?: string;
}

export interface InvoiceInfo {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  date?: Date;

  /**  */
  invoiceNumber?: string;

  /**  */
  expirationDate?: Date;

  /**  */
  invoiceStatus?: InvoiceStatus;

  /**  */
  currencyCode?: string;

  /**  */
  amountIncludeVat?: number;

  /**  */
  paymentType?: PaymentType;

  /**  */
  accountType?: AccountType;

  /**  */
  accountId?: string;

  /**  */
  hasAttachment?: boolean;

  /**  */
  dunningId?: string;

  /**  */
  isDunningFee?: boolean;
}

export interface InvoiceInfoPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: InvoiceInfo[];
}

export interface MkIdentifierCloseRequest {
  /**  */
  closedCause?: MkClosedCause;
}

export interface NewPerson {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  personId?: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  phoneNumber: string;

  /**  */
  email: string;

  /**  */
  confirmEmail: string;

  /**  */
  subscribeNewsLetter: boolean;

  /**  */
  language: LanguageType;

  /**  */
  phonePrefix: string;
}

export interface Notification {
  /**  */
  type?: NotificationType;

  /**  */
  numberOfNotifications?: number;

  /**  */
  priority?: number;
}

export interface Order {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  accountId?: string;

  /**  */
  companyName?: string;

  /**  */
  countryId?: string;

  /**  */
  countryName?: string;

  /**  */
  isDefaultAddress?: boolean;

  /**  */
  addressName?: string;

  /**  */
  addressLine2?: string;

  /**  */
  cityName?: string;

  /**  */
  zipcode?: string;

  /**  */
  orderLines?: OrderLine[];

  /**  */
  orderNo?: string;

  /**  */
  productType?: ProductType;

  /**  */
  sentToCustomer?: Date;

  /**  */
  attention?: string;
}

export interface OrderHistory {
  /**  */
  identifierInfos?: IdentifierInfo[];

  /**  */
  orderedDate?: Date;

  /**  */
  productType?: ProductType;

  /**  */
  sentToCustomer?: Date;

  /**  */
  customerNumber?: string;

  /**  */
  addressName?: string;

  /**  */
  cityName?: string;

  /**  */
  zipcode?: string;

  /**  */
  attention?: string;

  /**  */
  identifierInfosCount?: number;
}

export interface OrderHistoryPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: OrderHistory[];
}

export interface OrderLine {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  orderId?: string;

  /**  */
  identifierInfo?: IdentifierInfo;

  /**  */
  productRef?: string;
}

export interface Partner {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  partnerUrl?: string;
}

export interface PaymentCardPaymentInfo {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  date?: Date;

  /**  */
  notaNr?: string;

  /**  */
  amount?: number;

  /**  */
  currencyCode?: string;

  /**  */
  invoiceId?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  dunningId?: string;

  /**  */
  isDunningFee?: boolean;
}

export interface PaymentCardPaymentInfoPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: PaymentCardPaymentInfo[];
}

export interface PaymentMethod {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  createdByUserFirstName?: string;

  /**  */
  createdByUserLastName?: string;

  /**  */
  isCreatedByCurrentUser?: boolean;

  /**  */
  type?: PaymentType;

  /**  */
  cardNumber?: string;

  /**  */
  cardType?: CreditCardType;

  /**  */
  handleId?: string;

  /**  */
  expirationDate?: Date;

  /**  */
  startOn?: Date;

  /**  */
  accountId?: string;

  /**  */
  userId?: string;

  /**  */
  paymentFrequency?: string;

  /**  */
  paymentCardStatus?: PaymentCardStatus;

  /**  */
  invoicePaymentMethodStatus?: InvoicePaymentMethodStatus;
}

export interface PaymentMethodCreationUrlResponse {
  /**  */
  id?: string;

  /**  */
  url?: string;
}

export interface PaymentMethodPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: PaymentMethod[];
}

export interface PaymentTypeInfo {
  /**  */
  paymentType?: PaymentType;

  /**  */
  recipientEmail?: string;

  /**  */
  paymentAgreementId?: string;

  /**  */
  eanNumber?: string;
}

export interface Person {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  currentAccountId: string;

  /**  */
  phonePrefix?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email: string;

  /**  */
  accountIds?: string[];

  /**  */
  customerNumberSubset?: string[];

  /**  */
  defaultAccountId?: string;

  /**  */
  signUpInProgress?: boolean;

  /**  */
  role?: UserRole;

  /**  */
  identifierId?: string;

  /**  */
  language?: LanguageType;

  /**  */
  consent?: boolean;

  /**  */
  lastTimeConsentReminderWasShown?: Date;
}

export interface PersonDetails {
  /**  */
  id?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phonePrefix?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  language?: LanguageType;

  /**  */
  consent?: boolean;

  /**  */
  lastTimeConsentReminderWasShown?: Date;
}

export interface PersonPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: Person[];
}

export interface PersonPrivilegesUpdate {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  role?: UserRole;

  /**  */
  accountsIds?: string[];
}

export interface PersonResponse {
  /**  */
  id?: string;

  /**  */
  firstname: string;

  /**  */
  lastname: string;

  /**  */
  phonePrefix?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email: string;

  /**  */
  customerIds?: string[];

  /**  */
  customers?: CustomerResponse[];

  /**  */
  addressId?: string;

  /**  */
  externalAuthId?: string;

  /**  */
  status?: PersonStatus;

  /**  */
  language?: Language;

  /**  */
  createdOn?: Date;

  /**  */
  updatedOn?: Date;

  /**  */
  consent?: boolean;

  /**  */
  dateOfConsent?: Date;

  /**  */
  lastTimeConsentReminderWasShown?: Date;

  /**  */
  roles?: SecurityRole[];
}

export interface PrivateCustomer {
  /**  */
  streetName: string;

  /**  */
  cityName: string;

  /**  */
  zipCode: string;

  /**  */
  countryId: string;

  /**  */
  paymentType: PaymentType;

  /**  */
  languageIsoCode: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  email: string;

  /**  */
  phoneNumber: string;
}

export interface Product {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  status?: ProductStatus;

  /**  */
  type?: ProductType;

  /**  */
  price?: number;

  /**  */
  customerProducts?: CustomerProduct[];
}

export interface ProductInfo {
  /**  */
  identifierId?: string;

  /**  */
  identifierNumber?: string;

  /**  */
  identifierStatus?: IdentifierStatus;

  /**  */
  productType?: ProductType;

  /**  */
  reference?: string;

  /**  */
  licensePlate?: string;

  /**  */
  countryIsoCode?: string;

  /**  */
  vin?: string;

  /**  */
  vehicleSize?: VehicleSize;

  /**  */
  fuelType?: FuelType;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  euronorm?: Euronorm;

  /**  */
  greenDiscountStatus?: VehicleGreenDiscountStatus;

  /**  */
  co2Status?: VehicleCo2Status;

  /**  */
  hasGreenDiscount?: boolean;

  /**  */
  expirationDate?: Date;

  /**  */
  latestChangeType?: string;

  /**  */
  lastModifiedBy?: string;

  /**  */
  lastModifiedDate?: Date;

  /**  */
  vehicleDocumentId?: string;

  /**  */
  vehicleDocumentationStatus?: VehicleDocumentationStatus;

  /**  */
  servicesOnIdentifier?: Service[];

  /**  */
  paymentMethod?: PaymentMethod;

  /**  */
  panNumbers?: object;

  /**  */
  drivableInNorway?: boolean;

  /**  */
  customerNumber?: string;

  /**  */
  customerName?: string;

  /**  */
  remainingTrips?: number;

  /**  */
  vehicleCountryId?: string;

  /**  */
  convertingToTollingBoxId?: string;

  /**  */
  agreement?: Agreement;

  /**  */
  co2Class?: Co2Class;

  /**  */
  maxPermissibleWeight?: number;

  /**  */
  tollAppUser?: TollAppUser;

  /**  */
  tractorAxles?: VehicleTractorAxles;

  /**  */
  hasObeIdentifierReference?: boolean;
}

export interface ProductInfoPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: ProductInfo[];
}

export interface Service {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  supplierId?: string;

  /**  */
  name?: string;

  /**  */
  isActive?: boolean;
}

export interface SignUpPrivateRequest {
  /**  */
  privateCustomer?: PrivateCustomer;

  /**  */
  privatePerson?: NewPerson;

  /**  */
  paymentProviderTicket?: string;
}

export interface SubAccount {
  /**  */
  accountRequest?: AccountRequest;

  /**  */
  contractManager?: Person;

  /**  */
  dailyContact?: Person;

  /**  */
  dailyContactPersonName?: string;

  /**  */
  dailyContactPersonEmail?: string;

  /**  */
  dailyContactPersonPhone?: string;

  /**  */
  paymentTypeInfo?: PaymentTypeInfo;
}

export interface SubAccountsListItem {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  customerNumber?: string;

  /**  */
  name?: string;

  /**  */
  cvr?: string;

  /**  */
  contactPersonFirstName?: string;

  /**  */
  contactPersonLastName?: string;

  /**  */
  contactPersonEmail?: string;

  /**  */
  identifiersCount?: number;

  /**  */
  isDailyContact?: boolean;

  /**  */
  isCurrentPersonRelatedToAccount?: boolean;

  /**  */
  isParent?: boolean;

  /**  */
  contactPersonId?: string;

  /**  */
  state?: AccountState;
}

export interface SubAccountsListItemPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: SubAccountsListItem[];
}

export interface TollAppUser {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  isTollAppActivated?: boolean;
}

export interface Transaction {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  identifierId?: string;

  /**  */
  identifierNumber?: string;

  /**  */
  identifierReferenceId?: string;

  /**  */
  invoiceId?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  documentNumber?: string;

  /**  */
  accountId?: string;

  /**  */
  identifierReference?: string;

  /**  */
  productType?: ProductType;

  /**  */
  date?: Date;

  /**  */
  tollChargerName?: string;

  /**  */
  vehiclePlate?: string;

  /**  */
  amount?: number;

  /**  */
  currency?: string;

  /**  */
  vendor?: string;

  /**  */
  description?: string;

  /**  */
  actorName?: string;

  /**  */
  euronorm?: Euronorm;

  /**  */
  invoiceStatus?: InvoiceStatus;

  /**  */
  mapViewUrl?: string;

  /**  */
  excludeHoursFromTimeStamp?: boolean;

  /**  */
  dueDate?: Date;
}

export interface TransactionPaginatedList {
  /**  */
  currentPage?: number;

  /**  */
  totalPages?: number;

  /**  */
  pageSize?: number;

  /**  */
  filteredCount?: number;

  /**  */
  totalCount?: number;

  /**  */
  paginatedItems?: Transaction[];
}

export interface Vehicle {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  plate?: string;

  /**  */
  vin?: string;

  /**  */
  vehicleSize?: VehicleSize;

  /**  */
  euronorm?: Euronorm;

  /**  */
  fuelType?: FuelType;

  /**  */
  vehicleDocumentation?: VehicleDocumentation;

  /**  */
  hasGreenDiscount?: boolean;

  /**  */
  greenDiscountStatus?: VehicleGreenDiscountStatus;

  /**  */
  country?: Country;

  /**  */
  countryId?: string;

  /**  */
  identifierIds?: string[];

  /**  */
  co2Class?: Co2Class;

  /**  */
  maxPermissibleWeight?: number;

  /**  */
  customerRef?: string;

  /**  */
  co2Status?: VehicleCo2Status;

  /**  */
  tractorAxles?: VehicleTractorAxles;
}

export interface VehicleCard {
  /**  */
  licensePlate?: string;

  /**  */
  chassisNumber?: string;

  /**  */
  vehicleSize?: VehicleSize;

  /**  */
  fuelType?: FuelType;

  /**  */
  euroNorm?: Euronorm;

  /**  */
  isoCode?: string;

  /**  */
  brand?: string;

  /**  */
  model?: string;

  /**  */
  color?: string;

  /**  */
  co2Class?: Co2Class;

  /**  */
  maxPermissibleWeight?: number;
}

export interface VehicleDocumentation {
  /**  */
  id?: string;

  /**  */
  meta_LastModifiedBy?: string;

  /**  */
  meta_LastModifiedDate?: Date;

  /**  */
  meta_CreatedBy?: string;

  /**  */
  meta_CreatedDate?: Date;

  /**  */
  name?: string;

  /**  */
  fileReference?: string;

  /**  */
  approvalStatus?: VehicleDocumentationStatus;

  /**  */
  vehicleId?: string;
}

export enum AccountPaymentType {
  'Invoice' = 'Invoice',
  'CreditCard' = 'CreditCard',
  'InvoiceAndCreditCard' = 'InvoiceAndCreditCard'
}

export enum AccountState {
  'Active' = 'Active',
  'Frozen' = 'Frozen',
  'Inactive' = 'Inactive',
  'AwaitingDeactivation' = 'AwaitingDeactivation',
  'Blocked' = 'Blocked',
  'Closed' = 'Closed',
  'Pending' = 'Pending'
}

export enum AccountStatus {
  'Active' = 'Active',
  'Frozen' = 'Frozen',
  'Closed' = 'Closed',
  'Inactive' = 'Inactive',
  'Blocked' = 'Blocked'
}

export enum AccountType {
  'Private' = 'Private',
  'Business' = 'Business'
}

export enum AgentResellerModel {
  'Agent' = 'Agent',
  'Reseller' = 'Reseller',
  'Both' = 'Both'
}

export enum ClosedCause {
  'DebtCollection' = 'DebtCollection',
  'Loss' = 'Loss',
  'PassedAway' = 'PassedAway',
  'Ceased' = 'Ceased',
  'Bankruptcy' = 'Bankruptcy',
  'CustomerClosed' = 'CustomerClosed',
  'BankruptcyExternal' = 'BankruptcyExternal',
  'BankruptcyCaseClosed' = 'BankruptcyCaseClosed',
  'VATClosed' = 'VATClosed',
  'DebtCollectionMonitoring' = 'DebtCollectionMonitoring'
}

export enum Co2Class {
  'Class1' = 'Class1',
  'Class2' = 'Class2',
  'Class3' = 'Class3',
  'Class4' = 'Class4',
  'Class5' = 'Class5'
}

export enum CreditCardType {
  'VISA' = 'VISA',
  'DK' = 'DK',
  'VISA_DK' = 'VISA_DK',
  'DANKORT' = 'DANKORT',
  'VISA_ELEC' = 'VISA_ELEC',
  'MC' = 'MC',
  'AMEX' = 'AMEX',
  'MAESTRO' = 'MAESTRO'
}

export enum CustomerEconomyBlockedCause {
  'Unblocked' = 'Unblocked',
  'OE1' = 'OE1',
  'Dunning' = 'Dunning',
  'DebtCollection' = 'DebtCollection',
  'Bankruptcy' = 'Bankruptcy',
  'Installment' = 'Installment',
  'Reconstruction' = 'Reconstruction',
  'ForcedDissolution' = 'ForcedDissolution',
  'ReadyForDebtCollection' = 'ReadyForDebtCollection',
  'BankruptcyExternal' = 'BankruptcyExternal',
  'BankruptcyCaseClosed' = 'BankruptcyCaseClosed'
}

export enum CustomerStatus {
  'Pending' = 'Pending',
  'Active' = 'Active',
  'Blocked' = 'Blocked',
  'Closed' = 'Closed',
  'Inactive' = 'Inactive'
}

export enum CustomerType {
  'Private' = 'Private',
  'Business' = 'Business',
  'Partner' = 'Partner'
}

export enum DrivingVehicleType {
  'HGV' = 'HGV',
  'LV' = 'LV',
  'LVE' = 'LVE'
}

export enum EconomyBlockedCause {
  'Unblocked' = 'Unblocked',
  'OE1' = 'OE1',
  'Dunning' = 'Dunning',
  'DebtCollection' = 'DebtCollection',
  'Bankruptcy' = 'Bankruptcy',
  'Installment' = 'Installment',
  'Reconstruction' = 'Reconstruction',
  'ForcedDissolution' = 'ForcedDissolution',
  'ReadyForDebtCollection' = 'ReadyForDebtCollection',
  'BankruptcyExternal' = 'BankruptcyExternal',
  'BankruptcyCaseClosed' = 'BankruptcyCaseClosed'
}

export enum Euronorm {
  'Euro1' = 'Euro1',
  'Euro2' = 'Euro2',
  'Euro3' = 'Euro3',
  'Euro4' = 'Euro4',
  'Euro5' = 'Euro5',
  'Euro6' = 'Euro6',
  'EEV' = 'EEV'
}

export enum FuelType {
  'Gasoline' = 'Gasoline',
  'GasolineHybrid' = 'GasolineHybrid',
  'Hydrogen' = 'Hydrogen',
  'Diesel' = 'Diesel',
  'DieselHybrid' = 'DieselHybrid',
  'Electric' = 'Electric',
  'LNG' = 'LNG',
  'NaturalGas' = 'NaturalGas',
  'Petroleum' = 'Petroleum'
}

export enum IdentifierStatus {
  'Pending' = 'Pending',
  'Active' = 'Active',
  'Closed' = 'Closed',
  'Inactive' = 'Inactive',
  'Frozen' = 'Frozen',
  'Terminated' = 'Terminated',
  'Blocked' = 'Blocked',
  'BizzOnItsWay' = 'BizzOnItsWay',
  'CreditCardExpiresSoon' = 'CreditCardExpiresSoon',
  'UnpaidInvoices' = 'UnpaidInvoices',
  'IssuesWithCreditCard' = 'IssuesWithCreditCard',
  'Cancelled' = 'Cancelled'
}

export enum InsuranceStatus {
  'NotInsured' = 'NotInsured',
  'PartiallyInsured' = 'PartiallyInsured',
  'Insured' = 'Insured',
  'Disapproved' = 'Disapproved'
}

export enum InvoiceFrequency {
  'Immediately' = 'Immediately',
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly'
}

export enum InvoicePaymentMethodStatus {
  'Active' = 'Active',
  'Pending' = 'Pending',
  'Blocked' = 'Blocked'
}

export enum InvoiceStatus {
  'Paid' = 'Paid',
  'NotPaid' = 'NotPaid',
  'MissingPayment' = 'MissingPayment'
}

export enum Language {
  'Danish' = 'Danish',
  'English' = 'English'
}

export enum LanguageType {
  'Danish' = 'Danish',
  'English' = 'English'
}

export enum LicensePlateValidationResult {
  'NotReferenced' = 'NotReferenced',
  'ReferencedOnLicensePlateIdentifier' = 'ReferencedOnLicensePlateIdentifier',
  'RegisteredInStorebelt' = 'RegisteredInStorebelt'
}

export enum MKAccountStatus {
  'Active' = 'Active',
  'Frozen' = 'Frozen',
  'Inactive' = 'Inactive',
  'Blocked' = 'Blocked',
  'Closed' = 'Closed'
}

export enum MkClosedCause {
  'L1' = 'L1',
  'L2' = 'L2',
  'L3' = 'L3',
  'L4' = 'L4',
  'L6' = 'L6',
  'L7' = 'L7',
  'L8' = 'L8',
  'L9' = 'L9',
  'L10' = 'L10',
  'L11' = 'L11',
  'SL3' = 'SL3',
  'VKL1' = 'VKL1',
  'VKL2' = 'VKL2',
  'VKL' = 'VKL',
  'None' = 'None'
}

export enum NotificationType {
  'BlockedAccount' = 'BlockedAccount',
  'MissingPayment' = 'MissingPayment',
  'CardAboutToExpire' = 'CardAboutToExpire',
  'CardExpired' = 'CardExpired',
  'AccountActivated' = 'AccountActivated',
  'NoMissingPayments' = 'NoMissingPayments',
  'CardValid' = 'CardValid',
  'OrderNewProduct' = 'OrderNewProduct',
  'ConvertBizzToTollingBox' = 'ConvertBizzToTollingBox'
}

export enum PartnersMkLinksTo {
  'BropassOresund' = 'BropassOresund',
  'ForSea' = 'ForSea'
}

export enum PaymentCardStatus {
  'ValidCard' = 'ValidCard',
  'BlockedCard' = 'BlockedCard',
  'ExpiredCard' = 'ExpiredCard',
  'CardAboutToExpire' = 'CardAboutToExpire'
}

export enum PaymentType {
  'Invoice' = 'Invoice',
  'CreditCard' = 'CreditCard',
  'PBS' = 'PBS',
  'OnlinePaymentProvider' = 'OnlinePaymentProvider',
  'BankTransfer' = 'BankTransfer',
  'LeverandoerService' = 'LeverandoerService'
}

export enum PersonStatus {
  'Active' = 'Active',
  'Frozen' = 'Frozen',
  'Inactive' = 'Inactive',
  'AwaitingDeactivation' = 'AwaitingDeactivation',
  'Blocked' = 'Blocked',
  'Closed' = 'Closed'
}

export enum ProductStatus {
  'Active' = 'Active',
  'Inactive' = 'Inactive'
}

export enum ProductType {
  'Bizz' = 'Bizz',
  'LicensePlate' = 'LicensePlate',
  'BizzCommercial' = 'BizzCommercial',
  'OneTimeANPR' = 'OneTimeANPR',
  'TollingBox' = 'TollingBox',
  'TollingBoxSubscription' = 'TollingBoxSubscription',
  'TollingApp' = 'TollingApp'
}

export enum SalesTaxGroup {
  'None' = 'None',
  'DKKØB' = 'DKKØB',
  'DKSALG' = 'DKSALG',
  'DKUDLÆG' = 'DKUDLÆG',
  'EUKØB' = 'EUKØB',
  'EUSALG' = 'EUSALG',
  'ØVRIGKØB' = 'ØVRIGKØB',
  'ØVRIGSALG' = 'ØVRIGSALG'
}

export enum SecurityRole {
  'ParentAccountAdmin' = 'ParentAccountAdmin',
  'SubAccountAdmin' = 'SubAccountAdmin',
  'ProductAccess' = 'ProductAccess',
  'PrivatePerson' = 'PrivatePerson'
}

export enum Segment {
  'LV' = 'LV',
  'HGV' = 'HGV',
  'LVE' = 'LVE',
  'Agent' = 'Agent'
}

export enum UserRole {
  'ParentAccountAdmin' = 'ParentAccountAdmin',
  'SubAccountAdmin' = 'SubAccountAdmin',
  'ProductAccess' = 'ProductAccess',
  'PrivatePerson' = 'PrivatePerson'
}

export enum VehicleCo2Status {
  'Unavailable' = 'Unavailable',
  'Uploaded' = 'Uploaded'
}

export enum VehicleDocumentationStatus {
  'pending' = 'pending',
  'processing' = 'processing',
  'approved' = 'approved',
  'declined' = 'declined'
}

export enum VehicleGreenDiscountStatus {
  'NotFulfilled' = 'NotFulfilled',
  'Pending' = 'Pending',
  'Declined' = 'Declined',
  'Approved' = 'Approved',
  'AutoFulfilled' = 'AutoFulfilled',
  'PreviouslyApproved' = 'PreviouslyApproved'
}

export enum VehicleSize {
  'VehicleUnder3500kg' = 'VehicleUnder3500kg',
  'BusOver3500kg' = 'BusOver3500kg',
  'VehicleOver3500kg' = 'VehicleOver3500kg'
}

export enum VehicleTractorAxles {
  'Two' = 'Two',
  'Three' = 'Three',
  'FourOrMore' = 'FourOrMore'
}
