/* eslint-disable import/no-cycle */
import { makeObservable, action, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export enum AdministrationTab {
  AccountTab = 1,
  UserTab = 2,
  AccountInformationTab = 3,
  PaymentCardsTab = 4
}

export default class AdministrationTabStore extends BaseStore {
  activeTab: number = AdministrationTab.AccountTab;

  setActiveTab = (activeTab: number) => {
    this.activeTab = activeTab;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      activeTab: observable,
      setActiveTab: action
    });
  }
}
