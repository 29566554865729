import React, { FC } from 'react';
import styled from 'styled-components';
import { Person, UserRole } from '../../../swagger/api';
import ContentStore from '../../../stores/ContentStore';

export const AccountAdminChip = styled.div`
  background: #c8e6ff;
  border-radius: 5px;
  padding: 4px 8px 4px;
  display: inline-block;
  position: relative;
  right: 8px;
  left: 0px;
  color: #2d2d2d;
  font-size: 15px;
  font-style: normal;
  font-weight: 475;
  letter-spacing: -0.2px;
`;

export const SubAccountAdminChip = styled(AccountAdminChip)`
  background: #ece8ff;
`;

export const ProductAccessChip = styled(AccountAdminChip)`
  background: #ccf4e0;
`;

interface UserRoleprops {
  person: Person;
  contentStore: ContentStore;
}

export const UserRoleChip: FC<UserRoleprops> = (props) => {
  const { person, contentStore } = props;
  // eslint-disable-next-line consistent-return
  const getUserChip = () => {
    switch (person.role) {
      case UserRole.ParentAccountAdmin:
        return (
          <AccountAdminChip>
            {contentStore.cmsStore.getContent('AccountAdminRole')?.content}
          </AccountAdminChip>
        );
      case UserRole.SubAccountAdmin:
        return (
          <SubAccountAdminChip>
            {contentStore.cmsStore.getContent('SubAccountAdministatorRole')?.content}
          </SubAccountAdminChip>
        );
      case UserRole.ProductAccess:
        return (
          <ProductAccessChip>
            {contentStore.cmsStore.getContent('ProductAccessRole')?.content}
          </ProductAccessChip>
        );
      default:
    }
  };

  const userChip = getUserChip();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{userChip}</>;
};

export default { UserRoleChip, AccountAdminChip, SubAccountAdminChip, ProductAccessChip };
