import React, { FC } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import useStore from '../../../hooks/useStore';
import { OutlinedButton } from '../../../common-components/Buttons';
import AdministrationStore from '../../../stores/AdministrationStore';
import AdministrationAccountStore from '../../../stores/adminstration-stores/AdministrationAccountStore';
import { ReactComponent as Add } from '../../../layout/icons/grey-add.svg';
import { ContentSmallCamptonBook } from '../../../layout/globalFonts';
import ContentStore from '../../../stores/ContentStore';
import { overlayInnerStyle } from '../../../common-components/TooltipStyling';

const StyledCreateButton = styled(OutlinedButton)`
  cursor: pointer;
  padding: 20px 10px 20px 10px;
  &:disabled {
    opacity: 0;
  }
  &:hover {
    opacity: 1;
  }
`;

const OpenModal = (administrationAccountStore: AdministrationAccountStore) => {
  administrationAccountStore.openModal();
};

const CreateSubAccountButton: FC = () => {
  const administrationStore: AdministrationStore = useStore(AdministrationStore);
  const contentStore: ContentStore = useStore(ContentStore);
  const createNewSubAccount = contentStore.cmsStore.getContent('CreateSubAccount');

  const { administrationAccountStore } = administrationStore;
  return (
    <Tooltip
      placement="bottom"
      title={createNewSubAccount?.tooltip}
      color="#2d2d2d"
      overlayInnerStyle={overlayInnerStyle}
    >
      <StyledCreateButton onClick={() => OpenModal(administrationAccountStore)} icon={<Add />}>
        <ContentSmallCamptonBook style={{ paddingLeft: '15px' }}>
          {createNewSubAccount?.content}
        </ContentSmallCamptonBook>
      </StyledCreateButton>
    </Tooltip>
  );
};

export default observer(CreateSubAccountButton);
