/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class ModalStore extends BaseStore {
  visibleModals: React.ReactNode[] = [];

  openModal = (modal: React.ReactNode) => {
    this.visibleModals.push(modal);
  };

  closeModal = () => {
    this.visibleModals.pop();
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      visibleModals: observable,
      closeModal: action,
      openModal: action
    });
  }
}
