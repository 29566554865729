/* eslint-disable import/no-cycle */
import { RootStore } from '../RootStore';

export default abstract class BaseStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
}
