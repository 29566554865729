/* eslint-disable no-param-reassign */
import { Col, Divider, Form, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledInput } from '../../../../common-components/Input';
import {
  H9CamptonBoldSemiBold19,
  SmallGrey12,
  ContentSmallGreenLink,
  ContentSmallCamptonBook
} from '../../../../layout/globalFonts';
import SubAccountStore from '../../../../stores/adminstration-stores/SubAccountStore';
import ContentStore from '../../../../stores/ContentStore';
import EditDailyContactModal, {
  DailyContactInformation
} from '../../edit-daily-contact/EditDailyContactModal';
import CreateButton from './CreateButton';

const RegisterInput = styled(StyledInput)`
  width: 245px;
  height: 48px;
`;

const SectionInfo = styled.div`
  width: 200px;
  margin-right: 150px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const StyledSubTitle = styled(ContentSmallCamptonBook)`
  line-height: 19px;
`;

const StyledTitle = styled(H9CamptonBoldSemiBold19)`
  margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
  @media screen and (min-width: 1218px) {
    flex-wrap: nowrap;
  }
`;

const StyledSubRow = styled(Row)`
  @media screen and (min-width: 1000px) and (max-width: 1217px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
`;

interface DailyContactProps {
  form: FormInstance;
  contentStore: ContentStore;
  subAccountStore: SubAccountStore;
}

const DailyContact: FC<DailyContactProps> = (props) => {
  const { form, contentStore, subAccountStore } = props;
  const { modalStore } = contentStore.rootStore;
  const dailyContactPerson = contentStore.cmsStore.getContent('DailyContactPerson')?.content;
  const dailyContactPersonInfo =
    contentStore.cmsStore.getContent('DailyContactPersonInfo')?.content;
  const dailyContactPersonName =
    contentStore.cmsStore.getContent('DailyContactPersonName')?.content;
  const dailyContactPersonEmail =
    contentStore.cmsStore.getContent('DailyContactPersonEmail')?.content;
  const dailyContactPersonPhoneNumber = contentStore.cmsStore.getContent(
    'DailyContactPersonPhoneNumber'
  )?.content;

  const setDailyContact = (dailyContactInformation: DailyContactInformation) => {
    form.setFieldsValue({
      dailyContactName: dailyContactInformation.name,
      dailyContactEmail: dailyContactInformation.email,
      dailyContactPhoneNumber: dailyContactInformation.phoneNumber
    });
  };

  return (
    <>
      <StyledRow>
        <Col>
          <SectionInfo>
            <StyledTitle>{dailyContactPerson}</StyledTitle>
            <StyledSubTitle>{dailyContactPersonInfo}</StyledSubTitle>
          </SectionInfo>
        </Col>
        <Col>
          <StyledSubRow gutter={20}>
            <Col>
              <SmallGrey12>{dailyContactPersonName}</SmallGrey12>
              <Form.Item name="dailyContactName">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{dailyContactPersonEmail}</SmallGrey12>
              <Form.Item name="dailyContactEmail">
                <RegisterInput maxLength={100} type="text" disabled />
              </Form.Item>
            </Col>
            <Col>
              <SmallGrey12>{dailyContactPersonPhoneNumber}</SmallGrey12>
              <Form.Item name="dailyContactPhoneNumber">
                <RegisterInput maxLength={50} type="text" disabled />
              </Form.Item>
            </Col>
          </StyledSubRow>
          <Row>
            <Col>
              <ContentSmallGreenLink
                onClick={() =>
                  modalStore.openModal(
                    <EditDailyContactModal
                      closeModal={modalStore.closeModal}
                      primaryButtonClick={setDailyContact}
                      dailyContactName={form.getFieldValue('dailyContactName')}
                      dailyContactEmail={form.getFieldValue('dailyContactEmail')}
                      dailyContactPhone={form.getFieldValue('dailyContactPhoneNumber')}
                    />
                  )
                }
              >
                {contentStore.cmsStore.getContent('ChooseDailyContact')?.content}
              </ContentSmallGreenLink>
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <Row>
        <StyledDivider />
      </Row>
      {subAccountStore.isFormFilledIn && (
        <CreateButton loading={false} contentStore={contentStore} />
      )}
    </>
  );
};

export default observer(DailyContact);
