import React, { FC, useEffect, useRef, useState } from 'react';
import { Menu, Button } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import useCheckIfClickedOutside from '../../hooks/useCheckIfClickedOutside';
import useStore from '../../hooks/useStore';
import ContentStore from '../../stores/ContentStore';
import { PrimaryButton, IconButton } from '../../common-components/Buttons';
import { ColoredCircleWithWhiteNumber, DropdownArrow } from '../../common-components/StyledIcons';
import { ReactComponent as FilterIcon } from '../../layout/icons/filter-white.svg';
import { BrobizzCheckbox } from '../../common-components/Selectors';
import {
  GreenButtonText,
  OutlinedButtonTextGrey,
  DropDownText,
  DropDownTextBlack,
  ContentSmallCamptonBook
} from '../../layout/globalFonts';
import { FilterMenuContent, FilterObject, Filters } from '../../Types';
import subscriptTwos from '../../common-components/SubscriptTwos';

const StyledMenu = styled(Menu)`
  position: absolute;
  right: 5px;
  top: 51px;
  z-index: 99;
  width: 302px;
  border-radius: 8px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153) !important;
  margin: 5px;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }
  .ant-menu-inline .ant-menu-item::after {
    content: none;
  }
  .ant-menu-item-selected::after {
    content: none;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #2d2d2d;
  }
  .ant-input-affix-wrapper-borderless:hover {
    background-color: #efefef;
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: -12.2px;
  }
  .ant-menu-inline {
    /* max-height: 30vh; */
    /* overflow: auto;
    overflow-x: hidden; */
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-menu-submenu-expand-icon {
    transform: rotate(0deg);
    transition: transform 0.1s ease 0s;
  }
  .ant-menu-submenu-open {
    .ant-menu-submenu-expand-icon {
      transform: rotate(180deg);
      transition: transform 0.1s ease 0s;
    }
  }
`;

const StyledMenuItemGroup = styled(Menu.ItemGroup)<{ isfirstitem: boolean }>`
  .ant-menu-item-group-title {
    margin-top: ${({ isfirstitem: isfirstgroup }) => (isfirstgroup ? '10px' : '20px')};
`;

const StyledMenuItem = styled(Menu.Item)`
  content: none !important;
  background: #fff !important;
  .ant-menu-title-content {
    margin-left: 16px;
  }
`;

const StyledSubMenu = styled(Menu.SubMenu)`
  .ant-menu-inline {
    background: #ffffff;
  }
  .ant-menu-submenu-title:active {
    background: #fff;
  }
  .ant-menu-title-content {
    margin-left: 16px;
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-right: 20px;
`;

const StyledRemoveAllButton = styled(Button)`
  background: #fff !important;
  padding-left: 0px;
  ${(props) => props.disabled && `opacity: 0.5;`}
`;

const SearchButton = styled(PrimaryButton)`
  position: absolute;
  right: 20px;
  top: 24px;
  bottom: 24px;
`;

interface FilterProps {
  allMenus: FilterMenuContent;
  activeFilters: Filters;
  temporaryFilters: Filters;
  setActiveFilters: (temporaryFilters: Filters) => void;
  setTemporaryFilters: (filters: Filters) => void;
  toDate?: Date | null;
  fromDate?: Date | null;
  setToDate?: (toDate: Date) => void;
  setFromDate?: (fromDate: Date) => void;
}

const TableFilter: FC<FilterProps> = (props) => {
  const {
    allMenus,
    activeFilters,
    temporaryFilters,
    setActiveFilters,
    setTemporaryFilters,
    toDate,
    fromDate,
    setFromDate,
    setToDate
  } = props;
  const contentStore: ContentStore = useStore(ContentStore);
  const { sharedDateFilterStore } = contentStore.rootStore.filterStore;
  const [openKeys, setOpenKeys] = useState(['']);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const refDiv: any = useRef(null);
  const [hasFilterChanges, setHasFilterChanges] = useState(false);

  const checkIfFilterHasChanges = () => {
    const keys: string[] = Object.keys(temporaryFilters);
    setHasFilterChanges(false);
    for (let i = 0; i < keys.length; i += 1) {
      if (temporaryFilters[keys[i]].length > 0) setHasFilterChanges(true);
    }
  };

  const setOpenSubMenus = (keys: string[]) => {
    const rootSubmenuKeys = Object.keys(allMenus);
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const clearFilters = () => {
    sharedDateFilterStore.clearDateFilters();
    const emptyFilters: Filters = {};
    Object.keys(activeFilters).forEach((key: string) => {
      emptyFilters[key] = [];
    });
    setActiveFilters(emptyFilters);
  };

  const setTempFiltersOnCheckboxClick = (
    checked: boolean,
    menuItem: FilterObject,
    filterGroup: string
  ) => {
    const copyOfTempFilter = JSON.parse(JSON.stringify(temporaryFilters));
    if (checked === true) {
      copyOfTempFilter[filterGroup].push(menuItem);
      setTemporaryFilters(copyOfTempFilter);
    } else {
      copyOfTempFilter[filterGroup] = copyOfTempFilter[filterGroup].filter(
        (tag: FilterObject) => tag.text !== menuItem.text
      );
      setTemporaryFilters(copyOfTempFilter);
    }
  };

  const onShowSearchClick = () => {
    setActiveFilters(temporaryFilters);
    setIsDropdownOpen(!isDropdownOpen);
  };

  useCheckIfClickedOutside(isDropdownOpen, setIsDropdownOpen, refDiv);

  useEffect(() => {
    checkIfFilterHasChanges();
  }, [temporaryFilters]);

  useEffect(() => {
    setTemporaryFilters(JSON.parse(JSON.stringify(activeFilters)));
  }, [activeFilters]);

  const renderMenuComponent = (
    Component: React.FC<{
      temporaryFilters: Filters;
      setTemporaryFilters: (filter: Filters) => void;
      contentStore: ContentStore;
      toDate: Date | null | undefined;
      fromDate: Date | null | undefined;
      setToDate: ((toDate: Date) => void) | undefined;
      setFromDate: ((fromDate: Date) => void) | undefined;
    }>
  ) => (
    <Component
      temporaryFilters={temporaryFilters}
      setTemporaryFilters={setTemporaryFilters}
      contentStore={contentStore}
      toDate={toDate}
      fromDate={fromDate}
      setToDate={setToDate}
      setFromDate={setFromDate}
    />
  );

  const renderMenuItems = (Sub: string) =>
    allMenus[Sub].menuItems!.map((menuItem: FilterObject) => (
      <StyledMenuItem key={Sub + menuItem.text}>
        <BrobizzCheckbox
          checked={temporaryFilters[Sub]?.some(
            (element: FilterObject) => element.text === menuItem.text
          )}
          onChange={(e) => setTempFiltersOnCheckboxClick(e.target.checked, menuItem, Sub)}
        >
          <ContentSmallCamptonBook>{menuItem.text}</ContentSmallCamptonBook>
        </BrobizzCheckbox>
      </StyledMenuItem>
    ));

  const renderSubmenu = (Sub: string) => (
    <>
      <StyledSubMenu
        key={Sub}
        title={
          <>
            <DropDownTextBlack>{subscriptTwos(allMenus[Sub].title)}</DropDownTextBlack>
            {temporaryFilters[Sub]?.length > 0 && (
              <ColoredCircleWithWhiteNumber color="#00a050">
                {temporaryFilters[Sub].length}
              </ColoredCircleWithWhiteNumber>
            )}
          </>
        }
      >
        {allMenus[Sub].menuItems !== undefined
          ? renderMenuItems(Sub)
          : renderMenuComponent(allMenus[Sub].component!)}
      </StyledSubMenu>
      <Menu.Divider />
    </>
  );

  const renderSubmenuWithoutDropdown = (Sub: string) =>
    allMenus[Sub].menuItems !== undefined
      ? renderMenuItems(Sub)
      : renderMenuComponent(allMenus[Sub].component!);

  const hasGreyTitles = () =>
    allMenus.greyMainMenuSubTitles !== undefined &&
    allMenus.greyMainMenuSubTitles.greyTitles !== undefined &&
    allMenus.greyMainMenuSubTitles.greyTitles!.length > 0;

  const showCheckBoxesWithoutHeader = () =>
    hasGreyTitles() &&
    allMenus.greyMainMenuSubTitles.greyTitles![0].title ===
      contentStore.cmsStore.getContent('Show')?.content!;

  const filterMenu = (
    <StyledMenu
      expandIcon={<DropdownArrow />}
      mode="inline"
      openKeys={openKeys}
      onOpenChange={setOpenSubMenus}
      inlineIndent={0}
    >
      {hasGreyTitles()
        ? allMenus.greyMainMenuSubTitles.greyTitles!.map(
            (element: { title: string; items: string[] }, index: number) => (
              <StyledMenuItemGroup
                isfirstitem={index === 0}
                title={<DropDownText>{element.title}</DropDownText>}
                key={element.title}
              >
                {!(index === 0 && showCheckBoxesWithoutHeader()) && <Menu.Divider />}
                {element.items.map((submenu: string, menuIndex: number) =>
                  menuIndex === 0 && index === 0 && showCheckBoxesWithoutHeader()
                    ? renderSubmenuWithoutDropdown(submenu)
                    : renderSubmenu(submenu)
                )}
              </StyledMenuItemGroup>
            )
          )
        : Object.keys(allMenus).map((submenu) => renderSubmenu(submenu))}
      <StyledMenuItem key="ShowSearch" style={{ height: '96px' }}>
        <StyledRemoveAllButton type="text" onClick={clearFilters} disabled={!hasFilterChanges}>
          <OutlinedButtonTextGrey>
            {contentStore.cmsStore.getContent('RemoveAll')?.content}
          </OutlinedButtonTextGrey>
        </StyledRemoveAllButton>
        <SearchButton onClick={onShowSearchClick} disabled={!hasFilterChanges}>
          <GreenButtonText>
            {contentStore.cmsStore.getContent('ShowSearch')?.content}
          </GreenButtonText>
        </SearchButton>
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <div ref={refDiv}>
      <IconButton icon={<StyledFilterIcon />} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <GreenButtonText>{contentStore.cmsStore.getContent('Filter')?.content}</GreenButtonText>
      </IconButton>
      {isDropdownOpen && filterMenu}
    </div>
  );
};

TableFilter.defaultProps = {
  toDate: null,
  fromDate: null,
  setFromDate: undefined,
  setToDate: undefined
};

export default observer(TableFilter);
