import { useEffect, useState } from 'react';

const useFetchListDataWithPagination = (
  fetchFunction: () => Promise<any>,
  paginationPageNumer: number
) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchFunction().finally(() => {
      setIsLoading(false);
    });
  }, [paginationPageNumer]);

  return isLoading;
};

export default useFetchListDataWithPagination;
