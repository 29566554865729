/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import RouteUrlPaths from '../../RouteUrlPaths';
import UserStore from '../../stores/UserStore';
import { UserRole } from '../../swagger/api';

interface ProtectedFrontPageRouteProps {
  userStore: UserStore;
}

const ProtectedFrontPageRoute: FC<ProtectedFrontPageRouteProps> = (props) => {
  const { userStore, children } = props;
  if (userStore.person?.role === UserRole.ProductAccess) {
    return <Navigate to={RouteUrlPaths.CommercialProducts} />;
  }

  if (userStore.isSelectAccountShown) {
    return <Navigate to={RouteUrlPaths.SelectAccount} />;
  }

  return <>{children}</>;
};

export default ProtectedFrontPageRoute;
