const isTollAppOrderOpen = () => {
  const tollAppOrderDateString = process.env.REACT_APP_TOLL_APP_ORDER_DATE;
  if (tollAppOrderDateString) {
    const tollAppOrderDate = new Date(tollAppOrderDateString);
    const currentDate = new Date();
    return currentDate > tollAppOrderDate;
  }
  return false;
};

export default isTollAppOrderOpen;
