/* eslint-disable import/no-cycle */
import React, { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Modal, Row } from 'antd';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';
import ModalStore from '../stores/ModalStore';
import useStore from '../hooks/useStore';
import { PrimaryButton, SecondaryButton, RedPrimaryButton } from './Buttons';
import { ReactComponent as CloseIcon } from '../layout/icons/close-green.svg';
import {
  H6CamptonBold25,
  ContentSmallCamptonBook,
  ContentSmallCamptonBookBold,
  ContentSmallGreenLink,
  H6CamptonBold25Red
} from '../layout/globalFonts';
import useIsMobileView from '../hooks/useIsMobileView';

const StyledModal = styled(Modal)<{
  ismobileview: boolean;
  aligntextcenter: boolean;
  addpaddingtop: boolean;
  mobilebuttondivheight: number;
}>`
  text-align: ${({ aligntextcenter }) => aligntextcenter && 'center !important'};
  padding-bottom: 0;
  ${(props) => props.ismobileview && 'top: 0%'};
  ${(props) => props.ismobileview && 'margin: 0%'};

  .ant-modal-content {
    ${(props) => !props.ismobileview && 'display: inline-flex; padding: 53px 10% 6% 10%'};
    ${(props) => props.ismobileview && 'min-height: 100vh; width: 100vw'};
    border-radius: ${(props) => (props.ismobileview ? '0px' : '20px')};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    .ant-modal-body {
      ${(props) => props.ismobileview && props.addpaddingtop && 'padding-top: 20%'};
      padding-bottom: ${(props) => props.ismobileview && `${props.mobilebuttondivheight}px`};
    }
  }

  .ant-modal-close {
    margin: 2% 2% 0 0;
  }
`;

const TopIconRow = styled(Row)`
  margin-bottom: 4%;
`;

const HeaderRow = styled(Row)`
  text-align: center;
`;

const ContentRow = styled(Row)<{
  isFromAccountInformationTab?: boolean;
}>`
  text-align: center;
  ${(props) =>
    props.isFromAccountInformationTab ? 'margin: 9.5px 0 50px 0;' : 'margin: 2% 0 10% 0;'}
`;

const ButtonRow = styled(Row)`
  margin: 0 0 5% 0;
`;

const MobileViewButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 14px 0;
  background-color: #fff;
  z-index: 1070;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153);
`;

const ContentText = styled(ContentSmallCamptonBook)`
  line-height: 19px;
`;

interface CustomModalProps {
  header?: string;
  closable: boolean;
  contentText?: string | null | undefined;
  topIconDesktop?: ReactNode;
  topIconMobile?: ReactNode;
  centerIcon?: React.FunctionComponent;
  contextBoldText?: string;
  primaryButtonText?: string;
  showPrimaryButtonInMobileView?: boolean;
  greenLinkText?: string;
  isPrimaryButtonDisabled?: boolean;
  mobileViewSecondaryButton?: boolean;
  alignTextCenter?: boolean;
  isHeaderRed?: boolean;
  addPaddingTop?: boolean;
  removeHorizontalPaddingForChildElement?: boolean;
  redPrimaryButton?: boolean;
  isLoading?: boolean;
  isFromAccountInformationTab?: boolean;
  onPrimaryButtonClick?: () => void;
  onGreenLinkClick?: () => void;
  onCloseClick?: () => void;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    header,
    closable,
    contentText,
    topIconDesktop,
    topIconMobile,
    centerIcon,
    contextBoldText,
    primaryButtonText,
    showPrimaryButtonInMobileView,
    greenLinkText,
    isPrimaryButtonDisabled,
    mobileViewSecondaryButton,
    isHeaderRed,
    alignTextCenter,
    addPaddingTop,
    removeHorizontalPaddingForChildElement,
    redPrimaryButton,
    isLoading,
    onPrimaryButtonClick,
    onGreenLinkClick,
    onCloseClick,
    children,
    isFromAccountInformationTab
  } = props;

  const isMobileView = useIsMobileView();
  const refDiv: any = useRef(null);
  const [mobileButtonHeight, setMobileButtonHeight] = useState(0);

  const modalStore: ModalStore = useStore(ModalStore);

  useLayoutEffect(() => {
    // if the modal has a fixed bottom button, this use the height of the bottom to make more space in bottom of modal
    if (showPrimaryButtonInMobileView && onPrimaryButtonClick && isMobileView) {
      const mobileButtonDivHeight = refDiv.current.clientHeight;
      if (mobileButtonDivHeight) {
        setMobileButtonHeight(mobileButtonDivHeight);
      }
    }
  });

  const greenLinkClick = () => {
    if (onGreenLinkClick) onGreenLinkClick();
  };

  const primaryButtonClick = () => {
    if (onPrimaryButtonClick) onPrimaryButtonClick();
  };

  const onClose = () => {
    modalStore.closeModal();
    if (onCloseClick) onCloseClick();
  };

  const primaryButton = redPrimaryButton ? (
    <RedPrimaryButton
      loading={isLoading}
      onClick={() => primaryButtonClick()}
      disabled={isPrimaryButtonDisabled}
    >
      {primaryButtonText}
    </RedPrimaryButton>
  ) : (
    <PrimaryButton
      loading={isLoading}
      onClick={() => primaryButtonClick()}
      disabled={isPrimaryButtonDisabled}
    >
      {primaryButtonText}
    </PrimaryButton>
  );

  const topIcon = isMobileView ? topIconMobile : topIconDesktop;
  const topIconRow = topIcon && (
    <TopIconRow justify="center" style={{ marginBottom: '28px' }}>
      {topIcon}
    </TopIconRow>
  );
  const centerIconRow = centerIcon && <Row justify="center">{centerIcon}</Row>;
  const headerRow = (
    <HeaderRow justify="center">
      {isHeaderRed ? (
        <H6CamptonBold25Red>{header}</H6CamptonBold25Red>
      ) : (
        <H6CamptonBold25>{header}</H6CamptonBold25>
      )}
    </HeaderRow>
  );
  const contentRow = contentText && (
    <ContentRow isFromAccountInformationTab={isFromAccountInformationTab} justify="center">
      <ContentText>{contentText}</ContentText>
    </ContentRow>
  );
  const contextBoldTextRow = contextBoldText && (
    <ContentRow justify="center">
      <ContentSmallCamptonBookBold>{contextBoldText}</ContentSmallCamptonBookBold>
    </ContentRow>
  );
  const primaryButtonTextRow = primaryButtonText && (
    <ButtonRow justify="center">{primaryButton}</ButtonRow>
  );
  const greenLinkTextRow = greenLinkText && (
    <Row justify="center">
      <ContentSmallGreenLink onClick={() => greenLinkClick()}>
        {greenLinkText}
      </ContentSmallGreenLink>
    </Row>
  );

  const mobileViewButtons = primaryButtonText && showPrimaryButtonInMobileView && (
    <MobileViewButton ref={refDiv}>
      <Row justify="center">{primaryButton}</Row>
      {mobileViewSecondaryButton && (
        <Row justify="center" style={{ marginTop: '32px' }}>
          <SecondaryButton onClick={() => greenLinkClick()}>{greenLinkText}</SecondaryButton>
        </Row>
      )}
    </MobileViewButton>
  );

  return (
    <RemoveScroll>
      <StyledModal
        visible
        footer={null}
        maskClosable={false}
        centered={!isMobileView}
        keyboard={closable}
        closable={closable}
        closeIcon={closable ? <CloseIcon /> : closable}
        onCancel={() => onClose()}
        ismobileview={isMobileView}
        aligntextcenter={alignTextCenter!}
        addpaddingtop={addPaddingTop!}
        mobilebuttondivheight={mobileButtonHeight}
      >
        {topIconRow}
        {headerRow}
        {contentRow}
        {centerIconRow}
        <div
          style={{
            marginLeft: removeHorizontalPaddingForChildElement ? '-24px' : undefined,
            marginBottom: isFromAccountInformationTab ? '36px' : undefined
          }}
        >
          {children}
        </div>
        {contextBoldTextRow}
        {isMobileView ? mobileViewButtons : primaryButtonTextRow}
        {!isMobileView && greenLinkTextRow}
      </StyledModal>
    </RemoveScroll>
  );
};

CustomModal.defaultProps = {
  header: undefined,
  topIconDesktop: undefined,
  topIconMobile: undefined,
  centerIcon: undefined,
  contextBoldText: undefined,
  primaryButtonText: undefined,
  showPrimaryButtonInMobileView: true,
  greenLinkText: undefined,
  isPrimaryButtonDisabled: false,
  mobileViewSecondaryButton: false,
  isHeaderRed: false,
  onPrimaryButtonClick: undefined,
  onGreenLinkClick: undefined,
  onCloseClick: undefined,
  contentText: undefined,
  alignTextCenter: true,
  addPaddingTop: true,
  removeHorizontalPaddingForChildElement: false,
  redPrimaryButton: false,
  isLoading: false,
  isFromAccountInformationTab: false
};

export default CustomModal;
