import React, { FC, useEffect } from 'react';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import ModalStore from '../../../../stores/ModalStore';
import { AccountPaymentType, PaymentMethod, UserRole } from '../../../../swagger/api';
// eslint-disable-next-line import/no-cycle
import UpdatePaymentCardDropdownModal from '../../../administration-features/payment-card-features/modals/UpdatePaymentCardDropdownModal';
import { openSnackbar } from '../../../notifications/Snackbar';
import CreateNewPaymentCardModal from '../../../administration-features/payment-card-features/modals/CreateNewPaymentCardModal';

interface RemovePaymentCardModalProps {
  contentStore: ContentStore;
  productId: string;
  paymentMethod: PaymentMethod;
  modalStore: ModalStore;
}

const RemovePaymentCardModal: FC<RemovePaymentCardModalProps> = (props) => {
  const { contentStore, productId, paymentMethod, modalStore } = props;
  const { userStore, paymentCardStore, loadingStore } = contentStore.rootStore;
  const { administrationPaymentCardsStore } = contentStore.rootStore.administrationStore;
  let customerHasPaymentCards: boolean = false;

  // Check if customer has any cards.
  useEffect(() => {
    const promise = administrationPaymentCardsStore.getPaymentCardsInfo();
    promise.then(() => {
      if (
        administrationPaymentCardsStore.paymentCards &&
        administrationPaymentCardsStore.paymentCards.length > 0
      ) {
        customerHasPaymentCards = true;
      }
    });
  }, []);

  const isInvoiceCustomer =
    contentStore.rootStore.userStore.currentAccount?.accountPaymentType !==
    AccountPaymentType.CreditCard;

  const contentText = isInvoiceCustomer
    ? contentStore.cmsStore.getContent('InvoiceUserRemovePaymentCard')?.content
    : contentStore.cmsStore.getContent('PaymentCardUserRemovePaymentCard')?.content;

  const greenLinkText = contentStore.cmsStore.getContent('ReplacePaymentCard')?.content;

  const snackBarText = isInvoiceCustomer
    ? contentStore.cmsStore
        .getContent('RemovePaymentCardSnackbarInvoice')
        ?.content.replace('{maskedCardNumbers}', paymentMethod.cardNumber!)
    : contentStore.cmsStore
        .getContent('RemovePaymentCardSnackbarNotInvoice')
        ?.content.replace('{maskedCardNumbers}', paymentMethod.cardNumber!);

  const onPrimaryClick = async () => {
    loadingStore.enableGlobalSpinner();
    await paymentCardStore.setPaymentCardOnProduct(productId, undefined);
    await contentStore.rootStore.productStore.productItemsStore.getProduct(
      productId,
      'TollingBoxConversion'
    );
    loadingStore.disableGlobalSpinner();
    openSnackbar(snackBarText!);
    modalStore.closeModal();
    return productId;
  };

  const replacePaymentCard = () => {
    if (userStore.person?.role === UserRole.ProductAccess || !customerHasPaymentCards) {
      modalStore.openModal(
        <CreateNewPaymentCardModal modalStore={modalStore} identifierId={productId} />
      );
    } else {
      modalStore.closeModal();
      modalStore.openModal(
        <UpdatePaymentCardDropdownModal
          contentStore={contentStore}
          productId={productId}
          paymentCard={paymentMethod!}
          modalStore={modalStore}
        />
      );
    }
  };

  return (
    <CustomModal
      header={contentStore.cmsStore.getContent('RemovePaymentCardFromProduct')?.content!}
      closable
      contentText={contentText}
      primaryButtonText={contentStore.cmsStore.getContent('RemovePaymentCardFromProduct')?.subText!}
      greenLinkText={greenLinkText}
      onPrimaryButtonClick={onPrimaryClick}
      onGreenLinkClick={replacePaymentCard}
    >
      {!isInvoiceCustomer}
      <div style={{ marginBottom: '46px' }}>
        {contentStore.cmsStore.getContent('PaymentCardUserRemovePaymentCard')?.subText}
      </div>
    </CustomModal>
  );
};

export default RemovePaymentCardModal;
