import { useEffect, useState } from 'react';

const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1000);

  useEffect(() => {
    function handleResize() {
      const currentIsMobileView = window.innerWidth < 1000;
      if (currentIsMobileView !== isMobileView) {
        setIsMobileView(currentIsMobileView);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileView]);

  return isMobileView;
};

export default useIsMobileView;
