/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useRef, useState } from 'react';
import { Row, Col, Tooltip } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import ContentStore from '../../../stores/ContentStore';
import { AccountInformationText } from '../../../layout/globalFonts';
import { ReactComponent as SmallArrowRightWhite } from '../../../layout/icons/small-white-arrow-right.svg';
import AccountStore from '../../../stores/AccountStore';

import { overlayInnerStyle } from '../../../common-components/TooltipStyling';

const ArrowIcon = styled(SmallArrowRightWhite)`
  position: absolute;
  opacity: 0.66;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

const WrapperMultipleAccounts = styled.div<{ isChangeAccountOpen: boolean }>`
  cursor: pointer;
  background-color: #193232;
  border-top: 1px solid #122424;
  min-height: 70px;
  width: 100%;
  &:hover {
    background-color: #122424;
  }
  ${(props) => props.isChangeAccountOpen && `background-color: #122424`}
`;

const WrapperOneAccount = styled.div`
  background-color: #193232;
  min-height: 70px;
`;

const Content = styled.div`
  padding: 15px 24px;
`;

const CustomerNo = styled(AccountInformationText)`
  font-size: 15px;
  line-height: 18px;
`;

const AccountName = styled(AccountInformationText)`
  opacity: 0.66;
`;

interface CustomerProps {
  contentStore: ContentStore;
  accountStore: AccountStore;
}

const CustomerInformation: FC<CustomerProps> = (props) => {
  const { contentStore, accountStore } = props;
  const { userStore, manageUserAccessStore } = contentStore.rootStore;

  const content = (
    <Content>
      <Row align="middle" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)' }}>
        <Col>
          <Row>
            <AccountName>{userStore.currentAccount?.name}</AccountName>
          </Row>
          <Row>
            <CustomerNo>{userStore.currentAccount?.customerNumber}</CustomerNo>
          </Row>
        </Col>
        {!manageUserAccessStore.isPrivateCustomer() && (
          <Col>
            <ArrowIcon />
          </Col>
        )}
      </Row>
    </Content>
  );

  const CustomerInformationContent =
    userStore.person?.accountIds?.length! > 1 && !manageUserAccessStore.isPrivateCustomer() ? (
      <Tooltip
        placement="top"
        title={contentStore.cmsStore.getContent('ChangeAccount')?.tooltip}
        color="#2d2d2d"
        overlayInnerStyle={overlayInnerStyle}
      >
        <WrapperMultipleAccounts
          onClick={() => accountStore.setIsChangeAccountOpen(!accountStore.isChangeAccountOpen)}
          isChangeAccountOpen={accountStore.isChangeAccountOpen}
        >
          {content}
        </WrapperMultipleAccounts>
      </Tooltip>
    ) : (
      <WrapperOneAccount>{content}</WrapperOneAccount>
    );

  return CustomerInformationContent;
};

export default observer(CustomerInformation);
