/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { Filters, FilterMenuContent, FilterObject } from '../../Types';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export type OtherTransactionsTableFilter = {
  dates: FilterObject[];
};

export default class OtherTransactionFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setOtherTransactionsFilterContent() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: OtherTransactionsTableFilter = {
    dates: []
  };

  temporaryFilters: OtherTransactionsTableFilter = {
    dates: []
  };

  otherTransactionsSearchVal: string = '';

  fromDate: Date | null = null;

  toDate: Date | null = null;

  requestParams: {
    accountId: string;
    searchString?: string | undefined;
    fromDate?: string | undefined;
    toDate?: string | undefined;
    pageNumber?: number | undefined;
  } = {
    accountId: ''
  };

  setRequestParams = () => {
    this.requestParams.searchString = this.otherTransactionsSearchVal;
    this.requestParams.pageNumber = this.rootStore.transactionsStore.otherPageNumber;
    this.requestParams.fromDate = this.fromDate
      ? this.fromDate?.toLocaleString('en-US')
      : undefined;
    this.requestParams.toDate = this.toDate ? this.toDate?.toLocaleString('en-US') : undefined;
  };

  setFromDate = async (fromDate: Date) => {
    this.fromDate = fromDate;
  };

  setToDate = (toDate: Date) => {
    this.toDate = toDate;
  };

  clearDateFilters = () => {
    this.fromDate = null;
    this.toDate = null;
  };

  setotherTransactionsSearchVal = (searchString: string) => {
    this.otherTransactionsSearchVal = searchString;
    this.rootStore.transactionsStore.setOtherCurrentPage(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.transactionsStore.setOtherCurrentPage(1);
  };

  setTemporaryFilters = (filters: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filters));
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      allMenus: observable,
      activeFilters: observable,
      otherTransactionsSearchVal: observable,
      temporaryFilters: observable,
      fromDate: observable,
      toDate: observable,
      setFromDate: action,
      setToDate: action,
      clearDateFilters: action,
      setotherTransactionsSearchVal: action,
      setActiveFilters: action
    });
  }
}
