/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import {
  IdentifierService,
  PaymentMethod,
  PaymentMethodService,
  PaymentType
} from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export enum Currency {
  DKK = 'DKK'
}

export default class PaymentCardStore extends BaseStore {
  createPaymentCardUrl?: string;

  newPaymentCardHandleId?: string;

  paymentMethod?: string;

  paymentMethods: PaymentMethod[] = [];

  deletePaymentCard = async (paymentCardId: string) => {
    await PaymentMethodService.deletePaymentAgreementById({
      customerId: this.rootStore.userStore.currentAccount?.id!,
      paymentAgreementId: paymentCardId
    });

    return this.rootStore.administrationStore.administrationPaymentCardsStore.getValidPaymentCardsOwnedByLoggedInPerson();
  };

  // eslint-disable-next-line class-methods-use-this
  makeProductsOnPaymentCardInactive = (paymentCardId: string) => {
    const promise = PaymentMethodService.makeProductsOnPaymentCardInactive({
      paymentMethodId: paymentCardId
    });
    return promise;
  };

  setPaymentMethod = (paymentMethod?: string) =>
    new Promise((resolve) => {
      this.paymentMethod = paymentMethod;
      resolve(paymentMethod);
    });

  getPaymentMethodsByAccountId = () => {
    const promise = PaymentMethodService.getPaymentMethodsByAccountId({
      accountId: this.rootStore.userStore.currentAccount?.id!
    }).then((result) => {
      this.paymentMethods = result;
    });
    return promise;
  };

  getCreatePaymentCardUrl = () => {
    const currentBaseUrl = window.location.href.split('?')[0];
    const cancelUrl = currentBaseUrl;
    const acceptUrl = currentBaseUrl;
    const promise = PaymentMethodService.getPaymentMethodCreationUrlAsync({
      cancelUrl,
      acceptUrl,
      currency: Currency.DKK
    });
    promise.then((response) => {
      this.createPaymentCardUrl = response.url;
    });
    return promise;
  };

  getCreatePaymentCardUrlForExistingCustomer = (accountId?: string) => {
    const currentBaseUrl = window.location.href.split('?')[0];
    const cancelUrl = currentBaseUrl;
    const acceptUrl = currentBaseUrl;
    const promise = PaymentMethodService.getPaymentMethodCreationUrlForExistingCustomerAsync({
      cancelUrl,
      acceptUrl,
      currency: Currency.DKK,
      accountId
    });
    promise.then((response) => {
      this.createPaymentCardUrl = response.url;
    });
    return promise;
  };

  createOrUpdatePaymentCard = (
    newCardHandleId: string,
    account?: string,
    isNewCustomer?: boolean,
    oldPaymentMethodId?: string
  ) => {
    const accountId = account || this.rootStore.userStore.currentAccount?.id!;
    const paymentProviderTicket = newCardHandleId;
    const oldPaymentAgreement = oldPaymentMethodId;
    const createCardPromise = PaymentMethodService.createOrUpdatePaymentMethod({
      paymentProviderTicket,
      accountId,
      oldPaymentAgreement,
      isNewCustomer,
      paymentType: PaymentType.CreditCard
    });

    this.setNewPaymentCardHandleId(newCardHandleId);
    return createCardPromise;
  };

  // eslint-disable-next-line class-methods-use-this
  createInvoicePaymentMethod = (accountId: string) => {
    const promise = PaymentMethodService.createOrUpdatePaymentMethod({
      accountId,
      isNewCustomer: true,
      paymentType: PaymentType.Invoice
    });
    return promise;
  };

  updatePaymentCard = (oldCardId: string, newCardId: string) => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = IdentifierService.updatePaymentMethodReferencesOnIdentifiers({
      oldCardId,
      newCardId,
      accountId
    });
    return promise;
  };

  setPaymentCardOnProduct = (identifierId: string, paymentMethodId?: string) => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = IdentifierService.updatePaymentCardOnIdentifier({
      accountId,
      identifierId,
      paymentMethodId
    });
    promise.then(() => {
      this.rootStore.productStore.productItemsStore.getProducts();
    });
    return promise;
  };

  setNewPaymentCardHandleId = (newPaymentCardHandleId?: string) => {
    this.newPaymentCardHandleId = newPaymentCardHandleId;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      createPaymentCardUrl: observable,
      paymentMethods: observable,
      newPaymentCardHandleId: observable,
      createOrUpdatePaymentCard: action,
      createInvoicePaymentMethod: action,
      getCreatePaymentCardUrl: action,
      deletePaymentCard: action,
      makeProductsOnPaymentCardInactive: action,
      setPaymentCardOnProduct: action,
      updatePaymentCard: action,
      getPaymentMethodsByAccountId: action,
      setNewPaymentCardHandleId: action
    });
  }
}
