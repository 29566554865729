import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import useStore from '../../hooks/useStore';
import MenuStore from '../../stores/MenuStore';
import Menu from './Menu';
import useIsMobileView from '../../hooks/useIsMobileView';
import RouteUrlPaths from '../../RouteUrlPaths';

const MenuContainer: FC = () => {
  const menuStore: MenuStore = useStore(MenuStore);
  const { manageUserAccessStore } = menuStore.rootStore;
  const location = useLocation();
  const isMobile = useIsMobileView();

  const isPrivateDesktopFrontPage = () =>
    location.pathname === '/' && manageUserAccessStore.isPrivateCustomer() && !isMobile;

  const pagesWithoutMenuSider = () =>
    location.pathname === `/${RouteUrlPaths.SelectAccount}` ||
    location.pathname === `/${RouteUrlPaths.CreateCustomer}` ||
    manageUserAccessStore.isPrivateCustomerInDebtCollection() ||
    isPrivateDesktopFrontPage();

  const showMenuSider = () => {
    if (pagesWithoutMenuSider()) return false;
    if (!isMobile) return true;
    return menuStore.isMenuSiderOpen;
  };

  return showMenuSider() ? <Menu /> : null;
};

export default observer(MenuContainer);
