import React from 'react';
import { Row, Select } from 'antd';
import { ReactComponent as AddIcon } from '../../../../../../layout/icons/add24by24.svg';
import { GreenLinkText } from '../../../../../../layout/globalFonts';
import CMSStore from '../../../../../../stores/content-stores/CMSStore';

const { Option } = Select;
const addCreditCardOption = (addCreditCardKey: string, cmsStore: CMSStore) => (
  <Option key={addCreditCardKey}>
    <Row gutter={7}>
      <AddIcon />
      <GreenLinkText style={{ paddingLeft: '7px' }}>
        {cmsStore.getContent('AddCreditCard')?.content}
      </GreenLinkText>
    </Row>
  </Option>
);

export default addCreditCardOption;
