import React, { FC } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { OutlinedButton } from '../../common-components/Buttons';
import { ReactComponent as DownloadArrow } from '../../layout/icons/download.svg';
import useStore from '../../hooks/useStore';
import ContentStore from '../../stores/ContentStore';
import { overlayInnerStyle } from '../../common-components/TooltipStyling';

const StyledDownloadButton = styled(OutlinedButton)`
  cursor: pointer;
  padding: 12px;
  &:disabled {
    opacity: 0;
  }
  &:hover {
    opacity: 1;
  }
`;

interface DownloadButtonProps {
  downloadFunction: () => void;
}

const DownloadButton: FC<DownloadButtonProps> = (props) => {
  const { downloadFunction } = props;
  const contentStore: ContentStore = useStore(ContentStore);

  return (
    <Tooltip
      placement="bottomRight"
      title={contentStore.cmsStore.getContent('Download')?.tooltip}
      color="#2d2d2d"
      overlayInnerStyle={overlayInnerStyle}
    >
      <StyledDownloadButton onClick={downloadFunction} icon={<DownloadArrow />}>
        {' '}
      </StyledDownloadButton>
    </Tooltip>
  );
};

export default DownloadButton;
