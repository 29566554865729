import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useIsMobileView from '../../hooks/useIsMobileView';
import { ReactComponent as Logo } from '../../layout/logos/brobizz-logo-white.svg';
import { ReactComponent as User } from '../../layout/icons/menuSider/user.svg';
import { ReactComponent as LogOut } from '../../layout/icons/menuSider/logout.svg';
import { White15px475weight } from '../../layout/globalFonts';
import useStore from '../../hooks/useStore';
import MenuStore from '../../stores/MenuStore';
import RouteUrlPaths from '../../RouteUrlPaths';
import logOut from '../../utils/logOut';

const StyledHeader = styled.div`
  width: 100%;
  background: #193232;
  padding: 0 5%;
  height: 82px;
  line-height: 0px;
`;

const UserIcon = styled(User)<{ isopen: boolean }>`
  path {
    fill: ${({ isopen }) => (isopen ? '#193232' : '#fff')};
  }
`;

const LogOutIcon = styled(LogOut)`
  path {
    fill: #fff;
  }
`;

const MyProfileLink = styled(White15px475weight)<{ isopen: boolean }>`
  color: ${({ isopen }) => (isopen ? '#193232' : '#fff')};
`;

const FirstDiv = styled.div<{ isopen: boolean }>`
  transform: ${({ isopen }) => (isopen ? 'rotate(45deg)' : 'rotate(0)')};
`;

const SecondDiv = styled.div<{ isopen: boolean }>`
  opacity: ${({ isopen }) => (isopen ? '0' : '1')};
  transform: ${({ isopen }) => (isopen ? 'translateX(20px)' : 'translateX(0)')};
`;

const ThirdDiv = styled.div<{ isopen: boolean }>`
  transform: ${({ isopen }) => (isopen ? 'rotate(-45deg)' : 'rotate(0)')};
`;

const HeaderCol = styled(Col)`
  width: 71px;
`;

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  div {
    width: 2rem;
    height: 0.25rem;
    background: #00a050;
    border-radius: 5px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

const BurgerMenuHeader: FC = () => {
  const menuStore: MenuStore = useStore(MenuStore);
  const { cmsStore } = menuStore.rootStore.contentStore;
  const { authenticationStore, manageUserAccessStore } = menuStore.rootStore;
  const isMobileView = useIsMobileView();
  const debtCollection = manageUserAccessStore.isPrivateCustomerInDebtCollection();

  const myProfileIcon = (
    <div>
      <Row justify="center">
        <UserIcon isopen={menuStore.isMenuSiderOpen} />
      </Row>
      <Row justify="center" style={{ paddingTop: '5px' }}>
        <MyProfileLink isopen={menuStore.isMenuSiderOpen}>
          {cmsStore.getContent('myProfile')?.content}
        </MyProfileLink>
      </Row>
    </div>
  );

  const myProfile = menuStore.isMenuSiderOpen ? (
    myProfileIcon
  ) : (
    <Link
      to={{ pathname: RouteUrlPaths.MyProfile }}
      onClick={() => {
        menuStore.setIsMenuSiderOpen(false);
        menuStore.setSelectedMenuItem(RouteUrlPaths.MyProfile);
      }}
    >
      {myProfileIcon}
    </Link>
  );

  const logOutDiv = (
    <div>
      <Row justify="center" onClick={() => logOut(authenticationStore)}>
        <LogOutIcon />
      </Row>
      <Row justify="center" style={{ paddingTop: '5px' }}>
        <White15px475weight>{cmsStore.getContent('LogOut')?.content}</White15px475weight>
      </Row>
    </div>
  );

  return (
    <div>
      {isMobileView && (
        <StyledHeader>
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            {!debtCollection ? (
              <HeaderCol>
                <StyledBurger
                  onClick={() => menuStore.setIsMenuSiderOpen(!menuStore.isMenuSiderOpen)}
                >
                  <FirstDiv isopen={menuStore.isMenuSiderOpen} />
                  <SecondDiv isopen={menuStore.isMenuSiderOpen} />
                  <ThirdDiv isopen={menuStore.isMenuSiderOpen} />
                </StyledBurger>
              </HeaderCol>
            ) : (
              <Col flex="auto" />
            )}
            <Col flex="auto" />
            <Col>
              <Link
                to={{ pathname: RouteUrlPaths.Frontpage }}
                onClick={() => {
                  menuStore.setIsMenuSiderOpen(false);
                  menuStore.setSelectedMenuItem('');
                }}
              >
                <Logo />
              </Link>
            </Col>
            <Col flex="auto" />
            <HeaderCol>{debtCollection ? logOutDiv : myProfile}</HeaderCol>
          </Row>
        </StyledHeader>
      )}
    </div>
  );
};

export default observer(BurgerMenuHeader);
