import React, { FC } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import useStore from '../../../../hooks/useStore';
import { OutlinedButton } from '../../../../common-components/Buttons';
import { ReactComponent as Add } from '../../../../layout/icons/grey-add.svg';
import { ContentSmallCamptonBook } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import { overlayInnerStyle } from '../../../../common-components/TooltipStyling';

const StyledCreateButton = styled(OutlinedButton)`
  cursor: pointer;
  padding: 20px 10px 20px 10px;
  &:disabled {
    opacity: 0;
  }
  &:hover {
    opacity: 1;
  }
`;

const CreateSubAccountButton: FC = () => {
  const contentStore: ContentStore = useStore(ContentStore);
  const { createUserStore } = contentStore.rootStore.administrationStore;
  const createUser = contentStore.cmsStore.getContent('CreateUser');

  return (
    <Tooltip
      placement="bottom"
      title={createUser?.tooltip}
      color="#2d2d2d"
      overlayInnerStyle={overlayInnerStyle}
    >
      <StyledCreateButton onClick={() => createUserStore.openCreateUserModal()} icon={<Add />}>
        <ContentSmallCamptonBook style={{ paddingLeft: '15px' }}>
          {createUser?.content}
        </ContentSmallCamptonBook>
      </StyledCreateButton>
    </Tooltip>
  );
};

export default observer(CreateSubAccountButton);
