import { useEffect, useState } from 'react';
import ContentStore from '../stores/ContentStore';
import useStore from './useStore';
import { UserRole } from '../swagger/api';

const useContentLoader = (isPublicPage: boolean = false) => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const contentStore: ContentStore = useStore(ContentStore);
  const { cmsStore } = contentStore;
  const { userStore } = contentStore.rootStore;

  const isReadyToLoadContent = () =>
    isPublicPage ||
    (!!userStore.person &&
      (!!userStore.currentAccount ||
        userStore.person.role === UserRole.ProductAccess ||
        userStore.isSelectAccountShown ||
        userStore.person?.signUpInProgress));

  useEffect(() => {
    setIsContentLoaded(false);
    if (isReadyToLoadContent()) {
      Promise.all([cmsStore.getCMSErrorContent(), cmsStore.getCMSContent()]).then(() => {
        setIsContentLoaded(true);
      });
    }
  }, [userStore.person?.language, userStore.currentAccount?.accountPaymentType]);

  return isContentLoaded;
};
export default useContentLoader;
