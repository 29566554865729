/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import CustomModal from '../../../common-components/CustomModal';
import useStore from '../../../hooks/useStore';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { Locale } from '../../../stores/content-stores/CMSStore';
import logOut from '../../../utils/logOut';

const EmailNotVerifiedModal: FC = () => {
  const authenticationStore: AuthenticationStore = useStore(AuthenticationStore);
  const cmsLocale = authenticationStore.rootStore.contentStore.cmsStore.getCMSLocale();
  const header = cmsLocale === Locale.Danish ? 'Login afvist' : 'Login rejected';
  const primaryButtonText =
    cmsLocale === Locale.Danish ? 'Gå tilbage til Log ind' : 'Go back to Log in';
  const onPrimaryButtonClick = () => {
    logOut(authenticationStore);
  };

  const message =
    cmsLocale === Locale.Danish
      ? 'Før du kan logge ind med din nye e-mailadresse, skal den verificeres. Vi har sendt dig en e-mail med et link til at bekræfte e-mailadressen.'
      : 'Before you can log in you need to verify your new e-mail address. We have sent you an e-mail with a link to confirm the e-mail address.';
  return (
    <CustomModal
      header={header}
      closable={false}
      contentText={message}
      primaryButtonText={primaryButtonText}
      showPrimaryButtonInMobileView
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
};

export default EmailNotVerifiedModal;
