/* eslint-disable import/no-cycle */
import { makeObservable, observable, action } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

// MenuItemTitle matches the names in hygraph
export enum MenuItemTitle {
  Frontpage = 'Frontpage',
  Products = 'Products',
  Usage = 'Usage',
  Payment = 'Payment',
  Order = 'DoOrder',
  Ordering = 'Ordering',
  Administration = 'Administration',
  MyProfile = 'myProfile',
  PaymentCard = 'PaymentCard',
  BizzAndLicenseplate = 'BizzAndLicensePlate',
  TransactionsAndUsage = 'TransactionAndUsage',
  LogOut = 'LogOut'
}

export default class MenuStore extends BaseStore {
  mobileWidth: number = 1000;

  isMenuSiderOpen: boolean = window.innerWidth > this.mobileWidth;

  isAnimationOpen: boolean = window.innerWidth > this.mobileWidth;

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  setIsMenuSiderOpen = async (isOpen: boolean) => {
    if (isOpen) {
      this.setIsAnimationOpen(isOpen);
      this.isMenuSiderOpen = isOpen;
    } else {
      this.setIsAnimationOpen(isOpen);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((r) => setTimeout(r, 300));
      this.isMenuSiderOpen = isOpen;
    }
  };

  selectedMenuItem: string | undefined = window.location.href.toLocaleLowerCase().split('/').pop();

  setSelectedMenuItem = (menuItem?: string) => {
    this.selectedMenuItem = menuItem;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      isMenuSiderOpen: observable,
      selectedMenuItem: observable,
      isAnimationOpen: observable,
      setIsMenuSiderOpen: action,
      setIsAnimationOpen: action
    });
  }
}
