/* eslint-disable import/no-cycle */
import { action, computed, makeObservable, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class ProductTableStore extends BaseStore {
  // productTableColumns, defaultColumns and activeColumns are initialized on CMS content load in the method setProductTableColumnNamesInit() of CMSStore.tsx
  productTableColumns: string[] = [];

  defaultColumns: string[] = [];

  activeColumns: string[] = [];

  isPersonalview: boolean = false;

  currentPage: number = 1;

  pageSize: number = 0;

  get getColumns() {
    return this.activeColumns;
  }

  changeView = (view: boolean) => {
    this.isPersonalview = view;
  };

  addColumn = (Column: string) => {
    this.activeColumns.push(Column);
  };

  removeColumn = (Column: string) => {
    this.activeColumns = this.activeColumns.filter((col) => col !== Column);
  };

  makeStandardView = () => {
    this.activeColumns = this.defaultColumns;
  };

  setCurrentPage = (pageNumber: number) => {
    this.currentPage = pageNumber;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      activeColumns: observable,
      currentPage: observable,
      getColumns: computed,
      makeStandardView: action,
      addColumn: action,
      removeColumn: action,
      setCurrentPage: action
    });
  }
}
