/* eslint-disable import/no-cycle */
import { FormInstance } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import {
  AddressLight,
  LanguageType,
  NewPerson,
  PaymentType,
  PrivateCustomer
} from '../swagger/api';
import getMkAddressObject from '../utils/getMkAddressObject';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import { Locale } from './content-stores/CMSStore';

export enum CreatePrivateUserSteps {
  EmailInfo = 1,
  AddressInfo = 2,
  PaymentInfo = 3,
  CreatePassword = 4
}

export default class CustomerCreationStore extends BaseStore {
  currentStep: CreatePrivateUserSteps = CreatePrivateUserSteps.EmailInfo;

  privateCustomer?: PrivateCustomer;

  privatePerson?: NewPerson;

  decreaseCurrentStep = () => {
    this.currentStep -= 1;
  };

  increaseCurrentStep = () => {
    this.currentStep += 1;
  };

  setPrivateCustomer = (form: FormInstance) => {
    const allValues = form.getFieldsValue(true);
    const cmsLocale = this.rootStore.contentStore.cmsStore.getCMSLocale();
    const languageIsoCode = cmsLocale === Locale.Danish ? 'DK' : 'US';
    const mkAddressObject: AddressLight = getMkAddressObject(
      form,
      this.rootStore.addressSelectorStore
    );
    const privateCustomer: PrivateCustomer = {
      streetName: mkAddressObject.addressLine1,
      cityName: mkAddressObject.city,
      zipCode: mkAddressObject.postalCode,
      countryId: mkAddressObject.countryId,
      paymentType: PaymentType.CreditCard,
      languageIsoCode,
      firstName: allValues.firstName,
      lastName: allValues.lastName,
      email: allValues.email,
      phoneNumber: allValues.phoneNumber
    };
    this.privateCustomer = privateCustomer;
  };

  setPrivatePerson = (form: FormInstance) => {
    const allValues = form.getFieldsValue(true);
    const cmsLocale = this.rootStore.contentStore.cmsStore.getCMSLocale();
    const language = cmsLocale === Locale.Danish ? LanguageType.Danish : LanguageType.English;
    const privatePerson: NewPerson = {
      firstName: allValues.firstName,
      lastName: allValues.lastName,
      email: allValues.email,
      confirmEmail: allValues.confirmEmail,
      phonePrefix: allValues.phonePrefix,
      phoneNumber: allValues.phoneNumber,
      subscribeNewsLetter: allValues.newsletterCheckbox || false,
      language
    };
    this.privatePerson = privatePerson;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentStep: observable,
      setPrivateCustomer: action,
      setPrivatePerson: action,
      decreaseCurrentStep: action,
      increaseCurrentStep: action
    });
  }
}
