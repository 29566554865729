import { Pagination } from 'antd';
import styled from 'styled-components';

export const BrobizzPagination = styled(Pagination)`
  .ant-pagination {
    margin: 50px;

    .ant-pagination-jump-next, .ant-pagination-jump-prev {
      pointer-events: none;
    }
    
    .ant-pagination-prev {
      margin-right: 50px;
    }

    .ant-pagination-next {
      margin-left: 50px;
    }

    justify-content: center;
  }

  .ant-pagination-item {
    border: none;
    font-family: Campton Book;
    font-size: 15px;
    font-weight: 500;
    &:hover a {
        color: #2d2d2d;
    }
  }

  .ant-pagination-item-active {
    border-bottom: medium solid #00a050;
    border-radius: 1px;
    a {
      color: #2d2d2d;
    }
  }

  .ant-pagination-item-link {
    font-size: 18px;
    border: none;
    background-color: #00a050;
    color: #ffffff;
    border-radius: 50%;
    &:hover {
      color: #ffffff;
      background-color: #03944c;
    }
    &:disabled {
      color: #ffffff;
      background-color: #00a050;
      opacity: 0.5;
      &:hover {
        color: #ffffff;
        background-color: #00a050;
        opacity: 0.5;
      }
  }

  .ant-pagination-item-ellipsis {
    font-size: 15px;
    letter-spacing: 0;
    color: #2d2d2d;
  }
`;

export default BrobizzPagination;
