import { useEffect, useState } from 'react';

const useFetchListData = (dataProp: any[], fetchFunction: () => Promise<any>) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (dataProp.length === 0) {
      setIsLoading(true);
      fetchFunction().then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return isLoading;
};

export default useFetchListData;
