import { Col, Row } from 'antd';
import React, { FC } from 'react';
import ProductStatusIcon from '../../../common-components/ProductStatusIcon';
import { ContentSmallCamptonBookWeight475 } from '../../../layout/globalFonts';
import ContentStore from '../../../stores/ContentStore';
import { IdentifierStatus } from '../../../swagger/api';

interface ProductStatusAndTextProps {
  productStatus: IdentifierStatus;
  contentStore: ContentStore;
  customTextSystemId?: string;
  isBlockedTextRed?: boolean;
}

const ProductStatusAndText: FC<ProductStatusAndTextProps> = (props) => {
  const { productStatus, contentStore, customTextSystemId, isBlockedTextRed } = props;

  const isEconomyBlocked = contentStore.rootStore.manageUserAccessStore.isEconomyBlocked();

  const statusText = () => {
    if (customTextSystemId) {
      return customTextSystemId;
    }
    if (isEconomyBlocked) {
      return 'EconomyBlockedIdentifier';
    }
    switch (productStatus) {
      case IdentifierStatus.Active:
        return 'ActiveProduct';
      case IdentifierStatus.BizzOnItsWay:
        return 'BizzOnItsWay';
      case IdentifierStatus.Pending:
        return 'PrivateBizzPending';
      case IdentifierStatus.CreditCardExpiresSoon:
        return 'PaymentCardExpiresSoon';
      case IdentifierStatus.Blocked:
        return 'ProductStatusBlocked';
      case IdentifierStatus.UnpaidInvoices:
        return 'MissingPaymentOnIdentifier';
      case IdentifierStatus.IssuesWithCreditCard:
        return 'IssusesWithCreditCard';
      case IdentifierStatus.Inactive:
        return 'ProductStatusInactive';
      default:
        return '';
    }
  };

  return (
    <Row gutter={8} wrap={false}>
      <Col>
        <ProductStatusIcon productStatus={productStatus} isEconomyBlocked={isEconomyBlocked} />
      </Col>
      <Col>
        <ContentSmallCamptonBookWeight475
          customColor={
            productStatus ===
              (IdentifierStatus.Blocked ||
                IdentifierStatus.IssuesWithCreditCard ||
                IdentifierStatus.UnpaidInvoices) && isBlockedTextRed
              ? '#ff004a'
              : '#747474'
          }
        >
          {contentStore.cmsStore.getContent(statusText())?.content}
        </ContentSmallCamptonBookWeight475>
      </Col>
    </Row>
  );
};

export default ProductStatusAndText;

ProductStatusAndText.defaultProps = {
  customTextSystemId: undefined,
  isBlockedTextRed: false
};
