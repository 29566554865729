import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import UserStore from '../../stores/UserStore';
import { AccountInfoLight } from '../../swagger/api';
import AccountCard from './component/AccountCard';
import { GreenLinkText } from '../../layout/globalFonts';
import ContentStore from '../../stores/ContentStore';
import LoadingSpinner from '../../common-components/LoadingSpinner';

const ShowMoreRow = styled(Row)`
  padding: 20px 20px;
  :hover {
    cursor: pointer;
  }
`;

interface AccountCardListProps {
  userStore: UserStore;
  contentStore: ContentStore;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountCardList: FC<AccountCardListProps> = (props) => {
  const { userStore, contentStore, isLoading, setIsLoading } = props;
  const [defaultAccountId, setDefaultAccountId] = useState(userStore.defaultAccountId);

  useEffect(() => {
    userStore.accounts = [];
    userStore.accountPageNumber = 1;
    setIsLoading(true);
    userStore.getAccountInfoLight().finally(() => setIsLoading(false));
  }, []);

  const selectedAccount: AccountInfoLight | undefined = userStore.accounts?.find(
    (account) => account.id === userStore.currentAccount?.id
  );

  const sortedAccounts: AccountInfoLight[] | undefined = userStore.accounts
    ?.slice()
    .sort((a: AccountInfoLight, b: AccountInfoLight) =>
      a === selectedAccount ? -1 : b === selectedAccount ? 1 : 0
    );

  const accountComponents = sortedAccounts?.map((account) => (
    <AccountCard
      key={account.id!}
      companyName={account.name!}
      number={account.customerNumber!}
      accountId={account.id!}
      isDefaultAccount={defaultAccountId === account.id}
      setDefaultAccountId={setDefaultAccountId}
    />
  ));

  function showMoreListItems(): void {
    userStore.accountPageNumber += 1;
    setIsLoading(true);
    userStore.getAccountInfoLight().finally(() => setIsLoading(false));
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {accountComponents}
      {userStore.accounts.length < userStore.accountFilteredCount! && (
        <ShowMoreRow justify="center">
          <GreenLinkText onClick={() => showMoreListItems()}>
            {contentStore.cmsStore.getContent('showMore')?.content}
          </GreenLinkText>
        </ShowMoreRow>
      )}
    </>
  );
};
export default observer(AccountCardList);
