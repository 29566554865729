import React, { FC } from 'react';
import { Col, Row, Tooltip } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { TabsPanel } from '../../common-components/Tabs';
import PageHeader from './PageHeader';
import PageSubHeader from './PageSubHeader';
import TableFilter from '../../features/table-filter/TableFilter';
import SearchBar from '../../features/search-bar/SearchBar';
import FilterTags from '../../features/table-filter/components/FilterTags';
import TableCounter from '../../features/table-features/TableCounter';
import { FilterMenuContent, Filters } from '../../Types';
import DownloadButton from '../../features/download-featues/DownloadButton';
import CreateSubAccountModal from '../../features/administration-features/sub-account-creation-feature/CreateSubAccountModal';
import AdministrationStore from '../../stores/AdministrationStore';
import useStore from '../../hooks/useStore';
import CreateSubAccountButton from '../../features/administration-features/components/CreateSubAccountButton';
import CreateUserButton from '../../features/administration-features/create-user/components/CreateUserButton';
import CreateUserModal from '../../features/administration-features/create-user/CreateUserModal';
import { overlayInnerStyle } from '../../common-components/TooltipStyling';
import { AdministrationTab } from '../../stores/adminstration-stores/AdministrationTabStore';
import PrivateNotificationBar from '../../features/notifications/PrivateNotificationBar';
import { Notification } from '../../swagger/api';
import { ColorScheme } from '../colorScheme';
import { setPageColor } from '../../utils/wrapperAppFunctions';
import { Campton10pxWeight550 } from '../globalFonts';

const { TabPane } = TabsPanel;

const StyledContent = styled.div`
  padding: 0 3% 0 3%;
  background-color: ${ColorScheme.White};
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-bottom: 1%;
`;

const SearchAndFilterRow = styled(Row)`
  margin-top: 2%;
  margin-bottom: 9%;
`;

const TagsRow = styled(Row)`
  margin-top: -9%;
  margin-bottom: 4%;
`;

const NotificationCircle = styled.div`
  background: #ff004a;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  font-size: 10px;
`;

const NotificationCircleText = styled(Campton10pxWeight550)`
  color: #fff;
  line-height: 0px;
`;

interface MultiTablePageLayoutProps {
  pageName: string;
  subText?: React.ReactNode;
  table: React.ReactNode;
  tableCount?: number;
  filteredTableCount?: number;
  entityInTotal?: string;
  filterMenus?: FilterMenuContent;
  activeFilters?: Filters;
  temporaryFilters?: Filters;
  primaryTableName?: string;
  primaryTabToolTip: string;
  secondaryTabToolTip: string;
  secondaryTabCount?: React.ReactNode;
  tertiaryTabToolTip?: string;
  quaternaryTabToolTip?: string;
  secondaryTableName?: string;
  tertiaryTableName?: string;
  quaternaryTableName?: string;
  searchValue?: string;
  activeTab: number;
  searchPlaceholder?: string;
  isAdminPageFrameAdminAndParentAccount?: boolean;
  showCreateUserOnUserTab?: boolean;
  tertiaryTabContent?: React.ReactNode;
  toDate?: Date | null;
  fromDate?: Date | null;
  notification?: Notification;
  missingPaymentNotification?: React.ReactNode;
  setToDate?: (toDate: Date) => void;
  setFromDate?: (fromDate: Date) => void;
  setActiveTab: (activeTab: number) => void;
  download?: () => void;
  setSearchFunc?: (val: string) => void;
  setActiveFilters?: (temporaryFilters: Filters) => void;
  setTemporaryFilters?: (filters: Filters) => void;
  searchFunction?: () => void;
}

const MultiTablePageLayout: FC<MultiTablePageLayoutProps> = (props) => {
  const {
    pageName,
    table,
    tableCount,
    filteredTableCount,
    entityInTotal,
    primaryTableName,
    secondaryTableName,
    tertiaryTableName,
    quaternaryTableName,
    primaryTabToolTip,
    secondaryTabToolTip,
    secondaryTabCount: secondaryTabCounter,
    tertiaryTabToolTip,
    quaternaryTabToolTip,
    filterMenus,
    activeFilters,
    temporaryFilters,
    searchValue,
    activeTab,
    isAdminPageFrameAdminAndParentAccount,
    showCreateUserOnUserTab,
    searchPlaceholder,
    tertiaryTabContent,
    toDate,
    fromDate,
    notification,
    missingPaymentNotification,
    subText,
    setFromDate,
    setToDate,
    setActiveFilters,
    setTemporaryFilters,
    setActiveTab,
    download,
    setSearchFunc,
    searchFunction
  } = props;
  setPageColor();
  const administrationStore: AdministrationStore = useStore(AdministrationStore);
  const { administrationAccountStore, createUserStore } = administrationStore;
  const { contentStore } = administrationStore.rootStore;
  const showDownloadButton = () =>
    download &&
    administrationStore.administrationTabStore.activeTab !== AdministrationTab.PaymentCardsTab;

  const tabContent = (
    <>
      {searchPlaceholder && setSearchFunc && (
        <SearchAndFilterRow gutter={16}>
          <Col flex="auto">
            {searchFunction && (
              <SearchBar
                searchVal={searchValue!}
                setSearchVal={setSearchFunc}
                searchPlaceholder={searchPlaceholder}
                searchFunction={searchFunction}
              />
            )}
          </Col>
          {isAdminPageFrameAdminAndParentAccount && (
            <Col>
              <CreateSubAccountButton />
            </Col>
          )}
          {showCreateUserOnUserTab && (
            <Col>
              <CreateUserButton />
            </Col>
          )}
          {setActiveFilters &&
            setTemporaryFilters &&
            filterMenus &&
            temporaryFilters &&
            activeFilters && (
              <Col>
                <TableFilter
                  allMenus={filterMenus}
                  activeFilters={activeFilters}
                  temporaryFilters={temporaryFilters}
                  setActiveFilters={setActiveFilters}
                  setTemporaryFilters={setTemporaryFilters}
                  toDate={toDate}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              </Col>
            )}
        </SearchAndFilterRow>
      )}
      {activeFilters &&
        !Object.values(activeFilters).every((filterType) => filterType.length === 0) && (
          <TagsRow>
            {activeFilters && setActiveFilters && (
              <FilterTags activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            )}
          </TagsRow>
        )}
      <StyledRow align="middle">
        <Col>
          {filteredTableCount && entityInTotal && tableCount && (
            <TableCounter
              totalCount={tableCount}
              filteredCount={filteredTableCount}
              entityInTotal={entityInTotal}
            />
          )}
        </Col>
        <Col flex="auto" />
        <Col>{showDownloadButton() && <DownloadButton downloadFunction={download!} />}</Col>
      </StyledRow>
      <StyledRow>
        <Col span={24}>{table}</Col>
      </StyledRow>
    </>
  );

  return (
    <StyledContent>
      {missingPaymentNotification}
      {notification && (
        <PrivateNotificationBar notification={notification} cmsStore={contentStore.cmsStore} />
      )}
      <PageHeader pageName={pageName} />
      {subText && <PageSubHeader subText={subText} />}
      <TabsPanel
        onChange={(clickedTab: string) => setActiveTab(parseInt(clickedTab, 10))}
        activeKey={activeTab.toString()}
      >
        {primaryTableName && (
          <TabPane
            tab={
              <Tooltip
                placement="bottom"
                title={primaryTabToolTip}
                color="#2d2d2d"
                overlayInnerStyle={overlayInnerStyle}
              >
                {primaryTableName}
              </Tooltip>
            }
            key="1"
          >
            {tabContent}
          </TabPane>
        )}
        {secondaryTableName && (
          <TabPane
            tab={
              <>
                <Tooltip
                  placement="bottom"
                  title={secondaryTabToolTip}
                  color="#2d2d2d"
                  overlayInnerStyle={overlayInnerStyle}
                >
                  {secondaryTableName}
                </Tooltip>
                {secondaryTabCounter && (
                  <NotificationCircle>
                    <NotificationCircleText>{secondaryTabCounter}</NotificationCircleText>
                  </NotificationCircle>
                )}
              </>
            }
            key="2"
          >
            {tabContent}
          </TabPane>
        )}
        {tertiaryTableName && (
          <TabPane
            tab={
              <Tooltip
                placement="bottom"
                title={tertiaryTabToolTip}
                color="#2d2d2d"
                overlayInnerStyle={overlayInnerStyle}
              >
                {tertiaryTableName}
              </Tooltip>
            }
            key="3"
          >
            {tertiaryTabContent}
          </TabPane>
        )}
        {quaternaryTableName && (
          <TabPane
            tab={
              <Tooltip
                placement="bottom"
                title={quaternaryTabToolTip}
                color="#2d2d2d"
                overlayInnerStyle={overlayInnerStyle}
              >
                {quaternaryTableName}
              </Tooltip>
            }
            key="4"
          >
            {tabContent}
          </TabPane>
        )}
      </TabsPanel>
      {administrationAccountStore.isModalVisible && <CreateSubAccountModal />}
      {createUserStore.isCreateUserModalVisible && <CreateUserModal />}
    </StyledContent>
  );
};

MultiTablePageLayout.defaultProps = {
  download: undefined,
  filteredTableCount: undefined,
  entityInTotal: undefined,
  filterMenus: undefined,
  activeFilters: undefined,
  temporaryFilters: undefined,
  setActiveFilters: undefined,
  setTemporaryFilters: undefined,
  tableCount: undefined,
  primaryTableName: undefined,
  secondaryTableName: undefined,
  secondaryTabCount: undefined,
  tertiaryTableName: undefined,
  quaternaryTableName: undefined,
  isAdminPageFrameAdminAndParentAccount: false,
  showCreateUserOnUserTab: false,
  tertiaryTabContent: undefined,
  tertiaryTabToolTip: undefined,
  quaternaryTabToolTip: undefined,
  searchValue: undefined,
  searchPlaceholder: undefined,
  setSearchFunc: undefined,
  toDate: null,
  fromDate: null,
  setFromDate: undefined,
  setToDate: undefined,
  notification: undefined,
  subText: undefined,
  searchFunction: undefined,
  missingPaymentNotification: undefined
};

export default observer(MultiTablePageLayout);
