import { Row } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../common-components/Buttons';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import RouteUrlPaths from '../../../../RouteUrlPaths';
import ContentStore from '../../../../stores/ContentStore';
import TransactionsStore from '../../../../stores/TransactionsStore';

const ButtonRow = styled(Row)<{ ismobileview: boolean }>`
  margin: ${(props) => (props.ismobileview ? '25px 0px' : '50px 0px 35px 0px')};
`;

const StyledLink = styled(Link)`
  color: rgb(255, 255, 255) !important;
`;

interface GoToTransactionsAndUsageButtonRowProps {
  contentStore: ContentStore;
  transactionsStore: TransactionsStore;
}

const GoToTransactionsAndUsageButtonRow: FC<GoToTransactionsAndUsageButtonRowProps> = (props) => {
  const { contentStore, transactionsStore } = props;
  const isMobileView = useIsMobileView();

  return (
    <ButtonRow justify="center" ismobileview={isMobileView}>
      <PrimaryButton>
        <StyledLink
          to={{ pathname: `/${RouteUrlPaths.TransactionAndUsage}` }}
          onClick={() => {
            transactionsStore.setActiveTab(1);
            window.scrollTo({ top: 0 });
            contentStore.rootStore.productStore.productCardStore.closeModal();
            contentStore.rootStore.menuStore.setSelectedMenuItem(RouteUrlPaths.TransactionAndUsage);
          }}
        >
          {contentStore.cmsStore.getContent('GoToTransactionsAndUsage')?.content}
        </StyledLink>
      </PrimaryButton>
    </ButtonRow>
  );
};

export default GoToTransactionsAndUsageButtonRow;
