import { Col, Row } from 'antd';
import React, { FC } from 'react';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import { PaymentMethod } from '../../../../swagger/api';
import { ReactComponent as Arrow } from '../../../../layout/icons/arrow-right.svg';
import { openSnackbar } from '../../../notifications/Snackbar';
// eslint-disable-next-line import/no-cycle
import PaymentCardCard from '../../../product-features/product-card/components/PaymentCardCard';

interface PaymentCardUpdatedModalProps {
  contentStore: ContentStore;
  oldPaymentCard: PaymentMethod;
  newPaymentCard: PaymentMethod;
  closeModal: () => void;
}
const PaymentCardUpdatedModal: FC<PaymentCardUpdatedModalProps> = (props) => {
  const { contentStore, oldPaymentCard, newPaymentCard, closeModal } = props;

  const snackbarText = contentStore.cmsStore
    .getContent('PaymentCardAdded')
    ?.content!.replace('{maskedNumbers}', newPaymentCard.cardNumber!);

  return (
    <CustomModal
      header={contentStore.cmsStore.getContent('PaymentCardUpdated')?.content!}
      closable
      primaryButtonText={contentStore.cmsStore.getContent('Ok')?.content}
      onPrimaryButtonClick={() => {
        closeModal();
        openSnackbar(snackbarText!);
      }}
    >
      <Row justify="center" wrap={false} style={{ margin: '40px -4px 42px' }}>
        <Col>
          <PaymentCardCard contentStore={contentStore} paymentMethod={oldPaymentCard} />
        </Col>
        <Col>
          <Arrow style={{ margin: '26px 18px 0px 18px' }} />
        </Col>
        <Col>
          <PaymentCardCard contentStore={contentStore} paymentMethod={newPaymentCard} />
        </Col>
      </Row>
    </CustomModal>
  );
};
export default PaymentCardUpdatedModal;
