import React, { FC, useState } from 'react';
import { Row, Form } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../common-components/CustomModal';
import SiblingaccountDropdown from '../SiblingaccountDropdown';
import { openSnackbar } from '../../../notifications/Snackbar';
import useStore from '../../../../hooks/useStore';
import { ProductInfo } from '../../../../swagger/api/index';
import AccountStore from '../../../../stores/AccountStore';
import { SmallGrey12 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';

const SelectorRow = styled(Row)`
  margin-bottom: 11.4%;
`;

interface MoveProductToSiblingaccountModalProps {
  closeModal: () => void;
  product: ProductInfo;
}

const MoveProductToSiblingaccountModal: FC<MoveProductToSiblingaccountModalProps> = (props) => {
  const { closeModal, product } = props;
  const accountStore: AccountStore = useStore(AccountStore);
  const contentStore: ContentStore = useStore(ContentStore);
  const [form] = Form.useForm();
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);

  const header = contentStore.cmsStore.getContent('MoveToAnotherAccountModal')?.content!;
  const content = contentStore.cmsStore.getContent('MoveToAnotherAccountModal')?.subText!;
  const primaryButtonText = contentStore.cmsStore.getContent('MoveToSubAccount')?.content;
  const greenLinkText = contentStore.cmsStore.getContent('Undo')?.content;

  const moveIdentifierToAccount = (accountId: string) => {
    const accountMovedText = contentStore.cmsStore.getContent('ProductHasBeenMoved')?.content!;
    const identifierId = product.identifierId!;
    accountStore
      .moveIdentifierToSiblingaccount(
        accountId, // the Siblingaccount it should be moved to.
        identifierId
      )
      .then(() => openSnackbar(accountMovedText));
    accountStore.setChosenSiblingaccount(undefined);
    closeModal();
  };

  return (
    <Form
      form={form}
      onFieldsChange={() => {
        setIsPrimaryButtonDisabled(
          form.getFieldError('SelectSiblingAccount').length > 0 ||
            accountStore.chosenSiblingaccount === undefined
        );
      }}
    >
      <CustomModal
        header={header}
        contentText={content}
        primaryButtonText={primaryButtonText}
        closable
        greenLinkText={greenLinkText}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
        onGreenLinkClick={() => {
          accountStore.setChosenSiblingaccount(undefined);
          closeModal();
        }}
        onPrimaryButtonClick={() => moveIdentifierToAccount(accountStore.chosenSiblingaccount!.id!)}
        onCloseClick={() => {
          accountStore.setChosenSiblingaccount(undefined);
          closeModal();
        }}
      >
        <Row>
          <SmallGrey12>{contentStore.cmsStore.getContent('CustomerNr')?.content}</SmallGrey12>
        </Row>
        <SelectorRow justify="center">
          <SiblingaccountDropdown contentStore={contentStore} />
        </SelectorRow>
      </CustomModal>
    </Form>
  );
};
export default observer(MoveProductToSiblingaccountModal);
