/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { Auth0Client } from '@auth0/auth0-spa-js';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

export default class AuthenticationStore extends BaseStore {
  authContext?: Auth0Client;

  isAuthenticated: boolean = false;

  isAccessDenied: boolean = false;

  setAuthContext = (authContext: Auth0Client) => {
    this.authContext = authContext;
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;
  };

  setIsAccessDenied = (isAccessDenied: boolean) => {
    this.isAccessDenied = isAccessDenied;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      authContext: observable,
      isAccessDenied: observable,
      isAuthenticated: observable,
      setAuthContext: action,
      setIsAccessDenied: action,
      setIsAuthenticated: action
    });
  }
}
