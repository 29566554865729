import React from 'react';
import { notification } from 'antd';
import { ReactComponent as Check } from '../../layout/icons/check.svg';
import { ReactComponent as Close } from '../../layout/icons/remove-snackbar.svg';

import './Snackbar.css';

export const openSnackbar = (message: string) => {
  notification.open({
    message,
    placement: 'bottomLeft',
    className: 'snackbar',
    icon: <Check />,
    closeIcon: <Close />
  });
};

export default openSnackbar;
