import styled from 'styled-components';
import { ReactComponent as ArrowCollapsed } from '../layout/icons/small-arrow-collapsed.svg';
import { ReactComponent as SearchGreen } from '../layout/icons/search-green.svg';
import { ReactComponent as Remove } from '../layout/icons/remove.svg';

export const DropdownArrow = styled(ArrowCollapsed)`
  width: 13px;
`;

export const SearchGreenIcon = styled(SearchGreen)`
  cursor: pointer;
`;

export const RemoveIcon = styled(Remove)`
  cursor: pointer;
`;

export const ColoredCircleWithWhiteNumber = styled.div<{ color: string }>`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: text-top;
  margin-left: 10px;
  font-size: 10px;
  line-height: 18px;
`;

export default { DropdownArrow, SearchGreenIcon, RemoveIcon, ColoredCircleWithWhiteNumber };
