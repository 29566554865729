import React, { FC } from 'react';
import { Row, Col } from 'antd';
import {
  ContentSmallCamptonBook,
  ContentSmallCamptonBookGrey
} from '../../../../layout/globalFonts';
import { IdentifierStatus, ProductType } from '../../../../swagger/api/index';
import ProductStatusIcon from '../../../../common-components/ProductStatusIcon';
import useStore from '../../../../hooks/useStore';
import ContentStore from '../../../../stores/ContentStore';

interface ColumnProps {
  productStatus?: IdentifierStatus;
  identifierNumber?: string;
  productType?: ProductType;
}

const StatusProductAndId: FC<ColumnProps> = (props) => {
  const { productStatus, identifierNumber, productType } = props;
  const statusIcon = <ProductStatusIcon productStatus={productStatus} />;
  const contentStore: ContentStore = useStore(ContentStore);

  return (
    <>
      <Row align="middle" gutter={12}>
        <Col>{statusIcon}</Col>
        <Col>
          <ContentSmallCamptonBook>
            {contentStore.cmsStore.getContent(productType!)?.content}
          </ContentSmallCamptonBook>
          <br />
        </Col>
      </Row>
      <Row>
        <ContentSmallCamptonBookGrey>{identifierNumber}</ContentSmallCamptonBookGrey>
      </Row>
    </>
  );
};

export default StatusProductAndId;

StatusProductAndId.defaultProps = {
  productStatus: undefined,
  identifierNumber: undefined,
  productType: undefined
};
