import React, { FC } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { ReactComponent as PaymentCardIcon } from '../../../../layout/icons/payment-card.svg';
import { ReactComponent as RemoveIcon } from '../../../../layout/icons/circle-x-grey.svg';
import { PaymentMethod } from '../../../../swagger/api';
import ModalStore from '../../../../stores/ModalStore';
import useStore from '../../../../hooks/useStore';
import ContentStore from '../../../../stores/ContentStore';
import {
  ContentSmallCamptonBook,
  ContentSmallCamptonBookGrey
} from '../../../../layout/globalFonts';
// eslint-disable-next-line import/no-cycle
import RemovePaymentCardModal from '../modals/RemovePaymentCardModal';

interface PaymentCardCardProps {
  contentStore: ContentStore;
  paymentMethod: PaymentMethod;
  productId?: string;
}

const OutlineBox = styled.div`
  display: inline-block;
  background: #fff;
  border: 1px solid #969696;
  border-radius: 5px;
  min-height: 76px;
  padding: 16px;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  cursor: pointer;
  margin-top: -11px;
  margin-right: -11px;
`;

const PaymentCardCard: FC<PaymentCardCardProps> = (props) => {
  const { contentStore, paymentMethod, productId } = props;
  const modalStore: ModalStore = useStore(ModalStore);

  return (
    <OutlineBox>
      <Row wrap={false}>
        <Col>
          <PaymentCardIcon style={{ marginRight: '16px' }} />
        </Col>
        <Col>
          <Row>
            <ContentSmallCamptonBook style={{ whiteSpace: 'nowrap' }}>
              {paymentMethod.cardNumber}
            </ContentSmallCamptonBook>
          </Row>
          <Row>
            <ContentSmallCamptonBookGrey>
              {contentStore.cmsStore.getContent(paymentMethod.cardType!)?.content}
            </ContentSmallCamptonBookGrey>
          </Row>
        </Col>
        {productId && paymentMethod.isCreatedByCurrentUser && (
          <>
            <Col flex="auto" />
            <Col>
              <StyledRemoveIcon
                onClick={() =>
                  modalStore.openModal(
                    <RemovePaymentCardModal
                      contentStore={contentStore}
                      productId={productId}
                      paymentMethod={paymentMethod}
                      modalStore={modalStore}
                    />
                  )
                }
              />
            </Col>
          </>
        )}
      </Row>
    </OutlineBox>
  );
};

export default PaymentCardCard;

PaymentCardCard.defaultProps = {
  productId: undefined
};
