/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export enum OrderFlowSteps {
  ChooseAmount = 0,
  FillProductNow = 1,
  ChoosePayment = 2,
  Delivery = 3,
  ConfirmOrder = 4,
  Receipt = 5,
  ChooseBizzesToConvert = 6,
  FillUserInfo = 7
}

export enum PrivateOrderFlowSteps {
  Order = 0,
  ChooseDelivery = 1,
  Confirm = 2,
  Receipt = 3
}

export default class OrderFlowStepperStore extends BaseStore {
  stepCount: number = 0;

  setStepCount = (newStep: number) => {
    this.stepCount = newStep;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      stepCount: observable,
      setStepCount: action
    });
  }
}
