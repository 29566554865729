/* eslint-disable import/no-cycle */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { StyledInput } from '../../../common-components/Input';
import ContentStore from '../../../stores/ContentStore';
import { SmallGrey12, ContentSmallCamptonBook } from '../../../layout/globalFonts';
import { Filters } from '../../../Types';

const InputField = styled(StyledInput)`
  width: 270px;
  .ant-input {
    ::placeholder {
      color: #747474;
    }
  }
`;

const PaddedRow = styled(Row)`
  margin: 24px 16px;
`;

interface AmountInFilterProps {
  temporaryFilters: Filters;
  setTemporaryFilters: (filters: Filters) => void;
  contentStore: ContentStore;
}

const AmountInFilter: FC<AmountInFilterProps> = (props) => {
  const { temporaryFilters, setTemporaryFilters, contentStore } = props;
  const [amountValues, setAmountValues] = useState<string[]>(['', '']);
  const [isFilterSetEmptyFromThisComponent, setIsFilterSetEmptyFromThisComponent] = useState(false);
  const placeholder = contentStore.cmsStore.getContent('Enter')?.content;

  let amountField: string = '';
  let suffix: string = '';
  if (temporaryFilters.amounts !== undefined) {
    amountField = 'amounts';
    suffix = 'kr';
  } else if (temporaryFilters.weights !== undefined) {
    amountField = 'weights';
    suffix = 'kg';
  }

  useEffect(() => {
    const amountValuesFromTempFilter =
      temporaryFilters[amountField].length > 0
        ? (temporaryFilters[amountField][0].filter as string[])
        : isFilterSetEmptyFromThisComponent
        ? amountValues
        : ['', ''];
    setIsFilterSetEmptyFromThisComponent(false);
    setAmountValues(amountValuesFromTempFilter);
  }, [temporaryFilters]);

  const setNumberEvent = (e: ChangeEvent, isStartValue: boolean) => {
    const numberValue = (e.target as HTMLTextAreaElement).value;
    if (Number(numberValue) || numberValue === '') {
      const newAmountValues =
        isStartValue === true ? [numberValue, amountValues[1]] : [amountValues[0], numberValue];
      const copyOfTempFilter = JSON.parse(JSON.stringify(temporaryFilters));
      if (newAmountValues[0] === '' || newAmountValues[1] === '') {
        copyOfTempFilter[amountField] = [];
        setIsFilterSetEmptyFromThisComponent(true);
        setAmountValues(newAmountValues);
        setTemporaryFilters(copyOfTempFilter);
      } else if (temporaryFilters[amountField].length > 0) {
        copyOfTempFilter[amountField][0].filter = newAmountValues;
        setTemporaryFilters(copyOfTempFilter);
      } else {
        const filterObject = { text: '', filter: [newAmountValues[0], newAmountValues[1]] };
        copyOfTempFilter[amountField][0] = filterObject;
        setTemporaryFilters(copyOfTempFilter);
      }
    }
  };

  return (
    <PaddedRow gutter={[0, 20]}>
      <Row>
        <Row>
          <SmallGrey12 style={{ marginBottom: 12 }}>
            {contentStore.cmsStore.getContent('From')?.content}
          </SmallGrey12>
        </Row>
        <Row>
          <ContentSmallCamptonBook>
            <InputField
              suffix={suffix}
              placeholder={placeholder}
              onChange={(val) => setNumberEvent(val, true)}
              value={amountValues[0]}
            />
          </ContentSmallCamptonBook>
        </Row>
      </Row>
      <Row>
        <Row>
          <SmallGrey12>{contentStore.cmsStore.getContent('To')?.content}</SmallGrey12>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <ContentSmallCamptonBook>
            <InputField
              suffix={suffix}
              placeholder={placeholder}
              onChange={(val) => setNumberEvent(val, false)}
              value={amountValues[1]}
            />
          </ContentSmallCamptonBook>
        </Row>
      </Row>
    </PaddedRow>
  );
};

export default observer(AmountInFilter);
