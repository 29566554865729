import { Row, Col, Divider } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { MobileSmallGrey } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';

const TableTitleRow = styled(Row)`
  margin: 34px 0px 16px 0px;
`;

const DividerLine = styled(Divider)`
  margin: 0;
  background-color: #e0e0e0;
  height: 1px;
`;

const FlexEndColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding-right: 18px !important;
`;

const StyledCol = styled(Col)`
  padding-left: 0 !important;
`;

interface TransactionsAndUsageTableMobileColumnTitleRowProps {
  contentStore: ContentStore;
}

const TransactionsAndUsageTableMobileColumnTitleRow: FC<
  TransactionsAndUsageTableMobileColumnTitleRowProps
> = (props) => {
  const { contentStore } = props;
  const { transactionsStore } = contentStore.rootStore;
  return (
    <>
      <DividerLine />
      <TableTitleRow gutter={12} style={{ marginLeft: 0, marginRight: 0, paddingLeft: '18px' }}>
        <StyledCol span={5}>
          <MobileSmallGrey>
            {
              contentStore.cmsStore.getContent(
                transactionsStore.activeTab === 1 ? 'Date' : 'TransactionsAndUsageTableDueDate'
              )?.content
            }
          </MobileSmallGrey>
        </StyledCol>
        <StyledCol span={10}>
          <MobileSmallGrey>
            {contentStore.cmsStore.getContent('Description')?.content}
          </MobileSmallGrey>
        </StyledCol>
        <FlexEndColumn span={9}>
          <MobileSmallGrey>
            {contentStore.cmsStore.getContent('AmountAndStatus')?.content}
          </MobileSmallGrey>
        </FlexEndColumn>
      </TableTitleRow>
      <DividerLine />
    </>
  );
};

export default TransactionsAndUsageTableMobileColumnTitleRow;
