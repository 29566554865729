import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import { ProductType } from '../../../../swagger/api';
import { isBizz } from '../../../../utils/productTypeUtilities';
import RouteUrlPaths from '../../../../RouteUrlPaths';

interface ProductIsBlockedModalProps {
  contentStore: ContentStore;
  productType: ProductType;
}

const ProductIsBlockedModal: FC<ProductIsBlockedModalProps> = (props) => {
  const { contentStore, productType } = props;
  const navigate = useNavigate();

  const headerText = isBizz(productType)
    ? contentStore.cmsStore.getContent('PrivateBizzBlocked')?.content!
    : contentStore.cmsStore.getContent('PrivatePayByPlateBlocked')?.content!;
  const contentText = isBizz(productType)
    ? contentStore.cmsStore.getContent('PrivateBizzBlocked')?.subText!
    : contentStore.cmsStore.getContent('PrivatePayByPlateBlocked')?.subText!;
  const primaryButtonText = isBizz(productType)
    ? contentStore.cmsStore.getContent('BlockedBizzButton')?.content!
    : contentStore.cmsStore.getContent('BlockedPayByPlateButton')?.content!;
  const link = RouteUrlPaths.TransactionAndUsage;

  return (
    <CustomModal
      header={headerText}
      contentText={contentText}
      primaryButtonText={primaryButtonText}
      closable
      onPrimaryButtonClick={() => {
        navigate(link);
        contentStore.rootStore.transactionsStore.setActiveTab(2);
        contentStore.rootStore.modalStore.closeModal();
        contentStore.rootStore.productStore.productCardStore.closeModal();
      }}
    />
  );
};

export default ProductIsBlockedModal;
