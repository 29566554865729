import React, { FC, useState } from 'react';
import { Menu, Dropdown, Col } from 'antd';
import styled from 'styled-components';
import { ProductInfo } from '../../../../swagger/api';
import { ContentSmallCamptonBook, SmallGrey12 } from '../../../../layout/globalFonts';
import { DropdownArrow } from '../../../../common-components/StyledIcons';

const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;

const StyledMenuItem = styled(Menu.Item)`
  margin: 3px 8px;
  border-radius: 6px;
  padding-right: 75px;
  cursor: auto;
  &:hover {
    background-color: #fff;
  }
`;

const StyledDropdownArrow = styled(DropdownArrow)<{ isArrowOpen: boolean }>`
  transition: transform 0.1s ease 0s;
  margin-left: 12px;
  transform: ${(props) => (props.isArrowOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

interface PanDropdownProps {
  product: ProductInfo;
}

const PanDropdown: FC<PanDropdownProps> = (props) => {
  const { product } = props;
  const [isArrowOpen, setIsArrowopen] = useState(false);

  const panMenuItem = (panNumber: string, countries: string) => (
    <StyledMenuItem key={panNumber} onClick={() => setIsArrowopen(false)}>
      <SmallGrey12>{countries}</SmallGrey12>
      <br />
      <ContentSmallCamptonBook>{panNumber}</ContentSmallCamptonBook>
    </StyledMenuItem>
  );

  const productMenu = (
    <StyledMenu>
      {product.panNumbers &&
        Object.entries(product.panNumbers).map((panInfo: string[]) =>
          panMenuItem(panInfo[0], panInfo[1])
        )}
    </StyledMenu>
  );

  return product.panNumbers && Object.entries(product.panNumbers).length > 0 ? (
    <Dropdown
      overlay={productMenu}
      placement="bottomLeft"
      trigger={['click']}
      arrow={false}
      onVisibleChange={(isVisible) => setIsArrowopen(isVisible)}
    >
      <Col>
        <ContentSmallCamptonBook>{product.identifierNumber} </ContentSmallCamptonBook>
        <StyledDropdownArrow isArrowOpen={isArrowOpen} />
      </Col>
    </Dropdown>
  ) : (
    <ContentSmallCamptonBook>{product.identifierNumber} </ContentSmallCamptonBook>
  );
};

export default PanDropdown;
