import { Divider, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentSmallCamptonBook, H9CamptonBoldSemiBold19 } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import CustomerCreationStore from '../../../../stores/CustomerCreationStore';
import SubAccountInformation from './SubAccountInformation';

const SectionInfo = styled.div`
  width: 200px;
  margin-right: 150px;
`;

interface AdditionalHeaderProps {
  form: FormInstance;
  contentStore: ContentStore;
  customerStore: CustomerCreationStore;
}

const FormSection = styled.div`
  padding-top: 0px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 18px;
  margin-bottom: 30px;
`;

const StyledSubTitle = styled(ContentSmallCamptonBook)`
  line-height: 19px;
`;

const StyledTitle = styled(H9CamptonBoldSemiBold19)`
  margin-bottom: 10px;
`;

const AdditionalHeaderInformation: FC<AdditionalHeaderProps> = (props) => {
  const { form, contentStore, customerStore } = props;

  const companyCvrInfo = contentStore.cmsStore.getContent('CompanyCvrInfo')?.content;
  const companyInformation = contentStore.cmsStore.getContent('CompanyInformation')?.content;

  return (
    <Row>
      <StyledDivider />
      <Row>
        <SectionInfo>
          <StyledTitle>{companyInformation}</StyledTitle>
          <StyledSubTitle>{companyCvrInfo}</StyledSubTitle>
        </SectionInfo>
      </Row>
      <FormSection>
        <SubAccountInformation
          form={form}
          contentStore={contentStore}
          customerCreationStore={customerStore}
        />
      </FormSection>
      <StyledDivider />
    </Row>
  );
};

export default observer(AdditionalHeaderInformation);
