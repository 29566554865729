import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import ModalStore from '../../stores/ModalStore';
import useStore from '../../hooks/useStore';

const ModalManager: FC = () => {
  const modalStore: ModalStore = useStore(ModalStore);
  const modals = modalStore.visibleModals.map((modal: React.ReactNode) => modal);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{modals}</>;
};

export default observer(ModalManager);
