/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["requestToCloseAccount"] }] */

import { action, makeObservable, observable } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import {
  AccountService,
  Account,
  IdentifierService,
  OrderService,
  Person,
  PersonService,
  AddressService,
  AddressLight
} from '../swagger/api';

export default class AccountStore extends BaseStore {
  isAccountBlocked: boolean = false;

  siblingaccounts: Account[] = [];

  chosenSiblingaccount: Account | undefined = undefined;

  personsOnAccount: Person[] = [];

  isChangeAccountOpen: boolean = false;

  isAnimationOpen: boolean = false;

  setIsAnimationOpen = (isOpen: boolean) => {
    this.isAnimationOpen = isOpen;
  };

  setIsChangeAccountOpen = async (isOpen: boolean) => {
    if (isOpen) {
      this.setIsAnimationOpen(isOpen);
      this.isChangeAccountOpen = isOpen;
    } else {
      this.setIsAnimationOpen(isOpen);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((r) => setTimeout(r, 500));
      this.isChangeAccountOpen = isOpen;
    }
  };

  getPersonsForAccount = (accountId: string, fromAllSubAccounts: boolean) => {
    PersonService.getUsersAsync({ accountId, fromAllSubAccounts }).then((result: Person[]) => {
      this.personsOnAccount = result;
    });
  };

  checkIsAccountBlocked = (accountId: string) => {
    const promise = OrderService.isAccountBlockedAsync({ accountId });
    promise.then((result) => {
      this.isAccountBlocked = result;
    });

    return promise;
  };

  getSiblingAccounts = () => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = AccountService.getSiblingAccountsRelatedToCurrentUserAsync({ accountId });
    promise.then((result) => {
      this.siblingaccounts = result;
    });
    return promise;
  };

  setChosenSiblingaccount = (subaccount: Account | undefined) => {
    this.chosenSiblingaccount = subaccount;
  };

  moveIdentifierToSiblingaccount = (accountId: string, identifierId: string) => {
    const promise = IdentifierService.moveIdentifierToAccountAsync({
      accountId, // the subaccount it should be moved to.
      identifierId
    });
    promise.then(() => {
      this.rootStore.productStore.productItemsStore.getProducts();
    });
    return promise;
  };

  updateAddress = (mkAddressObject: AddressLight) => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = AddressService.updateAddressAsync({
      accountId,
      body: mkAddressObject
    });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      siblingaccounts: observable,
      chosenSiblingaccount: observable,
      personsOnAccount: observable,
      isChangeAccountOpen: observable,
      isAnimationOpen: observable,
      isAccountBlocked: observable,
      getPersonsForAccount: action,
      setChosenSiblingaccount: action,
      getSiblingAccounts: action,
      moveIdentifierToSiblingaccount: action,
      checkIsAccountBlocked: action,
      setIsChangeAccountOpen: action,
      setIsAnimationOpen: action
    });
  }
}
