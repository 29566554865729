import { Button } from 'antd';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
  font-weight: 550;
  color: #fff;
  background-color: #00a050;
  border-color: #00a050;
  border-radius: 100px;
  padding: 12px 36px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: unset;
  &:hover {
    background-color: #03944c;
    color: #fff;
    border-color: #03944c;
  }
  &:focus {
    color: #fff;
    background-color: #00a050;
    border-color: #00a050;
  }
  &:disabled {
    color: #fff;
    background-color: #00a050;
    opacity: 0.5;
    &:hover {
      color: #fff;
      background-color: #00a050;
      opacity: 0.5;
    }
  }
`;

export const RedPrimaryButton = styled(PrimaryButton)`
  background-color: #ff004a;
  border-color: #ff004a;

  &:hover {
    background-color: #ff004a;
    border-color: #ff004a;
  }
  &:focus {
    background-color: #ff004a;
    border-color: #ff004a;
  }
  &:disabled {
    background-color: #ff004a;
    &:hover {
      background-color: #ff004a;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  font-weight: 550;
  color: #00a050;
  background-color: #fff;
  border-color: #00a050;
  border-radius: 100px;
  padding: 25px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: #fff;
    color: #03944c;
    border-color: #03944c;
  }
  &:focus {
    color: #00a050;
    background-color: #fff;
    border-color: #00a050;
  }
  &:disabled {
    color: #00a050;
    border-color: #00a050;
    background-color: #fff;
    opacity: 0.5;
    &:hover {
      color: #00a050;
      background-color: #fff;
      opacity: 0.5;
    }
  }
`;

export const OutlinedButton = styled(Button)`
  --antd-wave-shadow-color: none;
  color: #2d2d2d;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 36px;
  height: 48px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  white-space: normal;
  border-radius: 5px;
  &:hover {
    background-color: #fff;
    color: #2d2d2d;
    border: 1px solid #818181;
    box-sizing: border-box;
    border-radius: 5px;
  }
  &:focus {
    color: #2d2d2d;
    background-color: #fff;
    border-color: #818181;
  }
  &:disabled {
    color: #2d2d2d;
    background-color: #fff;
    opacity: 0.5;
    &:hover {
      color: #2d2d2d;
      background-color: #fff;
      opacity: 0.5;
    }
  }
`;

export const OutlinedIconButton = styled(OutlinedButton)`
  padding: 12px 20px;
`;

export const IconButton = styled(PrimaryButton)`
  padding: 14px 20px;
`;

export const BackButton = styled(Button)`
  color: #00a050;
  background-color: #fff;
  border: none;
  border-radius: 100px;
  padding: 25px 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    color: #00a050;
  }
`;

export default { PrimaryButton, SecondaryButton, OutlinedButton, IconButton, OutlinedIconButton };
