import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import useStore from '../../../hooks/useStore';
import AccountStore from '../../../stores/AccountStore';
import ChangeAccount from './ChangeAccount';

const ChangeAccountContainer: FC = () => {
  const accountStore: AccountStore = useStore(AccountStore);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{accountStore.isChangeAccountOpen && <ChangeAccount accountStore={accountStore} />}</>;
};

export default observer(ChangeAccountContainer);
