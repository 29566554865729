import React, { FC } from 'react';
import { Row } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { BrobizzTable } from '../../../../common-components/BrobizzTable';
import ContentStore from '../../../../stores/ContentStore';
import { ProductInfo, SubAccountsListItem } from '../../../../swagger/api';
import usePagination from '../../../../hooks/usePagination';
import { ManchetContentCamptonBook22Grey } from '../../../../layout/globalFonts';
import CreateUserStore from '../../../../stores/adminstration-stores/CreateUserStore';
import StatusProductAndId from '../../../product-features/product-table/components/StatusProductAndId';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';

interface ProductAccessTableProps {
  contentStore: ContentStore;
  createUserStore: CreateUserStore;
  isLoading: boolean;
}

const ProductAccessTable: FC<ProductAccessTableProps> = (props) => {
  const { contentStore, createUserStore, isLoading } = props;

  const selectProduct = (record: SubAccountsListItem, selected: boolean) => {
    if (selected) {
      createUserStore.setSelectedProduct(record);
    } else {
      createUserStore.setSelectedProduct(undefined);
    }
  };

  const pagination = usePagination(
    createUserStore.productsPageNumber,
    createUserStore.filteredProductCount!,
    createUserStore.productsPageSize,
    createUserStore.setCurrentProductPage
  );

  const columns: ColumnsType<ProductInfo> = [
    {
      title: contentStore.cmsStore.getContent('Product&Id')?.content,
      width: 200,
      fixed: 'left',
      render: (row: ProductInfo) => (
        <StatusProductAndId
          productStatus={row.identifierStatus}
          identifierNumber={row.identifierNumber}
          productType={row.productType}
        />
      )
    },
    {
      title: contentStore.cmsStore.getContent('Reference')?.content,
      dataIndex: 'reference',
      width: 200
    },
    {
      title: contentStore.cmsStore.getContent('licensePlate')?.content,
      dataIndex: 'licensePlate',
      width: 200
    }
  ];

  const rowSelection = {
    selectedRowKeys: [createUserStore.selectedProductAccessProduct?.identifierId as React.Key],
    onSelect: selectProduct,
    hideSelectAll: true
  };

  return (
    <BrobizzTable
      dataSource={createUserStore.productAccessProducts}
      rowKey="identifierId"
      columns={columns}
      showSorterTooltip={false}
      pagination={pagination}
      scroll={{ x: true }}
      sticky
      rowSelection={rowSelection}
      loading={{
        spinning: isLoading,
        indicator: <LoadingSpinner />
      }}
      locale={{
        emptyText: (
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <ManchetContentCamptonBook22Grey>
              {contentStore.cmsStore.getContent('SearchYieldedNoResultsDescription')?.content}
            </ManchetContentCamptonBook22Grey>
          </Row>
        )
      }}
    />
  );
};

export default observer(ProductAccessTable);
