import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Form } from 'antd';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';
import ContentStore from '../../../stores/ContentStore';
import ModalStore from '../../../stores/ModalStore';
import ProductCardStore from '../../../stores/product-stores/ProductCardStore';
import { ProductInfo } from '../../../swagger/api';
import FullPageModal from '../../modals/FullPageModal';
import LocalServices from './components/LocalServices';
import ProductCardHeader from './components/ProductCardHeader';
import { ReactComponent as CloseIcon } from '../../../layout/icons/close-green.svg';
import VehicleInformationTile from './components/VehicleInformationTile';
import useIsMobileView from '../../../hooks/useIsMobileView';
import useStore from '../../../hooks/useStore';
import { RedPrimaryButton } from '../../../common-components/Buttons';
import ConfirmCloseProductModal from './modals/ConfirmCloseProductModal';
import GoToTransactionsAndUsageButtonRow from './components/GoToTransactionsAndUsageButtonRow';
import ProductCardTransactionsAndUsageTable from './components/ProductCardTransactionsAndUsageTable';
// eslint-disable-next-line import/no-cycle
import TransactionAndUsageCollapsable from './components/TransactionAndUsageCollapsable';
import { isANPR, isBizz } from '../../../utils/productTypeUtilities';

const GreySection = styled.div<{ ismobileview: boolean }>`
  position: relative;
  background: #efefef;
  ${(props) => (props.ismobileview ? 'padding: 3.82vh 20px' : 'padding: 3.82vh 10.65%;')}
`;

const SmallSection = styled.section<{ ismobileview: boolean }>`
  position: relative;
  background-color: #fff;
  padding: ${({ ismobileview }) => (ismobileview ? '30px' : '48px 56px')};
  border-radius: 8px;
  margin: 10px 0px;
  height: 90%;
`;

const TransactionsSection = styled.div<{ ismobileview: boolean }>`
  position: relative;
  background: #fff;
  margin-top: 7.47vh;
  ${(props) => (props.ismobileview ? 'padding: 0px 20px' : 'padding: 0px 10.65%;')}
`;

interface PrivateProductCardModalProps {
  product: ProductInfo;
  contentStore: ContentStore;
  productCardStore: ProductCardStore;
}

const PrivateProductCardModal: FC<PrivateProductCardModalProps> = (props) => {
  const { product, contentStore, productCardStore } = props;
  const modalStore: ModalStore = useStore(ModalStore);
  const { transactionsStore } = contentStore.rootStore;
  const [form] = Form.useForm();
  const isMobileView = useIsMobileView();

  const closeProductText = isBizz(product.productType)
    ? contentStore.cmsStore.getContent('CloseThisBizz')?.content
    : contentStore.cmsStore.getContent('CloseThisLicensePlate')?.content;

  const onCancelProductCardModal = () => {
    transactionsStore.identifierTransactions = [];
    productCardStore.closeModal();
  };

  return (
    <RemoveScroll>
      <FullPageModal
        visible={productCardStore.isModalVisible}
        footer={null}
        onCancel={() => onCancelProductCardModal()}
        maskClosable
        closeIcon={<CloseIcon />}
        transitionName="modalFade"
        isopen={productCardStore.isAnimationOpen}
        ismobileview={isMobileView}
      >
        <Form name="PrivateProductCardModal" initialValues={product} form={form}>
          <ProductCardHeader contentStore={contentStore} product={product} form={form} />
        </Form>
        <GreySection ismobileview={isMobileView}>
          <Row gutter={22}>
            {isANPR(product.productType) && (
              <Col xs={24} xl={8}>
                <SmallSection ismobileview={isMobileView}>
                  <VehicleInformationTile
                    contentStore={contentStore}
                    productInfo={product}
                    modalStore={modalStore}
                    productCardStore={productCardStore}
                  />
                </SmallSection>
              </Col>
            )}
            <Col xs={24} xl={8}>
              <SmallSection ismobileview={isMobileView}>
                <LocalServices contentStore={contentStore} product={product} />
              </SmallSection>
            </Col>
          </Row>
        </GreySection>
        {isMobileView ? (
          <TransactionAndUsageCollapsable contentStore={contentStore} productInfo={product} />
        ) : (
          <>
            <TransactionsSection ismobileview={isMobileView}>
              <ProductCardTransactionsAndUsageTable
                contentStore={contentStore}
                transactionsStore={transactionsStore}
                product={product}
              />
            </TransactionsSection>
            <GoToTransactionsAndUsageButtonRow
              contentStore={contentStore}
              transactionsStore={transactionsStore}
            />
          </>
        )}
        <GreySection ismobileview={isMobileView} style={{ margin: '0' }}>
          <Row justify="center">
            <RedPrimaryButton
              onClick={() =>
                modalStore.openModal(
                  <ConfirmCloseProductModal
                    contentStore={contentStore}
                    productInfo={product}
                    modalStore={modalStore}
                    productCardStore={productCardStore}
                  />
                )
              }
            >
              {closeProductText}
            </RedPrimaryButton>
          </Row>
        </GreySection>
      </FullPageModal>
    </RemoveScroll>
  );
};

export default observer(PrivateProductCardModal);
