/* eslint-disable import/no-cycle */
import BaseStore from './base-stores/BaseStore';
import ProductCardStore from './product-stores/ProductCardStore';
import ProductItemsStore from './product-stores/ProductItemsStore';
import ProductTableStore from './product-stores/ProductTableStore';
import ProductTypeStore from './product-stores/ProductTypeStore';
import ProductVehicleStore from './product-stores/ProductVehicleStore';

export default class ProductStore extends BaseStore {
  productItemsStore: ProductItemsStore = new ProductItemsStore(this.rootStore);

  productTableStore: ProductTableStore = new ProductTableStore(this.rootStore);

  productCardStore: ProductCardStore = new ProductCardStore(this.rootStore);

  productVehicleStore: ProductVehicleStore = new ProductVehicleStore(this.rootStore);

  productTypeStore: ProductTypeStore = new ProductTypeStore(this.rootStore);
}
