/* eslint-disable import/no-cycle */
import { action, makeObservable } from 'mobx';
import {
  AccountInfo,
  CreateCustomerServiceIncidentRequest,
  CustomerServiceService,
  Person
} from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import { DailyContactInformation } from '../features/administration-features/edit-daily-contact/EditDailyContactModal';

export default class CustomerServiceStore extends BaseStore {
  requestToCloseAccount = (accountNumber: string, deactivationReason: string) => {
    const body: CreateCustomerServiceIncidentRequest = {};
    body.subject = 'Anmodning om at lukke underkonto';
    body.firstName = this.rootStore.userStore.person?.firstName;
    body.lastName = this.rootStore.userStore.person?.lastName;
    body.email = this.rootStore.userStore.person?.email;
    body.customerNumber = accountNumber;
    body.files = [];
    body.description = `email: ${this.rootStore.userStore.person!.email},\nfirstName: ${
      this.rootStore.userStore.person!.firstName
    },\nlastName: ${
      this.rootStore.userStore.person?.lastName
    },\ncustomerNumberOfCustomerMakingRequest: ${accountNumber},\nRequest to close ${accountNumber}.\nReason: ${deactivationReason}`;
    const promise = CustomerServiceService.createCustomerServiceIncident({ body });
    return promise;
  };

  requestToChangeDailyContact = (dailyContactInformation: DailyContactInformation) => {
    const body: CreateCustomerServiceIncidentRequest = {};
    body.subject = 'Anmodning om at ændre kontaktoplysninger på den daglige kontaktperson';
    body.firstName = dailyContactInformation.name;
    body.email = dailyContactInformation.email;
    body.customerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    body.description = `email: ${dailyContactInformation.email},\nname: ${dailyContactInformation.name},\nphoneNumber: ${dailyContactInformation.phoneNumber},\ncurrentPersonEmail: ${this.rootStore.userStore.person?.email},\ncurrentPersonFirstName: ${this.rootStore.userStore.person?.firstName},\ncurrentPersonLastName: ${this.rootStore.userStore.person?.lastName},\ncustomerNumberOfCustomerMakingRequest: ${body.customerNumber}`;
    body.files = [];
    const promise = CustomerServiceService.createCustomerServiceIncident({ body });
    return promise;
  };

  requestToApproveInvoiceCustomer = (
    customerNumber: string,
    cvrNumber: string,
    companyName: string,
    expectMinimumSpendingAmount?: string
  ) => {
    const currentPerson = this.rootStore.userStore.person;
    const currentCustomerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    const body: CreateCustomerServiceIncidentRequest = {};
    body.firstName = currentPerson?.firstName;
    body.lastName = currentPerson?.lastName;
    body.email = this.rootStore.userStore.person?.email;
    body.files = [];
    body.subject = `Anmodning om fakturaaftale på kontoen med kundenummer: ${customerNumber}`;
    body.customerNumber = currentCustomerNumber;
    body.description = `currentPersonEmail: ${currentPerson?.email}, currentPersonFirstName: ${
      currentPerson?.firstName
    },\ncurrentPersonLastName: ${currentPerson?.lastName},\n${
      currentCustomerNumber !== undefined
        ? `customerNumberOfCustomerMakingRequest: ${currentCustomerNumber},\n`
        : ''
    }customerNumberOfCustomerToBeApproved: ${customerNumber},\nCvrNumberOfCustomerToBeApproved: ${cvrNumber},\ncompanyNameOfCustomerToBeApproved: ${companyName}${
      expectMinimumSpendingAmount !== undefined
        ? `,\nexpectMinimumSpendingAmount: ${expectMinimumSpendingAmount}`
        : ''
    }`;
    const promise = CustomerServiceService.createCustomerServiceIncident({ body });
    return promise;
  };

  requestToUpdateInvoiceReceiverResponsible = (invoiceRecipientEmail: string) => {
    const currentPerson = this.rootStore.userStore.person;
    const incidentBody: CreateCustomerServiceIncidentRequest = {};
    incidentBody.subject = 'Anmodning om at ændre e-mailadresse på fakturamodtager';
    incidentBody.firstName = currentPerson?.firstName;
    incidentBody.lastName = currentPerson?.lastName;
    incidentBody.email = currentPerson?.email;
    incidentBody.customerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    incidentBody.files = [];
    incidentBody.description = `email: ${currentPerson?.email},\nfirstName: ${currentPerson?.firstName},\nlastName: ${currentPerson?.lastName},\nnewEmail: ${invoiceRecipientEmail},\ncurrentPersonEmail: ${currentPerson?.email},\ncurrentPersonFirstName: ${currentPerson?.firstName},\ncurrentPersonLastName: ${currentPerson?.lastName},\ncustomerNumberOfCustomerMakingRequest: ${incidentBody.customerNumber}`;
    return CustomerServiceService.createCustomerServiceIncident({ body: incidentBody });
  };

  requestToUpdateContractManager(NewContractManager: Person) {
    const currentPerson = this.rootStore.userStore.person;
    const incidentBody: CreateCustomerServiceIncidentRequest = {};
    incidentBody.subject = 'Anmodning om at ændre kontaktoplysninger på kontraktansvarlig';
    incidentBody.firstName = NewContractManager.firstName;
    incidentBody.lastName = NewContractManager.lastName;
    incidentBody.email = NewContractManager.email;
    incidentBody.customerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    incidentBody.files = [];
    incidentBody.description = `firstName: ${NewContractManager.firstName},\nlastName: ${NewContractManager.lastName},\nemail: ${NewContractManager.email},\nphoneNumber: ${NewContractManager.phoneNumber},\ncurrentPersonEmail: ${currentPerson?.email},\ncurrentPersonFirstName: ${currentPerson?.firstName},\ncurrentPersonLastName: ${currentPerson?.lastName},\ncustomerNumberOfCustomerMakingRequest: ${incidentBody.customerNumber}`;
    return CustomerServiceService.createCustomerServiceIncident({ body: incidentBody });
  }

  requestToUpdateAccountInfo(NewAccountInfo: AccountInfo) {
    const currentPerson = this.rootStore.userStore.person;
    const incidentBody: CreateCustomerServiceIncidentRequest = {};
    incidentBody.subject = 'Anmodning om at ændre virksomhedsoplysninger';
    incidentBody.firstName = currentPerson?.firstName;
    incidentBody.lastName = currentPerson?.lastName;
    incidentBody.email = currentPerson?.email;
    incidentBody.customerNumber = this.rootStore.userStore.currentAccount?.customerNumber;
    incidentBody.files = [];
    incidentBody.description = `companyName: ${NewAccountInfo.companyName},\naddress: ${NewAccountInfo?.streetName},\nZip code: ${NewAccountInfo?.zipCode},\nCity: ${NewAccountInfo?.cityName},\nCountry: ${NewAccountInfo?.countryName},\nAddressLine2: ${NewAccountInfo?.addressLineSecond}`;
    return CustomerServiceService.createCustomerServiceIncident({ body: incidentBody });
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      requestToApproveInvoiceCustomer: action,
      requestToCloseAccount: action,
      requestToChangeDailyContact: action,
      requestToUpdateInvoiceReceiverResponsible: action,
      requestToUpdateContractManager: action,
      requestToUpdateAccountInfo: action
    });
  }
}
