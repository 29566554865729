import React, { FC, useState } from 'react';
import { Row } from 'antd';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import ModalStore from '../../../../stores/ModalStore';
import { ProductInfo } from '../../../../swagger/api';
import ConfirmCloseProductModal from './ConfirmCloseProductModal';
import ProductCardStore from '../../../../stores/product-stores/ProductCardStore';
import { ContentSmallCamptonBook } from '../../../../layout/globalFonts';

interface PublicCustomerWantsToEditAnprLicensePlateProps {
  contentStore: ContentStore;
  productInfo: ProductInfo;
  modalStore: ModalStore;
  productCardStore: ProductCardStore;
}

const PublicCustomerWantsToEditAnprLicensePlate: FC<
  PublicCustomerWantsToEditAnprLicensePlateProps
> = (props) => {
  const { contentStore, productInfo, modalStore, productCardStore } = props;
  const [isLoading, setIsloading] = useState(false);

  const headerText = contentStore.cmsStore.getContent(
    'YouWantToEditLicensePlateModalTitle'
  )?.content;
  const contentTextPartOne = contentStore.cmsStore.getContent(
    'YouWantToEditLicensePlateModalContent'
  )?.content;
  const contentTextPartTwo = contentStore.cmsStore.getContent(
    'YouWantToEditLicensePlateModalContent'
  )?.subText;
  const primaryButtonText = contentStore.cmsStore.getContent('YesCloseThisLicensePlate')?.content;

  const onPrimaryButtonClick = () => {
    setIsloading(true);
    modalStore.closeModal();
    modalStore.openModal(
      <ConfirmCloseProductModal
        contentStore={contentStore}
        productInfo={productInfo}
        modalStore={modalStore}
        productCardStore={productCardStore}
      />
    );
  };

  return (
    <CustomModal
      header={headerText}
      primaryButtonText={primaryButtonText}
      closable
      onPrimaryButtonClick={onPrimaryButtonClick}
      redPrimaryButton
      isLoading={isLoading}
    >
      <Row style={{ marginBottom: '27px', marginTop: '9.5px' }}>
        <ContentSmallCamptonBook>{contentTextPartOne}</ContentSmallCamptonBook>
      </Row>
      <Row style={{ marginBottom: '44px' }}>
        <ContentSmallCamptonBook>{contentTextPartTwo}</ContentSmallCamptonBook>
      </Row>
    </CustomModal>
  );
};

export default PublicCustomerWantsToEditAnprLicensePlate;
