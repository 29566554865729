/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { AccountInfo, AccountPaymentType, PaymentMethod, Person } from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';
import { DailyContactInformation } from '../../features/administration-features/edit-daily-contact/EditDailyContactModal';

export default class AccountInformationStore extends BaseStore {
  accountInfo?: AccountInfo = undefined;

  contractManager?: Person = undefined;

  dailyContactPerson?: Person = undefined;

  dailyContactPersonName?: string = undefined;

  dailyContactPersonEmail?: string = undefined;

  dailyContactPersonPhone?: string = undefined;

  paymentMethod?: PaymentMethod = undefined;

  accountPaymentType?: AccountPaymentType = undefined;

  invoiceEmailRecipient?: string = undefined;

  getAccountInfo = () => {
    this.accountInfo = this.rootStore.userStore.currentAccount;
    this.contractManager = {
      firstName: this.rootStore.userStore.currentAccount?.contractManagerFirstName,
      lastName: this.rootStore.userStore.currentAccount?.contractManagerLastName,
      email: this.rootStore.userStore.currentAccount?.contractManagerEmail,
      phoneNumber: this.rootStore.userStore.currentAccount?.contractManagerPhone
    } as Person;
    this.dailyContactPerson = this.rootStore.userStore.currentAccount?.dailyContactPerson;
    this.dailyContactPersonName = this.rootStore.userStore.currentAccount?.dailyContactPersonName;
    this.dailyContactPersonEmail = this.rootStore.userStore.currentAccount?.dailyContactPersonEmail;
    this.dailyContactPersonPhone = this.rootStore.userStore.currentAccount?.dailyContactPersonPhone;
    this.paymentMethod = this.rootStore.userStore.currentAccount?.paymentMethod;
    this.invoiceEmailRecipient = this.rootStore.userStore.currentAccount?.invoiceRecipientEmail;
    this.accountPaymentType = this.rootStore.userStore.currentAccount?.accountPaymentType;
  };

  setAndUpdateEmailRecipient(invoiceRecipientEmail: string) {
    return this.rootStore.customerServiceStore.requestToUpdateInvoiceReceiverResponsible(
      invoiceRecipientEmail
    );
  }

  setAndUpdateDailyContact(dailyContact: DailyContactInformation) {
    return this.rootStore.customerServiceStore.requestToChangeDailyContact(dailyContact);
  }

  setAndUpdateContractManager(NewContractManager: Person) {
    return this.rootStore.customerServiceStore.requestToUpdateContractManager(NewContractManager);
  }

  setAndUpdateAccountInfo(NewAccountInfo: AccountInfo) {
    return this.rootStore.customerServiceStore.requestToUpdateAccountInfo(NewAccountInfo);
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      accountInfo: observable,
      accountPaymentType: observable,
      contractManager: observable,
      dailyContactPersonName: observable,
      dailyContactPersonEmail: observable,
      dailyContactPersonPhone: observable,
      paymentMethod: observable,
      getAccountInfo: action,
      setAndUpdateEmailRecipient: action,
      setAndUpdateContractManager: action,
      setAndUpdateDailyContact: action
    });
  }
}
