import React, { FC } from 'react';
import { Row } from 'antd';
import CustomModal from '../../../common-components/CustomModal';
import { ReactComponent as Check } from '../../../layout/icons/white-check-in-green-circle-small.svg';
import { ReactComponent as BigCheck } from '../../../layout/icons/white-check-in-green-circle-big.svg';
import { ContentSmallCamptonBook } from '../../../layout/globalFonts';
import CMSStore from '../../../stores/content-stores/CMSStore';
import logOut from '../../../utils/logOut';

interface AccountIsDeletedModalProps {
  cmsStore: CMSStore;
  closeModal: () => void;
}

const AccountIsDeletedModal: FC<AccountIsDeletedModalProps> = (props) => {
  const { cmsStore, closeModal } = props;

  const { authenticationStore } = cmsStore.rootStore;

  const header = cmsStore.getContent('YourAccountIsDeletedModal')?.content!;

  const primaryButtonText = cmsStore.getContent('Ok')?.content!;

  return (
    <CustomModal
      topIconDesktop={<Check />}
      topIconMobile={<BigCheck />}
      header={header}
      closable
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={() => {
        closeModal();
        logOut(authenticationStore);
      }}
    >
      <Row>
        <ContentSmallCamptonBook style={{ marginBottom: '42px' }}>
          {cmsStore.getContent('YourAccountIsDeletedModalContent')?.content!}
        </ContentSmallCamptonBook>
      </Row>
      <Row>{cmsStore.getContent('YourAccountIsDeletedModalContent')?.subText!}</Row>
    </CustomModal>
  );
};

export default AccountIsDeletedModal;
