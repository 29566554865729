/* eslint-disable import/no-cycle */
import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';

type DAWAAddress = {
  forslagstekst: string;
  data: {
    dør: string;
    etage: string;
    husnr: string;
    postnr: string;
    postnrnavn: string;
    vejnavn: string;
  };
};

export type SelectedAddress = {
  data: {
    resultater: [
      {
        adresse: {
          dør: string;
          etage: string;
          husnr: string;
          postnr: string;
          postnrnavn: string;
          vejnavn: string;
        };
      }
    ];
  };
};

export default class AddressSelectorStore extends BaseStore {
  useDawaSelector: boolean = true;

  addressOptions: { value: string }[] = [];

  selectedAddress: SelectedAddress | undefined = undefined;

  isOptionDropdownOpen: boolean = false;

  setUseDawaSelector = (useDawa: boolean) => {
    this.useDawaSelector = useDawa;
  };

  setIsOptionDropdownOpen = (isOpen: boolean) => {
    this.isOptionDropdownOpen = isOpen;
  };

  setSelectedAddress = (selectedAddress: string | undefined) => {
    const promise = axios
      .get('https://dawa.aws.dk/datavask/adresser', {
        method: 'get',
        params: {
          betegnelse: selectedAddress
        }
      })
      .then((val: SelectedAddress) => {
        // if result is one, we have a specific address. Otherwise the address is not selected and the dropdown stays open
        if (val.data.resultater.length === 1) {
          this.selectedAddress = val;
          this.setIsOptionDropdownOpen(false);
        } else {
          this.setIsOptionDropdownOpen(true);
        }
      });
    return promise;
  };

  setAddressOptions = (addressInput: string) => {
    const promise = axios
      .get('https://dawa.aws.dk/autocomplete?fuzzy=true&per_side=5', {
        method: 'get',
        params: {
          q: addressInput
        }
      })
      .then((val) => {
        const addAddressValue = (adr: DAWAAddress) => ({
          value: adr.forslagstekst
        });
        const adrOptions: { value: string }[] = val.data.map((adr: DAWAAddress) =>
          addAddressValue(adr)
        );
        this.addressOptions = adrOptions;
      });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      addressOptions: observable,
      selectedAddress: observable,
      isOptionDropdownOpen: observable,
      useDawaSelector: observable,
      setUseDawaSelector: action,
      setIsOptionDropdownOpen: action,
      setAddressOptions: action,
      setSelectedAddress: action
    });
  }
}
