import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';

interface ConfirmOpenOresundLinkModalProps {
  oresundUrl: string;
  contentStore: ContentStore;
  closeModal: () => void;
}

const ConfirmOpenOresundLinkModal: FC<ConfirmOpenOresundLinkModalProps> = (props) => {
  const { oresundUrl, contentStore, closeModal } = props;

  const header = contentStore.cmsStore.getContent('ConfirmOpenOresundLinkModal')?.content;
  const content = contentStore.cmsStore.getContent('ConfirmOpenOresundLinkModal')?.subText;
  const primaryButtonText = contentStore.cmsStore.getContent(
    'ConfirmOpenOresundLinkModalButton'
  )?.content;

  return (
    <CustomModal
      header={header}
      contentText={content}
      primaryButtonText={primaryButtonText}
      closable
      onGreenLinkClick={() => {
        closeModal();
      }}
      onPrimaryButtonClick={() => window.open(oresundUrl, '_blank')}
    />
  );
};

export default observer(ConfirmOpenOresundLinkModal);
