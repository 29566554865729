import { Switch, Radio, Checkbox } from 'antd';
import styled from 'styled-components';

export const StyledToggle = styled(Switch)`
  // Checked color must be set on parent element
  --antd-wave-shadow-color: none;
  background: #ababab;

  min-width: 38px;
  height: 20px;

  .ant-switch-handle::before {
    top: 1px;
    left: 2px;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    box-shadow: none;
    content: '';
  }

  &:focus {
    box-shadow: 0 0 0 0;
  }

  .ant-switch-checked:focus {
    box-shadow: 0 0 0 0;
  }
`;

export const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    align-items: center;
  }

  .ant-radio {
    padding-top: 1px;
    padding-bottom: 1px;
    top: 0;
  }

  .ant-radio-inner {
    border: 1px solid #969696 !important;
    box-sizing: border-box;
    border-radius: 20px;
    height: 24px;
    width: 24px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #00a050 !important;
      background: #00a050;
    }
  }

  .ant-radio-inner::after {
    background: #ffffff;
  }

  .ant-radio-checked::after {
    content: '';
    border: none;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 0;
  }
`;

export const BrobizzCheckbox = styled(Checkbox)`
  color: #2d2d2d;
  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1);
    top: 4px;
    height: 24px;
    width: 24px;
  }
  .ant-checkbox-inner::after {
    width: 7px;
    height: 14px;
  }
  .ant-checkbox + span {
    padding-left: 16px;
  }
  .ant-checkbox-inner {
    border: 2px solid #969696 !important;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #00a050;
      border-color: #00a050 !important;
    }
    &::after {
      border: none;
    }
  }
`;

export default { StyledRadio, StyledToggle, BrobizzCheckbox };
