import React, { FC } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {
  H8CamptonSemiBold20,
  SmallGrey12,
  CamptonBlack22,
  ContentSmallCamptonBookWeight625,
  GreenLinkText
} from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import { ProductInfo } from '../../../../swagger/api';
import licensePlateFormatting from '../../../../utils/licensePlateFormatting';
import ModalStore from '../../../../stores/ModalStore';
import PublicCustomerWantsToEditAnprLicensePlate from '../modals/PublicCustomerWantsToEditAnprLicensePlate';
import ProductCardStore from '../../../../stores/product-stores/ProductCardStore';

const HeaderSection = styled.section`
  padding-bottom: 10px;
`;

interface VehicleInformationTileProps {
  contentStore: ContentStore;
  productInfo: ProductInfo;
  modalStore: ModalStore;
  productCardStore: ProductCardStore;
}

const VehicleInformationTile: FC<VehicleInformationTileProps> = (props) => {
  const { contentStore, productInfo, modalStore, productCardStore } = props;

  const openEditLicensePlateModal = () =>
    modalStore.openModal(
      <PublicCustomerWantsToEditAnprLicensePlate
        contentStore={contentStore}
        modalStore={modalStore}
        productInfo={productInfo}
        productCardStore={productCardStore}
      />
    );

  return (
    <>
      <HeaderSection>
        <Row>
          <H8CamptonSemiBold20>
            {contentStore.cmsStore.getContent('Vehicle')?.content}
          </H8CamptonSemiBold20>
        </Row>
      </HeaderSection>
      <Row>
        <SmallGrey12>{contentStore.cmsStore.getContent('licensePlate')?.content}</SmallGrey12>
      </Row>
      <Row>
        <CamptonBlack22>{licensePlateFormatting(productInfo.licensePlate)}</CamptonBlack22>
      </Row>
      <Row>
        <GreenLinkText
          style={{ cursor: 'pointer', marginTop: '5px' }}
          onClick={openEditLicensePlateModal}
        >
          {contentStore.cmsStore.getContent('EditLicensePlate')?.content}
        </GreenLinkText>
      </Row>
      <Row justify="space-between" style={{ marginTop: '20px' }} gutter={[12, 12]}>
        <Col>
          <Row>
            <SmallGrey12>{contentStore.cmsStore.getContent('Country')?.content}</SmallGrey12>
          </Row>
          <Row>
            <ContentSmallCamptonBookWeight625>
              {productInfo.countryIsoCode}
            </ContentSmallCamptonBookWeight625>
          </Row>
        </Col>
        <Col>
          <Row>
            <SmallGrey12>{contentStore.cmsStore.getContent('Model')?.content}</SmallGrey12>
          </Row>
          <Row>
            <ContentSmallCamptonBookWeight625>
              {productInfo.brand ? `${productInfo.brand} ${productInfo.model}` : '-'}
            </ContentSmallCamptonBookWeight625>
          </Row>
        </Col>
        <Col>
          <Row>
            <SmallGrey12>{contentStore.cmsStore.getContent('Color')?.content}</SmallGrey12>
          </Row>
          <Row>
            <ContentSmallCamptonBookWeight625>
              {productInfo.color ? productInfo.color : '-'}
            </ContentSmallCamptonBookWeight625>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default observer(VehicleInformationTile);
