import React, { FC } from 'react';
import { IdentifierStatus } from '../swagger/api';
import { ReactComponent as ActiveProduct } from '../layout/icons/product-active.svg';
import { ReactComponent as PendingProduct } from '../layout/icons/product-pending.svg';
import { ReactComponent as InactiveProduct } from '../layout/icons/product-inactive.svg';
import { ReactComponent as ClosedOrBlockedProduct } from '../layout/icons/product-closed-or-blocked.svg';

interface ProductStatusIconProps {
  productStatus?: IdentifierStatus;
  isEconomyBlocked?: boolean;
}

const ProductStatusIcon: FC<ProductStatusIconProps> = (props) => {
  const { productStatus, isEconomyBlocked } = props;

  if (isEconomyBlocked) {
    return <ClosedOrBlockedProduct />;
  }

  switch (productStatus) {
    case IdentifierStatus.Active:
      return <ActiveProduct />;
    case IdentifierStatus.Pending:
      return <PendingProduct />;
    case IdentifierStatus.BizzOnItsWay:
      return <PendingProduct />;
    case IdentifierStatus.CreditCardExpiresSoon:
      return <PendingProduct />;
    case IdentifierStatus.Inactive:
      return <InactiveProduct />;
    case IdentifierStatus.Closed:
      return <ClosedOrBlockedProduct />;
    case IdentifierStatus.Blocked:
      return <ClosedOrBlockedProduct />;
    case IdentifierStatus.IssuesWithCreditCard:
      return <ClosedOrBlockedProduct />;
    case IdentifierStatus.UnpaidInvoices:
      return <ClosedOrBlockedProduct />;
    default:
      return null;
  }
};

export default ProductStatusIcon;

ProductStatusIcon.defaultProps = {
  productStatus: undefined,
  isEconomyBlocked: false
};
