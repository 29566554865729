import React, { FC } from 'react';
import { Row } from 'antd';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import ModalStore from '../../../../stores/ModalStore';
import { ReactComponent as BigWarningCross } from '../../../../layout/icons/white-cross-in-red-circle-big.svg';
import { ReactComponent as WarningCross } from '../../../../layout/icons/white-cross-in-red-circle.svg';
import { ContentSmallCamptonBook } from '../../../../layout/globalFonts';

interface NoIdentifierFoundWaitAMomentProps {
  contentStore: ContentStore;
  modalStore: ModalStore;
}

const NoIdentifierFoundWaitAMoment: FC<NoIdentifierFoundWaitAMomentProps> = (props) => {
  const { contentStore, modalStore } = props;

  const headerText = contentStore.cmsStore.getContent(
    'NoIdentifiersFoundTryAgainLaterModalTitle'
  )?.content;
  const contentTextPartOne = contentStore.cmsStore.getContent(
    'NoIdentifiersFoundTryAgainLaterModal'
  )?.content;
  const contentTextPartTwo = contentStore.cmsStore.getContent(
    'NoIdentifiersFoundTryAgainLaterModal'
  )?.subText;
  const primaryButtonText = contentStore.cmsStore.getContent(
    'NoIdentifiersFoundTryAgainLaterModalButton'
  )?.content;

  return (
    <CustomModal
      header={headerText}
      primaryButtonText={primaryButtonText}
      topIconDesktop={<WarningCross />}
      topIconMobile={<BigWarningCross />}
      closable
      isHeaderRed
      onPrimaryButtonClick={() => modalStore.closeModal()}
    >
      <Row style={{ marginBottom: '27px', marginTop: '9.5px' }}>
        <ContentSmallCamptonBook>{contentTextPartOne}</ContentSmallCamptonBook>
      </Row>
      <Row justify="center" style={{ marginBottom: '44px' }}>
        <ContentSmallCamptonBook>{contentTextPartTwo}</ContentSmallCamptonBook>
      </Row>
    </CustomModal>
  );
};

export default NoIdentifierFoundWaitAMoment;
