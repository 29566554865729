import React, { FC } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import CustomModal from '../../../../common-components/CustomModal';
import useStore from '../../../../hooks/useStore';
import ContentStore from '../../../../stores/ContentStore';
import tollBoxUtils, { VehicleDeclarationMandatoryFields } from '../../../../utils/tollBoxUtils';
import { ContentSmallLinkCamptonBook } from '../../../../layout/globalFonts';

const MissingVehicleDataWrapper = styled(ContentSmallLinkCamptonBook)`
  margin-top: 22px;
  margin-bottom: 44px;
  font-weight: 475;
`;

const FailedFieldsWrapper = styled.ul`
  padding-left: 50px;
  text-align: left;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const StyledDivider = styled(Divider)`
  margin-block: 27px;
`;

interface MissingVehicleDataModalProps {
  closeModal: () => void;
  validatedFailedFields?: VehicleDeclarationMandatoryFields[];
}

const MissingVehicleDataModal: FC<MissingVehicleDataModalProps> = (props) => {
  const { closeModal, validatedFailedFields } = props;
  const contentStore: ContentStore = useStore(ContentStore);

  const header = contentStore.cmsStore.getContent('MissingVehicleDataModal')?.content;
  const contentTop = contentStore.cmsStore.getContent('MissingVehicleDataModalContent')?.content;
  const contentBottom = contentStore.cmsStore.getContent('MissingVehicleDataModalContent')?.subText;
  const primaryButtonText = contentStore.cmsStore.getContent('Ok')?.content;
  const failedFields = validatedFailedFields && validatedFailedFields.length > 0 && (
    <FailedFieldsWrapper>
      {validatedFailedFields?.map((field) => (
        <li key={field}>
          {tollBoxUtils.convertVehicleDeclarationFieldToHygraph(field, contentStore) ?? field}
        </li>
      ))}
    </FailedFieldsWrapper>
  );
  return (
    <CustomModal
      header={header}
      isHeaderRed
      key="missingVehicleDataModal"
      primaryButtonText={primaryButtonText}
      closable
      onPrimaryButtonClick={() => closeModal()}
    >
      <MissingVehicleDataWrapper>
        {contentTop}
        <StyledDivider />
        {failedFields}
        <StyledDivider />
        {contentBottom}
      </MissingVehicleDataWrapper>
    </CustomModal>
  );
};

MissingVehicleDataModal.defaultProps = {
  validatedFailedFields: undefined
};

export default MissingVehicleDataModal;
