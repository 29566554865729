/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import RouteUrlPaths from '../../RouteUrlPaths';
import ManageUserAccessStore from '../../stores/ManageUserAccessStore';

interface ProtectedPrivateRouteProps {
  manageUserAccessStore: ManageUserAccessStore;
}

const ProtectedPrivateRoute: FC<ProtectedPrivateRouteProps> = (props) => {
  const { manageUserAccessStore, children } = props;
  if (!manageUserAccessStore.isPrivateCustomer()) {
    return <Navigate to={RouteUrlPaths.Frontpage} />;
  }
  return <>{children}</>;
};

export default ProtectedPrivateRoute;
