/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import { PaymentCardStatus, CreditCardType } from '../../swagger/api';
import { Filters, FilterMenuContent, FilterObject } from '../../Types';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export type PaymentCardsTableFilter = {
  isMyCards: FilterObject[];
  cardStatus: FilterObject[];
  cardType: FilterObject[];
};
export default class PaymentCardsFilterStore extends BaseStore {
  // allMenus is initialized on CMS content load in the method setPaymentCardsFilterContent() of CMSStore.tsx
  allMenus: FilterMenuContent = {};

  activeFilters: PaymentCardsTableFilter = {
    isMyCards: [],
    cardStatus: [],
    cardType: []
  };

  temporaryFilters: PaymentCardsTableFilter = {
    isMyCards: [],
    cardStatus: [],
    cardType: []
  };

  searchVal: string = '';

  requestParams: {
    accountId: string;
    searchString?: string | undefined;
    paymentAgreementOwnerPersonId?: string | undefined;
    cardStatus?: PaymentCardStatus[] | undefined;
    cardType?: CreditCardType[] | undefined;
    pageNumber?: number | undefined;
  } = {
    accountId: ''
  };

  setRequestParams = () => {
    this.requestParams.searchString = this.searchVal;
    this.requestParams.pageNumber =
      this.rootStore.administrationStore.administrationPaymentCardsStore.paymentCardsPageNumber;
    if (this.activeFilters.isMyCards.length === 1)
      this.requestParams.paymentAgreementOwnerPersonId = this.rootStore.userStore.person?.id;
    else this.requestParams.paymentAgreementOwnerPersonId = undefined;
    this.requestParams.cardStatus = this.activeFilters.cardStatus.map(
      (element: FilterObject) => element.filter as PaymentCardStatus
    );
    this.requestParams.cardType = this.activeFilters.cardType.map(
      (element: FilterObject) => element.filter as CreditCardType
    );
  };

  setSearchVal = (searchString: string) => {
    this.searchVal = searchString;
    this.rootStore.administrationStore.administrationPaymentCardsStore.setCurrentPage(1);
  };

  setActiveFilters = (temporaryFilters: Filters) => {
    this.activeFilters = JSON.parse(JSON.stringify(temporaryFilters));
    this.rootStore.administrationStore.administrationPaymentCardsStore.setCurrentPage(1);
  };

  setTemporaryFilters = (filters: Filters) => {
    this.temporaryFilters = JSON.parse(JSON.stringify(filters));
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      allMenus: observable,
      activeFilters: observable,
      searchVal: observable,
      temporaryFilters: observable,
      setSearchVal: action,
      setActiveFilters: action
    });
  }
}
