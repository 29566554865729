import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { BrobizzTable } from '../../../common-components/BrobizzTable';
import TransactionsStore from '../../../stores/TransactionsStore';
import ProductStore from '../../../stores/ProductStore';
import ContentStore from '../../../stores/ContentStore';
import useStore from '../../../hooks/useStore';
import usePagination from '../../../hooks/usePagination';
import { ManchetContentCamptonBook22Grey } from '../../../layout/globalFonts';
import PrivateProductCardModal from '../../product-features/product-card/PrivateProductCardModal';
import useTransactionsAndUsageColumns from '../../../hooks/useTransactionsAndUsageColumns';
import LoadingSpinner from '../../../common-components/LoadingSpinner';
import { Transaction } from '../../../swagger/api';

interface TransactionsAndUsageTableDesktopProps {
  transactionsStore: TransactionsStore;
  contentStore: ContentStore;
  showOutstandingOnly: boolean;
  isTableOnProductCard: boolean;
  transactions: Transaction[];
  isLoading: boolean;
}

const TransactionsAndUsageTableDesktop: FC<TransactionsAndUsageTableDesktopProps> = (props) => {
  const {
    transactionsStore,
    contentStore,
    showOutstandingOnly,
    isTableOnProductCard,
    transactions,
    isLoading
  } = props;
  const productStore: ProductStore = useStore(ProductStore);
  const { productItemsStore, productCardStore } = productStore;

  const columns = useTransactionsAndUsageColumns(
    contentStore,
    isTableOnProductCard,
    showOutstandingOnly
  );

  const pagination = showOutstandingOnly
    ? usePagination(
        transactionsStore.outstandingPagenumber,
        transactionsStore.numberOfOutstandingTransactions,
        transactionsStore.pageSizeOutstanding,
        transactionsStore.setOutstandingCurrentPage
      )
    : usePagination(
        transactionsStore.transactionsPageNumber,
        transactionsStore.filteredNumberOfTransactions,
        transactionsStore.pageSize,
        transactionsStore.setTransactionsCurrentPage
      );

  return (
    <>
      <Row>
        <Col span={24}>
          <BrobizzTable
            dataSource={transactions}
            rowKey="id"
            columns={columns}
            showSorterTooltip={false}
            pagination={!isTableOnProductCard && pagination}
            sticky
            loading={{
              spinning: isLoading,
              indicator: <LoadingSpinner />
            }}
            scroll={{ x: true }}
            locale={{
              emptyText: (
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                  <ManchetContentCamptonBook22Grey>
                    {showOutstandingOnly
                      ? contentStore.cmsStore.getContent('NoOutstandings')?.content
                      : contentStore.cmsStore.getContent('HereYouWillSeeConsumptionWhenRegistered')
                          ?.content}
                  </ManchetContentCamptonBook22Grey>
                </Row>
              )
            }}
          />
        </Col>
      </Row>
      {productCardStore.isModalVisible && productItemsStore.product && (
        <PrivateProductCardModal
          contentStore={contentStore}
          productCardStore={productCardStore}
          product={productItemsStore.product}
        />
      )}
    </>
  );
};
export default observer(TransactionsAndUsageTableDesktop);
