import { Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../common-components/Buttons';
import ContentStore from '../../../../stores/ContentStore';

const ConfirmButtonSection = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  max-width: 100%;
  width: 100vw;
  z-index: 99;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153);
`;
interface CreateButtonProps {
  loading: boolean;
  contentStore: ContentStore;
}
const CreateButton: FC<CreateButtonProps> = (props) => {
  const { contentStore, loading } = props;
  const create = contentStore.cmsStore.getContent('Create')?.content;
  return (
    <Row justify="center">
      <ConfirmButtonSection>
        <Form.Item>
          <Row justify="center" style={{ paddingTop: '24px' }}>
            <PrimaryButton loading={loading} htmlType="submit">
              {create}
            </PrimaryButton>
          </Row>
        </Form.Item>
      </ConfirmButtonSection>
    </Row>
  );
};

export default observer(CreateButton);
