/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import { FormInstance } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import {
  AccountService,
  PaymentType,
  PaymentTypeInfo,
  Person,
  PersonService,
  SubAccount
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import ContentStore from '../ContentStore';
import { RootStore } from '../RootStore';
import { openSnackbar } from '../../features/notifications/Snackbar';
import AdministrationAccountStore from './AdministrationAccountStore';

export enum SelectedPaymentMethod {
  Invoice = 'Invoice',
  CreditCard = 'CreditCard',
  EANInvoice = 'EANInvoice'
}

export default class SubAccountStore extends BaseStore {
  persons: Person[] = [];

  currentName!: string | undefined;

  currentLastName!: string | undefined;

  selectedPaymentMethod: SelectedPaymentMethod = SelectedPaymentMethod.Invoice;

  isFormFilledIn: boolean | undefined = false;

  subAccount = {} as SubAccount;

  dailyContactId: string | undefined;

  creditDeviation: boolean = false;

  creditApprovalStatus: boolean | undefined;

  selectedPerson: Person | undefined = undefined;

  setSelectedPerson = (person: Person | undefined) => {
    this.selectedPerson = person;
  };

  createSubAccount = (
    form: FormInstance,
    contentStore: ContentStore,
    administrationAccountStore: AdministrationAccountStore
  ) => {
    const parentAccountId = this.rootStore.userStore.currentAccount?.id!;
    const paymentAgreementId =
      this.selectedPaymentMethod === SelectedPaymentMethod.CreditCard
        ? this.rootStore.administrationStore.administrationPaymentCardsStore
            .paymentCardSelectedInDropdown?.id
        : undefined;
    this.setCompanyInfo(form, contentStore);
    this.setContractManagerInfo(form);
    this.setDailyContactInfo(form);
    this.setPaymentInfo(form, paymentAgreementId);
    const promise = AccountService.createSubAccount({
      accountId: parentAccountId!,
      body: this.subAccount
    })
      .then((customerNumberResult: string) => {
        if (this.selectedPaymentMethod !== SelectedPaymentMethod.CreditCard) {
          this.rootStore.customerServiceStore.requestToApproveInvoiceCustomer(
            customerNumberResult,
            form.getFieldValue('cvr'),
            form.getFieldValue('companyName')
          );
        }
      })
      .then(() => {
        administrationAccountStore.getAccountAdminInfo();
        administrationAccountStore.closeModal();
        openSnackbar(contentStore.cmsStore.getContent('CreateSubAccountNotification')?.content!);
      });
    return promise;
  };

  setCompanyInfo = (form: FormInstance, contentStore: ContentStore) => {
    const cvrInfo = JSON.parse(
      JSON.stringify(
        form.getFieldsValue([
          'companyName',
          'streetName',
          'cityName',
          'zipCode',
          'countryName',
          'cvr'
        ])
      )
    );
    const country = contentStore.genericContentStore.allCountries.filter(
      (e) => e.name === cvrInfo.countryName
    );
    this.subAccount.accountRequest = cvrInfo;
    if (this.subAccount.accountRequest !== undefined) {
      this.subAccount.accountRequest.isoCode = country[0].isoCode!;
      this.subAccount.accountRequest.countryId = country[0].id!;
    }
  };

  setContractManagerInfo = (form: FormInstance) => {
    const contractManager = JSON.parse(
      JSON.stringify(
        form.getFieldsValue(['managerName', 'managerSurname', 'managerEmail', 'managerPhoneNumber'])
      )
    );
    this.subAccount.contractManager = {
      firstName: contractManager.managerName,
      lastName: contractManager.managerSurname,
      email: contractManager.managerEmail,
      phoneNumber: contractManager.managerPhoneNumber
    } as Person;
    if (
      this.subAccount.contractManager.phoneNumber === null ||
      this.subAccount.contractManager.phoneNumber === undefined
    ) {
      this.subAccount.contractManager.phoneNumber = '12345678';
    }
  };

  setDailyContactInfo = (form: FormInstance) => {
    const dailyContactName = JSON.parse(JSON.stringify(form.getFieldValue('dailyContactName')));
    const dailyContactEmail = JSON.parse(JSON.stringify(form.getFieldValue('dailyContactEmail')));
    const dailyContactPhone = JSON.parse(
      JSON.stringify(form.getFieldValue('dailyContactPhoneNumber'))
    );
    this.subAccount.dailyContact = {
      id: this.dailyContactId,
      firstName: dailyContactName,
      lastName: '-', // Placeholder
      email: dailyContactEmail,
      phoneNumber: dailyContactPhone
    } as Person;
    this.subAccount.dailyContactPersonName = dailyContactName === undefined ? '' : dailyContactName;
    this.subAccount.dailyContactPersonEmail =
      dailyContactEmail === undefined ? '' : dailyContactEmail;
    this.subAccount.dailyContactPersonPhone =
      dailyContactPhone === undefined ? '' : dailyContactPhone;
  };

  setPaymentInfo = (form: FormInstance, paymentAgreementId: string | undefined) => {
    const paymentTypeInfo = JSON.parse(
      JSON.stringify(form.getFieldsValue(['paymentType', 'recipientEmail', 'eanNumber']))
    );
    this.subAccount.paymentTypeInfo = {} as PaymentTypeInfo;
    if (this.subAccount.paymentTypeInfo !== undefined) {
      this.subAccount.paymentTypeInfo.paymentType =
        paymentTypeInfo.paymentType === SelectedPaymentMethod.CreditCard
          ? PaymentType.CreditCard
          : PaymentType.Invoice;
      this.subAccount.paymentTypeInfo.recipientEmail = paymentTypeInfo.recipientEmail;
      this.subAccount.paymentTypeInfo.paymentAgreementId = paymentAgreementId;
      if (paymentTypeInfo.eanNumber !== undefined) {
        this.subAccount.paymentTypeInfo.eanNumber = paymentTypeInfo.eanNumber;
      }
    }
  };

  setName = (name: string) => {
    if (name !== '') {
      this.currentName = name;
    } else {
      this.currentName = undefined;
    }
  };

  setLastName = (lastName: string) => {
    if (lastName !== '') {
      this.currentLastName = lastName;
    } else {
      this.currentLastName = undefined;
    }
  };

  setPaymentMethod = (paymentMethod: SelectedPaymentMethod, form: FormInstance) => {
    this.selectedPaymentMethod = paymentMethod;
    if (this.selectedPaymentMethod === SelectedPaymentMethod.CreditCard) {
      if (
        form.getFieldValue('recipientEmail') === undefined ||
        form.getFieldValue('recipientEmail') === null
      ) {
        form.setFieldsValue({ recipientEmail: '' });
      }
    }
    if (
      (this.selectedPaymentMethod === SelectedPaymentMethod.Invoice ||
        this.selectedPaymentMethod === SelectedPaymentMethod.EANInvoice) &&
      form.getFieldValue('recipientEmail') === ''
    ) {
      form.setFieldsValue({ recipientEmail: undefined });
    }
  };

  getContractManager = (Id: string, form: FormInstance) => {
    this.persons = [];
    this.currentName = undefined;
    this.currentLastName = undefined;
    const promise = AccountService.getAccountInfoById({ accountId: Id })
      .then((account) => {
        this.creditApprovalStatus = account.creditApproved;
        this.creditDeviation = account.creditDeviation!;
        form.setFieldsValue({
          managerName: account.contractManagerFirstName,
          managerSurname: account.contractManagerLastName,
          managerEmail: account.contractManagerEmail,
          managerPhoneNumber: account.contractManagerPhone
        });
      })
      .then(() => {
        PersonService.getUsersAsync({
          accountId: Id,
          fromAllSubAccounts: true
        }).then((e) => {
          e.forEach((person) => {
            this.persons.push(person);
          });
        });
      });
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      getContractManager: action,
      setName: action,
      setLastName: action,
      setPaymentMethod: action,
      persons: observable,
      currentName: observable,
      currentLastName: observable,
      selectedPaymentMethod: observable,
      isFormFilledIn: observable,
      selectedPerson: observable,
      setSelectedPerson: action
    });
  }
}
