import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import PageHeader from '../../../../layout/components/PageHeader';
import TollingBoxTypeCard from './components/TollingBoxTypeCard';
import { ContentCamptonBook18 } from '../../../../layout/globalFonts';
import { ReactComponent as BizzIcon } from '../../../../layout/icons/payment/bizz-big.svg';
import { ReactComponent as ArrowIcon } from '../../../../layout/icons/arrow-right.svg';
import { ReactComponent as TollingBoxIcon } from '../../../../layout/icons/tolling-box-big.svg';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import RouteUrlPaths from '../../../../RouteUrlPaths';
import ContentStore from '../../../../stores/ContentStore';
import useStore from '../../../../hooks/useStore';
import OrderStore from '../../../../stores/OrderStore';
import { OrderFlowSteps } from '../../../../stores/order-stores/OrderFlowStepperStore';

const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 3%;
  background-color: #efefef;
`;

const StyledSubWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextWrapper = styled.div`
  margin-bottom: 18px;
`;

const StyledText = styled(ContentCamptonBook18)`
  display: block;
  text-align: center;
`;

const StyledRow = styled(Row)`
  gap: 26px;
  justify-content: center;
  max-width: 665px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectTollingBoxOrderType: FC = () => {
  const isMobile = useIsMobileView();
  const navigate = useNavigate();
  const contentStore: ContentStore = useStore(ContentStore);
  const orderStore: OrderStore = useStore(OrderStore);

  const header = contentStore.cmsStore.getContent('SelectTollingBoxOrderTypeHeader')?.content;
  const subHeader = contentStore.cmsStore.getContent('SelectTollingBoxOrderTypeSubHeader')?.content;
  const subSubHeader = contentStore.cmsStore.getContent(
    'SelectTollingBoxOrderTypeSubHeader'
  )?.subText;

  const headerStandardTollingBox = contentStore.cmsStore.getContent(
    'StandardTollingBoxCardHeader'
  )?.content;
  const headerConverterTollingBox = contentStore.cmsStore.getContent(
    'ConvertTollingBoxCardHeader'
  )?.content;

  const orderTollingBoxButtonContent = contentStore.cmsStore.getContent(
    'OrderTollingBoxButtonContent'
  )?.content;
  const convertTollingBoxButtonContent = contentStore.cmsStore.getContent(
    'ConvertTollingBoxButtonContent'
  )?.content;

  const standerTollingBoxIcon = (
    <StyledIconWrapper>
      <TollingBoxIcon />
    </StyledIconWrapper>
  );

  const converterTollingBoxIcon = (
    <StyledIconWrapper>
      <BizzIcon />
      <ArrowIcon />
      <TollingBoxIcon />
    </StyledIconWrapper>
  );

  const preOrderAgreements = [
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText1')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText2')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText3')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText4')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText5')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText6')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText7')?.content,
    contentStore.cmsStore.getContent('TollingBoxPreOrderCardBulletText8')?.content
  ];

  const conversionOrderAgreements = [
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText1')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText2')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText3')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText4')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText5')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText6')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText7')?.content,
    contentStore.cmsStore.getContent('TollingBoxConversionOrderCardBulletText8')?.content
  ];

  const orderTollingBoxHandler = () => {
    orderStore.orderFlowStepperStore.setStepCount(OrderFlowSteps.ChooseAmount);
    navigate(`/${RouteUrlPaths.OrderTollingBox}`);
  };

  const convertTollingBoxHandler = () => {
    navigate(`/${RouteUrlPaths.ConvertToTollingBox}`, {
      state: { showSelectBizzesToConvert: true, bizzId: undefined }
    });
  };

  return (
    <StyledWrapper>
      <PageHeader pageName={header} />
      <StyledSubWrapper isMobile={isMobile}>
        <StyledTextWrapper>
          <StyledText style={{ marginBottom: 24 }}>{subHeader}</StyledText>
          <StyledText>{subSubHeader}</StyledText>
        </StyledTextWrapper>
        <StyledRow>
          <TollingBoxTypeCard
            buttonContent={orderTollingBoxButtonContent}
            selectTypeHandler={orderTollingBoxHandler}
            agreements={preOrderAgreements}
            icon={standerTollingBoxIcon}
            header={headerStandardTollingBox}
          />
          <TollingBoxTypeCard
            buttonContent={convertTollingBoxButtonContent}
            selectTypeHandler={convertTollingBoxHandler}
            agreements={conversionOrderAgreements}
            icon={converterTollingBoxIcon}
            header={headerConverterTollingBox}
          />
        </StyledRow>
      </StyledSubWrapper>
    </StyledWrapper>
  );
};

export default observer(SelectTollingBoxOrderType);
