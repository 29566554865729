import { Col, Collapse, Divider, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  ContentLink,
  ContentSmallCamptonBookWeight475Grey,
  H5CamptonBold30
} from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import { ProductInfo } from '../../../../swagger/api';
import GoToTransactionsAndUsageButtonRow from './GoToTransactionsAndUsageButtonRow';
// eslint-disable-next-line import/no-cycle
import TransactionsAndUsageTableMobile from '../../../transaction-features/transactions-and-usage/TransactionsAndUsageTableMobile';
import useFetchListDataWithFunctionParameters from '../../../../hooks/fetchDataHooks/useFetchListDataWithFunctionParameters';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';

const CollapseCards = styled(Collapse)`
  width: 100%;
  .ant-collapse-header {
    cursor: auto !important;
    margin-top: 25px;
    padding: 0px 20px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse-header-text {
    width: 100%;
  }
`;

const DividerLine = styled(Divider)`
  margin: 0px 0px 0px -20px;
  background-color: #e0e0e0;
  height: 1px;
`;

const TableTitleRow = styled(Row)`
  margin: 34px 0px 16px 0px;
`;

interface TransactionAndUsageCollapsableProps {
  contentStore: ContentStore;
  productInfo: ProductInfo;
}

const TransactionAndUsageCollapsable: FC<TransactionAndUsageCollapsableProps> = (props) => {
  const { contentStore, productInfo } = props;
  const { transactionsStore } = contentStore.rootStore;
  const [foldText, setFoldText] = useState<string>('ViewTrips');

  const isLoading = useFetchListDataWithFunctionParameters(
    transactionsStore.identifierTransactions,
    true,
    transactionsStore.getIdentifierTransactions,
    productInfo.identifierId!
  );

  const changeFoldText = () =>
    foldText === 'ViewTrips' ? setFoldText('HideTrips') : setFoldText('ViewTrips');

  const panelHeader = (
    <Row align="middle">
      <Col>
        <H5CamptonBold30>{contentStore.cmsStore.getContent('Trips')?.content}</H5CamptonBold30>
      </Col>
      <Col flex="auto" />
      <Col>
        <ContentLink>{contentStore.cmsStore.getContent(foldText)?.content}</ContentLink>
      </Col>
    </Row>
  );

  const noTripsRow = (
    <>
      <DividerLine />
      <TableTitleRow justify="center" gutter={12}>
        <Row>
          <ContentSmallCamptonBookWeight475Grey>
            {contentStore.cmsStore.getContent('NoTripsOnTheProduct')?.content}
          </ContentSmallCamptonBookWeight475Grey>
        </Row>
      </TableTitleRow>
      <DividerLine />
    </>
  );

  return (
    <Row>
      <CollapseCards ghost onChange={changeFoldText}>
        <Collapse.Panel header={panelHeader} showArrow={false} key="1">
          {transactionsStore.identifierTransactions.length === 0 ? (
            isLoading ? (
              <LoadingSpinner />
            ) : (
              noTripsRow
            )
          ) : (
            <div style={{ padding: '0 3%' }}>
              <TransactionsAndUsageTableMobile
                contentStore={contentStore}
                transactions={transactionsStore.identifierTransactions}
                showOutstandingOnly={false}
                isTableOnProductCard
                transactionsStore={transactionsStore}
                isLoading={isLoading}
              />
            </div>
          )}
          <GoToTransactionsAndUsageButtonRow
            contentStore={contentStore}
            transactionsStore={transactionsStore}
          />
        </Collapse.Panel>
      </CollapseCards>
    </Row>
  );
};
export default observer(TransactionAndUsageCollapsable);
