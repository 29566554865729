import React, { FC } from 'react';
import FormItem from 'antd/es/form/FormItem';
import { StyledSelect } from '../../../../common-components/Input';
import { DropdownArrow } from '../../../../common-components/StyledIcons';
import {
  AccountAdminChip,
  SubAccountAdminChip,
  ProductAccessChip
} from '../../components/UserRoleChips';
import { ReactComponent as CheckIcon } from '../../../../layout/icons/check.svg';
import ContentStore from '../../../../stores/ContentStore';
import { UserRole } from '../../../../swagger/api';
import CreateUserStore from '../../../../stores/adminstration-stores/CreateUserStore';

const { Option } = StyledSelect;

interface UserAccessDropdownProps {
  contentStore: ContentStore;
  createUserStore: CreateUserStore;
}

const UserAccessDropdown: FC<UserAccessDropdownProps> = (props) => {
  const { contentStore, createUserStore } = props;
  const { userStore } = contentStore.rootStore;
  const userRoleDropdown = (
    <FormItem name="userAccess">
      <StyledSelect
        menuItemSelectedIcon={<CheckIcon />}
        placeholder={contentStore.cmsStore.getContent('UserAccess')?.content}
        getPopupContainer={(trigger) => trigger}
        suffixIcon={<DropdownArrow />}
        style={{ width: '237px' }}
        onChange={(value: any) => {
          createUserStore.selectedUserRole = value;
          createUserStore.resetSelectedAccountIds();
          createUserStore.setSelectedProduct(undefined);
        }}
      >
        {userStore.person?.role === UserRole.ParentAccountAdmin && (
          <Option value={UserRole.ParentAccountAdmin}>
            <AccountAdminChip>
              {contentStore.cmsStore.getContent('AccountAdminRole')?.content}
            </AccountAdminChip>
          </Option>
        )}
        <Option value={UserRole.SubAccountAdmin}>
          <SubAccountAdminChip>
            {contentStore.cmsStore.getContent('SubAccountAdministatorRole')?.content}
          </SubAccountAdminChip>
        </Option>
        <Option value={UserRole.ProductAccess}>
          <ProductAccessChip>
            {contentStore.cmsStore.getContent('ProductAccessRole')?.content}
          </ProductAccessChip>
        </Option>
      </StyledSelect>
    </FormItem>
  );

  return userRoleDropdown;
};

export default UserAccessDropdown;
