import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import CustomModal from '../../../common-components/CustomModal';
import { ReactComponent as BigWarningCross } from '../../../layout/icons/white-cross-in-red-circle-big.svg';
import { ReactComponent as WarningCross } from '../../../layout/icons/white-cross-in-red-circle.svg';
import RouteUrlPaths from '../../../RouteUrlPaths';
import CMSStore from '../../../stores/content-stores/CMSStore';

interface PayOutstandingsBeforeAccountCloseProps {
  cmsStore: CMSStore;
}

const PayOutstandingsBeforeAccountClose: FC<PayOutstandingsBeforeAccountCloseProps> = (props) => {
  const { cmsStore } = props;
  const { transactionsStore } = cmsStore.rootStore;
  const navigate = useNavigate();
  const headerText = cmsStore.getContent('PayOutstandingDebtBeforeAccountClose')?.content!;
  const contentText = cmsStore.getContent('PayOutstandingDebtBeforeAccountClose')?.subText;
  const primaryButtonText = cmsStore.getContent('PayDebtBeforeAccountCloseButton')?.content!;

  const onPrimaryButtonClick = () => {
    transactionsStore.setActiveTab(2);
    navigate(RouteUrlPaths.TransactionAndUsage);
  };

  return (
    <CustomModal
      topIconDesktop={<WarningCross />}
      topIconMobile={<BigWarningCross />}
      header={headerText}
      contentText={contentText}
      primaryButtonText={primaryButtonText}
      closable
      isHeaderRed
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
};

export default PayOutstandingsBeforeAccountClose;
