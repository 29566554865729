const usePagination = (
  currentPage: number,
  filteredCount: number,
  pageSize: number,
  setPage: (page: number) => void
) => ({
  pageSize,
  showSizeChanger: false,
  total: filteredCount,
  current: currentPage,
  hideOnSinglePage: true,
  onChange: (pageNumber: number) => {
    window.scrollTo({ top: 0 });
    setPage(pageNumber);
  }
});
export default usePagination;
