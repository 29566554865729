import React, { FC, useState } from 'react';
import { Row } from 'antd';
import CustomModal from '../../../common-components/CustomModal';
import ContentStore from '../../../stores/ContentStore';
import ModalStore from '../../../stores/ModalStore';
import { ReactComponent as ExclamationIcon } from '../../../layout/icons/yellow-circle-black-exclamation.svg';
import { ReactComponent as BigExclamationIcon } from '../../../layout/icons/yellow-circle-black-exclamation-big.svg';
import AccountIsDeletedModal from './AccountIsDeletedModal';

interface ConfirmCloseAccountModalProps {
  contentStore: ContentStore;
  modalStore: ModalStore;
}

const ConfirmCloseAccountModal: FC<ConfirmCloseAccountModalProps> = (props) => {
  const { contentStore, modalStore } = props;
  const { userStore } = contentStore.rootStore;
  const [isLoading, setIsloading] = useState(false);

  const header = contentStore.cmsStore.getContent('ConfirmAccountDeletionModal')?.content!;

  const content = contentStore.cmsStore.getContent('ConfirmAccountDeletionModal')?.subText;

  const primaryButtonText = contentStore.cmsStore.getContent('DeleteMyAccount')?.content!;

  const greenLinkText = contentStore.cmsStore.getContent('Undo')?.content!;

  const legalText = (
    <Row justify="center" style={{ margin: '-22px 0px 33px 0px' }}>
      {contentStore.cmsStore.getContent('ClosePrivateAccountLegalText')?.content!}
    </Row>
  );

  const closeAccount = () => {
    setIsloading(true);
    userStore.deleteUser(userStore.person?.id!, userStore.currentAccount?.id!).then(() => {
      setIsloading(false);
      modalStore.closeModal();
      modalStore.openModal(
        <AccountIsDeletedModal
          cmsStore={contentStore.cmsStore}
          closeModal={modalStore.closeModal}
        />
      );
    });
  };

  return (
    <CustomModal
      topIconDesktop={<ExclamationIcon />}
      topIconMobile={<BigExclamationIcon />}
      header={header}
      contentText={content}
      closable
      isLoading={isLoading}
      primaryButtonText={primaryButtonText}
      mobileViewSecondaryButton
      greenLinkText={greenLinkText}
      onGreenLinkClick={() => modalStore.closeModal()}
      onPrimaryButtonClick={closeAccount}
      redPrimaryButton
    >
      {legalText}
    </CustomModal>
  );
};

export default ConfirmCloseAccountModal;
