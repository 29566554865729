import React, { FC } from 'react';
import { Row, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { BrobizzTable } from '../../../../common-components/BrobizzTable';
import ContentStore from '../../../../stores/ContentStore';
import { SubAccountsListItem, UserRole } from '../../../../swagger/api';
import usePagination from '../../../../hooks/usePagination';
import { ReactComponent as CheckIcon } from '../../../../layout/icons/check.svg';
import { ManchetContentCamptonBook22Grey, TableHeader } from '../../../../layout/globalFonts';
import { overlayInnerStyle } from '../../../../common-components/TooltipStyling';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';
import NumberOfIdentifiersOnAccount from '../../components/NumberOfIdentifiersOnAccount';

const SelectAllTableHeader = styled(TableHeader)`
  marginleft: '-20px';
`;
interface AccountAccessTableProps {
  contentStore: ContentStore;
  accountsWithAccessRights: SubAccountsListItem[];
  selectedUserRole: UserRole;
  selectedAccountIds: React.Key[];
  addSelectedAccountIds: (selectedAccountIds: React.Key[]) => void;
  removeSelectedAccountIds: (deselectedAccountId: React.Key[]) => void;
  selectOrDeselectAll: (selected: boolean, selectedAccounts: SubAccountsListItem[]) => void;
  accountPageNumber: number;
  filteredAccountCount: number;
  accountPageSize: number;
  setCurrentAccountPage: (pageNumber: number) => void;
  hideAllSelector: boolean;
  showContactPerson?: boolean;
  isLoading: boolean;
}

const AccountAccessTable: FC<AccountAccessTableProps> = (props) => {
  const {
    contentStore,
    accountsWithAccessRights,
    selectedUserRole,
    selectedAccountIds,
    addSelectedAccountIds,
    removeSelectedAccountIds,
    selectOrDeselectAll,
    accountPageNumber,
    filteredAccountCount,
    accountPageSize,
    setCurrentAccountPage,
    hideAllSelector,
    showContactPerson,
    isLoading
  } = props;

  const addOrRemoveSelectedAccount = (record: SubAccountsListItem, selected: boolean) => {
    if (selected) {
      const selectedAccount: React.Key[] = [record.id!];
      addSelectedAccountIds(selectedAccount);
    } else {
      const deselectedAccount: React.Key[] = [record.id!];
      removeSelectedAccountIds(deselectedAccount);
    }
  };

  const selectAll = (
    selected: boolean,
    selectedRows: SubAccountsListItem[],
    changeRows: SubAccountsListItem[]
  ) => {
    selectOrDeselectAll(selected, changeRows);
  };

  const pagination = usePagination(
    accountPageNumber,
    filteredAccountCount!,
    accountPageSize,
    setCurrentAccountPage
  );

  const contactPersonColumns = [
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('DailyContact')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('DailyContact')?.content}
        </Tooltip>
      ),
      width: '17%',
      render: (row: SubAccountsListItem) => (row.isDailyContact ? <CheckIcon /> : '-')
    }
  ];

  const columns: ColumnsType<SubAccountsListItem> = [
    {
      title: (
        <SelectAllTableHeader>
          {contentStore.cmsStore.getContent('SelectAll')?.content}
        </SelectAllTableHeader>
      ),
      width: '5%'
    },
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('CustomerNr')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('CustomerNr')?.content}
        </Tooltip>
      ),
      width: showContactPerson ? '15%' : '20%',
      dataIndex: 'customerNumber'
    },
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('Name')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('Name')?.content}
        </Tooltip>
      ),
      width: showContactPerson ? '38%' : '40%',
      dataIndex: 'name'
    },
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('CVR')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('CVR')?.content}
        </Tooltip>
      ),
      width: showContactPerson ? '15%' : '20%',
      dataIndex: 'cvr'
    },
    {
      title: (
        <Tooltip
          placement="top"
          title={contentStore.cmsStore.getContent('ProductsCount')?.tooltip}
          color="#2d2d2d"
          overlayInnerStyle={overlayInnerStyle}
        >
          {contentStore.cmsStore.getContent('ProductsCount')?.content}
        </Tooltip>
      ),
      width: showContactPerson ? '15%' : '20%',
      align: 'right',
      render: (row: SubAccountsListItem) =>
        row.isParent ? '-' : <NumberOfIdentifiersOnAccount accountId={row.id!} />
    },
    ...(showContactPerson ? contactPersonColumns : [])
  ];

  const isRowDisabled = (row: SubAccountsListItem) =>
    !row.isCurrentPersonRelatedToAccount ||
    (selectedUserRole !== UserRole.ParentAccountAdmin && row.isParent) ||
    selectedUserRole === UserRole.ParentAccountAdmin;

  const frameAccountId = accountsWithAccessRights.find((x) => x.isParent)?.id;

  const selectedAccountIdsForRole = () => {
    if (selectedUserRole === UserRole.ParentAccountAdmin) {
      return accountsWithAccessRights.map((x) => x.id!);
    }
    return selectedAccountIds
      .filter(
        (x) =>
          frameAccountId === undefined ||
          (x !== frameAccountId && selectedUserRole === UserRole.SubAccountAdmin)
      )
      .slice();
  };

  const rowSelection = {
    selectedRowKeys: selectedAccountIdsForRole() as React.Key[],
    getCheckboxProps: (row: SubAccountsListItem) => ({
      disabled: isRowDisabled(row)
    }),
    onSelect: addOrRemoveSelectedAccount,
    onSelectAll: selectAll,
    hideSelectAll: hideAllSelector
  };

  return (
    <BrobizzTable
      dataSource={accountsWithAccessRights}
      rowKey="id"
      columns={columns}
      showSorterTooltip={false}
      pagination={pagination}
      loading={{
        spinning: isLoading,
        indicator: <LoadingSpinner />
      }}
      sticky
      scroll={{ x: true }}
      rowSelection={rowSelection}
      locale={{
        emptyText: (
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <ManchetContentCamptonBook22Grey>
              {contentStore.cmsStore.getContent('SearchYieldedNoResultsDescription')?.content}
            </ManchetContentCamptonBook22Grey>
          </Row>
        )
      }}
    />
  );
};

export default observer(AccountAccessTable);

AccountAccessTable.defaultProps = {
  showContactPerson: true
};
