/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router';
import RouteUrlPaths from '../../RouteUrlPaths';
import UserStore from '../../stores/UserStore';
import { UserRole } from '../../swagger/api';

interface ProtectedAdministrationRouteProps {
  userStore: UserStore;
}

const ProtectedAdministrationRoute: FC<ProtectedAdministrationRouteProps> = (props) => {
  const { userStore, children } = props;
  if (userStore.person?.role === UserRole.ProductAccess) {
    return <Navigate to={RouteUrlPaths.Frontpage} />;
  }
  return <>{children}</>;
};

export default ProtectedAdministrationRoute;
