/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { action, makeObservable, observable } from 'mobx';
import {
  Country,
  IdentifierInfo,
  Order,
  OrderLine,
  OrderService,
  ProductType
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class PrivateOrderStore extends BaseStore {
  productType?: ProductType = undefined;

  productReference?: string;

  licensePlate?: string;

  countryIsoCode?: string = 'DK';

  isInformationConfirmed?: boolean;

  isLicensePlateConfirmed: boolean = false;

  setIsInformationConfirmed = (isConfirmed: boolean) => {
    this.isInformationConfirmed = isConfirmed;
  };

  setIsLicensePlateConfirmed = (isConfirmed: boolean) => {
    this.isLicensePlateConfirmed = isConfirmed;
  };

  setLicensePlate = (licensePlate?: string) => {
    this.licensePlate = licensePlate?.toUpperCase();
  };

  setCountryIsoCode = (countryIsoCode: string) => {
    this.countryIsoCode = countryIsoCode;
  };

  setProductReference = (reference: string) => {
    this.productReference = reference;
  };

  setProductType(productType: ProductType) {
    this.productType = productType;
    this.rootStore.orderStore.submitOrderStore.order.productType = productType;
  }

  submitOrder = (fallBackOrderFlowStep: number) => {
    const accountId = this.rootStore.userStore.currentAccount?.id!;
    const order: Order = {};
    order.isDefaultAddress = true;
    const countryId = this.rootStore.contentStore.genericContentStore.allCountries.find(
      (country: Country) => country.isoCode === this.countryIsoCode
    )?.id;
    order.accountId = accountId;
    order.productType = this.productType;
    const orderLines: OrderLine[] = [{}];
    const identifierInfo: IdentifierInfo = {
      reference: this.productReference,
      licensePlate: this.licensePlate,
      countryId,
      productType: this.productType,
      accountId,
      paymentMethodId: this.rootStore.paymentCardStore.paymentMethods[0].id,
      brand: this.rootStore.productStore.productVehicleStore.carBrand,
      model: this.rootStore.productStore.productVehicleStore.carModel,
      color: this.rootStore.productStore.productVehicleStore.carColor
    };
    orderLines[0].identifierInfo = identifierInfo;
    order.orderLines = orderLines;
    const body: Order = order;
    const promise = OrderService.createPrivateOrder({
      accountId,
      body
    });
    promise.catch(() => {
      this.rootStore.orderStore.orderFlowStepperStore.setStepCount(fallBackOrderFlowStep);
    });
    promise.then(() => {
      this.rootStore.productStore.productVehicleStore.cleanStore();
    });
    return promise;
  };

  resetOrderFlow = () => {
    this.rootStore.orderStore.orderFlowStepperStore.stepCount = 0;
    this.productType = undefined;
    this.productReference = undefined;
    this.licensePlate = undefined;
    this.countryIsoCode = 'DK';
    this.isInformationConfirmed = undefined;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      countryIsoCode: observable,
      productType: observable,
      productReference: observable,
      licensePlate: observable,
      isInformationConfirmed: observable,
      isLicensePlateConfirmed: observable,
      setCountryIsoCode: action,
      setIsInformationConfirmed: action,
      setLicensePlate: action,
      setProductReference: action,
      setProductType: action,
      submitOrder: action,
      setIsLicensePlateConfirmed: action
    });
  }
}
