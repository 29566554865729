/* eslint-disable react/jsx-no-useless-fragment */
import React, { ChangeEvent, FC } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { SearchGreenIcon, RemoveIcon } from '../../common-components/StyledIcons';

const StyledRemove = styled(RemoveIcon)`
  margin-top: 14px;
  margin-bottom: 8px;
`;

const StyledInput = styled(Input)<{
  isAccountSearchBar?: boolean;
}>`
  border-radius: 100px;
  background: ${({ isAccountSearchBar }) =>
    isAccountSearchBar ? 'rgba(255, 255, 255, 0.05) !important' : '#efefef'};
  height: 52px;
  padding-right: 20px;
  padding-left: 28px;
  ${({ isAccountSearchBar }) =>
    isAccountSearchBar ? 'caret-color: #fff; opacity: 1 !important;' : ''}
  &:hover {
    background: #efefef;
  }

  .ant-input {
    font-size: 15px;
    color: ${({ isAccountSearchBar }) => (isAccountSearchBar ? '#fff' : '#2d2d2d')};
    font-weight: 400;
    line-height: 18px;
    ${({ isAccountSearchBar }) =>
      isAccountSearchBar ? 'opacity: 1;' : '::placeholder { color: #747474; }'}
  }
`;

interface SearchBarProps {
  searchPlaceholder: string;
  searchVal: string;
  setSearchVal: (value: string) => void;
  searchFunction: () => void;
  isAccountSearchBar?: boolean;
}

const SearchBar: FC<SearchBarProps> = (props) => {
  const { setSearchVal, searchFunction, searchVal, searchPlaceholder, isAccountSearchBar } = props;

  const typingEvent = (e: ChangeEvent) => {
    const searchString = (e.target as HTMLTextAreaElement).value;
    setSearchVal(searchString);
    if (searchString === '') {
      searchFunction();
    }
  };

  const suffixIcon = searchVal ? null : <SearchGreenIcon onClick={searchFunction} />;

  return (
    <StyledInput
      isAccountSearchBar={isAccountSearchBar!}
      maxLength={100}
      bordered={false}
      placeholder={searchPlaceholder}
      suffix={suffixIcon}
      value={searchVal}
      allowClear={{ clearIcon: <StyledRemove /> }}
      onPressEnter={() => searchFunction()}
      onChange={(val) => typingEvent(val)}
    />
  );
};

SearchBar.defaultProps = {
  isAccountSearchBar: undefined
};

export default SearchBar;
