import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { ProductInfo } from '../../../../swagger/api';
import { BrobizzTable } from '../../../../common-components/BrobizzTable';
import { H5CamptonBold30, ManchetContentCamptonBook22Grey } from '../../../../layout/globalFonts';
import ContentStore from '../../../../stores/ContentStore';
import TransactionsStore from '../../../../stores/TransactionsStore';
import useTransactionsAndUsageColumns from '../../../../hooks/useTransactionsAndUsageColumns';
import LoadingSpinner from '../../../../common-components/LoadingSpinner';
import useFetchListDataWithFunctionParameters from '../../../../hooks/fetchDataHooks/useFetchListDataWithFunctionParameters';

interface ProductCardTransactionsAndUsageTableProps {
  transactionsStore: TransactionsStore;
  contentStore: ContentStore;
  product: ProductInfo;
}

const ProductCardTransactionsAndUsageTable: FC<ProductCardTransactionsAndUsageTableProps> = (
  props
) => {
  const { transactionsStore, contentStore, product } = props;
  const isTableOnProductCard = true;
  const showOutstandingOnly = false;

  const isLoading = useFetchListDataWithFunctionParameters(
    transactionsStore.identifierTransactions,
    true,
    transactionsStore.getIdentifierTransactions,
    product.identifierId!
  );

  const columns = useTransactionsAndUsageColumns(
    contentStore,
    isTableOnProductCard,
    showOutstandingOnly
  );

  return (
    <>
      <Row>
        <H5CamptonBold30>{contentStore.cmsStore.getContent('Trips')?.content}</H5CamptonBold30>
      </Row>
      <Row>
        <Col span={24}>
          <BrobizzTable
            dataSource={transactionsStore.identifierTransactions}
            rowKey="id"
            columns={columns}
            pagination={false}
            showSorterTooltip={false}
            sticky
            loading={{
              spinning: isLoading,
              indicator: <LoadingSpinner />
            }}
            scroll={{ x: true }}
            locale={{
              emptyText: (
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                  <ManchetContentCamptonBook22Grey>
                    {
                      contentStore.cmsStore.getContent('HereYouWillSeeConsumptionWhenRegistered')
                        ?.content
                    }
                  </ManchetContentCamptonBook22Grey>
                </Row>
              )
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(ProductCardTransactionsAndUsageTable);
