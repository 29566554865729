/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import { FormInstance } from 'antd';
import { action, makeObservable, observable } from 'mobx';
import {
  PaymentType,
  Customer,
  DrivingVehicleType,
  AccountService,
  CvrService
} from '../swagger/api';
import BaseStore from './base-stores/BaseStore';
import ContentStore from './ContentStore';
import { RootStore } from './RootStore';

export default class CustomerCreationStore extends BaseStore {
  currentStep: number = 1;

  RegisterCustomer = {} as Customer;

  paymentType: PaymentType | undefined;

  isDisplayingFooter: boolean = true;

  isDisplayingSlider: boolean = true;

  setCustomerInfo = (cvrNumber: string, form: FormInstance) => {
    if (cvrNumber !== undefined) {
      CvrService.getCvrInfo({ cvrNumber }).then((e) => {
        form.setFieldsValue({
          companyName: e.companyName,
          streetName: e.streetName,
          addressLineSecond: e.addressLineSecond,
          zipCode: e.zipCode,
          cityName: e.cityName
        });
      });
      this.currentStep = 1;
    }
  };

  setCustomerData = (form: FormInstance, contentStore: ContentStore) => {
    if (this.currentStep === 1) {
      this.setCustomerDataFirstPage(form, contentStore);
    }
    if (this.currentStep === 2) {
      this.setCustomerDataSecondPage(form);
    }
    if (this.currentStep === 3 && this.paymentType === PaymentType.Invoice) {
      this.setCustomerDataThirdPage(form);
    }
  };

  setCustomerDataFirstPage = (form: FormInstance, contentStore: ContentStore) => {
    const customerInfo = JSON.parse(
      JSON.stringify(form.getFieldsValue(['vehicleType', 'registerBusinessFormPolicy']))
    );
    const info = JSON.parse(
      JSON.stringify(
        form.getFieldsValue([
          'companyName',
          'streetName',
          'addressLineSecond',
          'cityName',
          'zipCode',
          'countryName',
          'cvr'
        ])
      )
    );
    this.RegisterCustomer.accountRequest = info;
    // eslint-disable-next-line default-case
    switch (customerInfo.vehicleType) {
      case 'LVE':
        this.RegisterCustomer.accountRequest!.vehicleType = DrivingVehicleType.LVE;
        break;
      case 'HGV':
        this.RegisterCustomer.accountRequest!.vehicleType = DrivingVehicleType.HGV;
        break;
    }
    this.RegisterCustomer.accountRequest!.isAcceptedPolicy =
      customerInfo.registerBusinessFormPolicy;
    const country = contentStore.genericContentStore.allCountries.filter(
      (e) => e.name === this.RegisterCustomer.accountRequest?.countryName
    );
    if (this.RegisterCustomer.accountRequest !== undefined) {
      this.RegisterCustomer.accountRequest.isoCode = country[0].isoCode!;
      this.RegisterCustomer.accountRequest.countryId = country[0].id!;
    }
  };

  setCustomerDataSecondPage = (form: FormInstance) => {
    const payment = JSON.parse(
      JSON.stringify(form.getFieldsValue(['paymentMethod', 'isAcceptPaymentTypePolicy']))
    );
    this.paymentType = payment.paymentMethod;
    this.RegisterCustomer.paymentType = payment.paymentMethod;
    this.RegisterCustomer.isAcceptPaymentTypePolicy = payment.isAcceptPaymentTypePolicy;
  };

  setCustomerDataThirdPage = (form: FormInstance) => {
    const declarationOfConsent = JSON.parse(
      JSON.stringify(
        form.getFieldsValue([
          'ExpectMinimumSpendingAmount',
          'UnderstandCreditApprovalProcess',
          'UnderstandBrobizzCheckUsage',
          'amount'
        ])
      )
    );

    this.RegisterCustomer.declarationOfConsent = declarationOfConsent;
  };

  swipeToNextPage = (form: FormInstance, contentStore: ContentStore) => {
    this.setCustomerData(form, contentStore);
    if (
      this.currentStep === 1 ||
      (this.currentStep === 2 && this.paymentType !== PaymentType.CreditCard)
    ) {
      this.currentStep += 1;
      return Promise.resolve();
    }
    if (this.currentStep === 2 && this.paymentType === PaymentType.CreditCard) {
      this.currentStep += 1;
      return Promise.resolve();
    }
    if (this.currentStep === 3 && this.paymentType === PaymentType.Invoice) {
      const promise = this.createCustomer(this.RegisterCustomer).then(
        (customerIdAndCustomerNumber) => {
          this.rootStore.paymentCardStore
            .createInvoicePaymentMethod(customerIdAndCustomerNumber.customerId!)
            .then(() => {
              this.rootStore.customerServiceStore.requestToApproveInvoiceCustomer(
                customerIdAndCustomerNumber.customerNumber!,
                form.getFieldValue('cvr'),
                form.getFieldValue('companyName'),
                form.getFieldValue('amount')
              );
            });
        }
      );
      return promise.then(() => (this.currentStep += 1));
    }
    return Promise.resolve();
  };

  swipeToPreviousPage = () => {
    if (this.currentStep !== 1) {
      this.currentStep -= 1;
    }
  };

  createCustomer = (customer: Customer) => {
    const promise = AccountService.createCustomer({ body: customer }).then(
      (customerIdAndCustomerNumber) => {
        this.paymentType = customer.paymentType;
        return customerIdAndCustomerNumber;
      }
    );
    return promise;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentStep: observable,
      paymentType: observable,
      isDisplayingFooter: observable,
      isDisplayingSlider: observable,
      swipeToPreviousPage: action,
      swipeToNextPage: action
    });
  }
}
