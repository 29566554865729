import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { GreenLinkText } from '../layout/globalFonts';
import ContentStore from '../stores/ContentStore';

const StickyRow = styled(Row)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 14px 0;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.153);
`;

const CancelChangesLinkCol = styled(Col)`
  cursor: pointer;
  padding-top: 14px;
`;

interface StickySaveRowProps {
  undoChangesClick?: () => void;
  saveButtonWithClick: React.ReactNode;
  contentStore: ContentStore;
}

const StickySaveRow: FC<StickySaveRowProps> = (props) => {
  const { undoChangesClick, saveButtonWithClick, contentStore } = props;

  return (
    <StickyRow justify="space-between">
      <CancelChangesLinkCol xs={{ span: 24, order: 2 }} md={{ span: 5, order: 1 }}>
        <Row justify="center">
          {undoChangesClick && (
            <GreenLinkText onClick={() => undoChangesClick()}>
              {contentStore.cmsStore.getContent('UndoChanges')?.content}
            </GreenLinkText>
          )}
        </Row>
      </CancelChangesLinkCol>
      <Col xs={{ span: 24, order: 1 }} md={{ span: 3, order: 2 }}>
        <Row justify="center">{saveButtonWithClick}</Row>
      </Col>
      <Col xs={0} md={{ span: 5, order: 3 }} />
    </StickyRow>
  );
};

export default StickySaveRow;

StickySaveRow.defaultProps = {
  undoChangesClick: undefined
};
