import { Row } from 'antd';
import styled from 'styled-components';
import React, { FC } from 'react';
import CustomModal from '../../../../common-components/CustomModal';
import ContentStore from '../../../../stores/ContentStore';
import { PaymentMethod } from '../../../../swagger/api';
import { openSnackbar } from '../../../notifications/Snackbar';
import { ReactComponent as ConfirmIcon } from '../../../../layout/icons/green-circle-with-checkmark.svg';
// eslint-disable-next-line import/no-cycle
import PaymentCardCard from '../../../product-features/product-card/components/PaymentCardCard';

const MobileIcon = styled(ConfirmIcon)`
  width: 56px;
  height: 56px;
`;

interface PaymentCardCreatedModalProps {
  contentStore: ContentStore;
  paymentMethod: PaymentMethod;
  isUpdate: boolean;
  closeModal: () => void;
  isFromOrderFlow: boolean;
}

const PaymentCardCreatedModal: FC<PaymentCardCreatedModalProps> = (props) => {
  const { contentStore, paymentMethod, isUpdate, closeModal, isFromOrderFlow } = props;

  const snackbarText = isUpdate
    ? contentStore.cmsStore.getContent('PaymentCardUpdated')?.content!
    : contentStore.cmsStore
        .getContent('PaymentCardAdded')
        ?.content!.replace('{maskedNumbers}', paymentMethod.cardNumber!);

  const primaryButtonText = isFromOrderFlow
    ? contentStore.cmsStore.getContent('ApplyNewCreditCard')?.content!
    : contentStore.cmsStore.getContent('Ok')?.content;

  return (
    <CustomModal
      header={contentStore.cmsStore.getContent('PaymentCardCreated')?.content!}
      contentText={contentStore.cmsStore.getContent('PaymentCardCreated')?.subText!}
      closable
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={() => {
        closeModal();
        openSnackbar(snackbarText!);
      }}
      topIconDesktop={<ConfirmIcon />}
      topIconMobile={<MobileIcon />}
    >
      <Row justify="center" style={{ marginBottom: '50px' }}>
        <PaymentCardCard contentStore={contentStore} paymentMethod={paymentMethod} />
      </Row>
    </CustomModal>
  );
};

export default PaymentCardCreatedModal;
