/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { makeObservable, action, observable, runInAction } from 'mobx';
import {
  PaymentMethod,
  PaymentMethodService,
  IdentifierService,
  PaymentType,
  PaymentCardStatus
} from '../../swagger/api';
import BaseStore from '../base-stores/BaseStore';
import { RootStore } from '../RootStore';

export default class AdministrationPaymentCardsStore extends BaseStore {
  paymentCards?: PaymentMethod[] = [];

  validPaymentCardsOwnedByLoggedInPerson: PaymentMethod[] = [];

  pageSize: number = 0;

  paymentCardsPageNumber: number = 1;

  filteredPaymentCards?: number = 0;

  paymentCardSelectedInDropdown?: PaymentMethod;

  getProductsOnPaymentCard = (paymentCardId: string) => {
    const promise = IdentifierService.getIdentifiersByPaymentMethod({
      paymentMethodId: paymentCardId
    });
    return promise;
  };

  isValidPaymentCard = (paymentMethod: PaymentMethod) =>
    paymentMethod.type === PaymentType.CreditCard &&
    (paymentMethod.paymentCardStatus === PaymentCardStatus.ValidCard ||
      paymentMethod.paymentCardStatus === PaymentCardStatus.CardAboutToExpire);

  getValidPaymentCardsOwnedByLoggedInPerson = () => {
    const promise = PaymentMethodService.getPaymentMethodsByPersonId({
      personId: this.rootStore.userStore.person?.id!
    });
    promise.then((result: PaymentMethod[]) => {
      runInAction(() => {
        this.validPaymentCardsOwnedByLoggedInPerson = result.filter(
          (paymentMethod: PaymentMethod) => this.isValidPaymentCard(paymentMethod)
        );
      });
    });
    return promise;
  };

  getPaymentCardsInfo = () => {
    this.rootStore.filterStore.paymentCardsFilterStore.setRequestParams();
    const params = this.rootStore.filterStore.paymentCardsFilterStore.requestParams;
    params.accountId = this.rootStore.userStore.currentAccount?.id!;
    const promise = PaymentMethodService.getPaginatedCreditCardPaymentMethodsByAccountId({
      accountId: params.accountId,
      paymentAgreementOwnerPersonId: params.paymentAgreementOwnerPersonId,
      cardTypes: params.cardType,
      cardStatuses: params.cardStatus,
      searchString: params.searchString,
      pageNumber: this.paymentCardsPageNumber,
      pageSize: undefined
    });
    promise.then((result) => {
      this.paymentCards = result.paginatedItems;
      this.filteredPaymentCards = result.filteredCount;
      this.pageSize = result.pageSize!;
    });
    return promise;
  };

  setPaymentCardSelectedInDropdown = (selectedCard: PaymentMethod | undefined) => {
    this.paymentCardSelectedInDropdown = selectedCard;
  };

  setCurrentPage = (pageNumber: number) => {
    this.paymentCardsPageNumber = pageNumber;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      paymentCards: observable,
      pageSize: observable,
      paymentCardsPageNumber: observable,
      filteredPaymentCards: observable,
      paymentCardSelectedInDropdown: observable,
      validPaymentCardsOwnedByLoggedInPerson: observable,
      getValidPaymentCardsOwnedByLoggedInPerson: action,
      getPaymentCardsInfo: action,
      getProductsOnPaymentCard: action,
      setCurrentPage: action,
      setPaymentCardSelectedInDropdown: action
    });
  }
}
