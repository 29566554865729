import React, { FC } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react-lite';
import lottieSpinner from '../layout/spinner.json';
import LoadingStore from '../stores/LoadingStore';
import useStore from '../hooks/useStore';

const WrapperDiv = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

enum Size {
  large = 'lg',
  medium = 'md',
  small = 'sm',
  extraSmall = 'xs'
}

interface SpinnerProps {
  justify?: string;
  global?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Spinner: FC<SpinnerProps> = ({ justify, global, size }) => {
  const loadingStore: LoadingStore = useStore(LoadingStore);

  let width = 200;
  let height = 200;

  if (size === Size.large) {
    width = 200;
    height = 200;
  } else if (size === Size.medium) {
    width = 100;
    height = 100;
  } else if (size === Size.small) {
    width = 50;
    height = 50;
  } else if (size === Size.extraSmall) {
    width = 25;
    height = 25;
  }

  const Loading = (
    <WrapperDiv justify={justify}>
      <Lottie
        animationData={lottieSpinner}
        color="white"
        style={{
          height: `${height}px`,
          width: `${width}px`,
          position: global ? 'fixed' : 'static',
          left: global ? `calc(50% - ${width / 2}px)` : 'undefined',
          top: global ? `calc(50% - ${width / 2}px)` : 'undefined',
          zIndex: global ? '99999' : 'auto'
        }}
        loop
      />
    </WrapperDiv>
  );

  return loadingStore.showGlobalSpinner && global ? Loading : !global ? Loading : null;
};

Spinner.defaultProps = {
  justify: 'center',
  global: false,
  size: Size.large
};

export default observer(Spinner);
