/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import RouteUrlPaths from '../../RouteUrlPaths';
import ManageUserAccessStore from '../../stores/ManageUserAccessStore';

interface ProtectedUsagePaymentOrderRouteProps {
  manageUserAccessStore: ManageUserAccessStore;
}

const ProtectedUsagePaymentOrderRoute: FC<ProtectedUsagePaymentOrderRouteProps> = (props) => {
  const { manageUserAccessStore, children } = props;
  if (!manageUserAccessStore.showPagesUsageOrderTransactions()) {
    return <Navigate to={RouteUrlPaths.Frontpage} />;
  }
  return <>{children}</>;
};

export default ProtectedUsagePaymentOrderRoute;
