import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { ContentSmallCamptonBook } from '../globalFonts';

const SubHeader = styled.div`
  margin-bottom: 22px;
`;

interface PageSubHeaderProps {
  subText: React.ReactNode;
}

const PageSubHeader: FC<PageSubHeaderProps> = (props) => {
  const { subText } = props;
  return (
    <SubHeader>
      <Row>
        <Col>
          <ContentSmallCamptonBook>{subText}</ContentSmallCamptonBook>
        </Col>
        <Col flex="auto" />
      </Row>
    </SubHeader>
  );
};

export default PageSubHeader;
