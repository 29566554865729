/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactElement } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import MenuStore from '../../../stores/MenuStore';
import RouteUrlPaths from '../../../RouteUrlPaths';
import useStore from '../../../hooks/useStore';
import UserStore from '../../../stores/UserStore';
import { UserRole } from '../../../swagger/api';
import CMSStore from '../../../stores/content-stores/CMSStore';

const StyledMenuItem = styled.div<{
  isselected: boolean;
  isparent: boolean;
  iscommercialorderitem: boolean;
  isproductaccessitem: boolean;
  isbizzandnumberplates: boolean;
  isprivatepaymentcarditem: boolean;
  isprivatefrontpage: boolean;
}>`
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  ${({ iscommercialorderitem, isprivatepaymentcarditem }) =>
    (iscommercialorderitem || isprivatepaymentcarditem) && 'margin-bottom: 50px'};
  ${({ isbizzandnumberplates }) => isbizzandnumberplates && 'height: 58px'};
  ${({ isproductaccessitem }) => isproductaccessitem && 'margin-bottom: 50px'};
  ${({ isprivatefrontpage }) => isprivatefrontpage && 'opacity: .8'};

  :hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const IconCol = styled(Col)<{
  isselected: boolean;
  isprivatefrontpage: boolean;
}>`
  padding-left: 5px;
  margin-top: 6px;

  & g {
    ${({ isselected }) => (isselected ? 'opacity: 1' : 'opacity: .5')};
    ${({ isprivatefrontpage }) => isprivatefrontpage && 'opacity: .5'};
  }
`;

export type MenuItemType = {
  key: RouteUrlPaths;
  icon: React.ReactNode;
  label: ReactElement;
} | null;

interface MenuItemProps {
  isSelected: boolean;
  isParent: boolean;
  menuItem: MenuItemType;
  menuStore: MenuStore;
  cmsStore: CMSStore;
}

const MenuItem: FC<MenuItemProps> = (props) => {
  const { menuItem, isSelected, menuStore, isParent, cmsStore } = props;
  const userStore: UserStore = useStore(UserStore);
  const { manageUserAccessStore } = cmsStore.rootStore;
  const isPrivateCustomer = manageUserAccessStore.isPrivateCustomer();
  const routeKey = menuItem?.key;

  return (
    <StyledMenuItem
      isselected={isSelected}
      isparent={isParent}
      isprivatefrontpage={routeKey === RouteUrlPaths.Frontpage}
      isprivatepaymentcarditem={routeKey === RouteUrlPaths.PaymentCard}
      iscommercialorderitem={routeKey === RouteUrlPaths.Order && !isPrivateCustomer}
      isbizzandnumberplates={routeKey === RouteUrlPaths.PrivateProducts}
      isproductaccessitem={
        routeKey === RouteUrlPaths.CommercialProducts &&
        userStore.person?.role === UserRole.ProductAccess
      }
    >
      <Row wrap={false} align={RouteUrlPaths.PrivateProducts === routeKey ? 'top' : 'middle'}>
        <IconCol isselected={isSelected} isprivatefrontpage={routeKey === RouteUrlPaths.Frontpage}>
          {menuItem?.icon}
        </IconCol>
        <Col style={{ paddingLeft: '18px' }}>{menuItem?.label}</Col>
      </Row>
    </StyledMenuItem>
  );
};

export default MenuItem;
