import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import CustomModal from '../../../../common-components/CustomModal';
import useStore from '../../../../hooks/useStore';
import AdministrationStore from '../../../../stores/AdministrationStore';
import ContentStore from '../../../../stores/ContentStore';
import ModalStore from '../../../../stores/ModalStore';
import { PaymentMethod } from '../../../../swagger/api';
// eslint-disable-next-line import/no-cycle
import PaymentCardCard from '../../../product-features/product-card/components/PaymentCardCard';
// eslint-disable-next-line import/no-cycle
import PaymentCardUpdatedModal from './PaymentCardUpdatedModal';
import SelectPaymentCardDropdown from '../SelectPaymentCardDropdown';
import CreateNewPaymentCardModal from './CreateNewPaymentCardModal';

const CardRow = styled(Row)`
  margin-bottom: 26px;
`;

const DropdownRow = styled(Row)`
  margin-bottom: 50px;
`;

interface UpdatePaymentCardDropdownModalProps {
  contentStore: ContentStore;
  productId?: string;
  paymentCard: PaymentMethod;
  modalStore: ModalStore;
}

const UpdatePaymentCardDropdownModal: FC<UpdatePaymentCardDropdownModalProps> = (props) => {
  const { contentStore, productId, paymentCard, modalStore } = props;
  const administrationStore: AdministrationStore = useStore(AdministrationStore);
  const { paymentCardStore } = contentStore.rootStore;
  const { productItemsStore } = contentStore.rootStore.productStore;
  const { administrationPaymentCardsStore } = administrationStore;
  const [isLoading, setIsloading] = useState(false);

  const openCardIsUpdatedModal = () =>
    modalStore.openModal(
      <PaymentCardUpdatedModal
        contentStore={contentStore}
        oldPaymentCard={paymentCard}
        newPaymentCard={administrationPaymentCardsStore.paymentCardSelectedInDropdown!}
        closeModal={modalStore.closeModal}
      />
    );

  const updateCardAndOpenModal = () => {
    let promise;
    setIsloading(true);
    if (productId) {
      promise = paymentCardStore
        .setPaymentCardOnProduct(
          productId,
          administrationPaymentCardsStore.paymentCardSelectedInDropdown!.id!
        )
        .then(async () => {
          await productItemsStore.getProduct(productId, 'TollingBoxConversion');
        });
    } else {
      promise = paymentCardStore.updatePaymentCard(
        paymentCard.id!,
        administrationPaymentCardsStore.paymentCardSelectedInDropdown!.id!
      );
    }
    promise.then(() => {
      modalStore.closeModal();
      openCardIsUpdatedModal();
    });
    promise.finally(() => setIsloading(false));
  };

  const createNewCardOnGreenLinkclick = () => {
    if (productId) {
      modalStore.closeModal();
      modalStore.openModal(
        <CreateNewPaymentCardModal modalStore={modalStore} identifierId={productId} />
      );
    } else {
      modalStore.closeModal();
      modalStore.openModal(
        <CreateNewPaymentCardModal modalStore={modalStore} oldPaymentCard={paymentCard} />
      );
    }
  };

  return (
    <CustomModal
      header={contentStore.cmsStore.getContent('UpdatePaymentCard')?.content!}
      contentText={contentStore.cmsStore.getContent('UpdatePaymentCard')?.subText!}
      closable
      isLoading={isLoading}
      mobileViewSecondaryButton
      onCloseClick={() =>
        administrationPaymentCardsStore.setPaymentCardSelectedInDropdown(undefined)
      }
      primaryButtonText={contentStore.cmsStore.getContent('Choose')?.content}
      onPrimaryButtonClick={() => updateCardAndOpenModal()}
      greenLinkText={contentStore.cmsStore.getContent('CreateNewPaymentCard')?.content}
      onGreenLinkClick={() => createNewCardOnGreenLinkclick()}
      isPrimaryButtonDisabled={!administrationPaymentCardsStore.paymentCardSelectedInDropdown}
    >
      <CardRow justify="center">
        <PaymentCardCard paymentMethod={paymentCard} contentStore={contentStore} />
      </CardRow>

      <DropdownRow>
        <SelectPaymentCardDropdown
          administrationPaymentCardsStore={administrationPaymentCardsStore}
          contentStore={contentStore}
        />
      </DropdownRow>
    </CustomModal>
  );
};

export default observer(UpdatePaymentCardDropdownModal);

UpdatePaymentCardDropdownModal.defaultProps = {
  productId: undefined
};
