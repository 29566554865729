const dateFormatting = (dateTime: Date) => {
  const modifiedDate = new Date(dateTime);
  const year = modifiedDate.getFullYear().toString().slice(2);
  const month =
    modifiedDate.getMonth() + 1 < 10
      ? `0${modifiedDate.getMonth() + 1}`
      : modifiedDate.getMonth() + 1;
  const date = modifiedDate.getDate() < 10 ? `0${modifiedDate.getDate()}` : modifiedDate.getDate();
  return `${date}.${month}.${year}`;
};

export default dateFormatting;
