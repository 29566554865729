/* eslint-disable import/no-cycle */
import BaseStore from './base-stores/BaseStore';
import BizzesForConversionFilterStore from './filter-stores/BizzesForConversionFilterStore';
import CardPaymentFilterStore from './filter-stores/CardPaymentFilterStore';
import InvoiceFilterStore from './filter-stores/InvoiceFilterStore';
import OtherTransactionFilterStore from './filter-stores/OtherTransactionFilterStore';
import PaymentCardsFilterStore from './filter-stores/paymentCardsFilterStore';
import ProductFilterStore from './filter-stores/ProductfilterStore';
import SharedDateFilterStore from './filter-stores/SharedDateFilterStore';
import TransactionFilterStore from './filter-stores/TransactionFilterStore';

export default class FilterStore extends BaseStore {
  cardPaymentFilterStore: CardPaymentFilterStore = new CardPaymentFilterStore(this.rootStore);

  invoiceFilterStore: InvoiceFilterStore = new InvoiceFilterStore(this.rootStore);

  otherTransactionFilterStore: OtherTransactionFilterStore = new OtherTransactionFilterStore(
    this.rootStore
  );

  productFilterStore: ProductFilterStore = new ProductFilterStore(this.rootStore);

  transactionFilterStore: TransactionFilterStore = new TransactionFilterStore(this.rootStore);

  sharedDateFilterStore: SharedDateFilterStore = new SharedDateFilterStore(this.rootStore);

  paymentCardsFilterStore: PaymentCardsFilterStore = new PaymentCardsFilterStore(this.rootStore);

  bizzesForConversionFilterStore: BizzesForConversionFilterStore =
    new BizzesForConversionFilterStore(this.rootStore);
}
